import AccordionItem from './AccordionItem';
import { Typography, List, ListItem, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const AccordionMenu = ({ data, selectedItem }) => {
    return (
        data && <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}
        >
            <Typography
                variant="h1"
            >
                <b>{data.group}</b>
            </Typography>
            <List>
                {
                    data?.data.map((subCategory, i) => {
                        return (
                            <ListItem
                                key={"accordion_" + i}
                                sx={{
                                    px: 0,
                                    mb: 2,
                                    flexDirection: "column"
                                }}
                            >
                                <AccordionItem
                                    data={subCategory}
                                    color={data.color}
                                    altColor={data.altColor}
                                    selectedItem={selectedItem}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Box>
    )
}

AccordionMenu.propTypes = {
    data: PropTypes.object,
    selectedItem: PropTypes.string
}

export default AccordionMenu;
