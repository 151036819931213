import React from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = ({ value, dragging, index, ...restProps }) => {
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <div>
        <Handle value={value} {...restProps} />
      </div>
    </SliderTooltip>
  );
};

handle.propTypes = {
  value: PropTypes.number,
  dragging: PropTypes.bool,
  index: PropTypes.number,
}

const RangeSlider = ({ min = 0, max = 10, onChange }) => {
  const handleOnChange = ([_min, _max]) => {
    onChange([_min, _max]);
  }

  return (
    <div style={{ margin: "50px 0 25px 0", padding: "0 15px", width: "100%" }}>
      <Range
        min={min}
        max={max}
        defaultValue={[min, max]}
        onChange={handleOnChange}
        tipFormatter={value => `${value}`}
        allowCross={false}
        handle={handle}
        tipProps={{ visible: true }}
      />
    </div>
  )
}

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func
}

export default RangeSlider;
