import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Container,
  Grid,
  Box,
  Card,
  CardHeader,
  Typography,
  Divider,
  useTheme,
  Skeleton,
  Avatar,
  Tooltip,
  Stack,
  Collapse,
  Button
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import Bread from '../../../components/Bread';
import { getTrainingEventDetail, getAvailableTrainingModuleTypes } from '../API/LJAPI';
import { trainingModuleAPI } from '../../../api/trainingModuleAPI';
import Label from '../../../components/Label';
import { useTranslation } from 'react-i18next';
import TrainingActions from '../../dashboard/trainingCatalogue/User/TrainingActions';
import _ from 'lodash';
import moment from 'moment';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import getPhotos from '../../../utils/getEmployeesPhotos';
import { convertNumToTime } from '../../../utils/utils';

const dummy = false;

const shortDateFormat = 'DD-MM-YYYY';

const TrainingDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(false);
  const [filterDetails, setFilterDetails] = useState([]);
  const [registrationStatus, setRegistrationStatus] = useState();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [photoMap, setPhotoMap] = useState(new Map());
  const [trainersToggled, setTrainersToggled] = useState(false);
  const defaultBreadcrumb = [
    { label: "Learning Journey", link: "/" }
  ];
  const location = useLocation();
  const category = location.state?.category;
  const subcategory = location.state?.subcategory.replace(/ /g, '+');

  const formatDate = (date, locale) => {
    const dateFormat = i18n.language === 'pt' ? "dd 'de' MMMM 'às' HH'h'mm" : "dd MMMM 'at' HH'h'mm";
    return format(date, dateFormat, { locale });
  };

  const statusOptions = [
    { text: t('common:status.all'), key: 'All' },
    { text: t('common:status.canceled'), key: 'X', color: theme.palette.states.fail },
    { text: t('common:status.rejected'), key: 'R', color: theme.palette.states.fail },
    { text: t('common:status.onGoing'), key: 'D', color: theme.palette.states.pending },
    { text: t('LJ:status.accepted'), key: 'Y', color: theme.palette.states.ongoing },
    { text: t('LJ:status.enroled'), key: 'S', color: theme.palette.states.pending },
    { text: t('LJ:status.concluded'), key: 'T', color: theme.palette.states.success }
  ];

  const handleGetStatus = (status) => {
    return statusOptions.find(x => x.key === status) ? statusOptions.find(x => x.key === status) : null;
  }

  const getData = async () => {
    if (!id) {
      navigate('/');
      toast.error(t("LJ:noTraining"));
    }
    setIsLoading(true);
    const result = dummy ? await getTrainingEventDetail(id) : await trainingModuleAPI.getTrainingEventDetail(id);
    setIsLoading(false);
    if (result && !result.error) {
      const _data = { ...result.value.trainingEvents[0] };
      if (!_data) {
        navigate('/');
        toast.error(t("LJ:noTraining"));
      }
      _data.registrationLimit = moment(_data.registrationLimit).format(shortDateFormat)
      _data.cancelationLimitFormatted = moment(_data.cancelationLimit).format(shortDateFormat)
      _data.communicationDate = moment(_data.communicationDate).format(shortDateFormat)
      const enroledTypes = ["P", "R"];
      const isSameOrAfter = moment(_data.cancelationLimit).isSameOrAfter(moment(), "day");
      _data.canEnrole = _data.eventStatus === "P" && isSameOrAfter;
      _data.canCancel = !enroledTypes.includes(_data.registrationStatus) && isSameOrAfter;
      setData(_data);
      setRegistrationStatus(handleGetStatus(_data.registrationStatus === "P" && (moment(_data.registrationLimit).isSame(moment(), "day") || moment(_data.registrationLimit).isAfter(moment())) ? null : _data.registrationStatus))
    } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
      result.exceptionMessages.messages.forEach(m => toast.error(m.description));
    } else {
      toast.error(t("common:common.errors.unexpected"));
    }
  }

  const handleGetFilterDetails = async () => {
    if (!id) {
      navigate('/');
      toast.error(t("LJ:noTraining"));
    }
    const result = await getAvailableTrainingModuleTypes();
    if (result && !result.error) {
      setFilterDetails(result.value.filter(x => !x.hidden));
    } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
      result.exceptionMessages.messages.forEach(m => toast.error(m.description));
    } else {
      toast.error(t("common:common.errors.unexpected"));
    }
  }

  const listDates = (training) => {
    const dates = [];
    training?.trainingEventSessions?.map(session => {
      const _date = formatDate(new Date(session?.startDate), i18n.language === 'pt' ? pt : en);
      dates.push(_date);
      return session;
    })
    return dates?.join(', ');
  }

  const handleGetPhotos = async (userList) => {
    if (userList) {
      const newUserList = [];
      for (const user_ of userList) {
        if (!photoMap.get(user_)) {
          newUserList.push(user_);
        }
      }
      if (newUserList.length > 0) {
        const newMap = await getPhotos(userList, photoMap);
        if (newMap) {
          setPhotoMap(newMap);
        }
      }
    }
  };

  useEffect(() => {
    getData();
    handleGetFilterDetails();
  }, []);

  useEffect(() => {
    if (data.trainingEventInstructors) {
      const usernames = data?.trainingEventInstructors?.map(x => x.trainingInstructorUsername);
      if (usernames.length > 0) {
        handleGetPhotos(usernames);
      }
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{window.platform_name + " | "}{t('LJ:trainingDetail')}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Bread
              levels={
                data?.trainingModuleName ? [
                  ...defaultBreadcrumb,
                  { label: data.mainCategoryName, link: `/?category=${category}` },
                  { label: data.subCategoryName, link: `/?category=${category}&subcategory=${subcategory}` },
                  { label: data.trainingModuleName },
                ]
                  :
                  defaultBreadcrumb
              }
              additionalContent={
                registrationStatus &&
                <Label customColor={registrationStatus.color} sx={{ mt: 2 }}>
                  {registrationStatus.text}
                </Label>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={8}
              >
                <Card
                  sx={{
                    p: 2
                  }}
                >
                  {
                    isLoading ?
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Skeleton sx={{ transform: "initial", height: 80, width: "100%" }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Skeleton sx={{ transform: "initial", height: 800, width: "100%" }} />
                        </Grid>
                      </Grid>
                      :
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          {
                            data.trainingEventName && <>
                              <Typography
                                variant="h1"
                                sx={{
                                  mb: 1
                                }}
                              >
                                <b>{data.trainingEventName}</b>
                              </Typography>
                            </>
                          }
                          <div dangerouslySetInnerHTML={{ __html: data.description }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider sx={{ py: 2 }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center"
                            }}
                            component={Button}
                            variant="outlined"
                            onClick={() => setTrainersToggled(!trainersToggled)}
                          >
                            <Typography
                              variant="h2"
                            >
                              <b>{t('LJ:trainersAndPartners')}</b>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {trainersToggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Box>
                          </Box>
                          {
                            data?.trainingEventInstructors ?
                              <Collapse
                                in={trainersToggled}
                              >
                                <Stack
                                  spacing={3}
                                  direction="column"
                                >
                                  {
                                    data.trainingEventInstructors.map((instructor, instructor_i) => {
                                      const isInternal = instructor.employeeID !== null;
                                      return (
                                        <Box
                                          key={instructor.trainingInstructorID + "_" + instructor_i}
                                        >
                                          <CardHeader
                                            avatar={
                                              isInternal ?
                                                <Avatar
                                                  src={`data:image/png;base64, ${photoMap.get(instructor.trainingInstructorUsername)}`}
                                                />
                                                :
                                                <Avatar
                                                  src={`data:image/png;base64, ${instructor.photo}`}
                                                />
                                            }
                                            sx={{
                                              pb: 0
                                            }}
                                            title={instructor.trainingInstructorName}
                                          />
                                          <Box
                                            sx={{
                                              pl: 2
                                            }}
                                          >
                                            <div dangerouslySetInnerHTML={{ __html: instructor.trainingInstructorDescription }} />
                                            {instructor.instructorHistory.length > 0 ?
                                              <>
                                                <Typography color="primary" sx={{ fontWeight: "bold" }}>{t('LJ:history')}:</Typography>
                                                {instructor.instructorHistory.map((item, index) => (
                                                  <>
                                                    <Label
                                                      key={`instructorHistory${index}`}
                                                      customColor={{
                                                        main: "transparent",
                                                        secondary: theme.palette.primary.main
                                                      }}
                                                      sx={{
                                                        textTransform: "initial !important",
                                                        fontSize: "16px",
                                                        mr: 2,
                                                        mb: 1,
                                                        mt: 1
                                                      }}
                                                    >
                                                      <b style={{ marginRight: 8 }}>{t('training:main.new')}:</b><span style={{ marginRight: 8 }}>{item.trainingEventName}</span>
                                                      <b style={{ marginRight: 8 }}>{t('LJ:workload')}:</b><span style={{ marginRight: 8 }}>{convertNumToTime(item.totalHours)}</span>
                                                      <b style={{ marginRight: 8 }}>{t('common:labels.year')}:</b><span style={{ marginRight: 8 }}>{item.trainingEventYear}</span>
                                                    </Label>
                                                  </>
                                                ))}
                                                <Divider />
                                              </>
                                              : null}
                                          </Box>
                                        </Box>)
                                    })
                                  }
                                </Stack>
                              </Collapse>
                              : <p>
                                -
                              </p>
                          }
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider sx={{ py: 2 }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center"
                            }}
                          >
                            {
                              [{
                                key: "totalHours",
                                label: t('LJ:totalHours'),
                                unit: "H"
                              },
                              {
                                key: 'credits',
                                label: t('LJ:credits')
                              },
                              {
                                key: 'registrationLimit',
                                label: t('LJ:registrationLimit')
                              },
                              {
                                key: 'cancelationLimitFormatted',
                                label: t('LJ:cancelationLimit')
                              },
                              {
                                key: 'communicationDate',
                                label: t('training:options.communicationDate')
                              }].map(x => (
                                <Label
                                  key={`trainingLabel_${x.key}`}
                                  customColor={{
                                    main: "transparent",
                                    secondary: theme.palette.primary.main
                                  }}
                                  sx={{
                                    textTransform: "initial !important",
                                    fontSize: "16px",
                                    mr: 2,
                                    mb: 1,
                                    mt: 1
                                  }}
                                >
                                  <b style={{ marginRight: 8 }}>{x.label}:</b>{data[x.key] || 0}{x.unit && x.unit}
                                </Label>
                              ))
                            }
                            <Box
                              sx={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                              }}
                            >
                              <TrainingActions
                                data={data}
                                refresh={getData}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                  }
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <Card
                  sx={{
                    p: 2,
                    backgroundColor: "lightgray"
                  }}
                >

                  <Grid
                    container
                  >
                    {
                      isLoading ?
                        <Grid
                          item
                          xs={12}
                        >
                          <Box>
                            {
                              _.times(8, i => (<Box key={'skeleton_' + i} sx={{ p: 1, display: "inline-flex", width: `${30 + Math.floor(Math.random() * 30)}%` }}><Skeleton sx={{ transform: "initial", height: 80, width: "100%" }} /></Box>))
                            }
                          </Box>
                        </Grid>
                        :
                        <Grid
                          item
                          xs={12}
                        >
                          <Box>
                            {
                              filterDetails.map((x, i) => {
                                let content = "";

                                if (x.id === 'textQuery') {
                                  content = data.canEnrole ? <span><b>{t('LJ:availability')}</b>{t('LJ:openApplications')}</span> : <span><b>{t('LJ:availability')}</b>{t('LJ:closedApplications')}</span>
                                } else if (x.id === "Dates") {
                                  content = listDates(data);
                                } else if (x.id === "trimester") {
                                  //content = data[x.id] === "T1" ? t("LJ:trimester.t1") : data[x.id] === "T2" ? t("LJ:trimester.t2") : data[x.id] === "T3" ? t("LJ:trimester.t3") : t("LJ:trimester.t4");
                                  content = data[x.id]
                                } else if (x.id === "languageTypeDesc") {
                                  //content = data[x.id] === null ? t("LJ:portuguese") : t("LJ:english");
                                  content = data[x.id]
                                } else if (x.id === "mandatory") {
                                  content = data[x.id] === false ? t('LJ:optional') : t('LJ:recommended')
                                } else if (data[x.id]) {
                                  content = data[x.id];
                                } else {
                                  content = t('LJ:metaAll');
                                }

                                return (
                                  <Label
                                    key={`trainingLabel_${x.key}_${i} `}
                                    customColor={{
                                      main: i === 0 ? theme.palette.primary.main : "whitesmoke",
                                      secondary: i === 0 ? "transparent" : "white"
                                    }}
                                    sx={{
                                      textTransform: "initial !important",
                                      fontSize: "16px",
                                      whiteSpace: "initial !important",
                                      py: theme.spacing(1),
                                      px: theme.spacing(2),
                                      m: `0px ${theme.spacing()} ${theme.spacing()} 0`,
                                      color: i === 0 ? "white" : "initial",
                                      width: i === 0 ? "100%" : "initial",
                                      maxWidth: "100%",
                                      backgroundColor: i === 0 && data.eventStatus === "P" && (moment(data.registrationLimit).isSame(moment(), "day") || moment(data.registrationLimit).isAfter(moment())) ? theme.palette.primary.main : i === 0 && (data.eventStatus === "T" || data.eventStatus === "Z") && process.env.REACT_APP_TOPBAR_COLOR
                                    }}
                                  >
                                    <b
                                      style={{
                                        color: i === 0 ? "white" : theme.palette.primary.main,
                                        marginRight: theme.spacing()
                                      }}
                                    >
                                      {x.translatedLabel}
                                    </b>
                                    <Tooltip
                                      title={content}
                                    // disableHoverListener={!data[x.id]}
                                    >
                                      <div
                                        style={{
                                          /*textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden"*/
                                        }}
                                      >
                                        {content}
                                      </div>
                                    </Tooltip>
                                  </Label>)
                              })
                            }
                          </Box>
                        </Grid>
                    }
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
};

TrainingDetail.propTypes = {
}

export default TrainingDetail;
