import { colors } from '@material-ui/core';

export default {
  styleOverrides: {
    root: {
      zIndex: 100,
      "&.Mui-disabled": {
        opacity: 0.4
      }
    },
    deletable: {
      '&:focus': {
        backgroundColor: colors.blueGrey[100]
      }
    }
  }
};
