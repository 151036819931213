import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loginAD } from '../api/apiUtils';
import { userAPI } from '../api/userAPI';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        // const accessToken = window.localStorage.getItem('accessToken');

        // if (accessToken) {
        //   const user = await authApi.me(accessToken);
        let data = null;
        if (process.env.REACT_APP_CUSTOM_AUTH === 'AZUREAD') {
          const adResponse = await loginAD();
          localStorage.setItem('session', JSON.stringify({
            accessToken: adResponse
          }));
          data = await userAPI.AzureAuthenticate();
        } else {
          const session = JSON.parse(window.localStorage.getItem('session'));
          if (session) {
            data = await userAPI.Authenticate();
          }
        }
        if (data && !data.error && data.value) {
          localStorage.setItem('session', JSON.stringify(data.value));
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                avatar: `data:image/jpeg;base64, ${data.value.photo}`,
                name: data.value.name,
                jobRole: data.value.jobRole,
                roles: data.value.roles,
                defaultRoute: data.value.defaultRoute,
                userModules: data.value.userModules
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const data = await userAPI.LdapLogin(email, password);
    // const user = await userAPI.me(data.value.accessToken);

    if (!data.error && data.value) {
      localStorage.setItem('session', JSON.stringify(data.value));
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          // avatar: `data:image/jpeg;base64, ${data.value.photo}`,
          user: {
            name: data.value.name,
            jobRole: data.value.jobRole,
            roles: data.value.roles,
            defaultRoute: data.value.defaultRoute,
            userModules: data.value.userModules
          }
        }
      });
      navigate('/');
    } else {
      throw new Error(data.description ? data.description : 'Erro: Utilizador ou password errada!');
    }
  };

  const logout = async () => {
    localStorage.removeItem('session');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
