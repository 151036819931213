import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Button, Box, Collapse, Link, useTheme, Tooltip } from '@material-ui/core';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from "prop-types";
import moment from 'moment';
import { useState } from "react";

const AccordionItem = ({ data, color, altColor, selectedItem }) => {
    const [params, setSearchParams] = useSearchParams();
    const [toggledItem, setToggledItem] = useState(params.get('subcategory') || null);
    const toggled = toggledItem === data?.group;
    const theme = useTheme();
    const { t } = useTranslation();
    const handleToggleItem = (item) => {
        setSearchParams({ category: selectedItem, subcategory: item, toggled: true })
        setToggledItem(item === toggledItem ? null : item);
    }

    const listDates = (training) => {
        const dates = [];
        training?.trainingEventSessions?.map(session => {
            const _date = moment(session?.startDate).format("DD-MM-YYYY");
            dates.push(_date);
            return session;
        })
        return dates?.join(', ');
    }

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    width: "100%",
                    maxWidth: "100%",
                    color: "white !important",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "0 !important",
                    background: color || altColor ? `${color || altColor} !important` : ""
                }}
                component={Button}
                variant="contained"
                onClick={() => handleToggleItem(data.group)}
            >
                <span style={{ textTransform: "initial" }}>
                    {data.group} ({data.trainingEvents?.filter(x => x.passes !== false).length})
                </span>
                <Box
                    sx={{
                        ml: "auto",
                        display: "flex",
                        alignItems: "center",
                        color: "white"
                    }}
                >
                    {toggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
            </Box>
            <Collapse
                in={toggled}
                sx={{
                    width: "100%"
                }}
            >
                <Box>
                    <p
                        style={{
                            background: "lightgray",
                            padding: 16,
                            margin: 0,
                            width: "100%",
                            textAlign: "justify",
                            whiteSpace: "normal"
                        }}
                    >
                        {data.trainingCategoryDescription || "Missing sub-competency description"}
                    </p>
                    <PerfectScrollbar
                        options={{
                            useBothWheelAxes: true
                        }}
                        style={{
                            display: "flex",
                            padding: "8px 0",
                            overflowX: "auto",
                            background: "rgba(255, 255, 255, 0.5"
                        }}
                    >
                        {
                            data?.trainingEvents?.length > 0 ? data.trainingEvents?.map((training, training_index) => {
                                const canEnrole = training.eventStatus === "P" && (moment(training.registrationLimit).isSame(moment(), "day") || moment(training.registrationLimit).isAfter(moment()));
                                const statusColor = canEnrole ? theme.palette.primary.main : "gray";
                                //return training.passes !== false && <Link
                                return training && <Link
                                    key={"accordionItem_" + training_index}
                                    style={{
                                        padding: 16,
                                        margin: 16,
                                        // border: "solid 1px " + (training.eventStatus === "P" ? color : altColor || theme.palette.text.primary),
                                        border: "solid 1px",
                                        borderColor: statusColor,
                                        display: "inline-block",
                                        width: "15vw",
                                        minWidth: 400,
                                        position: "relative",
                                        textDecoration: "none"
                                    }}
                                    component={RouterLink}
                                    to={{ pathname: `/detail/${training.trainingEventID}` }}
                                    state={{ data: training, category: selectedItem, subcategory: toggledItem }}
                                >
                                    <i
                                        style={{
                                            position: "absolute",
                                            top: 22,
                                            left: 0,
                                            width: 0,
                                            height: 0,
                                            borderTop: "6px solid transparent",
                                            borderBottom: "6px solid transparent",
                                            // borderLeft: "6px solid " + (color || altColor || theme.palette.text.primary)
                                            borderLeft: "6px solid " + (statusColor)

                                        }}
                                    />
                                    {/* <div style={{ color: color || altColor || theme.palette.text.primary }}><b>{training.trainingModuleName}</b></div> */}
                                    <Tooltip
                                        title={canEnrole ? training.trainingEventName : `${training.trainingEventName} (${t('LJ:closed')})`}
                                    >
                                        <div
                                            style={{
                                                color: statusColor,
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <b>{canEnrole ? training.trainingEventName : `${training.trainingEventName} (${t('LJ:closed')})`}</b>
                                        </div>
                                    </Tooltip>
                                    <br />
                                    <div
                                        style={{
                                            color: canEnrole ? theme.palette.text.primary : "gray"
                                        }}
                                    >
                                        {
                                            [
                                                {
                                                    label: t('LJ:format'),
                                                    key: "locationTypeDesc"
                                                },
                                                {
                                                    label: `${t('LJ:language')}(s)`,
                                                    key: "languageTypeDesc"
                                                },
                                                {
                                                    label: t('LJ:dates'),
                                                    key: "dates",
                                                    value: listDates(training)
                                                },
                                                {
                                                    label: t('LJ:limitDate'),
                                                    key: "registrationLimit",
                                                    value: moment(training.registrationLimit).format('DD-MM-YYYY')
                                                },
                                                {
                                                    label: t('LJ:enroleStatus'),
                                                    key: "eventStatus",
                                                    value: training.eventStatus === "P" && (moment(training.registrationLimit).isSame(moment(), "day") || moment(training.registrationLimit).isAfter(moment())) ? t('LJ:openApplications') : <b><u>{t('LJ:closedApplications')}</u></b>,
                                                },
                                            ].map(meta => (
                                                <div
                                                    key={meta.key}
                                                    style={{
                                                        whiteSpace: "initial",
                                                    }}
                                                >
                                                    <b>{meta.label}:</b> {meta.value ? meta.value : training[meta.key] || "-"}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Link>
                            })
                                :
                                <Box
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    {t('LJ:noTrainingsFound')}
                                </Box>
                        }
                    </PerfectScrollbar>
                </Box>
            </Collapse>
        </>
    )
}

AccordionItem.propTypes = {
    data: PropTypes.object,
    color: PropTypes.string,
    altColor: PropTypes.string,
    selectedItem: PropTypes.string
}

export default AccordionItem;
