import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './animBG.css';

const size = 20;
const colors = ["#656565", "#39b387", "#49ddf0"];

const AnimBG = () => {
    const [blocks, setBlocks] = useState([]);

    const selectedColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const renderBlocks = (howmany) => {
        const wha = _.times(howmany, i => <div key={i} className="animBGBlock" style={{ transform: `scale(${Math.random()})`, opacity: `${Math.random() * 0.5}`, background: selectedColor(), margin: size + "px", height: size + "px", width: size + "px", animationDelay: `${Math.floor(Math.random() * 20)}s` }} />);
        return wha;
    }

    useEffect(() => {
        if (false) {
            const w = window.innerWidth / (size * 3);
            const h = window.innerHeight / (size * 3);
            const b = renderBlocks(w * h);
            setBlocks(b);
        }
    }, []);

    return (
        <div id="animBGWrapper">
            {false && blocks}
        </div>
    )
}

export default AnimBG;
