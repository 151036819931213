import { Skeleton, TableRow, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const TableRowLoading = (props) => {
  const { headCells = [...Array(4).keys()].map(x => ({ id: x })), idx, hasBulkActions } = props;
  return (
    <TableRow key={idx}>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
        >
          <Skeleton
            variant="text"
            animation="wave"
          />
        </TableCell>
      ))}
      {
        hasBulkActions && <TableCell>
          <Skeleton
            variant="text"
            animation="wave"
          />
        </TableCell>
      }
    </TableRow>
  );
};

TableRowLoading.propTypes = {
  headCells: PropTypes.array.isRequired,
  idx: PropTypes.string,
  hasBulkActions: PropTypes.bool
};

const TableLoading = React.memo(({ isLoading, headCells, numRows = 3, hasBulkActions }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const newRows = [];
    if (isLoading) {
      for (let i = 0; i < numRows; i++) {
        newRows.push(
          <TableRowLoading
            headCells={headCells}
            idx={i.toString()}
            key={i.toString()}
            hasBulkActions={hasBulkActions}
          />
        );
      }
    }
    setRows(newRows);
  }, [isLoading, headCells]);

  return (
    isLoading && (
      rows.map((row, index) => (
        <React.Fragment key={index}>{row}</React.Fragment>
      ))
    )
  );
});

TableLoading.propTypes = {
  isLoading: PropTypes.bool,
  hasBulkActions: PropTypes.bool,
  headCells: PropTypes.array.isRequired,
  numRows: PropTypes.number.isRequired
};

export default TableLoading;
