import { createContext, useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';

const initialState = true;

const SidebarContext = createContext(initialState);

export const SidebarProvider = (props) => {
    const { children } = props;
    const [sidebarToggled, setSidebarToggled] = useState(initialState);
    const isMobile = useMediaQuery('(max-width:998px)');

    const handleToggleSidebar = (value) => {
        setSidebarToggled(typeof (value) === "boolean" ? value : !sidebarToggled);
    }

    useEffect(() => {
        if (isMobile) {
            setSidebarToggled(false);
        }
    }, [isMobile]);

    return (
        <SidebarContext.Provider
            value={{
                sidebarToggled: sidebarToggled,
                handleToggleSidebar: handleToggleSidebar
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

SidebarProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const SidebarConsumer = SidebarContext.Consumer;

export default SidebarContext;
