import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import AuthLayout from './components/authentication/AuthLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import * as userPermissions from './consts/userRoles';
import useAuth from './hooks/useCustomAuth';
import LJDashboardLayout from './pages/LJ/Layout/DashboardLayout';
import LJMainPage from './pages/LJ/Pages/MainPage';
import LJTrainingDetailPage from './pages/LJ/Pages/TrainingDetail';
import LJFAQs from './pages/LJ/Pages/FAQs';
import LJTimeline from './pages/LJ/Pages/Timeline';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = () => {
  const auth = useAuth();
  const defaultPath = auth?.user?.defaultRoute || '/dashboard/profile/myprofile';

  return [
    {
      path: "",
      element: <AuthGuard roles={userPermissions.PORTAL}>
        <LJDashboardLayout />
      </AuthGuard>,
      children: [
        {
          path: "/",
          element: <LJMainPage />
        },
        {
          path: "/timeline",
          element: <LJTimeline />
        },
        {
          path: "/FAQ",
          children: [
            {
              path: "/",
              element: <LJFAQs />
            },
            {
              path: "/:page",
              element: <LJFAQs />
            }
          ]
        },
        {
          path: "/detail",
          children: [
            {
              path: "/",
              element: <LJTrainingDetailPage />
            },
            {
              path: "/:id",
              element: <LJTrainingDetailPage />
            },
          ]
        }
      ]
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          exact: true,
          element: <Navigate to={defaultPath} />
        },
        {
          path: '*',
          element: <Navigate to="error/404" />
        }
      ]
    },
    {
      path: 'error',
      element: <AuthLayout />,
      children: [
        {
          path: '/',
          exact: true,
          element: <Navigate to={defaultPath} />
        },
        {
          path: '401',
          element: <AuthorizationRequired />
        },
        {
          path: '404',
          element: <NotFound />
        },
        {
          path: '500',
          element: <ServerError />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ];
};

export default routes;
