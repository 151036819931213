import React from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import AuthNavbar from './AuthNavbar';

const AuthLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100vh',
  overflow: 'hidden',
  width: '100%'
}));

const AuthLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px'
});

const AuthLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const AuthLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const AuthLayout = () => (
  <AuthLayoutRoot>
    <AuthNavbar />
    <AuthLayoutWrapper>
      <AuthLayoutContainer>
        <AuthLayoutContent>
          <Outlet />
        </AuthLayoutContent>
      </AuthLayoutContainer>
    </AuthLayoutWrapper>
  </AuthLayoutRoot>
);

export default AuthLayout;
