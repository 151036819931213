import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const initialFilters = {
  EMPLOYEE: [],
  PROJECT: [],
  CANDIDATES: [],
  VACANCY: [],
  STAFFREPORT: [],
  EVALUATION: [],
  CV: []
}

export const restoreFilters = () => {
  let filters = null;

  try {
    const EMPLOYEE_storedData = window.localStorage.getItem('filters.EMPLOYEE');
    const PROJECT_storedData = window.localStorage.getItem('filters.PROJECT');
    const CANDIDATES_storedData = window.localStorage.getItem('filters.CANDIDATES');
    const VACANCY_storedData = window.localStorage.getItem('filters.VACANCY');
    const EVALUATION_storedData = window.localStorage.getItem('filters.EVALUATION');
    const STAFFREPORT_storedData = window.localStorage.getItem('filters.STAFFREPORT')
    const CV_storedData = window.localStorage.getItem('filters.CV')

    filters = {
      EMPLOYEE: EMPLOYEE_storedData ? JSON.parse(EMPLOYEE_storedData) : [],
      PROJECT: PROJECT_storedData ? JSON.parse(PROJECT_storedData) : [],
      CANDIDATES: CANDIDATES_storedData ? JSON.parse(CANDIDATES_storedData) : [],
      VACANCY: VACANCY_storedData ? JSON.parse(VACANCY_storedData) : [],
      EVALUATION: EVALUATION_storedData ? JSON.parse(EVALUATION_storedData) : [],
      STAFFREPORT: STAFFREPORT_storedData ? JSON.parse(STAFFREPORT_storedData) : [],
      CV: CV_storedData ? JSON.parse(CV_storedData) : [],
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return filters;
};

export const storeFilters = (key, filters) => {
  window.localStorage.setItem(`filters.${key}`, JSON.stringify(filters));
};

const FiltersContext = createContext({
  filters: initialFilters,
  saveFilters: () => { }
});

export const FiltersProvider = (props) => {
  const { children } = props;
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const filters_ = restoreFilters();

    if (filters_) {
      setFilters(filters_);
    }
  }, []);

  const onSaveFilters = (key, appliedFilters) => {
    const _filters = { ...filters };
    _filters[key] = appliedFilters;
    setFilters(_filters);
  }

  const saveFilters = (key, updatedFilters) => {
    onSaveFilters(key, updatedFilters);
    storeFilters(key, updatedFilters);
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        saveFilters
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const FiltersConsumer = FiltersContext.Consumer;

export default FiltersContext;
