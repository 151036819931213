/* eslint-disable */
export const hexToRGB = (h, func = false) => {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  if (!func) {
    return "rgb(" + +r + "," + +g + "," + +b + ")";
  }
  return func(+r, +g, +b);
}

export const RGBToHSL = (r, g, b, func = false) => {
  // Make r, g, and b fractions of 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;
  // Calculate hue
  // No difference
  if (delta === 0)
    h = 0;
  // Red is max
  else if (cmax === r)
    h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax === g)
    h = (b - r) / delta + 2;
  // Blue is max
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0)
    h += 360;
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  if (!func) {
    return {
      h: h,
      s: s,
      l: l
    };
  }
  return func(h, s, l);
}

export const HSLToRGB = (h, s, l) => {
  // Must be fractions of 1
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;
  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return "rgb(" + r + "," + g + "," + b + ")";
}

export const shiftColor = (color, sat = 1, lum = 1) => {
  if (color === undefined) {
    return;
  }
  const hex_re = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  const rgb_re = /rgb\((\d{1,3}),\s{0,1}(\d{1,3}),\s{0,1}(\d{1,3})\)/;
  const isRgb = rgb_re.exec(color);
  const isHex = hex_re.exec(color);
  if (isHex) {
    const hsl = hexToRGB(color, RGBToHSL);
    return HSLToRGB(hsl.h, hsl.s * sat, hsl.l * lum);
  }
  if (isRgb) {
    const rgb = color.replace('rgb(', '').replace(')', '').split(',');
    const r = rgb[0];
    const g = rgb[1];
    const b = rgb[2];
    const hsl = RGBToHSL(r, g, b);
    return HSLToRGB(hsl.h, hsl.s * sat, hsl.l * lum);
  }

  return color;
}

export const changeOpacity = (color, opacity) => {
  const hex_re = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  const rgb_re = /rgb\((\d{1,3}),\s{0,1}(\d{1,3}),\s{0,1}(\d{1,3})\)/;
  const isRgb = rgb_re.exec(color);
  const isHex = hex_re.exec(color);
  const opacity_number = isNaN(opacity) ? parseInt(opacity.replace(/^\D+/g, '')) : opacity;
  if (opacity_number && !isNaN(opacity_number) && opacity_number >= 0 && opacity_number <= 100) {
    if (isHex) {
      const numHex = opacity_number.toString(16);
      const num = numHex.length === 1 ? "0" + numHex : numHex;
      return color + num.toUpperCase();
    }
    if (isRgb) {
      return color;
    }
  }

  return color;
}