import { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import CircularMenu from '../Components/CircularMenu';
import { getAvailableTrainingModules, getAvailableTrainingModuleTypes } from '../API/LJAPI';
import { trainingModuleAPI } from '../../../api/trainingModuleAPI';
import toast from 'react-hot-toast';
import FiltersContext from '../Contexts/FiltersContext';
import SidebarContext from '../Contexts/SidebarContext';
import _ from 'lodash';
//import { groupByKey } from '../../../utils/collectionUtils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const dummy = false;

const MainPage = () => {
    const [data, setData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState(false);
    const { filtersSelections, setFiltersData } = useContext(FiltersContext);
    const { sidebarToggled } = useContext(SidebarContext);
    const { t } = useTranslation();

    const handleGetOverview = async () => {
        setIsLoading(true);

        const result = dummy ? await getAvailableTrainingModules() : await trainingModuleAPI.getAvailableTrainingModules(moment().year());
        if (result && !result.error) {
            const groupedBySubCompetencies = result.value.trainingCategories.map(x => {
                x.group = x.trainingCategoryName;
                x.isCenter = x.group === "Alinhamento";
                x.count = 0;
                x.data = [...x.trainingSubCategories].map(y => {
                    y.group = y.trainingCategoryName;
                    const match = result.value.trainingModules.filter(e => e.subCategoryName === y.group);
                    let trainingEvents = [...match].reduce((acc, curr) => acc.concat(curr?.trainingEvents || []), []);
                    trainingEvents = trainingEvents.filter(te => te.eventStatus !== "C" && te.eventStatus !== "D" && te.eventStatus !== "A");
                    //C cancelado
                    //D anulado
                    //A rascunho
                    y.trainingEvents = Array.isArray(y.trainingEvents) ? y.trainingEvents.concat(trainingEvents) : trainingEvents || [];
                    y.trainingEvents = y.trainingEvents.sort((a, b) => {
                        const a_canEnrole = a.eventStatus === "P" && moment(a.cancelationLimit).isSameOrAfter(moment(), "day");
                        const b_canEnrole = b.eventStatus === "P" && moment(b.cancelationLimit).isSameOrAfter(moment(), "day");
                        if (b_canEnrole && a_canEnrole) {
                            return moment(b.cancelationLimit).isBefore(moment(a.cancelationLimit)) ? 1 : -1;
                        }
                        return b_canEnrole ? 1 : a_canEnrole ? -1 : moment(b.cancelationLimit).isBefore(a.cancelationLimit) ? 1 : -1;
                    })
                    x.count += y.trainingEvents?.length;
                    return y;
                });
                delete x.trainingSubCategories;
                return x;
            })
            setData(groupedBySubCompetencies);
            handleSetFilterOptions(result.value.trainingModules)
            setIsLoading(false);
        } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
            result.exceptionMessages.messages.forEach(m => toast.error(m.description));
        } else {
            toast.error(t("common:common.errors.unexpected"));
        }
    }

    const getData = async () => {
        handleGetOverview();
    }

    async function handleSetFilterOptions(trainingModules) {
        const filtersResult = await getAvailableTrainingModuleTypes();
        const _filtersData = [...filtersResult.value.filter(x => x.filter)]
        const languageFilter = _filtersData.find(x => x.id === "languageTypeDesc")
        const experienceFilter = _filtersData.find(x => x.id === "experienceTypeDesc")
        const locationFilter = _filtersData.find(x => x.id === "locationTypeDesc")
        const trimesterFilter = _filtersData.find(x => x.id === "trimester")

        for (const training of trainingModules) {
            if (training.languageTypeDesc && !languageFilter.options.find(x => x.value === training.languageTypeDesc)) {
                languageFilter.options.push({
                    label: training.languageTypeDesc,
                    value: training.languageTypeDesc
                })
            }
            if (training.experienceTypeDesc && !experienceFilter.options.find(x => x.value === training.experienceTypeDesc)) {
                experienceFilter.options.push({
                    label: training.experienceTypeDesc,
                    value: training.experienceTypeDesc
                })
            }
            if (training.trainingEventID && training.locationTypeDesc && !locationFilter.options.find(x => x.value === training.locationTypeDesc)) {
                locationFilter.options.push({
                    label: training.locationTypeDesc,
                    value: training.locationTypeDesc
                })
            }
            if (training.trainingEvents.find(x => x.trimester !== null)) {
                for (const event of training.trainingEvents) {
                    if (event.trimester !== null && !trimesterFilter.options.find(x => x.value === event.trimester)) {
                        trimesterFilter.options.push({
                            label: event.trimester,
                            value: event.trimester
                        })
                    }
                }
            }
        }

        setFiltersData(_filtersData)
    }

    /*
    const deepSearchIn = (obj, keys) => {
        return keys.split('.').reduce((cur, key) => {
            return cur[key];
        }, obj);
    }
    */

    /*eslint-disable no-useless-escape*/
    const deepSearchIn = (from, ...selectors) =>
        [...selectors].map(s =>
            s
                .replace(/\[([^\[\]]*)\]/g, '.$1.')
                .split('.')
                .filter(_t => _t !== '')
                .reduce((prev, cur) => prev && prev[cur], from));

    const handleFilterData = () => {
        /*eslint-disable no-loop-func*/
        const filtersNames = filtersSelections ? Object.keys(filtersSelections) : [];
        if (data && filtersNames?.length > 0) {
            const _data = _.cloneDeep(data);
            const _filteredData = _data.map(cat => {
                let passCount = 0;
                let catData = _.cloneDeep(cat.data);
                catData = catData.map(subcat => {
                    let trainingEvents = _.cloneDeep(subcat.trainingEvents);
                    for (const filterName of filtersNames) {
                        trainingEvents = _.cloneDeep(trainingEvents);
                        trainingEvents = trainingEvents.filter(s => {
                            let matchTrainingEvents = false;
                            if (filtersSelections[filterName].type === "range") {
                                if (!s[filterName]) {
                                    //IF NO CREDITS, PASS
                                    matchTrainingEvents = true;
                                } else {
                                    const min = filtersSelections[filterName] && filtersSelections[filterName].value && filtersSelections[filterName].value[0] ? filtersSelections[filterName].value[0] : 0;
                                    const max = filtersSelections[filterName] && filtersSelections[filterName].value && filtersSelections[filterName].value[1] ? filtersSelections[filterName].value[1] : 999;
                                    if (s[filterName] && Number.isFinite(s[filterName])) {
                                        matchTrainingEvents = s[filterName] >= min && s[filterName] <= max ? true : matchTrainingEvents;
                                    }
                                }
                            } else if (filtersSelections[filterName].type === "text") {
                                filtersSelections[filterName].value?.searchIn?.forEach(_in => {
                                    const { value } = filtersSelections[filterName];
                                    if (value?.value) {
                                        const _searchIn = deepSearchIn(s, _in)[0];
                                        matchTrainingEvents = _searchIn?.toLowerCase().includes(value.value?.toLowerCase()) ? true : matchTrainingEvents;
                                    }
                                })
                            } else {
                                if (filterName === "trimester" || filterName === "eventStatus") {
                                    if (filterName === "eventStatus") {
                                        if (filtersSelections[filterName].value === "P") {
                                            matchTrainingEvents = filtersSelections[filterName] && filtersSelections[filterName].value === s[filterName] && (moment(s.registrationLimit).isSame(moment(), "day") || moment(s.registrationLimit).isAfter(moment())) ? true : matchTrainingEvents;
                                        } else {
                                            matchTrainingEvents = filtersSelections[filterName] && (filtersSelections[filterName].value === s[filterName] || (!moment(s.registrationLimit).isSame(moment(), "day") && moment(s.registrationLimit).isBefore(moment()))) ? true : matchTrainingEvents;
                                        }
                                    }
                                    if (filterName === "trimester") {
                                        matchTrainingEvents = filtersSelections[filterName] && filtersSelections[filterName].value === s[filterName] ? true : matchTrainingEvents;
                                    }
                                } else {
                                    matchTrainingEvents = filtersSelections[filterName] && filtersSelections[filterName].value === s[filterName] ? true : matchTrainingEvents;
                                }
                            }
                            if (matchTrainingEvents) {
                                passCount++;
                            }
                            return matchTrainingEvents;
                        })
                    }
                    return {
                        group: subcat.group,
                        trainingEvents: trainingEvents,
                        trainingCategoryDescription: subcat.trainingCategoryDescription
                    };
                })
                return {
                    group: cat.group,
                    data: catData,
                    count: passCount,

                };
            });
            const match = _filteredData.find(x => x.group === "Alinhamento");
            if (match) {
                match.isCenter = true;
            }
            setFilteredData(_filteredData);
        } else {
            setFilteredData(data);
        }
    }

    useEffect(() => {
        handleFilterData();
    }, [data, filtersSelections]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                p: 4
            }}
        >
            <CircularMenu
                sidebarToggled={sidebarToggled}
                data={isLoading ? [
                    { isCenter: true },
                    {},
                    {},
                    {},
                    {}
                ] : filteredData}
            />
        </Box>
    )
};

export default MainPage;
