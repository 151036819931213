import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      textTransform: "initial !important",
      height: "fit-content",
      boxShadow: 'none !important',
      "&.MuiButton-outlinedError": {
        borderColor: palette.error.main + '!important',
        color: palette.error.main,
        "& > *": {
          WebkitTextFillColor: palette.error.main,
        }
      },
      "&$disabled.MuiButton-contained": {
        color: 'white !important'
      },
      '&.buttonError': {
        background: '#F9ECEC',
        color: '#CB5355',
        border: '1px solid #CB5355'
      }
    },
    text: {
      color: palette.text.button
    },
    /*contained: {
      background: palette.primary.mainGradient,
      border: "solid 1px transparent",
      "&:hover": {
        background: palette.primary.lightGradient,
      },
      "&.Mui-disabled": {
        background: palette.primary.grayGradient,
        color: "white"
      }
    },*/
    containedSecondary: {
      background: palette.primary.main,
      border: `solid 1px ${palette.primary.main}`,
      color: palette.primary.contrastText,
      "&:hover": {
        background: palette.primary.dark,
      },
      "&.Mui-disabled": {
        background: palette.primary.grayGradient,
        color: "white"
      }
    },
    outlined: {
      border: "1px solid transparent !important",
      borderTop: `solid 1px ${palette.primary.grad1} !important`,
      borderBottom: `solid 1px ${palette.primary.grad2} !important`,
      background: `${palette.primary.mainGradient} right/1px, ${palette.primary.mainGradient} left/1px !important`,
      backgroundRepeat: "no-repeat !important",
      "& > *": {
        background: palette.primary.mainGradient,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
      },
      "&.Mui-disabled": {
        borderTop: `solid 1px ${palette.common[250]} !important`,
        borderBottom: `solid 1px ${palette.common[250]} !important`,
        background: `${palette.primary.grayGradient} right/1px, ${palette.primary.grayGradient} left/1px !important`,
        backgroundRepeat: "no-repeat !important",
        "& > *": {
          background: palette.text.secondary,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }
      },
      "&:hover": {
        border: "1px solid transparent",
        borderTop: `solid 1px ${palette.primary.grad1}`,
        borderBottom: `solid 1px ${palette.primary.grad2}`,
      }
    },
    outlinedSecondary: {
      border: `solid 1px ${palette.primary.main}`,
      color: palette.primary.main,
      background: "transparent"
    }
  }
};
