export default {
    styleOverrides: {
        root: {
            "&.MuiChip-root": {
                fontSize: "1rem"
            },
            "&.MuiAccordionSummary-root": {
                minHeight: "32px!important",
            },
        },
    }
};
