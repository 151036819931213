import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { getFAQs } from '../API/LJAPI';
import toast from 'react-hot-toast';
import { Box, IconButton, Tooltip, Card, Typography, useMediaQuery } from '@material-ui/core';
import {
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const FAQs = () => {
    const { page } = useParams();
    const navigate = useNavigate();
    const [FAQData, setFAQData] = useState([]);
    const [data, setData] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:768px');

    const navigatePrev = () => {
        if (parseInt(page, 10) - 1 >= 0) {
            navigate(`/FAQ/${parseInt(page, 10) - 1}`)
        }
    }

    const navigateNext = () => {
        if (parseInt(page, 10) + 1 <= FAQData.length - 1) {
            navigate(`/FAQ/${parseInt(page, 10) + 1}`)
        }
    }

    const getData = async () => {
        if (!page) {
            navigate('/');
            toast.error(t('LJ:noFAQ'));
        }
        if (FAQData && FAQData.length) {
            setData(FAQData[page]);
        } else {
            const result = await getFAQs();
            if (result && !result.error) {
                setFAQData(result.value);
                setData(result.value[page]);
            } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
                result.exceptionMessages.messages.forEach(m => toast.error(m.description));
            } else {
                toast.error(t("common:common.errors.unexpected"));
            }
        }
    }

    useEffect(() => {
        getData();
    }, [page]);

    return (
        <Box
            sx={{
                p: 2,
                height: "100%"
            }}
        >
            <Card
                sx={{
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box>
                    <IconButton
                        disabled={parseInt(page, 10) <= 0}
                        onClick={navigatePrev}
                    >
                        <Tooltip
                            title={t('LJ:previousFAQ')}
                        >
                            <div>
                                <ArrowBackIcon />
                            </div>
                        </Tooltip>
                    </IconButton>
                    {`${parseInt(page, 10) + 1}/${FAQData.length}`}
                    <IconButton
                        disabled={parseInt(page, 10) >= FAQData.length - 1}
                        onClick={navigateNext}
                    >
                        <Tooltip
                            title={t('LJ:nextFAQ')}
                        >
                            <div>
                                <ArrowForwardIcon />
                            </div>
                        </Tooltip>
                    </IconButton>
                </Box>
                <Box>
                    <Box>
                        <Typography
                            variant="h2g"
                            sx={{
                                fontSize: "1.8rem"
                            }}
                        >
                            <b>{data?.title}</b>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        overflow: "auto"
                    }}
                >
                    <div
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                        style={{
                            width: page && parseInt(page, 10) === 0 && !isMobile ? "60%" : "100%",
                            display: page && parseInt(page, 10) === 0 ? "inline-block" : "block",
                            verticalAlign: "top"
                        }}
                    />
                    {
                        page && parseInt(page, 10) === 0 && <Box
                            sx={{
                                px: 2,
                                width: isMobile ? "100%" : "40%",
                                display: "inline-block",
                                maxWidth: isMobile ? "100%" : "700px"
                            }}
                        >
                            <img
                                alt="Learning Journey"
                                src={process.env.REACT_APP_CLIENT_SECONDARY_LOGO}
                                style={{
                                    width: "100%"
                                }}
                            />
                        </Box>
                    }
                </Box>
            </Card>
        </Box>
    )
};

FAQs.propTypes = {
}

export default FAQs;
