import themeConfig from "../themeConfig";

export default {
    styleOverrides: {
        root: {
            maxWidth: "100vw",
            paddingBottom: themeConfig.spacing * 4
        }
    }
};
