import PropTypes from 'prop-types';
import {
  Grid,
  Avatar,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const TimelineComments = ({ stepData, photoMap }) => {
  return (
    <>
      {[...stepData.tasks].some(task => task?.comments?.length > 0 &&
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Comentários
        </Typography>)}
      {[...stepData.tasks].reverse().map((task, taskIndex) =>
        task?.comments?.length > 0 && <Card
          key={`timelineComments_${taskIndex}`}
          sx={{
            margin: "16px 0"
          }}
        >
          <CardContent>
            <Grid
              container
              sx={{
                m: 'auto 0',
                fontWeight: "bold",
                fontSize: "16px",
                alignItems: "center"
              }}
            >
              <Grid item>
                <Avatar
                  src={`data:image/png;base64, ${photoMap.get(task.userId)}`}
                  alt={task.userId}
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                />
              </Grid>
              <Grid item ml={2}>
                {task.employeeName} em {moment(task.sysCreateDate).format('DD/MM')}:
              </Grid>
            </Grid>
            <Typography mt={2}>
              {task.comments}
            </Typography>
          </CardContent>
        </Card>)}
    </>);
}

TimelineComments.propTypes = {
  stepData: PropTypes.object,
  photoMap: PropTypes.any,
};

export default TimelineComments;
