import moment from 'moment';

const nameNormalize = (name) => {
  if (name && name !== null) {
    const normalize = (typeof (name) === "boolean")
      ? Number(name)
      : name.toString().toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')
    return normalize;
  }
  return null;
};

const descendingComparator = (a, b, orderBy) => {
  const _a = { ...a };
  const _b = { ...b };
  if (!Number.isInteger(_a[orderBy]) && moment(_a[orderBy], 'DD/MM/YYYY', false).isValid() && !Number.isInteger(_b[orderBy]) && moment(_b[orderBy], 'DD/MM/YYYY', false).isValid()) {
    _a[orderBy] = moment(_a[orderBy], 'DD/MM/YYYY').format('YYYYMMDD');
    _b[orderBy] = moment(_b[orderBy], 'DD/MM/YYYY').format('YYYYMMDD');
  }
  if ((Number.isInteger(_a[orderBy]) && _b[orderBy] < _a[orderBy]) || (!Number.isInteger(_a[orderBy]) && nameNormalize(_b[orderBy]) < nameNormalize(_a[orderBy]))) {
    return -1;
  }
  if ((Number.isInteger(_a[orderBy]) && _b[orderBy] > _a[orderBy]) || (!Number.isInteger(_a[orderBy]) && nameNormalize(_b[orderBy]) > nameNormalize(_a[orderBy]))) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  const ordered = order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
  return ordered;
};

export const like = (word, searchInput) => {
  const searchableWord = word?.toString().toLowerCase()?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, "");
  let matchesAllWord = true;

  if (searchInput.length >= 1) {
    const searchInputArray = searchInput.split(' ');

    searchInputArray.forEach((w) => {
      if (searchableWord.indexOf(w.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) < 0) {
        // not found
        matchesAllWord = false;
      }
    });
  } else {
    return false;
  }
  return matchesAllWord;
};

export const applySort = (array, orderBy, orderDirection) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = getComparator(orderDirection, orderBy)(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const applyPagination = (array, page, limit) => array
  .slice(page * limit, page * limit + limit);

export const applyFilters = (headCells, array, query, filters) => {
  return array?.filter((element) => {
    let matches = true;
    if (query) {
      const properties = headCells.filter((x) => x.filter).map((x) => x.id);
      let containsQuery = false;

      properties.forEach((property) => {
        // case insensitive and accent insensitive
        if (element[property] && like(element[property], query)) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value && typeof value !== "boolean") {
          if (Array.isArray(value)) {
            let matchesArray = false;

            value.forEach(obj => {
              if (obj[Object.keys(obj)[0]] === element[key]) {
                matchesArray = true;
              }
            });
            matches = matchesArray;
          } else if (element[key] && like(element[key], value)) {
            if (matches) {
              matches = true;
            }
          } else {
            matches = false;
          }
        } else if (typeof value === "boolean") {
          matches = (element[key] === value) && matches;
        }
      });
    }

    return matches;
  });
};
