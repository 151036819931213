import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.primary.main,
      border: `1px solid ${palette.primary.main}`,
      padding: '8px',
      '& svg': {
        fontSize: '1rem',
      },
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: palette.primary.main,
        '& svg': {
          fontSize: '1.8rem',
        },
        '&: hover': {
          backgroundColor: palette.primary.greenHover1,
        }
      },
    },
  }
};
