import React from 'react';
import { Tooltip, Box, LinearProgress } from '@material-ui/core';
import {
    InfoRounded as InfoRoundedIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const KPIProgress = ({ description, tooltip, progress, progressCap = 100 }) => {
    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px"
                }}
            >
                <small>
                    <b>
                        {description}
                    </b>
                </small>
                <Tooltip
                    title={tooltip}
                    sx={{
                        ml: "8px"
                    }}
                >
                    <InfoRoundedIcon fontSize="small" />
                </Tooltip>
            </div>
            <Box>
                <LinearProgress
                    variant="determinate"
                    value={progress || 0}
                    max={progressCap}
                />
            </Box>
            <small>{progress || 0}%</small>
        </div>
    )
};

KPIProgress.propTypes = {
    description: PropTypes.string,
    tooltip: PropTypes.string,
    progress: PropTypes.number,
    progressCap: PropTypes.number
}

export default KPIProgress;
