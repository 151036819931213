import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  Link
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES?.split(';') || [];

const languageOptions = {
  en: {
    icon: '/static/icons/uk_flag.svg',
    label: 'English',
    available: availableLanguages.some((language) => language === 'en')
  },
  pt: {
    icon: '/static/icons/pt_flag.svg',
    label: 'Portuguese',
    available: availableLanguages.some((language) => language === 'pt')
  }
};

const LanguagePopover = ({ toggleMobileMenu = () => null, label }) => {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    navigate(0)
    localStorage.setItem('preferedLanguage', language);
    setOpen(false);
  };

  const selectedOption = languageOptions[i18n.language];
  return (
    <>
      {
        label ?
          <MenuItem
            onClick={handleOpen}
            ref={anchorRef}
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: "center"
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img
                  alt={selectedOption.label}
                  src={selectedOption.icon}
                />
              </Box>
            </ListItemIcon>
            <Link
              href="#"
            >
              {label}
            </Link>
          </MenuItem>
          :
          <IconButton
            onClick={handleOpen}
            ref={anchorRef}
          >
            <Box
              sx={{
                display: 'flex',
                height: 20,
                width: 20,
                '& img': {
                  width: '100%'
                }
              }}
            >
              <img
                alt={selectedOption.label}
                src={selectedOption.icon}
              />
            </Box>
          </IconButton>
      }
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {Object.keys(languageOptions).map((language, index) => (
          <React.Fragment
            key={index.toString()}
          >
            {
              languageOptions[language].available &&
              <MenuItem
                onClick={() => {
                  toggleMobileMenu(null);
                  handleChangeLanguage(language)
                }}
              >
                <ListItemIcon>
                  <Box
                    sx={{
                      display: 'flex',
                      height: 20,
                      width: 20,
                      '& img': {
                        width: '100%'
                      }
                    }}
                  >
                    <img
                      alt={languageOptions[language].label}
                      src={languageOptions[language].icon}
                    />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {languageOptions[language].label}
                    </Typography>
                  )}
                />
              </MenuItem>
            }
          </React.Fragment>
        ))}
      </Popover>
    </>
  );
};

LanguagePopover.propTypes = {
  toggleMobileMenu: PropTypes.func,
  label: PropTypes.string
}

export default LanguagePopover;
