import { profileAPI } from '../api/profileAPI';

async function getPhotos(userList, photoMap) {
  const userPhotos = await profileAPI.GetEmployeesPhotos(userList);
  if (!userPhotos.error) {
    const photoMap_ = new Map(JSON.parse(JSON.stringify(Array.from(photoMap))));
    for (const photo of userPhotos.value.usernames) {
      //photoMap_.set(photo.username, photo.photo && photo.photo !== "NOT_FOUND" ? `data:image/png;base64, ${photo.photo}` : "");
      // photoMap_.set(photo.username, photo.photo && photo.photo !== "NOT_FOUND" ? photo.photo : "");
      photoMap_.set(photo.username, photo.photo);
    }
    return photoMap_;
  }
}

export default getPhotos;
