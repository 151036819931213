//import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      "&.Mui-disabled": {
        cursor: "not-allowed",
        background: "whitesmoke",
        "& *": {
          cursor: "not-allowed"
        }
      }
    }
    /*root: {
      "& .MuiAutocomplete-endAdornment, input": {
        zIndex: 1,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        border: "solid 1px transparent",
        borderRadius: "5px",
        backgroundImage: "linear-gradient(white, white), linear-gradient(lightgray, lightgray) !important",
        backgroundOrigin: "border-box !important",
        backgroundClip: "content-box, border-box !important",
        padding: 0
      },
      "&:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline,.MuiOutlinedInput-notchedOutline:active": {
        borderColor: "transparent",
        backgroundImage: `linear-gradient(${palette.common.mainBackGround}, ${palette.common.mainBackGround}),  ${palette.primary.mainGradient} !important`,
        "& .MuiFormLabel-root.Mui-focused span": {
          background: palette.primary.mainGradient,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }
      }
    }*/
  }
}
