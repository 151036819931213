import palette from './palette';

export default {
  fontFamily: 'Calibri',
  color: palette.text.primary,
  primary: {
    color: palette.primary.main
  },
  disabled: {
    color: palette.common[250]
  },
  h1: {
    fontSize: '1.3rem',
    background: "none",
    WebkitTextFillColor: "initial"
  },
  h1g: {
    fontSize: '1.12rem',
    background: palette.text.primary,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  h2: {
    fontSize: '1.12rem'
  },
  h2g: {
    fontSize: '1.12rem',
    background: palette.primary.mainGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  h3: {
    fontSize: '1.08rem'
  },
  h4: {
    fontSize: '1.05rem'
  },
  h5: {
    fontSize: '1.03rem'
  },
  h6: {
    fontSize: '1rem',
    background: palette.primary.mainGradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  h6disabled: {
    color: palette.common[250],
    fontSize: '1rem'
  },
  button: {
    fontSize: '0.9rem',
    textTransform: 'capitalize',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '0.8rem',
    textTransform: 'uppercase'
  },
  formTitle: {
    fontSize: "1rem",
    fontWeight: "bold"
  },
  cardTitle: {
    fontWeight: "normal!important",
    fontSize: "1.3rem",
  },
  cardSubtitle: {
    fontWeight: "bold!important",
  },
};
