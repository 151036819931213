import { colors } from '@material-ui/core';
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      borderRadius: 3,
      overflow: 'hidden'
    },
    colorPrimary: {
      backgroundColor: colors.blueGrey[50]
    },
    bar: {
      background: palette.primary.mainGradient + "!important"
    }
  }
};
