import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
import getPhotos from '../../../utils/getEmployeesPhotos';
import Timeline from '@material-ui/lab/Timeline';
import { timelineOppositeContentClasses } from '@material-ui/lab/TimelineOppositeContent';
import TimelineModalRequesterItem from './timelineModalRequesterItem';
import TimelineModalItem from './timelineModalItem';
import TimelineComments from './timelineComments';
import palette from '../../../theme/palette';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const TimelineComponent = ({ timelineData }) => {
  const { t } = useTranslation();
  const [photoMap, setPhotoMap] = useState(new Map());
  const statusOption = [
    { value: 'TODO', color: palette.states.pending, label: t("common:status.pending") },
    { value: 'IN_PROGRESS', color: palette.states.pending, label: t("common:status.pending") },
    { value: 'PENDING', color: palette.states.pending, label: t("common:status.pending") },
    { value: 'SUBMITTED', color: palette.states.submitted, label: t("common:status.submitted") },
    { value: 'APPROVED', color: palette.states.success, label: t("common:status.approved") },
    { value: 'REJECTED', color: palette.states.fail, label: t("common:status.rejected") },
    { value: 'CANCELLED', color: palette.states.fail, label: t("common:status.canceled") }
  ];

  const handleGetPhotos = async (userList) => {
    if (userList) {
      const newUserList = [];
      for (const user_ of userList) {
        if (!photoMap.get(user_)) {
          newUserList.push(user_);
        }
      }
      if (newUserList.length > 0) {
        const newMap = await getPhotos(userList, photoMap);
        if (newMap) {
          setPhotoMap(newMap);
        }
      }
    }
  };

  useEffect(() => {
    if (timelineData?.steps) {
      const uniquePhotos = [...new Set(timelineData.steps.map(step => step.tasks.map(task => task.userId)).flat())];
      handleGetPhotos(uniquePhotos);
    }
  }, []);

  return (
    <>
      {
        timelineData ? <Timeline
          position="left"
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 8
            }
          }}
        >
          {
            timelineData?.steps && [...timelineData.steps].reverse().map((step, stepIndex) => <>
              <TimelineModalItem
                key={`timelineItem_${stepIndex}`}
                stepData={step}
                statusOption={statusOption}
                photoMap={photoMap}
              />
            </>)
          }
          <TimelineModalRequesterItem
            timelineData={timelineData}
            statusOption={statusOption}
            photoMap={photoMap}
          />
        </Timeline>
          :
          <Typography
            sx={{
              p: 2
            }}
          >
            No data found
          </Typography>
      }
      {
        timelineData?.steps && [...timelineData.steps].reverse().map((step, stepIndex) => <>
          <TimelineComments
            key={`timelineComments_${stepIndex}`}
            stepData={step}
            photoMap={photoMap}
          />
        </>)
      }
    </>
  );
}

TimelineComponent.propTypes = {
  timelineData: PropTypes.object,
};

export default TimelineComponent;
