import React, { createContext, useContext, useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import PropTypes from 'prop-types';

const AppInsightsContext = createContext();
export const useAppInsights = () => useContext(AppInsightsContext);

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATIONKEY,
        enableAutoRouteTracking: true
    }
});
appInsights.loadAppInsights();

export const AppInsightsProvider = ({ children }) => {
    useEffect(() => {
    }, []);

    return (
        <AppInsightsContext.Provider value={appInsights}>
            {children}
        </AppInsightsContext.Provider>
    );
};

AppInsightsProvider.propTypes = {
    children: PropTypes.node.isRequired
};
