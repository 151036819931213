import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useCustomAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import { useTranslation } from 'react-i18next';
import EmployeeAcceptancesModal from './components/dashboard/personal/modals/employeeAcceptancesModal';

const App = () => {
  const auth = useAuth();
  const { settings } = useSettings();
  const content = useRoutes(routes());
  const { i18n } = useTranslation();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    language: i18n.language
  });

  window.platform_name = process.env.REACT_APP_PLATFORM_NAME;

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: theme.palette.success.main,
                color: theme.palette.success.contrastText
              },
              iconTheme: {
                primary: theme.palette.success.contrastText,
                secondary: theme.palette.success.main,
              }
            },
            error: {
              style: {
                background: theme.palette.error.main,
                color: theme.palette.error.contrastText
              },
              iconTheme: {
                primary: theme.palette.error.contrastText,
                secondary: theme.palette.error.main,
              },
            },
          }}
          position="top-right"
        />
        <SettingsDrawer />
        {auth.isInitialized ? content : <SplashScreen />}
        {auth.isInitialized && process.env.REACT_APP_EMPLOYEE_ACCEPTANCE_REQUIRED === "true" ? <EmployeeAcceptancesModal /> : null}
        {/* {content} */}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
