import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import components from './components';
import themeConfig from './themeConfig';
import { ptPT, enUS } from '@material-ui/core/locale';

const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

const themesOptions = {
  [THEMES.LIGHT]: {
    spacing: themeConfig.spacing,
    palette,
    typography,
    components
  }
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const locale = config.language === 'en' ? enUS : ptPT;

  let theme = createTheme(merge({}, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 4
      }
    })
  }, {
    direction: config.direction,
  }, locale));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
