import { apiUtils } from './apiUtils';

class TrainingModuleAPI {
  async getAvailableTrainingModules(year) {
    const yearquery = year ? `year=${year}` : "";
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetAvailableTrainingModules?${yearquery}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async trainingEventRegister(params) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/trainingEventRegister`,
      params: params
    }

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async trainingEventCancelRegister(params) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/trainingEventCancelRegister`,
      params: params
    }

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async trainingEventCancelApprovedRegister(params) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/TrainingEventCancelApprovedRegister`,
      params: params
    }

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTrainingModuleTypes() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingModuleTypes`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getTrainingInstructorTypes() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingInstructorTypes`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getTrainingModules(id) {
    id = id ? `trainingModuleID=${id}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingModules?${id}`,
      params: { trainingModuleID: id }
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getTrainingEvents(id, year) {
    id = id ? `trainingEventID=${id}` : '';
    year = year ? `&year=${year}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingEvents?${id}${year}`,
      params: { trainingEventID: id }
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getTrainingEventDetail(id) {
    id = id ? `trainingEventID=${id}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingEventDetail?${id}`,
      params: { trainingEventID: id }
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async saveTrainingEvent(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/SaveTrainingEvent`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async saveTrainingModule(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/SaveTrainingModule`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTrainingEventRegistries(id) {
    id = id ? `trainingEventID=${id}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingEventRegistries?${id}`,
      params: { trainingEventID: id }
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTeamEventRegistries() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTeamEventRegistries`
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getGlobalEventRegistries(year) {
    year = year ? `year=${year}` : '';

    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetGlobalEventRegistries?${year}`
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async cancelTrainingEvent(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/CancelTrainingEvents`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async GetAvailableTrainingModuleTypes() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetAvailableTrainingModuleTypes`
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async GetTrainingSessionAttendances(id) {
    id = id ? `trainingEventID=${id}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingSessionAttendances?${id}`,
      params: { trainingEventID: id }
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async saveTrainingSessionAttendances(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/SaveTrainingSessionAttendances`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTrainersList() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetTrainingInstructor`,
      params: ""
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async saveTrainer(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/SaveTrainingInstructor`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTrainerDetail(id) {
    id = id ? `id=${id}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/getTrainerDetail?${id}`,
      params: ""
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async DeleteTrainingInstructors(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/DeleteTrainingInstructors`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTrainingRequests(selectedYear) {
    selectedYear = selectedYear ? `?Year=${selectedYear}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetExternalTraining${selectedYear}`,
      params: ""
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getAllTrainingRequests(selectedYear) {
    selectedYear = selectedYear ? `?Year=${selectedYear}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetAllExternalTraining${selectedYear}`,
      params: ""
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getTrainingRequestFilesInfo(id) {
    id = id ? `externalTrainingID=${id}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetExternalTrainingAttachmentInfo?${id}`,
      params: ""
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async downloadTrainingRequestFile(id, fileName, code) {
    id = id ? `externalTrainingID=${id}` : '';
    fileName = fileName ? `&FileName=${fileName}` : '';
    code = code ? `&Code=${code}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetExternalTrainingAttachment?${id}${fileName}${code}`,
      params: ""
    };

    const response = await apiUtils.handleDownloadMethod(Input);
    return response;
  }

  async getEmployeeTrainingHistory(selectedYear) {
    selectedYear = selectedYear ? `?Year=${selectedYear}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetEmployeeTrainingHistory${selectedYear}`,
      params: ""
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async saveTrainingRequest(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/SaveExternalTraining`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async UpdateExternalTrainingStatus(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/UpdateExternalTrainingStatus`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async getEmployeeInfo(props) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GetEmployeeInfo`,
      params: props
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async myTeam(showOnlyDirectReports) {
    showOnlyDirectReports = showOnlyDirectReports ? `showOnlyDirectReports=${showOnlyDirectReports}` : ''

    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/MyTeam?${showOnlyDirectReports}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async globalTeam() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/TrainingModule/GlobalTeam`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);
    return response;
  }
}

export const trainingModuleAPI = new TrainingModuleAPI();
