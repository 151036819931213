import { TableHead, TableRow, TableCell, TableSortLabel, Checkbox, IconButton } from '@material-ui/core';
import {
  Visibility as PrivacyIconOn,
  VisibilityOff as PrivacyIconOff
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const TableHeader = (props) => {
  const { order, orderBy, headCells, selectedSome, selectedAll, onSelectAllClick, onSortClick, hasCheckbox = true, togglePrivacy, privacy, disabledCheckBox = false } = props;
  return (
    <TableHead>
      <TableRow>
        {hasCheckbox ?
          <TableCell
            padding="checkbox"
          >
            <Checkbox
              indeterminate={selectedSome}
              checked={selectedAll}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'Seleccionar todos' }}
              disabled={disabledCheckBox}
            />
          </TableCell>
          :
          null}
        {
          headCells.filter((x) => !x.hidden).map((headCell) => {
            if (headCell.visibilityToggle) {
              return <TableCell
                key={headCell.id}
                align={headCell.align}
                sx={headCell.style}
              >
                <IconButton
                  aria-label="Privacidade"
                  component="span"
                  onClick={togglePrivacy}
                >
                  {
                    privacy ?
                      <PrivacyIconOff />
                      :
                      <PrivacyIconOn />
                  }
                </IconButton>
              </TableCell>;
            }
            if (headCell.selectColumn) {
              return <TableCell
                key={headCell.id}
                align={headCell.align}
                sx={headCell.style}
              >
                {headCell.label} <br />
                <Checkbox
                  style={{ padding: "0", maxWidth: "18vw" }}
                  onChange={headCell.selectColumn}
                  id={headCell.id}
                  name={`select-column-${headCell.id}`}
                />
              </TableCell>;
            }
            return <TableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={headCell.style}
            >
              {
                headCell.sort ?
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => onSortClick(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                  :
                  headCell.label
              }
            </TableCell>
          })
        }
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  togglePrivacy: PropTypes.func,
  privacy: PropTypes.bool,
  onSortClick: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  selectedAll: PropTypes.bool,
  selectedSome: PropTypes.bool,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headCells: PropTypes.array,
  hasCheckbox: PropTypes.bool,
  disabledCheckBox: PropTypes.bool
};

export default TableHeader;
