export const convertNumToTime = (number) => {
    if (!Number.isFinite(number)) {
        console.error("Parse error: Invalid number supplied");
        return "0h";
    }
    let sign = (number >= 0) ? 1 : -1;
    number *= sign;
    const hour = Math.floor(number);
    let decpart = number - hour;
    const min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    let minute = Math.floor(decpart * 60) + '';
    if (minute.length < 2) {
        minute = '0' + minute;
    }
    sign = sign === 1 ? '' : '-';
    return sign + hour + 'h' + (minute !== "00" ? minute : "");
}
