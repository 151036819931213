import palette from '../palette';

export default {
    styleOverrides: {
        root: {
            "& .MuiOutlinedInput-root": {
                "&.Mui-disabled": {
                    cursor: "not-allowed",
                    background: "whitesmoke",
                    "& *": {
                        cursor: "not-allowed"
                    },

                }
            },
            "&.readOnly": {
                "& .MuiOutlinedInput-root": {
                    "&.Mui-disabled": {
                        background: "transparent !important",
                        cursor: "initial !important",
                        "& input, & textarea": {
                            cursor: "initial !important",
                            WebkitTextFillColor: palette.text.primary + "!important",
                            color: palette.text.primary + "!important"
                        }
                    }
                }
            },
            /*width: "100%",
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                background: palette.primary.mainGradient,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent"
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderWidth: "1px",
                    paddingLeft: "0px !important"
                }
            },
            "& .MuiFormLabel-root": {
                background: "white",
                padding: "0px 5px"
            }*/
        }
    }
};
