import axios from 'axios';
import * as Msal from 'msal';
import { userAPI } from './userAPI';
import toast from 'react-hot-toast';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CUSTOM_AUTH_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_CUSTOM_AUTH_TENANT}`,
    redirectUri: process.env.REACT_APP_CUSTOM_AUTH_REDIRECT_URI
  }
};

const msalInstance = new Msal.UserAgentApplication(msalConfig);

const loginRequest = {
  scopes: [`api://${process.env.REACT_APP_CUSTOM_AUTH_CLIENT_ID}/access_as_user`] // optional Array<string>
};

export const loginAD = () => (
  new Promise((resolve) => {
    if (msalInstance && msalInstance.getAccount()) {
      msalInstance.acquireTokenSilent(loginRequest)
        .then((response) => {
          resolve(response.accessToken);
        })
        .catch((err) => {
          if (err.name === 'InteractionRequiredAuthError') {
            msalInstance.acquireTokenPopup(loginRequest)
              .then((response) => {
                resolve(response.accessToken);
              });
          }
        });
    } else {
      msalInstance.loginRedirect(loginRequest);
    }
  })
);

class ApiUtils {
  async handleTokenRefresh(props) {
    if (!props.method.includes("Authenticate")) {
      const sessionObjt = JSON.parse(localStorage.getItem("session"));
      if (sessionObjt) {
        const tokenExpiration = parseInt(sessionObjt.accessTokenExpiration, 10);
        const currentTime = Math.round((new Date()).getTime() / 1000);
        if (currentTime >= tokenExpiration) {
          if (process.env.REACT_APP_CUSTOM_AUTH === 'LDAP') {
            // const navigate = useNavigate();
            // navigate('/authentication/login');
            localStorage.removeItem('session');
            window.location.href = '/authentication/login';
            // throw new Error("Not logged in.");
          } else {
            const adResponse = await loginAD();
            localStorage.setItem('session', JSON.stringify({
              accessToken: adResponse
            }));
          }
          const data = await userAPI.AzureAuthenticate();
          if (!data.error && data.value) {
            localStorage.setItem('session', JSON.stringify(data.value));
          } else {
            throw new Error('Erro desconhecido');
          }
        }
      }
    }
  }

  async handleMethod(props) {
    await this.handleTokenRefresh(props);
    const session = JSON.parse(window.localStorage.getItem('session'));
    const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES?.split(';') || [];
    return axios({
      method: props.type,
      url: props.method, // process.env.REACT_APP_WEBAPI_URL + props.method,
      data: props.params,
      timeout: props.timeout ? props.timeout : 60000,
      headers: {
        Authorization: session ? `Bearer ${session.accessToken}` : null,
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With, Accept-Language',
        'Accept-Language': availableLanguages.length > 1 && localStorage.getItem('preferedLanguage') ? localStorage.getItem('preferedLanguage') : availableLanguages[0],
      }
    }).then((response) => {
      if (response && response.status === 200) {
        return response.data;
      }
      return response;
    }).catch((error) => {
      const response = {
        error: true,
        description: error.response?.data?.description || 'Erro desconhecido',
        exceptionMessages: error.response?.data?.exceptionMessages
      };
      return response;
    });
  }

  async handleDownloadMethod(props) {
    await this.handleTokenRefresh(props);
    const session = JSON.parse(window.localStorage.getItem('session'));
    const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES?.split(';') || [];
    return axios({
      method: props.type,
      url: props.method, // process.env.REACT_APP_WEBAPI_URL + props.method,
      data: props.params,
      timeout: props.timeout ? props.timeout : 60000,
      responseType: 'blob',
      headers: {
        Authorization: session ? `Bearer ${session.accessToken}` : null,
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With, Accept-Language',
        'Accept-Language': availableLanguages.length > 1 && localStorage.getItem('preferedLanguage') ? localStorage.getItem('preferedLanguage') : availableLanguages[0],
      }
    }).then((response) => {
      if (response && response.status === 200) {
        return response.data;
      }
      return response;
    }).catch(() => {
      const response = {
        error: true,
        description: 'Erro desconhecido'
      };
      return response;
    });
  }

  async handleFormDataMethod(props) {
    await this.handleTokenRefresh(props);
    const session = JSON.parse(window.localStorage.getItem('session'));
    return axios({
      method: props.type,
      url: props.method, // process.env.REACT_APP_WEBAPI_URL + props.method,
      data: props.params,
      timeout: 60000,
      headers: {
        Authorization: session ? `Bearer ${session.accessToken}` : null,
        'Content-Type': 'multipart/form-data;charset=utf-8',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With'
      }
    }).then((response) => {
      if (response && response.status === 200) {
        return response.data;
      }
      return response;
    }).catch(() => {
      const response = {
        error: true,
        description: 'Erro desconhecido'
      };
      return response;
    });
  }

  async handleError(result) {
    if (result.exceptionMessages && result.exceptionMessages.length > 0) {
      result.exceptionMessages.forEach(m => toast.error(m));
    } else {
      toast.error(result.description || "Erro inesperado");
    }
  }
}

export const apiUtils = new ApiUtils();
