import { apiUtils } from './apiUtils';

class WorkflowAPI {
  async UpdateTaskStatus(params, approvalType) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Workflow/${approvalType === 'APPROVE' ? 'ApproveTask' : 'RejectTask'}`,
      params: params
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async GetWorkflowStatus({ sourceID }) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Workflow`,
      params: {
        sourceIDs: sourceID
      }
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }

  async EnabledWorkflowApproval(params) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Workflow/EnabledWorkflowApproval`,
      params: params
    };
    const response = await apiUtils.handleMethod(Input);
    return response;
  }
}

export const workflowAPI = new WorkflowAPI();
