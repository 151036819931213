export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  // const bytes = new Uint8Array(len);
  // for (let i = 0; i < len; i++) {
  //   bytes[i] = binaryString.charCodeAt(i);
  // }
  return len;
};

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const isBase64 = (str) => {
  if (!str || str === '' || str.trim() === '') { return false; }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export const base64toBlob = (data, datatype = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document") => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  if (!isBase64(data)) {
    return false;
  }
  const bytes = atob(data);
  let { length } = bytes;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: datatype });
};
