import PropTypes from 'prop-types';
import { experimentalStyled } from '@material-ui/core/styles';

const LabelRoot = experimentalStyled('span')((({ theme, styleProps }) => {
  const { color, customColor } = styleProps;
  const backgroundColor = customColor ? typeof (customColor) === 'function' ? customColor(theme)?.main : customColor.main : theme.palette[color]?.main || theme.palette.primary.main;
  const fontColor = customColor ? typeof (customColor) === 'function' ? customColor(theme)?.contrastText : customColor.contrastText : theme.palette[styleProps.color]?.contrastText || theme.palette.primary.contrastText;
  const borderColor = customColor ? typeof (customColor) === 'function' ? customColor(theme)?.secondary : customColor.secondary : theme.palette[styleProps.color]?.secondary || "";
  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: theme.shape.borderRadius,
    color: fontColor,
    border: borderColor ? "solid 1px transparent" : false,
    borderColor: borderColor,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  };
}));

const Label = ({ color = 'grey', customColor, children, ...other }) => {
  const styleProps = { color, customColor };

  return (
    <LabelRoot
      styleProps={styleProps}
      {...other}
    >
      {children}
    </LabelRoot>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  customColor: PropTypes.object
};

export default Label;
