import palette from '../palette';

export default {
    styleOverrides: {
        root: {
            border: `1px solid ${palette.common[250]}`,
            "& .MuiDataGrid-columnHeaderWrapper": {
                borderBottom: `1px solid ${palette.common[250]}33`,
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold"
                }
            },
            "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${palette.common[250]}33`
            }
        }
    }
};
