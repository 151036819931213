import { useState, useEffect, useRef } from 'react';
import { css, ThemeProvider } from 'styled-components';
import './style.scss';
import PieMenu, { Slice } from 'react-pie-menu';
import MyPieCenter from './MyPieCenter';
import PropTypes from 'prop-types';
import AccordionMenu from '../AccordionMenu';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import { LoaderIcon } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CircularMenu = ({ sidebarToggled, data }) => {
  const [params, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery('(max-width:998px)');
  const [diameter, setDiameter] = useState(isMobile ? "80vw" : "30vw");
  const [radius, setRadius] = useState(isMobile ? "40vw" : "15vw");
  const [centerRadius, setCenterRadius] = useState(isMobile ? "40vw" : "15vw");
  const [selectedItem, setSelectedItem] = useState(params.get('category'));
  const [centerData, setCenterData] = useState(null);
  const [pieData, setPieData] = useState([]);
  const accordionRef = useRef(null);
  const theme = useTheme();
  const defaultColors = {
    "loading": {
      "color": "rgb(212,212,212)",
      "altColor": "rgb(212,212,212)"
    },
    "Alinhamento": {
      "color": "rgb(22,53,87)",
      "gradColor": "radial-gradient(circle, rgba(96,198,150,1) 0%, rgba(26,53,92,1) 50%, rgba(36,87,116,1) 56%, rgba(26,53,92,1) 57%, rgba(255,255,255,1) 58%, rgba(26,53,92,1) 59%, rgba(36,87,116,1) 60%, rgba(26,53,92,1) 70%, rgba(26,53,92,1) 100%)",
      "altColor": "rgb(43, 110, 113)"
    },
    "Negócio": {
      "color": "rgb(0,179,136)",
      "altColor": "rgb(43, 110, 113)"

    },
    "Know-How": {
      "color": "rgb(22,53,87)",
      "altColor": "rgb(43, 110, 113)"
    },
    "Interação": {
      "color": "rgb(21,94,123)",
      "altColor": "rgb(43, 110, 113)"
    },
    "Gestão": {
      "color": "rgb(50,128,121)",
      "altColor": "rgb(43, 110, 113)"
    }
  }

  const pieTheme = {
    pieMenu: {
      container: css`
          // style color...
        `,
      list: css`
          // set pie size...
        `,
      item: css`
          // rotate slice...
        `,
      center: css`
          // style color...
        `
    },
    slice: {
      container: css`
            &:hover{
                //background: red;
            }
            `,
      contentContainer: css`
          // center content...
        `,
      content: css`
                font-size:${isMobile ? "3vw" : "1.2vw"};
                font-weight:bold;
                transform: rotate(-${0}deg);
          // rotate content...
        `,
    }
  }

  const handleSelectItem = (item) => {
    if (isMobile && sidebarToggled) {
      return;
    }
    setSearchParams({ category: item })
    setSelectedItem(selectedItem === item ? null : item);
  }

  const handleData = () => {
    if (data?.length) {
      const _data = _.cloneDeep(data);
      _data.map(x => {
        const { color, altColor, gradColor } = defaultColors[x.group] || defaultColors.loading;
        x.color = color;
        x.altColor = altColor;
        x.gradColor = gradColor;
        return x;
      });
      setPieData(_data);
      setCenterData(_data.find(x => x.isCenter))
    }
  }

  const countAmount = (x) => {
    let count = 0;
    x?.data?.forEach(t => count += t?.trainingEvents?.length);
    return count;
  }

  useEffect(() => {
    setDiameter(isMobile ? "80vw" : "30vw");
    setRadius(isMobile ? "40vw" : "15vw");
    setCenterRadius(isMobile ? "26.664vw" : "9.999vw");
  }, [isMobile]);

  useEffect(() => {
    handleData();
  }, [data]);

  useEffect(() => {
    if (isMobile && selectedItem && accordionRef.current) {
      setTimeout(() => {
        accordionRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, theme.transitions.duration.standard)
    }
  }, [selectedItem]);

  return (
    <ThemeProvider theme={pieTheme}>
      <Box
        sx={{
          width: "100%",
          height: isMobile ? "initial" : "100%",
          display: isMobile ? "block" : "flex"
        }}
      >
        <Box
          sx={{
            height: isMobile ? `calc(${diameter})` : "100%",
            width: "100%",
            minWidth: isMobile ? "initial" : "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {
            pieData ? <PieMenu
              radius={radius}
              centerRadius={centerRadius}
              className="LJ-mainmenu"
              Center={() => <MyPieCenter
                data={centerData}
                padding={30}
                selectItem={() => handleSelectItem(centerData.group)}
                selectedItem={selectedItem}
                altColor={centerData?.altColor || ""}
                background={centerData?.gradColor || centerData?.color || ""}
                color="white"
              />}
            >
              {
                pieData?.length > 0 && pieData.filter(x => !x.isCenter).map((x, i) => <Slice
                  key={"slice_" + i}
                  bg={x.color}
                  color="white"
                  onSelect={() => handleSelectItem(x.group)}
                  className={x.group === selectedItem ? "selected" : ""}
                  attrs={{
                    sourceid: i
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: i === 2 ? "rotateZ(180deg)" : ""
                    }}
                  >
                    {x.group}
                    {
                      x.data?.length && <span style={{
                        width: "1vw",
                        height: "1vw",
                        minWidth: "18px",
                        minHeight: "18px",
                        fontSize: "1rem",
                        marginLeft: 8,
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        color: x.color
                      }}
                      >
                        {countAmount(x)}
                      </span>
                    }
                  </div>
                </Slice>)
              }
            </PieMenu>
              :
              <LoaderIcon />
          }
        </Box>
        <Box
          ref={accordionRef}
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: selectedItem ? "100vw" : 0,
            overflow: "hidden",
            transition: "800ms"
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              whiteSpace: "pre"
            }}
          >
            {
              selectedItem && pieData.find(x => x.group === selectedItem) && <PerfectScrollbar
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <AccordionMenu
                  data={pieData.find(x => x.group === selectedItem)} selectedItem={selectedItem}
                />
              </PerfectScrollbar>
            }
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

CircularMenu.propTypes = {
  sidebarToggled: PropTypes.bool,
  data: PropTypes.array
}

export default CircularMenu;
