import { apiUtils } from './apiUtils';

class ProfileAPI {
  async getProfile() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getEmployeeRoleHistory(data) {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeRoleHistory?EmployeeUsername=${data.username}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getProfileAsAdmin(props) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeInfo`,
      params: props
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getProfileUserData(data) {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeUserData?Username=${data.username}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async saveProfileUserData(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/SaveUserData`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getOptions() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeTypes`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async GetEmployeeAcceptances(data) {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeAcceptances?type=${data.type}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async SaveEmployeeAcceptances(props) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/SaveEmployeeAcceptances`,
      params: props
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async GetEmployeesPhotos(usernames) {
    usernames = usernames ? `?Usernames=${usernames}` : '';
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeesPhotos${usernames}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async saveEmployeeInfo(data) {
    const input = {};
    input.EmployeeInfo = { ...data };
    input.EmployeeInfo.incomeHolderID = data.incomeHolderID && data.incomeHolderID.value ? data.incomeHolderID.value : data.incomeHolderID;
    input.EmployeeInfo.maritialStatus = data.maritialStatus && data.maritialStatus.value ? data.maritialStatus.value : data.maritialStatus;
    input.EmployeeInfo.category = data.category && data.category.value ? data.category.value : data.category;
    input.EmployeeInfo.contractTypeID = data.contractTypeID && data.contractTypeID.value ? data.contractTypeID.value : data.contractTypeID;
    input.EmployeeInfo.department = data.department && data.department.value ? data.department.value : data.department;
    input.EmployeeInfo.departmentRoleID = data.departmentRoleID && data.departmentRoleID.value ? data.departmentRoleID.value : data.departmentRoleID;
    input.EmployeeInfo.employee_SYS_STATUS_ID = data.employee_SYS_STATUS_ID && data.employee_SYS_STATUS_ID.value ? data.employee_SYS_STATUS_ID.value : data.employee_SYS_STATUS_ID;
    input.EmployeeInfo.deliveryCenterTypeID = data.deliveryCenterTypeID && data.deliveryCenterTypeID.value ? data.deliveryCenterTypeID.value : 0;
    input.EmployeeInfo.countryID = data.countryID && data.countryID.value ? data.countryID.value : 0;

    input.academicQualifications = data.academicQualifications.map(q => {
      return { ...q, academicDegree: q.academicDegree && q.academicDegree.value ? q.academicDegree.value : q.academicDegree }
    });
    input.certifications = data.certifications.map(q => {
      return {
        ...q,
        certificationID: q.certificationID && q.certificationID.value ? q.certificationID.value : q.certificationID,
        certificatorID: q.certificatorID && q.certificatorID.value ? q.certificatorID.value : q.certificatorID,
      }
    });

    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/SaveEmployeeInfo`,
      params: input
    };
    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async saveEmployeeInfoAsAdmin(data) {
    const input = {};
    input.EmployeeInfo = { ...data };
    input.EmployeeInfo.incomeHolderID = data.incomeHolderID && data.incomeHolderID.value ? data.incomeHolderID.value : data.incomeHolderID;
    input.EmployeeInfo.maritialStatus = data.maritialStatus && data.maritialStatus.value ? data.maritialStatus.value : data.maritialStatus;
    input.EmployeeInfo.category = data.category && data.category.value ? data.category.value : data.category;
    input.EmployeeInfo.contractTypeID = data.contractTypeID && data.contractTypeID.value ? data.contractTypeID.value : data.contractTypeID;
    input.EmployeeInfo.department = data.department && data.department.value ? data.department.value : data.department;
    input.EmployeeInfo.departmentRoleID = data.departmentRoleID && data.departmentRoleID.value ? data.departmentRoleID.value : data.departmentRoleID;
    input.EmployeeInfo.employee_SYS_STATUS_ID = data.employee_SYS_STATUS_ID && data.employee_SYS_STATUS_ID.value ? data.employee_SYS_STATUS_ID.value : data.employee_SYS_STATUS_ID;
    input.EmployeeInfo.deliveryCenterTypeID = data.deliveryCenterTypeID && data.deliveryCenterTypeID.value ? data.deliveryCenterTypeID.value : 0;
    input.EmployeeInfo.countryID = data.countryID && data.countryID.value ? data.countryID.value : 0;
    if (data.newRoleStartDate !== null) { input.newRoleStartDate = data.newRoleStartDate; }

    input.academicQualifications = data.academicQualifications?.map(q => {
      return { ...q, academicDegree: q.academicDegree && q.academicDegree.value ? q.academicDegree.value : q.academicDegree }
    });
    input.certifications = data.certifications?.map(q => {
      return {
        ...q,
        certificationID: q.certificationID && q.certificationID.value ? q.certificationID.value : q.certificationID,
        certificatorID: q.certificatorID && q.certificatorID.value ? q.certificatorID.value : q.certificatorID,
      }
    });

    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/SaveEmployeeInfoAsAdmin`,
      params: input
    };
    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async GetEmployeeCVData(params) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeCVData`,
      params: params
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async getInfoInactiveUser(username) {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/GetEmployeeDeparture?EmployeeUsername=${username}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async saveInfoInactiveUser(data) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/Employee/SaveEmployeeDeparture`,
      params: data
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }
}

export const profileAPI = new ProfileAPI();
