import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import DuplicateIcon from '../icons/Duplicate';
import DownloadIcon from '../icons/Download';
import XIcon from '../icons/X';
import bytesToSize from '../utils/bytesToSize';
import VisibilityIcon from '@material-ui/icons/Visibility';

const CustomFileDropzone = (props) => {
  const {
    accept,
    disabled,
    files,
    // getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    // noClick,
    // noDrag,
    // noDragEventsBubbling,
    // noKeyboard,
    onDrop,
    // onDropAccepted,
    onDropRejected,
    // onFileDialogCancel,
    onRemove,
    // onRemoveAll,
    // onUpload,
    // preventDropOnDocument,
    hasError,
    onView,
    onDownload,
    enableDownload,
    preview = true,
    sx,
    key,
    icon,
    label = "Ficheiro",
    ...rest
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
    onDropRejected,
    hasError,
    rest
  });

  // const toBase64 = (file) => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = (error) => reject(error);
  // });

  // const openfile = async (file) => {
  //   const data = !file.fileUploaded ? await toBase64(file) : file.fileB64;
  //   const a = document.createElement('a');
  //   a.href = data;
  //   a.download = file.name;
  //   a.click();
  // };

  return (
    <Box
      key={key}
      sx={sx}
    >
      {
        files?.length < maxFiles && (
          <Box
            sx={{
              mb: 2,
              alignItems: 'center',
              border: 1,
              borderRadius: 1,
              borderColor: hasError ? 'error.main' : 'divider',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              outline: 'none',
              p: 1,
              ...(isDragActive && {
                backgroundColor: 'action.active',
                opacity: 0.5
              }),
              '&:hover': {
                backgroundColor: 'action.hover',
                cursor: 'pointer',
                opacity: 0.5
              }
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Box sx={{ p: 2 }}>
              <Typography
                color={hasError ? 'error' : 'textPrimary'}
                variant="h1"
              >
                {`Selecionar ${label}${(maxFiles && maxFiles === 1) ? '' : 's'} (max: ${bytesToSize(maxSize)})`}
              </Typography>
            </Box>
          </Box>
        )
      }
      {
        files?.length > 0 && (
          <Box>
            <List>
              {files.map((file, index) => (
                <ListItem
                  key={index.toString()}
                  sx={{
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                    /*'& + &': {
                      mt: 1
                    }*/
                  }}
                >
                  {
                    icon ? <Box
                      sx={{
                        height: 36,
                        mr: 2
                      }}
                    >
                      {icon}
                    </Box> : <ListItemIcon>
                      <DuplicateIcon fontSize="small" />
                    </ListItemIcon>
                  }
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{
                      color: 'textPrimary',
                      variant: 'subtitle2'
                    }}
                    secondary={file.size ? bytesToSize(file.size) : ''}
                  />
                  {enableDownload && (
                    <Tooltip title="Download">
                      <IconButton
                        edge="end"
                        onClick={() => onDownload && onDownload(file, false)}
                      >
                        {
                          file?.fileB64 ?
                            <Link
                              download={file.name}
                              href={file.fileB64}
                            >
                              <DownloadIcon />
                            </Link>
                            :
                            <DownloadIcon />
                        }
                      </IconButton>
                    </Tooltip>
                  )}
                  {
                    preview && <Tooltip title="Visualizar">
                      <IconButton
                        edge="end"
                        onClick={() => onView && onView(file)}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  }
                  {!disabled && (
                    <Tooltip title="Remover">
                      <IconButton
                        edge="end"
                        onClick={() => onRemove && onRemove(file)}
                      >
                        <XIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        )
      }
    </Box>
  );
};

CustomFileDropzone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
  hasError: PropTypes.bool,
  onView: PropTypes.func,
  preview: PropTypes.bool,
  onDownload: PropTypes.func,
  enableDownload: PropTypes.bool,
  sx: PropTypes.object,
  icon: PropTypes.object,
  label: PropTypes.string,
  key: PropTypes.any
};

CustomFileDropzone.defaultProps = {
  files: [],
  enableDownload: false
};

export default CustomFileDropzone;
