export const PORTAL = '903001';
export const PORTAL_PROFILE = '903003';
export const PORTAL_TEAM = '903006';
export const PORTAL_ADMINISTRATION = '903010';

export const PORTAL_PROFILE_EXPENSES = '903024';
export const PORTAL_PROFILE_PER_DIEMS = '903025';
export const PORTAL_PROFILE_REGISTRATION = '903004';
export const PORTAL_PROFILE_VACATIONS = '903005';
export const PORTAL_PROFILE_VACATIONS_APPROVAL = '903013';

export const PORTAL_PROFILE_HOURS = '903015';
export const PORTAL_PROFILE_PROJECT_EVALUATION = '903033';
export const PORTAL_PROFILE_TEAM_MANAGEMENT = '903011';

export const PORTAL_TEAM_EXPENSES_APPROVAL = '903026';
export const PORTAL_TEAM_PER_DIEMS_APPROVAL = '903027';
export const PORTAL_TEAM_HOURS_CONTROL = '903016';
export const PORTAL_TEAM_HOURS_CONTROL_BY_PROJECT = '903047';
export const PORTAL_TEAM_VACATIONS_APPROVAL = '903013';

export const PORTAL_PERF_EVAL_DASHBOARD = '903035';
export const PORTAL_PERF_EVAL_TEAM = '903036';
export const PORTAL_PERF_EVAL_ADMINISTRATION = '903034';

export const PORTAL_RECRUITMENT = '903030';
export const PORTAL_RECRUITMENT_PROCESS = '903031';
export const PORTAL_RECRUITMENT_OPPORTUNITY = '903032';
export const PORTAL_RECRUITMENT_REMOVE_CANDIDATES = '903032001';
export const PORTAL_RECRUITMENT_EDIT_ALL_MODULES = '903032003';
export const PORTAL_RECRUITMENT_DOWNLOAD_FILES = '903032005';
export const PORTAL_RECRUITMENT_OPPORTUNITY_FILTERED = '903032009';
export const PORTAL_RECRUITMENT_ACCESS = '903055';
export const PORTAL_RECRUITMENT_STAFF_REPORT = '903032006';
export const PORTAL_RECRUITMENT_STAFF_REPORT_MANAGEMENT = '903032007';
export const PORTAL_RECRUITMENT_STAFF_REPORT_DOWNLOAD_FILES = '903032008';

export const PORTAL_ADMINISTRATION_PROJECTS = '903022';
export const PORTAL_ADMINISTRATION_HOURS_CONTROL = '903023';
export const PORTAL_ADMINISTRATION_EXPENSES_APPROVAL = '903028';
export const PORTAL_ADMINISTRATION_APPROVAL = '903029';
export const PORTAL_ADMINISTRATION_GENERAL_VACATIONS = '903007';
export const PORTAL_ADMINISTRATION_DOWNLOAD_FILES = '903053';

export const PORTAL_GOALS_MANAGEMENT = '903018';
export const PORTAL_GOALS_AREA_MANAGEMENT = '903038';
export const PORTAL_GOALS_STRATEGY_MANAGEMENT = '903039';
export const PORTAL_EVALUATION_DOCUMENTS_MANAGEMENT = '903040';

export const PORTAL_RECRUITMENT_UNLOCK_MODULES = '903041';

export const PORTAL_EVALUATION_GOALS_EVALUATION = '903043';
export const PORTAL_EVALUATION_CCA_EVALUATION_SAVE = '903044';
export const PORTAL_EVALUATION_CCA_EVALUATION_SUBMIT = '903045';

export const PORTAL_LANGUAGE_CHANGE = '903046';

export const PORTAL_PROFILE_TRAINING = '903050';
export const PORTAL_TEAM_TRAINING_APPROVAL = '903051';
export const PORTAL_ADMINISTRATION_TRAINING = '903052';
