import palette from '../palette';

export default {
    styleOverrides: {
        root: {
            "&.activeCard": {
                borderRadius: "4px 4px 0px 0px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)"
            },
            '&.cardTable': {
                margin: '31px 0px 31px 0px',
                minWidth: 275,
            },
            "&.collateralEvaluationSelector.sticky": {
                position: "fixed",
                zIndex: 1299,
                top: "70px"
            },
            "&.collateralEvaluationSelector+.collateralEvaluationSelector_placeholder": {
                height: "100px",
                display: "none"
            },
            "&.collateralEvaluationSelector.sticky+.collateralEvaluationSelector_placeholder": {
                display: "block"
            },
            '&.calendarOverrride': {
                padding: 0
            },
            '&.handsontableOverride': {
                border: "none",
                boxShadow: "none",
                width: '100%',
                '.handsontable tr.linegreen td': {
                    background: "#E7F9FC !important"
                },
                '.handsontable tr.lineyellow td': {
                    background: "#FFF6ED !important",
                },
                '.handsontable tr.linered td': {
                    width: '105%',
                    background: "#FDEEEE !important",
                },
                '.handsontable tr.linegrey td': {
                    background: palette.hours.primary,
                },
                '.handsontable tr.lineaccepted td': {
                    background: '#f5f5f5 !important',
                },
                '.handsontable tr.lineblocked td': {
                    background: '#E7F9FC !important',
                },
                '.handsontable th': {
                    background: palette.common.white,
                    border: "initial",
                    fontWeight: 600,
                    color: palette.text.themeBlue,
                    alignItems: 'center',
                    borderBottom: '1px solid #F9FAFB !important'
                },
                '.handsontable th, .handsontable td': {
                    border: 'none',
                    verticalAlign: 'middle',
                    height: '65px',
                    textAlign: 'center',
                    borderBottom: '1px solid #F9FAFB !important',
                    boxShadow: 'inset 0px -1px 0px #e7ecf0'
                },
                '.handsontable thead th .relative': {
                    fontWeight: 'bold'
                },
                // '.handsontable thead th': {
                //     borderBottom: '1px solid #c9c9c9'
                // },
                '.handsontable .htLeft': {
                    textAlign: 'left !important'
                },
                '.handsontable .ht_clone_top th:nth-of-type(2)': {
                    borderRight: 'none'
                },
                '.handsontable .htAutocompleteArrow': {
                    float: 'right',
                    fontSize: 10,
                    color: palette.text.themeBlue,
                    cursor: 'pointer !important'
                },
                '.handsontable .htCheckboxRendererInput': {
                    accentColor: palette.common.white,
                    color: palette.common.white,
                    margin: 'unset'
                },
                '#hot-display-license-info': {
                    display: 'none'
                },
                '.handsontable.listbox': {
                    height: '400px !important',
                    '.ht_master': {
                        '.wtHolder': {
                            height: '400px !important',
                            overflow: 'none'
                        }
                    }
                },
                '.handsontable.listbox .ht_master table': {
                    borderSpacing: '0px',
                    color: palette.common.black,
                    background: palette.common[500],
                    'td': {
                        width: '100%',
                        color: palette.text.themeBlue,
                        background: palette.common.white,
                        verticalAlign: 'middle',
                        textAlign: 'left',
                        '&:hover': {
                            background: palette.divider,
                        }
                    },
                },

                'table.htCore': {
                    boxShadow: 'none',
                    borderRadius: '0',
                    textAlign: 'left',
                    background: palette.common.white,
                    tableLayout: 'fixed',
                    width: '100%',
                    'td': {
                        width: '100%',
                        color: palette.text.themeBlue,
                    },
                    '&:nth-of-type(7)': {
                        'td': {
                            padding: '0 8px 0 8px !important'
                        },
                    },
                },
                '.handsontable .htCenter': {
                    '&:hover': {
                        background: palette.divider
                    }
                },
                '.handsontable.listbox tr td.current, .handsontable.listbox tr:hover td': {
                    '&:hover': {
                        background: palette.divider
                    }
                },
            },
        }
    }
};
