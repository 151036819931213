import React, { useState } from 'react';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const CollapsibleSidebarElement = ({ children, title, startCollapsed }) => {
    const [collapsed, setCollapsed] = useState(startCollapsed ? true : false);
    return (
       <>
            <ListItem button onClick={() => setCollapsed(!collapsed)}>
                <ListItemText primary={<b>{title}</b>} />
                {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={collapsed}>
                {children}
            </Collapse>
        </>
    )
};

CollapsibleSidebarElement.propTypes = {
    children: PropTypes.object,
    title: PropTypes.string,
    startCollapsed: PropTypes.bool
}

export default CollapsibleSidebarElement;
