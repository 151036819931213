import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Divider, Typography, DialogTitle, IconButton, DialogContent, DialogActions, Box, CircularProgress } from '@material-ui/core';
import {
  Send as SendIcon,
  Close as CloseIcon,
  OpenInNew as OpenInNewIcon,
  CloseFullscreen as CloseFullscreenIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const ModalTemplate = ({ open, title, size = "sm", content, isSubmitting, onClose, onConfirm, canConfirm = true, onCancel, customActions, icon, canFullscreen, fullscreen = false, customDisplay = false, }) => {
  const [isFullscreen, setIsFullscreen] = useState(fullscreen);
  const toggleFullscreen = () => setIsFullscreen(canFullscreen ? !isFullscreen : fullscreen);
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={size}
      onClose={onClose}
      open={Boolean(open)}
      fullScreen={isFullscreen}
    >
      {
        title && (
          <>
            <DialogTitle
              disableTypography
              sx={{
                display: "flex",
                p: 2
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <>
                  {
                    icon && (
                      <Box
                        sx={{ display: "flex", marginRight: "10px" }}
                      >
                        {icon}
                      </Box>
                    )
                  }
                  {
                    typeof title === "string" ? <Typography
                      variant="h1"
                    >
                      {title}
                    </Typography>
                      :
                      title
                  }
                </>
              </Box>
              <Box
                sx={{
                  ml: "auto",
                  display: "flex",
                }}
              >
                {
                  canFullscreen && <IconButton
                    aria-label="close"
                    onClick={toggleFullscreen}
                    size="small"
                  >
                    {isFullscreen ? <CloseFullscreenIcon fontSize="medium" /> : <OpenInNewIcon fontSize="medium" />}
                  </IconButton>
                }
                {
                  onClose &&
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              </Box>
            </DialogTitle>
            <Divider />
          </>
        )
      }
      <DialogContent
        sx={{
          py: 1,
          px: 2,
          display: customDisplay ? customDisplay : "",
        }}
      >
        {content}
      </DialogContent>
      {(customActions || onConfirm || onCancel) && <>
        <Divider />
        <DialogActions
          sx={{
            py: 1,
            px: 2
          }}
        >
          <>
            {
              onCancel && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onCancel || null}
                  disabled={isSubmitting !== undefined ? isSubmitting : false}
                  startIcon={<CloseIcon />}
                >
                  {t('common:buttons.cancel')}
                </Button>
              )
            }
            {
              customActions ?
                customActions
                :
                onConfirm && (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    type="submit"
                    onClick={onConfirm}
                    disabled={isSubmitting !== undefined || canConfirm !== undefined ? isSubmitting || !canConfirm : false}
                    startIcon={isSubmitting ? <CircularProgress size="20px" /> : <SendIcon />}
                  >
                    {t('common:buttons.confirm')}
                  </Button>
                )
            }
          </>
        </DialogActions>
      </>}
    </Dialog>
  );
};

ModalTemplate.propTypes = {
  open: PropTypes.bool,
  fullscreen: PropTypes.bool,
  canFullscreen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  size: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  canConfirm: PropTypes.bool,
  onCancel: PropTypes.func,
  customActions: PropTypes.object,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  customDisplay: PropTypes.string,
};

export default ModalTemplate;
