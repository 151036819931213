import React from 'react';
import { PieCenter } from 'react-pie-menu';
import PropTypes from 'prop-types';

const MyPieCenter = ({ data, padding = 20, selectItem, selectedItem, altColor, background, color }) => {
  return <PieCenter id="center">
    <div
      onClick={() => selectItem(data)}
      className={data?.group === selectedItem ? "selected" : ""}
      style={{
        background: background,
        color: color || altColor,
        height: `calc(100% - ${padding}px)`,
        width: `calc(100% - ${padding}px)`,
        margin: `${padding / 2}px`
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          maxHeight: "5rem"
        }}
      >
        {data?.group}
        {
          data?.data?.length && <span style={{
            width: "1vw",
            height: "1vw",
            minWidth: "18px",
            minHeight: "18px",
            fontSize: "1rem",
            marginLeft: 8,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            color: altColor
          }}
          >
            {data.count}
          </span>
        }
      </div>
      {
        false && data?.data && <ul>
          {
            data.data.map((sub, index) => {
              return <li key={'sub_' + index}>{sub.group}</li>
            })
          }
        </ul>
      }
    </div>
  </PieCenter>
}

MyPieCenter.propTypes = {
  data: PropTypes.object,
  padding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectItem: PropTypes.func,
  selectedItem: PropTypes.string,
  altColor: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string
}

export default MyPieCenter;
