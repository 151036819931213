export default {
    spacing: 8,
    themeColor1: process?.env?.REACT_APP_THEME_PRIMARY_COLOR || "#00B098",
    themeColor2: process?.env?.REACT_APP_THEME_SECONDARY_COLOR || "#40BB73",
    themeColor3: process?.env?.REACT_APP_THEME_TERTIARY_COLOR || "#96C93D",
    commonWhite: "#fff",
    commonGray: "#C1C1C1",
    commonBlack: "#000",
    textPrimary: process?.env?.REACT_APP_THEME_TEXTPRIMARY_COLOR || "#0b3b66",
    textSecondary: process?.env?.REACT_APP_THEME_TEXTSECONDARY_COLOR || "#B8BBC2",
    textError: "#D97880",
    details: {
        main: "#E7ECF0",
        contrastText: "#0B3B66"
    },
    paperWhite: "#f4f6f8",
    alertlevels: {},
    states: {
        success: {
            contrastText: "#FFF",
            main: "#0CB232",
            description: "conclusão positiva"
        },
        fail: {
            contrastText: "#FFF",
            main: "#EF5858",
            description: "conclusão negativa"
        },
        pending: {
            contrastText: "#FFF",
            main: "#FFA348",
            description: "Em espera de acção, pendente, admitido condicional, admitido automático"
        },
        submitted: {
            contrastText: "#FFF",
            main: "#0BC0E4",
            description: "Submissão"
        },
        draft: {
            contrastText: "#FFF",
            main: "#BECCD7",
            description: "Rascunho"
        },
        ongoing: {
            contrastText: "#FFF",
            main: "#07869F",
            description: "Em andamento"
        },
        archived: {
            contrastText: "#FFF",
            main: "#0B3B66",
            description: "Arquivado"
        }
    },
    labels: {
        no_go: {
            contrastText: "#fff",
            main: "#D97880"
        },
        fail: {
            contrastText: "#fff",
            main: "#E8AEB3"
        },
        go: {
            contrastText: "#fff",
            main: "#E1B090"
        },
        ongoing: {
            contrastText: "#fff",
            main: "#8EDADF"
        },
        draft: {
            contrastText: "#fff",
            main: "#707889"
        },
        standby: {
            contrastText: "#fff",
            main: "#B8BBC2"
        }
    },
    others: {
        greenHover1: "#007363",
    }
};
