// import { colors } from '@material-ui/core';

export default {
  styleOverrides: {
    root: {
      "&.customState": {
        "& .MuiAlert-message, .MuiAlert-icon": {
          paddingTop: 0
        },
        "& .MuiAlert-icon": {
          marginRight: 0,
          paddingBottom: 0,
          paddingRight: 16,
          alignItems: "center",
          justifyContent: "center",
          width: "calc(8px * 4)"
        },
        "&.MuiAlert-filledError": {
          backgroundColor: '#FDEEEE',
          color: '#EF5858'
        },
        "&.MuiAlert-filledWarning": {
          backgroundColor: '#FFF6ED',
          color: '#FFA348'
        },
        "&.MuiAlert-filledInfo": {
          backgroundColor: '#E7F9FC',
          color: '#0BC0E4'
        },
        "&.MuiAlert-filledSuccess": {
          backgroundColor: '#E7F7EA',
          color: '#0CB232'
        }
      }
    }
  }
};
