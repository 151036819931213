import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Link,
  Avatar,
  useTheme,
  CircularProgress,
  CardHeader,
  CardContent,
  IconButton,
  Autocomplete,
  ListItem,
  FormControlLabel,
  useMediaQuery
} from '@material-ui/core';
import {
  Search as SearchIcon
} from '@material-ui/icons';
import Scrollbar from './Scrollbar';
import TableAction from './TableUtils/TableAction';
import TableHeader from './TableUtils/TableHeader';
import TableLoading from './TableUtils/TableLoading';
import { applyPagination, applySort, applyFilters } from './TableUtils/TableUtils';
import { useTranslation } from 'react-i18next';
import getPhotos from '../utils/getEmployeesPhotos';
import Modal from './ModalTemplate';
import { isBase64 } from '../utils/base64Utils';
import Label from './Label';
import { workflowAPI } from '../api/workflowAPI';
import toast from 'react-hot-toast';
import TimelineModal from './Timeline/Monthly/timelineModal';
import moment from 'moment';

const ConfirmModal = ({ action, content, open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const actionTitle = () => {
    switch (action) {
      case "REMOVE":
        return t('common:messages.removeSelectedTitle');
      default:
        break;
    }
  }

  return (
    <Modal
      size="md"
      onClose={onClose}
      onCancel={onClose}
      open={open}
      title={actionTitle()}
      onConfirm={onConfirm}
      content={
        <>
          {content}
        </>
      }
    />
  );
}

ConfirmModal.propTypes = {
  action: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

/*
    uniqueID: identifies row map keys
    to: adds a wrapping link element with a replaceable placeholder %to%
    avatar: Adds and avatar pre-pending the id content;
    onClick: Adds a click event,
    unit: append unit to value

*/
const defaultHeaderCells = [
  { id: "employeeID", uniqueID: true, hidden: true },
  { id: 'employeeName', label: "Name", sort: true, filter: true, avatar: "employeeUsername", to: "/dashboard/hr/trainingCatalogue/%to%" },
];

const CustomTable = ({
  data,
  headerCells = defaultHeaderCells,
  customButtons,
  title,
  tableCustomActions,
  handleRemoveAction,
  isLoading,
  statusSearch,
  statusOptions,
  options,
  hasCheckbox
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const initialSort = headerCells.find(x => x.initialSort);
  const [orderBy, setOrderBy] = useState(initialSort ? initialSort.id : headerCells[1].id);
  const [order, setOrder] = useState(initialSort ? initialSort.order || "asc" : "asc");
  const { t } = useTranslation();
  const [photoMap, setPhotoMap] = useState(new Map());
  const theme = useTheme();
  const [uniqueID, setUniqueID] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const [paginatedData, setPaginatedData] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const hasDefaultActions = !!handleRemoveAction;
  const hasBulkActions = Boolean(hasDefaultActions || tableCustomActions);
  const [statusModalLoading, setStatusModalLoading] = useState(true);
  const [statusTimelineData, setStatusTimelineData] = useState(false);
  const [openTimelineModal, setOpenTimelineModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const dateTimeFormat = "DD/MM/YYYY";
  const [selectedValue, setSelectedValue] = useState();
  const [checkedValue, setCheckedValue] = useState(false);
  const isMobile = useMediaQuery('(max-width:998px)');

  //TableActions
  const handleSelectAll = () => {
    setSelected(!selected?.length || paginatedData?.length > selected.length ? paginatedData.map(x => x[uniqueID]) : []);
  };

  const handleSelectOne = (event, id) => {
    if (!selected.includes(id)) {
      setSelected((prevSelected) => [...prevSelected, id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((_id) => _id !== id));
    }
  };

  const handleOpenStatusTimelineModal = async (sourceID) => {
    setStatusModalLoading(true);
    setOpenTimelineModal(true);
    const workflowStatus = await workflowAPI.GetWorkflowStatus({ sourceID: [sourceID] });
    if (workflowStatus && workflowStatus.value && !workflowStatus.error) {
      setStatusTimelineData(workflowStatus.value[0]);
      setStatusModalLoading(false);
    } else {
      if (workflowStatus.description) {
        toast.error(workflowStatus.description);
        setStatusTimelineData({});
      }
    }
  }

  //Pagination
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleStatusChange = (value) => {
    setPage(0);
    setSelectedStatus(value);
  };

  const handleCheckedValue = () => {
    setPage(0);
    setCheckedValue(!checkedValue);
  }

  const handleSelectedValue = (value) => {
    setPage(0);
    setSelectedValue(value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  }

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleGetPhotos = async (userList) => {
    if (userList) {
      const newUserList = [];
      for (const user_ of userList) {
        if (!photoMap.get(user_)) {
          newUserList.push(user_);
        }
      }
      if (newUserList.length > 0) {
        const newMap = await getPhotos(userList, photoMap);
        if (newMap) {
          setPhotoMap(newMap);
        }
      }
    }
  };

  const handleConfirm = (type) => {
    let content = "";
    switch (type) {
      case "REMOVE":
        content = {
          action: "REMOVE",
          content: <>
            {t('common:messages.removeSelected')}
          </>
        };
        break;
      default:
        break;
    }
    setConfirmModalOpen(content);
  }

  useEffect(() => {
    const avatarKey = headerCells.find(x => x.avatar);
    if (!isLoading && paginatedData && avatarKey) {
      let usernames = [
        ...paginatedData
          .filter((x) => {
            if (x[avatarKey.avatar] === null) {
              return x[avatarKey.id] && x[avatarKey.id] !== "" && !isBase64(x[avatarKey.id]);
            }
            return x[avatarKey.avatar] && x[avatarKey.avatar] !== "" && !isBase64(x[avatarKey.avatar]);
          })
          .map((avatar) => (avatar[avatarKey.avatar] !== null ? avatar[avatarKey.avatar] : avatar[avatarKey.id]))];
      usernames = [...new Set(usernames)];
      handleGetPhotos(usernames);
    }
  }, [paginatedData]);

  useEffect(() => {
    setSelected([]);
    if (headerCells?.length) {
      const unique = headerCells.find(x => x.uniqueID);
      const _uniqueID = unique?.id ? unique.id : headerCells[1] ? headerCells[1].id : null;
      setUniqueID(_uniqueID);
    }
    if (headerCells?.length && data?.length) {
      const filters = {
        status: selectedStatus?.length > 0 ? selectedStatus.filter(x => x.key).map(x => x.key) : null
      }
      let _data = data;
      if (selectedValue) {
        _data = data.filter(item => item.externalTrainingTypeDesc === selectedValue.text)
      }
      if (checkedValue) {
        _data = data.filter(x => x.hasCCP)
      }
      const _filteredData = applyFilters(headerCells, _data, query, filters);
      const sortedData = applySort(_filteredData, orderBy, order);
      const _paginatedData = applyPagination(sortedData, page, limit);
      setFilteredData(_filteredData);
      setPaginatedData(_paginatedData);
    } else {
      setFilteredData([]);
      setPaginatedData([]);
    }
  }, [headerCells, data, page, limit, order, orderBy, query, selectedStatus, selectedValue, checkedValue]);

  return (
    <Box sx={{ py: 2 }}>
      <Card>
        {
          title && <><CardHeader title={title} /><Divider /></>
        }
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: 2
            }}
          >
            <Box
              sx={{
                maxWidth: '100%',
                width: 300,
                mb: isMobile ? 2 : 0
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('vacations:label.search')}
                value={query}
                variant="outlined"
              />
            </Box>
            {options &&
              <Box
                sx={{
                  maxWidth: '100%',
                  width: 250,
                  ml: isMobile ? 0 : 2,
                  mb: isMobile ? 2 : 0
                }}
              >
                <Autocomplete
                  fullWidth
                  options={options?.filter(x => x.value !== false)}
                  getOptionLabel={(option) => option.text}
                  onChange={(e, value) => handleSelectedValue(value)}
                  value={selectedValue}
                  renderInput={
                    (_params) =>
                      <TextField
                        {..._params}
                        variant="outlined"
                        label={t("LJ:trainingType")}
                      />
                  }
                />
              </Box>}
            {statusSearch &&
              <Box
                sx={{
                  maxWidth: '100%',
                  width: 250,
                  ml: isMobile ? 0 : 2
                }}
              >
                <Autocomplete
                  fullWidth
                  options={statusOptions?.filter(x => x.key !== false)}
                  getOptionLabel={(option) => option.text}
                  onChange={(e, value) => handleStatusChange(value)}
                  value={selectedStatus}
                  multiple
                  renderOption={_props => (
                    /*eslint-disable*/
                    <ListItem
                      {..._props}
                    >
                      <Label
                        customColor={statusOptions[_props["data-option-index"]].color}
                      >
                        {statusOptions[_props["data-option-index"]].text}
                      </Label>
                    </ListItem>
                    /*eslint-enable*/
                  )}
                  renderInput={
                    (_params) =>
                      <TextField
                        {..._params}
                        variant="outlined"
                        label={t('common:labels.status')}
                      />
                  }
                />
              </Box>}
            {hasCheckbox &&
              <Box
                sx={{
                  ml: 2
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedValue}
                      onChange={handleCheckedValue}
                    />
                  }
                  label={t('LJ:ccpTrainers')}
                />
              </Box>}
            <Box
              sx={{
                ml: "auto"
              }}
            >
              {
                customButtons && typeof (customButtons) === "object" ?
                  customButtons
                  :
                  customButtons?.map((action, action_index) => <Box key={`action_${action_index}`}>{action}</Box>)
              }
            </Box>
          </Box>
          <Box>
            <TableAction
              enableBulkActions={hasBulkActions && selected.length >= 1}
              enableEditAction={selected.length === 1}
              selectedCounter={selected.length}
              customActions={tableCustomActions}
              handleRemoveAction={() => handleConfirm('REMOVE')}
              enableRemove
            />
            <Divider />
            <Scrollbar>
              <Box sx={{ p: 2, minWidth: 700 }}>
                <Table stickyHeader>
                  <TableHeader
                    headCells={headerCells}
                    selectedAll={Boolean(paginatedData.length > 0 && selected.length === paginatedData.length)}
                    selectedSome={Boolean(paginatedData.length > 0 && selected.length && selected.length < paginatedData.length)}
                    onSelectAllClick={handleSelectAll}
                    onSortClick={handleRequestSort}
                    orderBy={orderBy}
                    order={order}
                    hasCheckbox={hasBulkActions}
                  />
                  <TableBody>
                    <TableLoading
                      isLoading={isLoading}
                      headCells={headerCells}
                      numRows={limit}
                    />
                    {!isLoading && paginatedData.length > 0 ?
                      paginatedData.map((x, index) => {
                        const isSelected = selected.includes(x[uniqueID]);
                        return (
                          <TableRow
                            hover
                            key={`${x[uniqueID]}_${index}`}
                            selected={isSelected}
                          >
                            {
                              (hasDefaultActions || tableCustomActions) && <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isSelected}
                                  color="primary"
                                  onChange={(event) => handleSelectOne(event, x[uniqueID])}
                                  value={isSelected}
                                  sx={{
                                    cursor: "pointer"
                                  }}
                                />
                              </TableCell>
                            }
                            {
                              headerCells.filter(f => !f.hidden).map((headerCell, headerCell_index) => {
                                let cellContent = `${x[headerCell?.id]}`;
                                switch (headerCell?.type) {
                                  case "date":
                                    cellContent = moment(x[headerCell?.id]).format(dateTimeFormat);
                                    break;
                                  default:
                                    break;
                                }
                                if (headerCell.avatar) {
                                  const isEmail = x[headerCell.avatar]?.includes('@'); //Check if it is an email
                                  const _isBase64 = x[headerCell.avatar] !== null;

                                  cellContent = <Box
                                    key={`${x[headerCell.id]}_${headerCell_index}`}
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    {
                                      _isBase64 ?
                                        (
                                          isEmail ?
                                            !photoMap || photoMap.get(x[headerCell.avatar]) === "" ?
                                              <CircularProgress />
                                              :
                                              <Avatar
                                                mx={2}
                                                src={`data:image/png;base64, ${photoMap.get(x[headerCell.avatar])}`}
                                              />
                                            :
                                            <Avatar
                                              mx={2}
                                              src={`data:image/png;base64,${x[headerCell.avatar]}`}
                                            />
                                        )
                                        :
                                        !photoMap || photoMap.get(x[headerCell.id]) === "" ?
                                          <CircularProgress />
                                          :
                                          <Avatar
                                            mx={2}
                                            src={`data:image/png;base64, ${photoMap.get(x[headerCell.id])}`}
                                          />
                                    }
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        alignSelf: 'center',
                                      }}
                                    >
                                      {x[headerCell.id]}
                                    </div>
                                  </Box>
                                }
                                if (headerCell.to) {
                                  cellContent = <Link
                                    color="inherit"
                                    component={RouterLink}
                                    to={headerCell.to.replace("%to%", x[headerCell.to])}
                                    variant="subtitle2"
                                    sx={{
                                      color: 'primary.main',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {cellContent}
                                  </Link>
                                }
                                if (headerCell.onClick) {
                                  cellContent = <button
                                    type="button"
                                    onClick={() => headerCell.onClick(x[uniqueID])}
                                    style={{
                                      width: "100%",
                                      background: "transparent",
                                      textAlign: "left",
                                      border: "none",
                                      outline: "none",
                                      boxShadow: "none",
                                      color: theme.palette.primary.main,
                                      cursor: "pointer"
                                    }}
                                  >
                                    {cellContent}
                                  </button>
                                }
                                if (headerCell.action) {
                                  let passes = !headerCell.actionCondition;
                                  if (!passes) {
                                    if (headerCell.actionCondition.what && (headerCell.actionCondition.equals || headerCell.actionCondition.differs)) {
                                      if (headerCell.actionCondition.equals) {
                                        passes = headerCell.actionCondition.equals && x[headerCell.actionCondition.what] === headerCell.actionCondition.equals;
                                      }
                                      if (headerCell.actionCondition.differs) {
                                        passes = headerCell.actionCondition.differs && x[headerCell.actionCondition.what] !== headerCell.actionCondition.differs;
                                      }
                                    }
                                  }
                                  cellContent = passes ? <IconButton
                                    onClick={() => headerCell.action(x[uniqueID])}
                                  >
                                    {headerCell.icon}
                                  </IconButton> : <></>
                                }
                                if (headerCell.customActions) {
                                  let passes = !headerCell.actionCondition;
                                  if (!passes) {
                                    if (headerCell.actionCondition.what && (headerCell.actionCondition.equals || headerCell.actionCondition.differs)) {
                                      if (headerCell.actionCondition.equals) {
                                        passes = headerCell.actionCondition.equals && x[headerCell.actionCondition.what] === headerCell.actionCondition.equals;
                                      }
                                      if (headerCell.actionCondition.differs) {
                                        passes = headerCell.actionCondition.differs && x[headerCell.actionCondition.what] !== headerCell.actionCondition.differs;
                                      }
                                    }
                                  }
                                  cellContent = passes ? headerCell.customActions(x[uniqueID]) : <></>
                                }
                                if (headerCell.statusOptions) {
                                  const currentStatus = headerCell.statusOptions.find(s => s.key === x[headerCell.id])
                                    ||
                                    headerCell.statusOptions.find(s => s.key === "A");
                                  cellContent = <Label
                                    customColor={currentStatus?.color}
                                    onClick={() => {
                                      if (headerCell.workflow) {
                                        handleOpenStatusTimelineModal(x[uniqueID]);
                                      }
                                    }}
                                    sx={{
                                      cursor: headerCell.workflow ? "pointer" : "initial"
                                    }}
                                  >
                                    {currentStatus?.text}
                                  </Label>
                                }
                                return <TableCell
                                  align={headerCell.align || "left"}
                                  key={`tablecell_${x[uniqueID]}_${headerCell_index}`}
                                >
                                  {cellContent}
                                </TableCell>
                              })
                            }
                          </TableRow>
                        )
                      })
                      :
                      !isLoading && <TableRow>
                        <TableCell
                          colSpan={100}
                        >
                          {t("common:tableActions.noResults")}
                        </TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
            <TablePagination
              component="div"
              count={filteredData?.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              showFirstButton
              showLastButton
            />
          </Box>
        </CardContent>
      </Card>
      <ConfirmModal
        open={confirmModalOpen}
        action={confirmModalOpen?.action}
        content={confirmModalOpen?.content}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => {
          switch (confirmModalOpen.action) {
            case "REMOVE":
              handleRemoveAction(data.filter(x => selected.includes(x[uniqueID])));
              setConfirmModalOpen(false);
              break;
            default:
              break;
          }
        }}
      />
      <TimelineModal
        open={openTimelineModal}
        onClose={() => setOpenTimelineModal(false)}
        timelineData={statusTimelineData}
        modalLoading={statusModalLoading}
      />
    </Box>
  );
};

CustomTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array
  ]).isRequired,
  headerCells: PropTypes.array.isRequired,
  title: PropTypes.string,
  customButtons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  tableCustomActions: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRemoveAction: PropTypes.func,
  statusSearch: PropTypes.bool,
  statusOptions: PropTypes.array,
  options: PropTypes.array,
  hasCheckbox: PropTypes.bool
};

export default CustomTable;
