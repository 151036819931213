import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
// import ContactsPopover from './ContactsPopover';
// import ContentSearch from './ContentSearch';
// import LanguagePopover from './LanguagePopover';
// import Logo from '../Logo';

const NavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const Navbar = () => (
  <NavbarRoot>
    <Toolbar sx={{ minHeight: 64, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <RouterLink to="/">
        <Box
          sx={{
            '& img': {
              width: 100,
              height: 36
            }
          }}
        >
          <img
            alt="Components"
            src="/static/logo.svg"
          />
        </Box>
      </RouterLink>
      <Box
        sx={{
          flexGrow: 1,
          ml: 2
        }}
      />
      {/* <LanguagePopover /> */}
      {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box> */}
    </Toolbar>
  </NavbarRoot>
);

export default Navbar;
