import { useContext } from 'react';
import { Box, Drawer, Divider, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import CollapsibleSidebarElement from './CollapsibleSidebarElement';
import FAQList from '../FAQList';
import FiltersList from '../FiltersList';
import FiltersContext from '../../Contexts/FiltersContext';
import { useTranslation } from 'react-i18next';
import SidebarContext from '../../Contexts/SidebarContext';

const Sidebar = ({ isMobile, navHeight, drawerWidth }) => {
    const { filtersData, filtersSelections, clearFilters, updateFilterSelection } = useContext(FiltersContext);
    const { sidebarToggled, handleToggleSidebar } = useContext(SidebarContext);
    const { t } = useTranslation();
    return (
        <Drawer
            className="LJ_sidebar"
            sx={{
                zIndex: 1000000000,
                '& .MuiDrawer-paper': {
                    height: `calc(100vh - ${navHeight}px)`,
                    mt: `${navHeight}px`,
                    width: drawerWidth
                }
            }}
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={sidebarToggled}
            onClose={() => handleToggleSidebar(false)}
        >
            <PerfectScrollbar>
                <Box
                    sx={{
                        py: 2
                    }}
                >
                    <CollapsibleSidebarElement
                        title="LEARNING JOURNEY"
                    >
                        <FAQList />
                    </CollapsibleSidebarElement>
                    <Divider sx={{ m: 2, borderColor: "#e0e0e033" }} />
                    <CollapsibleSidebarElement
                        title={t('LJ:advancedFilters')}
                        startCollapsed
                    >
                        <FiltersList data={filtersData} filtersSelections={filtersSelections} updateFilterSelection={updateFilterSelection} clearFilters={clearFilters} />
                    </CollapsibleSidebarElement>
                </Box>
            </PerfectScrollbar>

            <Divider sx={{ borderColor: "#e0e0e033" }} />
            <Box
                sx={{
                    py: 2,
                    px: 3
                }}
            >
                <Typography
                    align="center"
                >
                    {t('LJ:version')} {process.env.REACT_APP_BUILD_BUILDNUMBER}.{process.env.REACT_APP_ASPNETCORE_ENVIRONMENT === "QA" ? "1" : "0"}
                </Typography>
            </Box>

        </Drawer>
    )
};

Sidebar.propTypes = {
    isMobile: PropTypes.bool,
    navHeight: PropTypes.number,
    drawerWidth: PropTypes.number
}

export default Sidebar;
