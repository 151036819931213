import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { getAvailableTrainingModuleTypes } from '../API/LJAPI';
import { useTranslation } from 'react-i18next';

const initialFilters = [];

export const restoreFilters = async ({ t }) => {
    let filters = null;

    const result = await getAvailableTrainingModuleTypes();
    if (result && !result.error) {
        filters = result.value.filter(x => x.filter);
    } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
        result.exceptionMessages.messages.forEach(m => toast.error(m.description));
    } else {
        toast.error(t ? t("common:common.errors.unexpected") : "Unexpected error");
    }
    /*
    try {
        const storedData = window.localStorage.getItem('LJfilters');
        filters = storedData ? JSON.parse(storedData) : []
    } catch (err) {
        console.error(err);
        // If stored data is not a strigified JSON this will fail,
        // that's why we catch the error
    }*/

    return filters;
};

export const storeFilters = (key, filters) => {
    window.localStorage.setItem(`LJfilters`, JSON.stringify(filters));
};

const FiltersContext = createContext({
    data: initialFilters
});

export const FiltersProvider = (props) => {
    const { children } = props;
    const [filtersData, setFiltersData] = useState(initialFilters);
    const [filtersSelections, setfiltersSelections] = useState({});
    const { t } = useTranslation();

    const getData = async () => {
        const filters_ = await restoreFilters({ t: t });

        if (filters_) {
            setFiltersData(filters_);
        }
    }

    const clearFilters = () => {
        setfiltersSelections({});
    }

    const updateFilterSelection = (filterType, filterID, optionValue) => {
        const _filtersSelections = { ...filtersSelections };
        if (!_filtersSelections[filterID] && optionValue !== undefined) {
            _filtersSelections[filterID] = {
                type: filterType,
                value: optionValue
            }
        } else {
            if ((optionValue === undefined || !optionValue.value)
                || _filtersSelections[filterID].value === optionValue
                || _filtersSelections[filterID].value === null
                || _filtersSelections[filterID].value === "") {
                delete _filtersSelections[filterID];
            } else if (optionValue !== undefined) {
                _filtersSelections[filterID] = {
                    type: filterType,
                    value: optionValue
                }
            }
        }
        setfiltersSelections(_filtersSelections);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <FiltersContext.Provider
            value={{
                filtersData,
                updateFilterSelection,
                clearFilters,
                filtersSelections,
                setFiltersData
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
};

FiltersProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const FiltersConsumer = FiltersContext.Consumer;

export default FiltersContext;
