import React from 'react';
import {
  Box,
  Grid,
  Card,
  Skeleton,
} from '@material-ui/core';

const CardKPISkeleton = () => {
  return (
    <Card
      sx={{
        p: 2,
        height: "100%"
      }}
    >
      <Grid
        container
        sx={{
          height: "100%"
        }}
      >
        <Grid
          item
          xs={12}
        >
          <Skeleton
            variant="text"
            sx={{
              fontSize: '1rem',
              display: "inline-flex",
              fontWeight: "bold",
              minHeight: "50px",
              width: "100%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end"
            }}
          >
            <Grid
              container
            >
              <Grid
                item
                xs={12}
              >
                <Box
                  sx={{
                    mr: "10px"
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      display: "inline-flex",
                    }}
                  />
                </Box>
                <Skeleton
                  variant="text"
                  sx={{
                    display: "inline-flex",
                    fontSize: '1rem',
                    width: "50%",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
};

export default CardKPISkeleton;
