import palette from "../palette";

export default {
    styleOverrides: {
        popper: {
            "&#projectApprovalListTooltip": {
                "& .MuiTooltip-tooltip": {
                    padding: 8,
                    fontSize: 12,
                    opacity: "95% !important",
                    //maxWidth: "min-content",
                    minWidth: 200,
                    color: "white"
                },
                "& table th, & table td": {
                    fontSize: "inherit",
                    color: "inherit"
                },
                "& table th:first-of-type, & table td:first-of-type": {
                    paddingLeft: 8
                },
                "& table th:last-of-type, & table td:last-of-type": {
                    paddingRight: 8
                },
                "& div[data-type='TEAM'] table thead": {
                    display: "none",
                    //background: "#FFFFFF22"
                },
                "& div[data-type='PROJECT'] table thead tr": {
                    background: palette.primary.main,
                    "& th:first-of-type": {
                        borderRadius: "4px 0 0 4px"
                    },
                    "& th:last-of-type": {
                        borderRadius: "0 4px 4px 0"
                    }
                },
                "& table": {
                    tableLayout: "fixed",
                    "& th, & td": {
                        border: "none",
                        padding: 4,
                        lineHeight: "1rem"
                    }
                },
                "& div>ul": {
                    paddingLeft: "15px"
                }
            }
        }
    }
};
