import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      ".MuiButton-root": {
        color: palette.text.primary,
        '&.active': {
          color: palette.primary.main
        }
      }
    }
  }
};
