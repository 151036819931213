import React, { useState } from 'react';
import {
  Close as CloseIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { Box, Typography, Button, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ModalTemplate';
import { trainingModuleAPI } from '../../../../api/trainingModuleAPI';
import toast from 'react-hot-toast';
import moment from 'moment';

const dateTimeFormat = "DD/MM/YYYY HH:mm";

const TrainingActions = ({ data = {}, refresh }) => {
  const [confirmEnroleModal, setConfirmEnroleModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const handleEnrole = async () => {
    const payload = {
      trainingEventID: data.trainingEventID,
      registryType: data.nEnrolled === data.nVacancies ? "WAIT_LIST" : "STANDARD",
      trainingEventRegistryID: data.trainingEventRegistryID
    };
    let result;
    setIsSubmitting(true);
    if (data.canEnrole && !data.canCancel) {
      result = await trainingModuleAPI.trainingEventRegister(payload);
    } else {
      result = await trainingModuleAPI.trainingEventCancelRegister(payload);
    }
    setIsSubmitting(false);
    if (result && !result.error) {
      toast.success(result?.description);
      setConfirmEnroleModal(false);
    } else {
      if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length > 0) {
        result.exceptionMessages.messages.forEach(m => {
          toast.error(m.description);
        })
      } else {
        toast.error('Erro desconhecido');
      }
    }
    refresh();
  }

  return !data ?
    null
    :
    <>
      {
        !data.canEnrole && !data.canCancel && <Button
          variant="contained"
          sx={{
            ml: 2
          }}
          onClick={
            () => window.open(`mailto:${process.env.REACT_APP_MAILTO}?subject=${t('LJ:interestedInTraining')} - ${data.trainingModuleName}&body=${t('LJ:mail.part1')}%0D%0A%0D%0A${data.mainCategoryName} > ${data.subCategoryName} > ${data.trainingModuleName}%0D%0A%0D%0A${t('LJ:mail.part2')}%0D%0A}`, "_blank")
          }
        >
          {t('LJ:interestedInTraining')}
        </Button>
      }
      {
        data.canCancel ? <Tooltip
          title={!"expired" ? "Excedeu o período de cancelamento" : ""}
          disabled="expired"
          disableHoverListener
        >
          <div>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              onClick={() => setConfirmEnroleModal(true)}
            >
              {t("common:buttons.cancel")}
            </Button>
          </div>
        </Tooltip>
          :
          data.canEnrole &&
          <Tooltip
            title={!"expired" ? "Excedeu o período de cancelamento" : ""}
            disabled="expired"
            disableHoverListener
          >
            <div>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => setConfirmEnroleModal(true)}
              >
                {t("training:buttons.enroll")}
              </Button>
            </div>
          </Tooltip>
      }
      <Modal
        open={confirmEnroleModal}
        title={`${t("training:options.signup")} - ${data.trainingEventName}`}
        content={
          <Box>
            <Box>
              <b>{t("training:details.startDateDesc")}: </b> {moment(data.trainingEventSessions[0]?.startDate).format(dateTimeFormat)}<br />
              <b>{t("training:details.sessionsDesc")}: </b>{data.nSessions} <br />
              <b>{t("training:details.cancellationLimitDesc")}: </b>{moment(data.cancelationLimit).format('DD/MM/YYYY')}<br />
              <b>{t("training:options.communicationDate")}: </b>{data.communicationDate}<br />
              <br />
            </Box>
            {
              data.canCancel ?
                <Typography>{t("training:messages.cancelSignup")}</Typography>
                :
                data.nEnrolled === data.nVacancies && data.nVacancies !== 0 ?
                  <Typography>{t("training:messages.proceedQueueSignup")}</Typography>
                  :
                  <Typography>{t("training:messages.proceedSignup")}</Typography>
            }
          </Box>
        }
        onClose={() => setConfirmEnroleModal(false)}
        onCancel={() => setConfirmEnroleModal(false)}
        onConfirm={handleEnrole}
        isSubmitting={isSubmitting}
      />
    </>
};

TrainingActions.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  refresh: PropTypes.func
}

export default TrainingActions;
