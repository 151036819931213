import { apiUtils } from './apiUtils';

class UserAPI {
  async AzureAuthenticate() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/User/AzureAuthenticate`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async Authenticate() {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/User/Authenticate`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async LdapLogin(email, password) {
    const Input = {
      type: 'POST',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/User/LdapLogin`,
      params: {
        Email: email,
        Password: password
      }
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }

  async DecodeToken(tid) {
    const Input = {
      type: 'GET',
      method: `${process.env.REACT_APP_WEBAPI_URL}/api/User/DecodeToken?token=${tid}`,
      params: {}
    };

    const response = await apiUtils.handleMethod(Input);

    return response;
  }
}

export const userAPI = new UserAPI();
