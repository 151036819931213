import PropTypes from 'prop-types';
import Label from '../../Label';
import {
  AvatarGroup,
  Avatar,
  Box,
} from '@material-ui/core';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import moment from 'moment';

const TimelineModalItem = ({ stepData, statusOption, photoMap }) => {
  return (
    <TimelineItem sx={{ width: "100%" }}>
      <TimelineOppositeContent
        sx={{
          m: 'auto 0',
          fontWeight: "bold",
          fontSize: "16px",
          display: "flex",
          justifyContent: "space-between"
        }}
        align="right"
      >
        <Box>
          <Box>
            {stepData.tasks.map(task => task.employeeName).join(", ")}
            {stepData.skipOverOtherTasks ? " *" : ""}
          </Box>
          <Box
            sx={{ fontWeight: "bold", }}
            variant="body2"
            color="text.secondary"
          >
            {stepData.displayName}
          </Box>
          {stepData.skipOverOtherTasks &&
            <Box
              variant="subtitle2"
              color="text.primary"
            >
              * Ignorar outras aprovações
            </Box>}
        </Box>
        <Label
          customColor={statusOption.find(x => x.value === stepData.stepStatus).color}
          sx={{ float: 'right', maxHeight: '25px' }}
        >
          {statusOption.find(x => x.value === stepData.stepStatus).label}
        </Label>
      </TimelineOppositeContent>
      <TimelineSeparator sx={{ flex: 3 }}>
        <TimelineConnector sx={{ height: '5px' }} />
        <TimelineDot
          sx={{
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: '100%',
          }}
        >
          <AvatarGroup
            max={4}
            sx={{
              width: '100%',
              justifyContent: 'center'
            }}
          >
            {stepData.tasks.map((task, taskIndex) => <Avatar
              key={`avatar_${taskIndex}`}
              src={`data:image/png;base64, ${photoMap.get(task.userId)}`}
              alt={task.employeeName}
              sx={{
                width: 40,
                height: 40,
              }}
            />)}
          </AvatarGroup>
        </TimelineDot>
        <TimelineConnector sx={{ height: '5px' }} />
      </TimelineSeparator>
      <TimelineContent
        sx={{ m: 'auto 0', fontWeight: "bold", }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        <Box>
          {moment.utc(stepData.tasks[0]?.sysModifyDate, "YYYY-MM-DD HH:mm:ss").locale('pt-Pt').format('DD/MM/YYYY')}
        </Box>
        <Box>
          {moment.utc(stepData.tasks[0]?.sysModifyDate, "YYYY-MM-DD HH:mm:ss").locale('pt-Pt').format('HH:mm')}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
}

TimelineModalItem.propTypes = {
  stepData: PropTypes.object,
  statusOption: PropTypes.array,
  photoMap: PropTypes.any,
};

export default TimelineModalItem;
