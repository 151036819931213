import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import menu_pt from './translations/pt/menu.json';
import menu_en from './translations/en/menu.json';
import timesheet_pt from './translations/pt/timesheet.json';
import timesheet_en from './translations/en/timesheet.json';
import common_pt from './translations/pt/common.json';
import common_en from './translations/en/common.json';
import vacations_pt from './translations/pt/vacations.json';
import vacations_en from './translations/en/vacations.json';
import objectives_pt from './translations/pt/objectives.json';
import objectives_en from './translations/en/objectives.json';
import training_pt from './translations/pt/training.json';
import training_en from './translations/en/training.json';
import employee_pt from './translations/pt/employee.json';
import employee_en from './translations/en/employee.json';
import profile_pt from './translations/pt/profile.json';
import profile_en from './translations/en/profile.json';
import evaluation_pt from './translations/pt/evaluation.json';
import evaluation_en from './translations/en/evaluation.json';
import LJ_pt from './translations/pt/LJ.json';
import LJ_en from './translations/en/LJ.json';

const resources = {
  en: {
    menu: menu_en,
    timesheet: timesheet_en,
    common: common_en,
    vacations: vacations_en,
    objectives: objectives_en,
    training: training_en,
    employee: employee_en,
    profile: profile_en,
    evaluation: evaluation_en,
    LJ: LJ_en
  },
  pt: {
    menu: menu_pt,
    timesheet: timesheet_pt,
    common: common_pt,
    vacations: vacations_pt,
    objectives: objectives_pt,
    training: training_pt,
    employee: employee_pt,
    profile: profile_pt,
    evaluation: evaluation_pt,
    LJ: LJ_pt
  },
};

const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES?.split(';') || [];

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: availableLanguages.length > 1 && localStorage.getItem('preferedLanguage') ? localStorage.getItem('preferedLanguage') : availableLanguages[0],
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false
    }
  });
