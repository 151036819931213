import { useEffect, useState } from 'react';
import { Autocomplete, Button, Box, Grid, Container, Tooltip, TextField, useTheme, IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  Add as PostAddIcon,
  KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon,
  MoreVert as MoreVertIcon
} from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import CardKPI from '../../../components/CardKPI';
import Bread from '../../../components/Bread';
import { getTimelineData } from '../API/LJAPI';
import { trainingModuleAPI } from '../../../api/trainingModuleAPI';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../components/CustomTable';
import RequestTrainingModal from "./RequestTrainingModal";
import { useNavigate } from 'react-router-dom';
import CardKPISkeleton from '../../../components/CardKPI/CardKPISkeleton';

const Timeline = () => {
  const currentYear = new Date().getFullYear();
  const [yearOptions, setYearOptions] = useState([currentYear, currentYear - 1, currentYear - 2, currentYear - 3]);
  const [data, setData] = useState(false);
  const [externalData, setExternalData] = useState(false);
  const [KPI, setKPI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [externalTableLoading, setExternalTableLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedTraining, setSelectedTraining] = useState(false);
  const [trainingMethodOptions, setTrainingMethodOptions] = useState([]);
  const [trainingTypesOptions, setTrainingTypesOptions] = useState([]);
  const theme = useTheme();
  const defaultBreadcrumb = [
    { label: "Learning Journey", link: "/" },
    { label: t("LJ:myCourse") }
  ];
  const navigate = useNavigate();

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedID, setSelectedID] = useState(null)

  const handleClick = (event, id) => {
    setSelectedID(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenRequestTrainingModal = (id) => {
    if (id) {
      const externalTraining = externalData.find(x => x.externalTrainingID === id)
      setSelectedTraining(externalTraining);
    } else {
      setSelectedTraining(null);
    }
  }

  const handleRowClick = (id) => {
    if (selectedID) {
      const history = data?.find(x => x.rowID === selectedID);

      if (history?.trainingEventID) {
        navigate(`/detail/${history.trainingEventID}`)
      } else if (history?.externalTrainingID) {
        handleOpenRequestTrainingModal(history.externalTrainingID);
      }
      handleClose()
    }
    const history_data = data?.find(x => x.rowID === id);
    if (history_data?.trainingEventID) {
      navigate(`/detail/${history_data.trainingEventID}`)
    } else if (history_data?.externalTrainingID) {
      handleOpenRequestTrainingModal(history_data.externalTrainingID);
    }
  }

  const MenuItemComponent = (id) => {
    const uniqueID = data.find(x => x.rowID === selectedID);
    const showMenuItem = uniqueID?.registrationStatus === "E";

    return (
      <>
        <IconButton onClick={(event) => handleClick(event, id)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleRowClick}>{t('training:main.detail')}</MenuItem>
          {
            ((showMenuItem && uniqueID?.feedbackSurveyPath)) && <MenuItem
              onClick={
                () => {
                  window.open(
                    uniqueID.feedbackSurveyPath,
                    '_blank'
                  )
                  handleClose();
                }
              }
            >
              {t('LJ:feedbackSurvey')}
            </MenuItem>
          }
        </Menu>
      </>
    )
  }

  const statusOptions = [
    { text: t('common:status.canceled'), key: 'X', color: theme.palette.states.fail },
    { text: t('common:status.rejected'), key: 'R', color: theme.palette.states.fail },
    { text: t('LJ:status.externalRegistryPending'), key: 'PR', color: theme.palette.states.pending },
    { text: t('LJ:status.externalRequestPending'), key: 'PP', color: theme.palette.states.pending, },
    { text: t('LJ:status.externalRequestPendingEvidence'), key: 'YF', color: theme.palette.states.pending },
    { text: t('LJ:status.approved'), key: 'Y', color: theme.palette.states.success },
    { text: t('LJ:status.externalRequestOngoing'), key: 'YS', color: theme.palette.states.success },
    { text: t('LJ:status.concluded'), key: 'T', color: theme.palette.states.ongoing }
  ];

  const historyStatusOptions = [
    { text: t('LJ:status.accepted'), key: 'Y', color: theme.palette.states.ongoing },
    { text: t('LJ:status.enroled'), key: 'S', color: theme.palette.states.pending },
    { text: t('LJ:status.concluded'), key: 'T', color: theme.palette.states.success },
    { text: t('LJ:status.pendingEvaluation'), key: 'E', color: theme.palette.states.pending },
    { text: t('LJ:status.notConcluded'), key: 'N', color: theme.palette.states.fail },
  ];

  const headerCells = [
    { id: 'rowID', uniqueID: true, hidden: true },
    { id: 'trainingModuleName', label: t('training:options.training'), filter: true, onClick: handleRowClick },
    { id: 'mainCategoryName', label: t('LJ:competency'), filter: true },
    { id: 'subCategoryName', label: t('LJ:subcompetency'), filter: true },
    { id: 'trainingModuleTypeDesc', label: t('training:details.type'), filter: true, hidden: true },
    { id: 'totalHours', label: `${t('training:details.duration')}(h)`, filter: true, unit: "H", align: "center" },
    { id: 'credits', label: t('training:options.credits'), filter: true, align: "center" },
    { id: 'registrationStatus', label: t('common:labels.status'), align: "right", filter: false, statusOptions: historyStatusOptions },
    { id: "action", align: "center", customActions: MenuItemComponent }
  ];

  const external_headerCells = [
    { id: 'externalTrainingID', uniqueID: true, hidden: true },
    { id: 'externalTrainingName', label: t('LJ:name'), onClick: handleOpenRequestTrainingModal, sort: true, filter: true },
    { id: 'totalHours', label: `${t('training:details.duration')}(h)`, align: "center", filter: true },
    { id: 'credits', label: t('training:options.credits'), align: "center", filter: true },
    { id: "endDate", label: t('LJ:endDate'), type: "date", align: "center" },
    { id: "externalTrainingTypeDesc", label: t('LJ:externalTrainingType') },
    { id: 'detailedStatus', label: t('common:labels.status'), filter: false, align: "right", statusOptions: statusOptions },
    { id: "action", label: "", align: "center", icon: <KeyboardArrowRightRoundedIcon />, action: handleOpenRequestTrainingModal, style: { paddingLeft: 0, paddingRight: 0, width: "20px" } }
  ];

  const getData = async () => {
    try {
      setIsLoading(true)
      setExternalTableLoading(true)
      setData([])
      setExternalData(false)
      const result_dummy = await getTimelineData(selectedYear);
      const history_result = await trainingModuleAPI.getEmployeeTrainingHistory(selectedYear);
      const result = await trainingModuleAPI.getTrainingRequests(selectedYear);
      if (result_dummy && !result_dummy.error) {
        setKPI(result.value?.trainingKPIs || []);
        const historyData = history_result.value?.employeeTrainingHistory || [];
        historyData.forEach((item, index) => {
          item.rowID = index + 1;
        });
        setData(historyData);
        handleSetExternalData(result.value?.externalTrainings || []);
        setTrainingMethodOptions(handleTypeListToOptions(result.value?.trainingMethodTypes))
        setTrainingTypesOptions(handleTypeListToOptions(result.value?.trainingTypes))
      } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
        result.exceptionMessages.messages.forEach(m => toast.error(m.description));
      } else {
        toast.error(t("common:common.errors.unexpected"));
      }
      setIsLoading(false);
      setExternalTableLoading(false)
    } catch (err) {
      console.error(err);
    }
  }

  const getExternalData = async () => {
    setExternalData([])
    setExternalTableLoading(true);
    const result = await trainingModuleAPI.getTrainingRequests(selectedYear);
    if (result && !result.error) {
      handleSetExternalData(result.value?.externalTrainings || []);
      setTrainingMethodOptions(handleTypeListToOptions(result.value?.trainingMethodTypes))
      setTrainingMethodOptions(handleTypeListToOptions(result.value?.trainingMethodTypes))
    } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
      result.exceptionMessages.messages.forEach(m => toast.error(m.description));
    } else {
      toast.error(t("common:common.errors.unexpected"));
    }
    setExternalTableLoading(false)
  }

  function handleTypeListToOptions(List) {
    const types = [];

    List.forEach((type) => {
      types.push({
        value: type.code,
        text: type.description,
      });
    });

    return types;
  }

  let updatedData = [];
  if (Array.isArray(data)) {
    updatedData = data.map(item => ({
      ...item,
      credits: item.credits === 0 ? '-' : item.credits,
    }));
  }

  // let updatedExternalData = [];
  // if (Array.isArray(externalData) && externalData.length) {
  //     updatedExternalData = externalData.map(item => ({
  //         ...item,
  //         credits: item.credits === 0 ? '-' : item.credits,
  //     }));
  // }

  function handleSetExternalData(_data) {
    let updatedExternalData = [];
    if (Array.isArray(_data) && _data.length) {
      updatedExternalData = _data.map(item => ({
        ...item,
        credits: item.credits === 0 ? '-' : item.credits,
      }));
    }
    setExternalData(updatedExternalData)
  }

  useEffect(() => {
    getData();
  }, [selectedYear]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!yearOptions.includes(currentYear)) {
        setYearOptions((prevYears) => [currentYear, ...prevYears]);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [yearOptions]);

  return (
    <>
      <Helmet>
        <title>{window.platform_name + " | "}{t("LJ:myCourse")}</title>
      </Helmet>
      <Container
        maxWidth={false}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Bread
              levels={defaultBreadcrumb}
              sx={{
                ".additionalContent": {
                  ml: "auto !important"
                }
              }}
              additionalContent={
                <Box>
                  <Autocomplete
                    disableClearable
                    options={yearOptions}
                    getOptionLabel={(option) => option.toString()}
                    sx={{ width: 150 }}
                    defaultValue={selectedYear}
                    onChange={(e, value) => {
                      setSelectedYear(value);
                    }}
                    renderInput={
                      (params) =>
                        <TextField
                          {...params}
                          label={t('common:labels.year')}
                          variant="outlined"
                        />
                    }
                  />
                </Box>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid
              container
              spacing={2}
            >
              {isLoading ?
                [...Array(4)].map((e, i) => <Grid
                  key={`cardkpi_${i}`}
                  item
                  xs={12}
                  sm={4}
                  md={3}
                >
                  <CardKPISkeleton key={`cardkpi_${i}`} />
                </Grid>)
                :
                KPI?.length > 0 && KPI.map((kpi, kpiIndex) => (
                  <Grid
                    key={`KPIData_${kpiIndex}`}
                    item
                    xs={12}
                    sm={KPI.length > 0 ? (12 / KPI.length) > 3 ? 3 : 12 / KPI.length : 12}
                  >
                    <CardKPI
                      title={kpi.label}
                      value={kpi.value}
                      unit={kpi.unit}
                      sx={{
                        "& .MuiTypography-root": {
                          mr: 1
                        }
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {
              data && <CustomTable
                title={t('LJ:myInternalCourse')}
                data={updatedData}
                headerCells={headerCells}
                isLoading={isLoading}
              />
            }
          </Grid>
          <Grid
            item
            xs={12}
          >
            {
              externalData && <CustomTable
                title={t('LJ:externalTrainings')}
                data={externalData || []}
                headerCells={external_headerCells}
                isLoading={isLoading || externalTableLoading}
                customButtons={
                  <Tooltip
                    title={t('LJ:externalTraining')}
                  >
                    <Button
                      variant="contained"
                      startIcon={<PostAddIcon />}
                      onClick={handleOpenRequestTrainingModal}
                    >
                      {t('LJ:externalTraining')}
                    </Button>
                  </Tooltip>
                }
              />
            }
          </Grid>
        </Grid>
      </Container>
      {
        selectedTraining !== false && <RequestTrainingModal
          open={selectedTraining !== false}
          onClose={(refresh) => { setSelectedTraining(false); if (refresh) { getData() } }}
          detail={selectedTraining}
          formatOptions={trainingMethodOptions}
          trainingTypesOptions={trainingTypesOptions}
          refreshData={getExternalData}
        />
      }
    </>
  )
};

export default Timeline;
