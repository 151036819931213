import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Box, Button, Divider, CircularProgress } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Modal from '../../ModalTemplate';
import { useTranslation } from 'react-i18next';

const TrainingRequestRejectModal = ({ onClose, open, confirm }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('Motivo de rejeição')}
            onClose={onClose}
            open={open}
            content={
                <Formik
                    initialValues={{
                        justification: ''
                    }}
                    enableReinitialize
                    validationSchema={Yup
                        .object()
                        .shape({
                            justification: Yup.string().required(t('common:messages.reason')),
                        })}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            confirm(values.justification);
                        } catch (err) {
                            setErrors(err);
                            toast.error(t('common:messages.error'));
                        }
                    }}
                >
                    {({
                        errors,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        values
                    }) => (
                        <form
                            autoComplete="off"
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        rows={4}
                                        name="justification"
                                        placeholder={t("Motivo de rejeição")}
                                        onChange={(e) => setFieldValue(`justification`, e.target.value)}
                                        value={values?.motive}
                                        error={Boolean(errors.justification)}
                                        helperText={errors.justification}
                                        variant="outlined"
                                        type="text"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                ml: 2
                                            }}
                                            onClick={onClose}
                                            disabled={isSubmitting}
                                            startIcon={isSubmitting && <CircularProgress size={20} />}
                                        >
                                            {t('common:buttons.cancel')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                ml: 2
                                            }}
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}
                                            startIcon={isSubmitting && <CircularProgress size={20} />}
                                        >
                                            {t('common:buttons.submit')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            }
        />
    );
};

TrainingRequestRejectModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    confirm: PropTypes.func
};

export default TrainingRequestRejectModal;
