import { shiftColor, changeOpacity } from '../utils/colorUtils';
import themeConfig from './themeConfig';

export default {
  type: "light",
  contrastThreshold: 3,
  tonalOffset: 0.2,
  details: themeConfig.details,
  common: {
    100: themeConfig.commonWhite,
    250: themeConfig.commonGray,
    500: themeConfig.textSecondary,
    1000: themeConfig.paperWhite,
    black: themeConfig.commonBlack,
    white: themeConfig.commonWhite,
    paper: themeConfig.paperWhite,
    mainBackGround: themeConfig.commonWhite
  },
  primary: {
    contrastText: themeConfig.commonWhite,
    main: themeConfig.themeColor1,
    transparent: {
      15: changeOpacity(themeConfig.themeColor1, 15),
      20: changeOpacity(themeConfig.themeColor1, 20),
      30: changeOpacity(themeConfig.themeColor1, 30),
      45: changeOpacity(themeConfig.themeColor1, 45),
      60: changeOpacity(themeConfig.themeColor1, 60),
    },
    grad1: themeConfig.themeColor1,
    grad2: themeConfig.themeColor2,
    light: shiftColor(themeConfig.themeColor1, 1, 1.3),
    dark: shiftColor(themeConfig.themeColor1, 1, 0.65),
    mainGradient: `linear-gradient(200.98deg, ${themeConfig.themeColor1} 20%, ${themeConfig.themeColor3} 105%)`,
    lightGradient: `linear-gradient(200.98deg, ${themeConfig.themeColor2} 20%, ${themeConfig.themeColor3} 105%)`,
    grayGradient: `linear-gradient(200.98deg, ${themeConfig.commonGray} 20%, ${themeConfig.commonWhite} 200%)`,
    greenHover1: themeConfig.others.greenHover1,
  },
  hours: {
    primary: "#E7F7EA",
    primaryText: '#3AC059',
    secondary: "#F9FAFB",
    error: '#FDEEEE',
    none: "#859DB3"
  },
  hoursTable: {
    primary: "#E6F7F5",
    none: "#F4F6F8"
  },
  eval: {
    color1: changeOpacity(themeConfig.themeColor1, 95),
    color2: "#859db3"
  },
  secondary: {
    contrastText: themeConfig.paperWhite,
    main: themeConfig.commonWhite
  },
  error: {
    contrastText: themeConfig.commonWhite,
    main: "#c64244",
    light: "rgb(209, 103, 105)",
    dark: "rgb(138, 46, 47)"
  },
  warning: {
    light: "#ffb74d",
    main: "#ff9800",
    dark: "#f57c00",
    contrastText: themeConfig.commonWhite,
  },
  info: {
    light: "#64b5f6",
    main: "#2196f3",
    dark: "#1976d2",
    contrastText: themeConfig.commonWhite
  },
  success: {
    light: "#81c784",
    main: "#4caf50",
    dark: "#388e3c",
    contrastText: themeConfig.commonWhite,
  },
  text: {
    primary: themeConfig.textPrimary,
    primaryGradient: `linear-gradient(200.98deg, ${themeConfig.themeColor1} -16.92%, ${themeConfig.themeColor3} 208.13%)`,
    secondary: themeConfig.textSecondary,
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
    link: "#00bcd4",
    themeBlue: "#0b3b66",
    button: "#767676"
  },
  divider: "#eeeeee",
  background: {
    paper: themeConfig.commonWhite,
    default: themeConfig.paperWhite
  },
  gradient: {
    primary: {
      '1': themeConfig.themeColor1,
      '05': themeConfig.themeColor2,
      '00': themeConfig.themeColor3
    },
    secondary: {
      '1': themeConfig.themeColor1,
      '05': themeConfig.themeColor2,
      '00': themeConfig.themeColor3
    }
  },
  states: {
    ...themeConfig.states
  },
  TYPE_IN_ANALISYS: {
    contrastText: themeConfig.labels.ongoing.contrastText,
    main: themeConfig.labels.ongoing.main,
  },
  TYPE_TO_ANALISE: {
    contrastText: themeConfig.labels.draft.contrastText,
    main: themeConfig.labels.draft.main,
  },
  TYPE_GO: {
    contrastText: themeConfig.labels.go.contrastText,
    main: themeConfig.labels.go.main,
  },
  TYPE_NO_GO: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: themeConfig.labels.no_go.main,
  },
  TYPE_STAND_BY: {
    contrastText: themeConfig.labels.standby.contrastText,
    main: themeConfig.labels.standby.main,
  },
  TYPE_NOT_INTERVIEWED: {
    contrastText: themeConfig.labels.standby.contrastText,
    main: themeConfig.labels.standby.main,
  },
  TYPE_GIVE_UP: {
    contrastText: themeConfig.labels.fail.contrastText,
    main: themeConfig.labels.fail.main,
  },
  TYPE_DRAFT: {
    contrastText: themeConfig.labels.draft.contrastText,
    main: themeConfig.labels.draft.main,
  },
  TYPE_ONGOING: {
    contrastText: themeConfig.labels.ongoing.contrastText,
    main: themeConfig.labels.ongoing.main,
  },
  TYPE_CANCEL: {
    contrastText: themeConfig.labels.fail.contrastText,
    main: themeConfig.labels.fail.main,
  },
  TYPE_ACCEPT: {
    contrastText: themeConfig.labels.go.contrastText,
    main: themeConfig.labels.go.main,
  },
  TYPE_ACCEPTED: {
    contrastText: themeConfig.labels.go.contrastText,
    main: themeConfig.labels.go.main,
  },
  TYPE_REJECT: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: themeConfig.labels.no_go.main,
  },
  TYPE_MOVED_TO_OPPORTUNITY: {
    contrastText: themeConfig.labels.go.contrastText,
    main: themeConfig.labels.go.main,
  },
  TYPE_F: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#0CB232",
  },
  TYPE_A: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#0BC0E4",
  },
  TYPE_FF: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#6DD184",
  },
  TYPE_AA: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#6DD9EF",
  },
  TYPE_C: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#FFC891",
  },
  TYPE_P: {
    contrastText: themeConfig.labels.ongoing.contrastText,
    main: themeConfig.labels.ongoing.main
  },
  TYPE_Y: {
    contrastText: themeConfig.labels.go.contrastText,
    main: themeConfig.labels.go.main
  },
  TYPE_D: {
    contrastText: themeConfig.labels.fail.contrastText,
    main: themeConfig.labels.fail.main
  },
  TYPE_T: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#0B3B66",
  },
  TYPE_E: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#07869F",
  },
  TYPE_W: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#859DB3",
  },
  TYPE_SUB: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#0BC0E4",
  },
  TYPE_PEN: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#FFA348",
  },
  TYPE_REP: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#EF5858",
  },
  TYPE_DISABLED: {
    contrastText: themeConfig.labels.no_go.contrastText,
    main: "#859DB3",
  },
  TYPE_COUNT: {
    contrastText: themeConfig.textPrimary + "!important",
    main: "#ebebeb",
  },
  TYPE_EMPTY: {
    contrastText: '#859DB3',
    main: "#ebebeb",
  },
  STATUS_W: {
    main: "#859DB3"
  },
  STATUS_F: {
    main: "#FFF6ED",
    secondary: shiftColor("#FFF6ED", 1, 0.8),
    contrastText: shiftColor("#FFF6ED", 1, 0.5)
  },
  STATUS_B: {
    main: "#E7F9FC",
    secondary: shiftColor("#E7F9FC", 1, 0.8),
    contrastText: shiftColor("#E7F9FC", 1, 0.5)
  },
  STATUS_P: {
    main: "#FFF6ED",
    secondary: shiftColor("#FFF6ED", 1, 0.8),
    contrastText: shiftColor("#FFF6ED", 1, 0.5)
  },
  STATUS_Y: {
    main: "#E7F7EA",
    //secondary: shiftColor("#E7F7EA", 1, 0.8),
    contrastText: shiftColor("#E7F7EA", 1, 0.5)
  },
  STATUS_D: {
    main: "#FDEEEE",
    //secondary: shiftColor("#FDEEEE", 1, 0.8),
    contrastText: shiftColor("#FDEEEE", 1, 0.5)
  },
  STATUS_EMPTY: {
    main: "#ebebeb",
    //secondary: shiftColor("#ebebeb", 1, 0.9),
    contrastText: shiftColor("#ebebeb", 1, 0.5)
  }
};
