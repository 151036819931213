import PropTypes from 'prop-types';
import Label from '../../Label';
import {
  Avatar,
  Box,
} from '@material-ui/core';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import moment from 'moment';

const TimelineModalInitialItem = ({ timelineData, statusOption, photoMap }) => {
  return (
    <TimelineItem sx={{ width: "100%" }}>
      <TimelineOppositeContent
        sx={{
          m: 'auto 0',
          fontWeight: "bold",
          fontSize: "16px",
          display: "flex",
          justifyContent: "space-between"
        }}
        align="right"
      >
        <Box>
          {timelineData.sysCreateEmployeeName}
        </Box>
        <Label
          customColor={statusOption.find(x => x.value === "SUBMITTED").color}
          sx={{ float: 'right', maxHeight: '25px' }}
        >
          {statusOption.find(x => x.value === "SUBMITTED").label}
        </Label>
      </TimelineOppositeContent>
      <TimelineSeparator sx={{ flex: 3 }}>
        <TimelineConnector sx={{ height: '5px' }} />
        <TimelineDot
          sx={{
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: "flex",
              justifyContent: 'center',
            }}
          >
            <Avatar
              src={`data:image/png;base64, ${photoMap.get(timelineData.sysCreateUserId)}`}
              alt={timelineData.sysCreateEmployeeName}
              sx={{
                width: 40,
                height: 40,
              }}
            />
          </Box>
        </TimelineDot>
        <TimelineConnector sx={{ height: '5px' }} />
      </TimelineSeparator>
      <TimelineContent
        sx={{ m: 'auto 0', fontWeight: "bold", }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        <Box>
          {moment.utc(timelineData.sysModifyDate, "YYYY-MM-DD HH:mm:ss").locale('pt-Pt').format('DD/MM/YYYY')}
        </Box>
        <Box>
          {moment.utc(timelineData.sysModifyDate, "YYYY-MM-DD HH:mm:ss").locale('pt-Pt').format('HH:mm')}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
}

TimelineModalInitialItem.propTypes = {
  timelineData: PropTypes.object,
  statusOption: PropTypes.array,
  photoMap: PropTypes.any,
};

export default TimelineModalInitialItem;
