import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card
} from '@material-ui/core';
import PropTypes from 'prop-types';
import KPIProgress from './KPIProgress';

const CardKPI = ({
    title,
    subtitle,
    value,
    unit = "",
    description,
    extraContent,
    sx
}) => {
    return (
        <Card
            sx={{
                p: 2,
                height: "100%",
                ...sx
            }}
        >
            <Grid
                container
                sx={{
                    height: "100%"
                }}
            >
                <Grid
                    item
                    xs={extraContent ? 6 : 12}
                >
                    <div
                        className="cardKPI_titles"
                        style={{
                            minHeight: "50px"
                        }}
                    >
                        <Typography
                            sx={{
                                display: "inline-flex",
                                fontWeight: "bold",
                            }}
                            variant="h2g"
                        >
                            <div dangerouslySetInnerHTML={{ __html: title }} />
                        </Typography>
                        <Typography
                            sx={{
                                minHeight: "50px",
                                color: "gray"
                            }}
                            variant="subtitle2"
                        >
                            <div dangerouslySetInnerHTML={{ __html: subtitle }} />
                        </Typography>
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-end"
                        }}
                    >
                        <Grid
                            container
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Box
                                    sx={{
                                        mr: "10px"
                                    }}
                                    className="cardKPI-values"
                                >
                                    {
                                        !value || typeof (value) === "string" ?
                                            <Typography
                                                className="cardKPI_value"
                                                sx={{
                                                    fontWeight: "bold",
                                                    fontSize: "30px",
                                                    display: "inline-flex"
                                                }}
                                            >
                                                {value ? value : '-'}
                                            </Typography>
                                            :
                                            value
                                    }
                                    {unit && <small className="cardKPI_unit" style={{ fontSize: "0.9rem" }}>{unit}</small>}
                                </Box>
                                {
                                    description && <Typography
                                        sx={{
                                            display: "inline-flex"
                                        }}
                                    >
                                        <small>{description}</small>
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {
                    extraContent && <Grid
                        item
                        xs={6}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "100%"
                            }}
                        >
                            {extraContent.variant === "linearContent" && <KPIProgress {...extraContent} />}
                        </Box>
                    </Grid>
                }
            </Grid>
        </Card>
    )
};

CardKPI.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    subtitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    unit: PropTypes.string,
    description: PropTypes.string,
    extraContent: PropTypes.object,
    sx: PropTypes.object,
}

export default CardKPI;
