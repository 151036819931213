export const getFAQs = async () => {
    return new Promise(res => {
        const data = {
            "error": false,
            "value": [
                {
                    title: "No que consiste a Learning Journey?",
                    description: `<div>
                    <p><span>Matriz transversal de desenvolvimento de competências dos Advogados e Colaboradores da VdA e VdA Legal Partners para o período 2021 a 2025.</span></p>
                    <p><span>Esta matriz assenta num modelo disruptivo de <strong>escolha livre, autónoma e responsável</strong>, em que cada um é o ator principal da sua <em>Learning Journey</em>. Cada um participa nas formações que melhor respondam aos seus objetivos individuais de desenvolvimento profissional, em alinhamento com aqueles que são os objetivos e necessidades da respetiva área em particular e da firma em geral.</span></p>
                    <p><span>As formações revestem carácter <strong>recomendado e opcional</strong> assinalando quais as formações indicadas para cada categoria, deixando de haver formações obrigatórias.</span></p>
                </div>`
                },
                {
                    title: "Quais as competências a desenvolver?",
                    description: `<div><p><span>As competências a desenvolver são aquelas que o próprio identificar para si em resposta às suas necessidades reais e imediatas.</span></p>
                    <p><span>As competências apresentadas no <em>Learning Journey</em> resultam do cruzamento de três fontes:</span></p>
                    <ol>
                        <li><span>As 5 macro competências avaliadas pelo <strong>Sistema de Gestão de Desempenho (SGD)</strong> da firma</span></li>
                        <li><span><strong>Sub-competências</strong> identificadas com base nos comportamentos avaliados pelo SGD</span></li>
                        <li><span>Necessidades formativas dos Advogados e Colaboradores aferidas no levantamento realizado a cada uma das áreas do escritório</span></li>
                    </ol>
                    <p><span>Destarte responde não só às necessidades dos Advogados e Colaboradores da firma como também às prioridades do escritório, com a vantajosa garantia de que qualquer que seja a escolha realizada estará sempre alinhada com o <em>supra</em> identificado.</span></p>
                    <p><span><br></span></p>
                    <p><span>Sucintamente, cada uma das competências visa desenvolver o seguinte:</span></p>
                    <p><span><strong>Gestão</strong></span></p>
                    <ul style="list-style-type: square;">
                        <li><u>Literacia Digital</u> - Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário</li>
                        <li><u><em>Project Management</em></u> - Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades</li>
                        <li><em><u>Engagement</u></em> - Compreender e integrar o contributo da atividade no funcionamento da Área, exercendo-a de forma disponível e diligente</li>
                        <li><u>Qualidade e Otimização</u> - Realizar, entregar e controlar o resultado do trabalho de forma eficaz</li>
                    </ul>
                    <p><span><br></span></p>
                    <p><span><strong><em>Know-How</em></strong></span></p>
                    <ul style="list-style-type: square;">
                        <li><span><u>Conhecimento Especializado</u> - Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções</span></li>
                        <li><span><u>Adaptabilidade e Resiliência</u> - Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica</span></li>
                        <li><span><u>Criatividade e Inovação</u> - Conceber novas soluções para os problemas e solicitações profissionais e desenvolver novos processos, com valor significativo para a Firma</span></li>
                    </ul>
                    <p><span><br></span></p>
                    <p><span><strong>Negócio</strong></span></p>
                    <ul style="list-style-type: square;">
                        <li><span><u>Gestão de Negócio</u> - Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma</span></li>
                        <li><span><u>Conhecimento do Negócio e Mercados</u> - Compreender de forma alargada o negócio da Firma e a sua dimensão global e internacional</span></li>
                        <li><span><u>Orientação para o Cliente</u> - Estabelecer, construir e potenciar relações de proximidade com o Cliente assim como ser promotor da colaboração entre as Áreas de Prática e de Gestão da Firma</span></li>
                    </ul>
                    <p><span><br></span></p>
                    <p><span><strong>Interação</strong></span></p>
                    <ul style="list-style-type: square;">
                        <li><span><em><u>Leadership &amp; Empowerment</u></em> - Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização</span></li>
                        <li><span><u>Relacionamento Interpessoal</u> - Interagir adequadamente com pessoas com diferentes características e em contextos sociais e profissionais distintos, suscitando confiança e contribuindo para uma cultura de coesão</span></li>
                        <li><span><u>Comunicação e Influência</u> - Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros</span></li>
                        <li><span><u>Colaboração</u> - Integrar em equipas de trabalho de constituição variada e gerar sinergias através de participação ativa</span></li>
                    </ul>
                    <p><span><br></span></p>
                    <p><span><strong>Alinhamento</strong></span></p>
                    <ul style="list-style-type: square;">
                        <li><span><u>Visão Estratégica</u> - Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área.&nbsp;Ter uma perspetiva de gestão alargada e direcionada para o futuro de modo a definir as estratégias e os objetivos de acordo com essa visão</span></li>
                        <li><span><u>Envolvimento e Experimentação</u> - Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação</span></li>
                        <li><span><u><em>Compliance</em> Interno</u> - Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma</span></li>
                    </ul>
                    <p><br></p>
                    <p><br></p>
                    <p><br></p>
                    <p><br></p></div>`
                },
                {
                    title: "Como construir o meu percurso individual de aprendizagem?",
                    description: `<div>
                                <p><span>A <em>Learning Journey&nbsp;</em>apresenta um portfólio anual de formações destinadas ao <strong style="font-weight: 700;">desenvolvimento profissional e à capacitação dos Advogados e Colaboradores do escritório</strong>.</span></p>
                                <p><span>Cada ano terá um <strong style="font-weight: 700;">foco de aprendizagem&nbsp;</strong>diferente, sendo dado maior ênfase a duas das macro competências da matriz do<em>&nbsp;Learning Journey</em>. Desta forma, conseguimos por um lado gerir eficazmente toda a oferta formativa e, por outro, desenvolver com maior profundidade as competências consideradas prioritárias para esse mesmo ano. Em todo o caso, o portfólio de formações disponível irá abranger todas as competências da matriz e não apenas as compreendidas pelos focos anuais.</span></p>
                                <p><span>As formações disponibilizadas no<em>&nbsp;Learning Journey</em> são bastante diversificadas, quer no seu conteúdo, quer no seu formato e, especialmente, ajustadas às diferentes necessidades e especificidades individuais, das áreas e das categorias profissionais. Assumem, por isso, caráter recomendado e opcional sem qualquer obrigatoriedade de participação.</span></p>
                                <p><span>Cada Advogado ou Colaborador deve consultar o plano regularmente de forma a conhecer as formações que vão sendo disponibilizadas e, de entre as opções, <strong style="font-weight: 700;">i</strong>
<strong style="font-weight: 700;">r selecionando quais as que melhor respondem às suas necessidades</strong>. Desta forma constrói o seu percurso individual de aprendizagem o qual deve ser partilhado e acompanhado também pelo seu responsável de área garantindo o alinhamento com o plano de desenvolvimento pessoal traçado.</span></p><br>
                           </div>`
                },
                {
                    title: "Como gerir o sistema de créditos?",
                    description: `<div>
                                <span>Para cada Advogado e Colaborador será definida uma <strong>carga horária anual recomendada</strong> associada a um sistema de créditos de gestão do investimento individual em formação, de acordo com cada categoria profissional.</span>
                                <p>A atribuição de créditos segue a regra:</p>
                                <ul>
                                    <li>1h de Formação Interna corresponde a 2 créditos</li>
                                    <li>1h de Formação Externa corresponde a 0,5 créditos</li>
                                </ul>
                                <p>
                                Os créditos atribuídos vão depender das escolhas realizadas por cada Advogado/Colaborador.
                                <br/>
                                Os objetivos de créditos definidos para cada categoria e por área são os seguintes:
                                </p>
                                <table>
                                    <thead>
                                        <th>
                                        <td></td>
                                        <td></td>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Estagiário(a)</b></td>
                                            <td>120 créditos</td>
                                        </tr>
                                        <tr>
                                            <td><b>Associado(a) ou Técnico(a)</b></td>
                                            <td>100 créditos</td>
                                        </tr>
                                        <tr>
                                            <td><b>Associado(a) Sénior ou Técnico(a) Sénior</b></td>
                                            <td>80 créditos</td>
                                        </tr>
                                        <tr>
                                            <td><b>Associado(a) Coordenador(a) ou Coordenador(a)</b></td>
                                            <td>60 créditos</td>
                                        </tr>
                                        <tr>
                                            <td><b>Sócio(a) / Diretor(a)</b></td>
                                            <td>40 créditos</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>Os créditos identificados são equiparados às restantes categorias de Consultores e <i>International Advisors.</i></p>
                                <h3>Formações Externas</h3>
                                <p>
                                    No caso das Formações Externas, para que sejam consideradas para efeitos de contabilização de créditos, tem que cumprir os seguintes requisitos cumulativos, independentemente de se tratar de uma formação propriamente dita ou de um webinar / conferência e de ter sido comparticipado pela VdA Academia ou pelo próprio formando, nomeadamente:
                                </p>
                                <ul>
                                    <li>Ser dado conhecimento à VdA Academia (via vdacademia@vda.pt), previamente à sessão ocorrer</li>
                                    <li>Constituir uma aquisição efetiva de novo conhecimento e com utilidade para o desenvolvimento profissional do participante e da firma</li>
                                    <li>Tratar-se de formação certificada, devendo disponibilizar o certificado à VdA Academia</li>
                                </ul>
                </div>`
                },
                {
                    title: "Quais as metodologias utilizadas?",
                    description: `<div><table style="width: 93%; margin-right: calc(7%);">
                    <tbody>
                        <tr>
                            <td colspan="2"><span>O <em>Learning Journey&nbsp;</em>é também <strong>diferenciador nas metodologias</strong> que utiliza apresentando uma&nbsp;</span><span>variedade de experiências formativas que potenciam o desenvolvimento e enriquecimento pessoal e profissional de cada um.</span></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%;"><br></td>
                            <td style="width: 73.597%;"><br></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%;"><span>As metodologias a utilizar são:</span><br><br></td>
                            <td style="width: 73.597%;"><br></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%; text-align: left; vertical-align: middle; background-color: rgb(239, 239, 239);">
                                <p><span><strong>&nbsp; Programas de Curta Duração</strong><strong>&nbsp;</strong></span></p>
                            </td>
                            <td style="width: 73.597%; vertical-align: middle; background-color: rgb(239, 239, 239);"><span>sessões de formação de grupo, <em>online</em> ou presencial, com a duração entre 4h a 16h</span></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%; vertical-align: middle;">
                                <p><span><strong>&nbsp; Programas de Longa Duração</strong><strong>&nbsp;</strong></span></p>
                            </td>
                            <td style="width: 73.597%;"><span>sessões de formação de grupo, <em>online</em> ou presencial, com duração igual ou superior a 17h</span></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%; vertical-align: middle; background-color: rgb(239, 239, 239);">
                                <p><span><strong>&nbsp; Conferências &amp; <em>Webinar</em></strong><strong><em>s</em>&nbsp;</strong></span></p>
                            </td>
                            <td style="width: 73.597%; background-color: rgb(239, 239, 239);"><span>sessões teóricas destinadas a um grupo alargado de participantes sobre um tema curto, específico e tendencialmente emergente</span></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%; vertical-align: middle;">
                                <p><em><span><strong>&nbsp; Workshops &amp; Learning Shots</strong></span></em><span><strong>&nbsp;</strong></span></p>
                            </td>
                            <td style="width: 73.597%;"><span>sessões práticas ou de partilha de grupo com número reduzido de elementos que têm por base um tema curto e específico. Poderá também revestir a forma de comunidades de aprendizagem, redes de colaboração para a construção de conhecimento e sabedoria coletivos. Tem a duração máxima de 4h</span></td>
                        </tr>
                        <tr>
                            <td style="width: 26.127%; background-color: rgb(239, 239, 239); vertical-align: middle;">
                                <p style="text-align: left;"><span><strong>&nbsp; Sessões&nbsp;</strong></span><em><span><strong>One to One</strong></span></em></p>
                            </td>
                            <td style="width: 73.597%; background-color: rgb(239, 239, 239);"><span>sessões individuais acompanhadas por um especialista na orientação do percurso profissional visando a concretização dos objetivos e da obtenção de melhores resultados profissionais e maior realização pessoal do formando</span></td>
                        </tr>
                    </tbody>
                </table>
                <p><br></p>
                <p><br></p>
                <hr>
                <p><br></p>
                <p><br></p></div>`
                },
                {
                    title: "Como será considerada a Learning Journey para efeitos de performance?",
                    description: `<div>
                    <p>
                    <span>O Percurso Individual de Aprendizagem de cada um terá reflexo no Sistema de Gestão de Desempenho (SGD) em termos <strong>qualitativos</strong>. Para o efeito, serão tidos em consideração os seguintes elementos:</span></p>
                                <ul style="box-sizing: border-box; margin-bottom: 1rem; margin-top: 0px; color: rgb(55, 55, 55); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; list-style-type: square;">
                                    <li>Número de créditos e horas de formação por ano</li>
                                    <li>Formação realizadas</li>
                                    <li>Partilha do conhecimento adquirido com a Firma</li>
                                    <li>Participação enquanto formador nas formações internas</li>
                                </ul>
                                <p style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: rgb(55, 55, 55);><span>Haverá ainda <strong>duas fases de validação do conhecimento</strong> nas sessões de formação. Uma primeira durante a formação, destinada a validar o conhecimento adquirido por meio de diferentes instrumentos. Uma segunda, numa fase posterior ao término da formação, em que será disponibilizado um inquérito para validação da eficácia da formação na qual tenha participado.</span></p>
                                <p style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: rgb(55, 55, 55);><span><br></span></p>
                    </div>`
                },
                {
                    title: "Quais os meios disponíveis de acompanhamento do meu percurso individual de aprendizagem?",
                    description: `<div>
                                <p><span>A VdA Academia disponibiliza um acompanhamento do percurso individual de aprendizagem denominado por <em style="box-sizing: border-box;"><strong style="box-sizing: border-box; font-weight: bolder;">Learning Guidance</strong></em>.</span></p>
                                <p><span>Este serviço poderá ir desde a realização de sessões individuais com o Advogado ou Colaborador como também à participação em reuniões da AP ou AG, ou outro tipo de acompanhamento a definir em conjunto entre a VdA Academia e o beneficiário.</span></p>
                                <br>
                                <p><span><strong>QUERO SABER MAIS</strong></span></p>
                                <p><span>Contacte a VdA Academia para o email <a style="color: rgb(0, 179, 136)" href="mailto:vdacademia@vda.pt" target="_blank" alt="VDA">vdacademia@vda.pt</a> para mais informações.</span></p>
                            </div>`
                },
                {
                    title: "Para mais informação",
                    description: `<div>
                    <span>Se tiver alguma questão, pretender sugerir alguma formação, propor alguma melhoria ou fazer algum elogio, envie por favor <em>email</em> para <a style="color: rgb(0, 179, 136)" href="mailto:vdacademia@vda.pt" target="_blank" alt="VDA">vdacademia@vda.pt</a></span>
                    </div>`
                }
            ]
        }
        res(data);
    })
}

export const getAvailableTrainingModuleTypes = () => {
    return new Promise(res => {
        const data = {
            error: false,
            value: [
                {
                    id: 'textQuery',
                    label: "",
                    type: "text",
                    searchIn: [
                        "trainingModuleName",
                        "trainingEvents[0].subCategoryName",
                        "trainingEvents[0].targetCategoriesDesc",
                        "trainingEvents[0].departmentsDesc",
                        "trainingEvents[0].instructorName"
                    ],
                    filter: true
                },
                {
                    id: 'eventStatus',
                    translatedLabel: "Disponibilidade",
                    type: "single",
                    options: [
                        {
                            "value": "P",
                            "translatedLabel": "Candidaturas Abertas"
                        },
                        {
                            "value": "T",
                            "translatedLabel": "Candidaturas Fechadas"
                        }
                    ],
                    filter: true,
                    hidden: true
                },
                {
                    id: 'mandatory',
                    translatedLabel: "Tipo de Percurso",
                    type: "single",
                    options: [
                        {
                            "value": true,
                            "translatedLabel": "Recomendado"
                        },
                        {
                            "value": false,
                            "translatedLabel": "Opcional"
                        }
                    ],
                    filter: true
                },
                {
                    id: 'experienceTypeDesc',
                    translatedLabel: "Tipo de Experiência",
                    type: "single",
                    options: [],
                    filter: true
                },
                {
                    id: 'locationTypeDesc',
                    translatedLabel: "Formato",
                    type: "single",
                    options: [],
                    filter: true
                },
                {
                    id: 'languageTypeDesc',
                    translatedLabel: "Idioma",
                    type: "single",
                    options: [],
                    filter: true
                },
                {
                    id: 'trimester',
                    translatedLabel: "Trimestre de realização",
                    type: "single",
                    options: [
                    ],
                    filter: true
                },
                {
                    id: 'departmentsDesc',
                    translatedLabel: "Área",
                    type: "single",
                    options: []
                },
                {
                    id: 'targetCategoriesDesc',
                    translatedLabel: "Senioridade",
                    type: "single",
                },
                {
                    id: "Dates",
                    translatedLabel: "Data(s)",
                    type: "single",
                    options: [
                        {
                            "value": 0,
                            "translatedLabel": "Option 1"
                        }
                    ]
                },
                {
                    id: "credits",
                    translatedLabel: "Créditos",
                    type: "range",
                    filter: true,
                    hidden: true
                }
            ]
        }
        res(data);
    })
}

export const getAvailableTrainingModules = async () => {
    return new Promise(res => {
        const data = {
            "code": 200,
            "description": null,
            "value": {
                "trainingModules": [
                    {
                        "trainingModuleID": "f4662e95-bb39-49a6-a5c3-043cce312a2a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | com Prof. Ana Perestrelo de Oliveira (10/10)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "f4662e95-bb39-49a6-a5c3-043cce312a2a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | com Prof. Ana Perestrelo de Oliveira (10/10)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "98419f69-f623-4753-8f83-4592b5f716d4",
                                        "trainingEventID": "b1464e71-5dd6-4072-a524-3c7b1606baaa",
                                        "nHours": 1,
                                        "startDate": "2023-12-14T00:00:00"
                                    }
                                ],
                                "trainingEventID": "b1464e71-5dd6-4072-a524-3c7b1606baaa",
                                "trainingEventName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (10/10) 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-12-14T00:00:00",
                                "registrationLimit": "2023-12-14T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-12-14T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "b1464e71-5dd6-4072-a524-3c7b1606baaa",
                        "trainingEventName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (10/10) 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-12-14T00:00:00",
                        "registrationLimit": "2023-12-14T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-12-14T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "52da9658-65f6-4a72-b47f-9d553064071c",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.3  Mecanismos resolutivos convencionais",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "52da9658-65f6-4a72-b47f-9d553064071c",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.3  Mecanismos resolutivos convencionais",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "32f2aec2-9bd4-4790-b1a8-ccca1033d90b",
                                        "trainingEventID": "7b8062ad-8819-4b25-882e-91019bb3ef1e",
                                        "nHours": 1.5,
                                        "startDate": "2023-12-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "7b8062ad-8819-4b25-882e-91019bb3ef1e",
                                "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.3  Mecanismos resolutivos convencionais 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-12-12T00:00:00",
                                "registrationLimit": "2023-12-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-12-12T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "52da9658-65f6-4a72-b47f-9d553064071c",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.3  Mecanismos resolutivos convencionais",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "32f2aec2-9bd4-4790-b1a8-ccca1033d90b",
                                        "trainingEventID": "7b8062ad-8819-4b25-882e-91019bb3ef1e",
                                        "nHours": 1.5,
                                        "startDate": "2023-12-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "7b8062ad-8819-4b25-882e-91019bb3ef1e",
                                "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.3  Mecanismos resolutivos convencionais 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Madalena Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-12-12T00:00:00",
                                "registrationLimit": "2023-12-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-12-12T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "7b8062ad-8819-4b25-882e-91019bb3ef1e",
                        "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.3  Mecanismos resolutivos convencionais 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-12-12T00:00:00",
                        "registrationLimit": "2023-12-12T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-12-12T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "110b5341-cb1b-43ba-a172-3a0e51820242",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "KM Legal Research Tools | Legislação VdA Legal Partners ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "110b5341-cb1b-43ba-a172-3a0e51820242",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | Legislação VdA Legal Partners ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "aa5daeba-0481-4168-893a-e27e197c39f4",
                                        "trainingEventID": "dbb2c557-0ec7-4220-8a9b-fb1b6fcb2e42",
                                        "nHours": 1.5,
                                        "startDate": "2023-12-11T00:00:00"
                                    }
                                ],
                                "trainingEventID": "dbb2c557-0ec7-4220-8a9b-fb1b6fcb2e42",
                                "trainingEventName": "KM Legal Research Tools | Legislação VdA Legal Partners  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ivanna Gomes (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-12-11T00:00:00",
                                "registrationLimit": "2023-12-11T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-12-11T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "dbb2c557-0ec7-4220-8a9b-fb1b6fcb2e42",
                        "trainingEventName": "KM Legal Research Tools | Legislação VdA Legal Partners  1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ivanna Gomes (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-12-11T00:00:00",
                        "registrationLimit": "2023-12-11T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-12-11T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "cb9ab70f-111d-41e2-b86a-8c1b5c286052",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "KM Legal Research Tools | Datajuris – Legislação e Jurisprudência",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "cb9ab70f-111d-41e2-b86a-8c1b5c286052",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | Datajuris – Legislação e Jurisprudência",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "69ce2d1d-a045-48d9-8028-5605fb24ac2f",
                                        "trainingEventID": "7d7f72ce-c80e-42f2-9adc-5c8daad331bd",
                                        "nHours": 1,
                                        "startDate": "2023-12-05T00:00:00"
                                    }
                                ],
                                "trainingEventID": "7d7f72ce-c80e-42f2-9adc-5c8daad331bd",
                                "trainingEventName": "KM Legal Research Tools | Datajuris – Legislação e Jurisprudência 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Santos Paiva (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-12-05T00:00:00",
                                "registrationLimit": "2023-12-05T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "59d220c0-50c9-4c19-a774-dcbb16f3e62d",
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-12-05T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "7d7f72ce-c80e-42f2-9adc-5c8daad331bd",
                        "trainingEventName": "KM Legal Research Tools | Datajuris – Legislação e Jurisprudência 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Joana Santos Paiva (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-12-05T00:00:00",
                        "registrationLimit": "2023-12-05T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "59d220c0-50c9-4c19-a774-dcbb16f3e62d",
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-12-05T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "4c9d4337-1e8e-42aa-80f9-18f855a042b3",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981001,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "SEGUNDO TESTE 28.11.2023",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 45,
                        "targetCategories": "108009",
                        "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>oi de teste</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "4c9d4337-1e8e-42aa-80f9-18f855a042b3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981001,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "SEGUNDO TESTE 28.11.2023",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 45,
                                "targetCategories": "108009",
                                "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>oi de teste</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "e7503d1d-8991-468c-a705-e7b83adde8e5",
                                        "trainingEventID": "2855d80c-21c8-4580-8109-ec80a215e338",
                                        "nHours": 1,
                                        "startDate": "2023-12-12T11:24:00"
                                    }
                                ],
                                "trainingEventID": "2855d80c-21c8-4580-8109-ec80a215e338",
                                "trainingEventName": "SEGUNDO TESTE 28.11.2023 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Milton Sousa (Nova SBE)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-12-04T00:00:00",
                                "registrationLimit": "2023-12-05T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995002001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Gestão de Negócio",
                                "subCategoryDesc": "Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma",
                                "mainCategoryName": "Negócio",
                                "departments": null,
                                "departmentsDesc": "Ambiente",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-12-20T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "4c9d4337-1e8e-42aa-80f9-18f855a042b3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981001,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "SEGUNDO TESTE 28.11.2023",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 45,
                                "targetCategories": "108009",
                                "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>oi de teste</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "bf00e555-0217-4208-9ef3-3a04d24cae64",
                                        "trainingEventID": "5c6d26cc-bd3d-4fb7-b4cc-b4041f8da32e",
                                        "nHours": 1,
                                        "startDate": "2023-12-06T15:07:00"
                                    }
                                ],
                                "trainingEventID": "5c6d26cc-bd3d-4fb7-b4cc-b4041f8da32e",
                                "trainingEventName": "SEGUNDO TESTE 28.11.2023 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mark Fritz (PBS)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-28T00:00:00",
                                "registrationLimit": "2023-11-28T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995002001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Gestão de Negócio",
                                "subCategoryDesc": "Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma",
                                "mainCategoryName": "Negócio",
                                "departments": null,
                                "departmentsDesc": "Ambiente",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-11-28T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "2855d80c-21c8-4580-8109-ec80a215e338",
                        "trainingEventName": "SEGUNDO TESTE 28.11.2023 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Milton Sousa (Nova SBE)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-12-04T00:00:00",
                        "registrationLimit": "2023-12-05T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995002001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Inglês",
                        "subCategoryName": "Gestão de Negócio",
                        "subCategoryDesc": "Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma",
                        "mainCategoryName": "Negócio",
                        "departments": null,
                        "departmentsDesc": "Ambiente",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-12-20T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "d910bfe1-fc23-4b9c-a5cb-ff49acffc35a",
                        "trainingModuleTypeID": 971002,
                        "trainingModuleTypeDesc": "Formação Externa",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981002,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "TESTE VAGAS",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                        "internalManagerName": "Anne Fernandes",
                        "internalManagerUsername": "LAF@vda.pt",
                        "financialCost": 100,
                        "targetCategories": "108039",
                        "targetCategoriesDesc": "Assistente (Prática) (I)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": 1,
                        "description": "<p>ACSCAC</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "d910bfe1-fc23-4b9c-a5cb-ff49acffc35a",
                                "trainingModuleTypeID": 971002,
                                "trainingModuleTypeDesc": "Formação Externa",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981002,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "TESTE VAGAS",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 100,
                                "targetCategories": "108039",
                                "targetCategoriesDesc": "Assistente (Prática) (I)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": 1,
                                "description": "<p>ACSCAC</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "04ebabe4-d7a5-40e9-b38a-18aa6e8bbe32",
                                        "trainingEventID": "bfe99040-358f-4056-a42e-3caa3f8c0d04",
                                        "nHours": 2,
                                        "startDate": "2023-12-01T15:25:00"
                                    }
                                ],
                                "trainingEventID": "bfe99040-358f-4056-a42e-3caa3f8c0d04",
                                "trainingEventName": "TESTE VAGAS 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Easy Talk",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 1,
                                "nWaitingList": 2,
                                "cancelationLimit": "2023-11-30T00:00:00",
                                "registrationLimit": "2023-11-30T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "S",
                                "onWaitingList": true,
                                "canEnrole": true,
                                "trainingEventRegistryID": "eb064c14-bd48-4ae6-98d7-056db6d03c4c",
                                "credits": 4,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Alemão",
                                "subCategoryName": "Adaptabilidade e Resiliência",
                                "subCategoryDesc": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "CA (Gestão)",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-11-30T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "bfe99040-358f-4056-a42e-3caa3f8c0d04",
                        "trainingEventName": "TESTE VAGAS 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Easy Talk",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 1,
                        "nWaitingList": 2,
                        "cancelationLimit": "2023-11-30T00:00:00",
                        "registrationLimit": "2023-11-30T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "S",
                        "onWaitingList": true,
                        "canEnrole": true,
                        "trainingEventRegistryID": "eb064c14-bd48-4ae6-98d7-056db6d03c4c",
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995005002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Alemão",
                        "subCategoryName": "Adaptabilidade e Resiliência",
                        "subCategoryDesc": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "CA (Gestão)",
                        "experienceTypeDesc": "Programas de Longa Duração",
                        "communicationDate": "2023-11-30T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "4401ec42-5be8-4feb-b3a1-1db031cc0185",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981002,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "quarto teste 28.11",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "58bdbe0c-7617-4a91-84da-8618dcec78bf",
                        "internalManagerName": "Teresa Cunha",
                        "internalManagerUsername": "TTC@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108039",
                        "targetCategoriesDesc": "Assistente (Prática) (I)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": 1,
                        "description": "<p>pçlkjhg</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "4401ec42-5be8-4feb-b3a1-1db031cc0185",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981002,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "quarto teste 28.11",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "58bdbe0c-7617-4a91-84da-8618dcec78bf",
                                "internalManagerName": "Teresa Cunha",
                                "internalManagerUsername": "TTC@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108039",
                                "targetCategoriesDesc": "Assistente (Prática) (I)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": 1,
                                "description": "<p>pçlkjhg</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "b11fe230-ab03-4926-9211-6ecc981b852f",
                                        "trainingEventID": "784e493d-f20f-4b31-89b0-2354082170fc",
                                        "nHours": 1,
                                        "startDate": "2023-12-07T16:38:00"
                                    }
                                ],
                                "trainingEventID": "784e493d-f20f-4b31-89b0-2354082170fc",
                                "trainingEventName": "quarto teste 28.11 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "AQUELE FORMADOR TESTE",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-28T00:00:00",
                                "registrationLimit": "2023-11-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": "d897237e-7532-4baf-8dcf-ed9b502aa37f",
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Adaptabilidade e Resiliência",
                                "subCategoryDesc": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "CA (Gestão)",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-11-29T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "784e493d-f20f-4b31-89b0-2354082170fc",
                        "trainingEventName": "quarto teste 28.11 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "AQUELE FORMADOR TESTE",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-28T00:00:00",
                        "registrationLimit": "2023-11-29T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": "d897237e-7532-4baf-8dcf-ed9b502aa37f",
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Inglês",
                        "subCategoryName": "Adaptabilidade e Resiliência",
                        "subCategoryDesc": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "CA (Gestão)",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-11-29T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "f82074f8-a56a-476d-8368-1d8bea11cdcb",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981002,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "terceiro teste 28.11",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108039",
                        "targetCategoriesDesc": "Assistente (Prática) (I)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 2,
                        "totalHours": 4,
                        "certifiedHours": null,
                        "nVacancies": 1,
                        "description": "<p>gggg</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "f82074f8-a56a-476d-8368-1d8bea11cdcb",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981002,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "terceiro teste 28.11",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108039",
                                "targetCategoriesDesc": "Assistente (Prática) (I)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 4,
                                "certifiedHours": null,
                                "nVacancies": 1,
                                "description": "<p>gggg</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "49b1e9b4-c097-45ba-9cff-5d708bb76758",
                                        "trainingEventID": "d1d390d4-416d-40ff-9668-0fa7a010e2db",
                                        "nHours": 2,
                                        "startDate": "2023-12-07T15:59:00"
                                    },
                                    {
                                        "trainingEventSessionID": "894bf09e-54a3-4834-87d5-3f0937bf88f7",
                                        "trainingEventID": "d1d390d4-416d-40ff-9668-0fa7a010e2db",
                                        "nHours": 2,
                                        "startDate": "2023-12-12T15:59:00"
                                    }
                                ],
                                "trainingEventID": "d1d390d4-416d-40ff-9668-0fa7a010e2db",
                                "trainingEventName": "terceiro teste 28.11 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Fred (IST)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 2,
                                "cancelationLimit": "2023-11-28T00:00:00",
                                "registrationLimit": "2023-11-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 8,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Francês",
                                "subCategoryName": "Adaptabilidade e Resiliência",
                                "subCategoryDesc": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Comunicação Corporativa",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-11-29T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "d1d390d4-416d-40ff-9668-0fa7a010e2db",
                        "trainingEventName": "terceiro teste 28.11 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Fred (IST)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 2,
                        "cancelationLimit": "2023-11-28T00:00:00",
                        "registrationLimit": "2023-11-29T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 8,
                        "trimester": null,
                        "subCategoryTypeID": 995005002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Francês",
                        "subCategoryName": "Adaptabilidade e Resiliência",
                        "subCategoryDesc": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Comunicação Corporativa",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-11-29T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "9222bca5-b3e1-433f-973a-f69b5dbad34c",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981002,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "TESTE 29/11/23 | LAF",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                        "internalManagerName": "Anne Fernandes",
                        "internalManagerUsername": "LAF@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108003,108006",
                        "targetCategoriesDesc": "Técnico(a) Senior,Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 2,
                        "totalHours": 3,
                        "certifiedHours": null,
                        "nVacancies": 5,
                        "description": "<p>Formação Teste | 18h11</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "9222bca5-b3e1-433f-973a-f69b5dbad34c",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981002,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "TESTE 29/11/23 | LAF",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108003,108006",
                                "targetCategoriesDesc": "Técnico(a) Senior,Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": 5,
                                "description": "<p>Formação Teste | 18h11</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "93927437-99c6-46ca-9cdb-c21800097cca",
                                        "trainingEventID": "1493bd95-74ec-4eab-ae5e-740e84d2a3f4",
                                        "nHours": 1.5,
                                        "startDate": "2023-11-30T18:13:00"
                                    },
                                    {
                                        "trainingEventSessionID": "ebad8b9c-e3d0-49a1-b414-d4322a90829b",
                                        "trainingEventID": "1493bd95-74ec-4eab-ae5e-740e84d2a3f4",
                                        "nHours": 1.5,
                                        "startDate": "2023-12-01T18:13:00"
                                    }
                                ],
                                "trainingEventID": "1493bd95-74ec-4eab-ae5e-740e84d2a3f4",
                                "trainingEventName": "TESTE 29/11/23 | LAF 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "TESTE TESTE",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-29T00:00:00",
                                "registrationLimit": "2023-11-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "c8141a53-b81c-4a67-a7d6-b7bdce478cee",
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995001001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Envolvimento e Experimentação",
                                "subCategoryDesc": "Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Corporate Affairs",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-29T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "1493bd95-74ec-4eab-ae5e-740e84d2a3f4",
                        "trainingEventName": "TESTE 29/11/23 | LAF 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "TESTE TESTE",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-29T00:00:00",
                        "registrationLimit": "2023-11-29T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "c8141a53-b81c-4a67-a7d6-b7bdce478cee",
                        "credits": 6,
                        "trimester": null,
                        "subCategoryTypeID": 995001001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Envolvimento e Experimentação",
                        "subCategoryDesc": "Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "Corporate Affairs",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-11-29T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "8f7de718-582a-4c01-86ab-70667b26b17b",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981003,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "quinto teste 28.10",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "58bdbe0c-7617-4a91-84da-8618dcec78bf",
                        "internalManagerName": "Teresa Cunha",
                        "internalManagerUsername": "TTC@vda.pt",
                        "financialCost": 40,
                        "targetCategories": "108002",
                        "targetCategoriesDesc": "Assistente (Operacional)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 50,
                        "certifiedHours": null,
                        "nVacancies": 1,
                        "description": "<p>sdfghj</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "Z",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "8f7de718-582a-4c01-86ab-70667b26b17b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981003,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "quinto teste 28.10",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "58bdbe0c-7617-4a91-84da-8618dcec78bf",
                                "internalManagerName": "Teresa Cunha",
                                "internalManagerUsername": "TTC@vda.pt",
                                "financialCost": 40,
                                "targetCategories": "108002",
                                "targetCategoriesDesc": "Assistente (Operacional)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 50,
                                "certifiedHours": null,
                                "nVacancies": 1,
                                "description": "<p>sdfghj</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2a31f164-2bce-4b89-9d4f-41e23467795c",
                                        "trainingEventID": "eabf22cf-c5ae-417f-a345-12d152a3addb",
                                        "nHours": 50,
                                        "startDate": "2023-11-28T23:07:00"
                                    }
                                ],
                                "trainingEventID": "eabf22cf-c5ae-417f-a345-12d152a3addb",
                                "trainingEventName": "quinto teste 28.10 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "AQUELE FORMADOR TESTE",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-28T00:00:00",
                                "registrationLimit": "2023-11-28T00:00:00",
                                "inviteOnly": true,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "4e9c4c5d-9c10-45a0-892a-a6a84d83ba2c",
                                "credits": 100,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Francês",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "CA (Gestão)",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-11-28T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "eabf22cf-c5ae-417f-a345-12d152a3addb",
                        "trainingEventName": "quinto teste 28.10 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "AQUELE FORMADOR TESTE",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-28T00:00:00",
                        "registrationLimit": "2023-11-28T00:00:00",
                        "inviteOnly": true,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "4e9c4c5d-9c10-45a0-892a-a6a84d83ba2c",
                        "credits": 100,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Francês",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "CA (Gestão)",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-11-28T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "1d17e1b3-995d-413f-91b9-b1f92fb2df2d",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981001,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "TESTE 28.11.2023",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 2000,
                        "targetCategories": "108006",
                        "targetCategoriesDesc": "Técnico(a) Senior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": 20,
                        "description": "<p>Programa muito giro, destinado a todos. Os objetivos da formação são:</p><ul><li>isto</li><li>aquilo</li><li>e tudo o mais</li></ul>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "1d17e1b3-995d-413f-91b9-b1f92fb2df2d",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981001,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "TESTE 28.11.2023",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 2000,
                                "targetCategories": "108006",
                                "targetCategoriesDesc": "Técnico(a) Senior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": 20,
                                "description": "<p>Programa muito giro, destinado a todos. Os objetivos da formação são:</p><ul><li>isto</li><li>aquilo</li><li>e tudo o mais</li></ul>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "5f1b04f6-fbc1-420b-b758-d882d8b1423c",
                                        "trainingEventID": "e8398ab8-ebad-40b5-937c-8bd24c9b708a",
                                        "nHours": 2,
                                        "startDate": "2023-11-29T10:44:00"
                                    }
                                ],
                                "trainingEventID": "e8398ab8-ebad-40b5-937c-8bd24c9b708a",
                                "trainingEventName": "TESTE 28.11.2023 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mark Fritz (PBS)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-28T00:00:00",
                                "registrationLimit": "2023-11-28T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "CA (Gestão),Ambiente,Client Value,Bancário & Financeiro,Comunicação Corporativa",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-28T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "1d17e1b3-995d-413f-91b9-b1f92fb2df2d",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981001,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "TESTE 28.11.2023",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 2000,
                                "targetCategories": "108006",
                                "targetCategoriesDesc": "Técnico(a) Senior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": 20,
                                "description": "<p>Programa muito giro, destinado a todos. Os objetivos da formação são:</p><ul><li>isto</li><li>aquilo</li><li>e tudo o mais</li></ul>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "22584e3f-3b06-439b-b2de-8e7100f48490",
                                        "trainingEventID": "7e8675cf-06e0-449c-8aa9-b1192854b8b4",
                                        "nHours": 2,
                                        "startDate": "2023-11-30T11:05:00"
                                    }
                                ],
                                "trainingEventID": "7e8675cf-06e0-449c-8aa9-b1192854b8b4",
                                "trainingEventName": "TESTE 28.11.2023 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Fred (IST)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-29T00:00:00",
                                "registrationLimit": "2023-11-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "CA (Gestão),Ambiente,Client Value,Bancário & Financeiro,Comunicação Corporativa",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-29T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "e8398ab8-ebad-40b5-937c-8bd24c9b708a",
                        "trainingEventName": "TESTE 28.11.2023 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Mark Fritz (PBS)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-28T00:00:00",
                        "registrationLimit": "2023-11-28T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "CA (Gestão),Ambiente,Client Value,Bancário & Financeiro,Comunicação Corporativa",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-11-28T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ff96bf87-28a2-451b-9115-6f83a18dda2c",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Dar e Receber Feedback",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 8,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta formação, abordará a importância do feedback nas relações de trabalho, com foco na melhoria das habilidades de dar e receber feedback de forma eficaz. Serão explorados os obstáculos comuns desse processo através de exercícios práticos com o intuito de aprimorar os diálogos de feedback no dia a dia. Além disso, será discutida a troca de feedback em contexto de reuniões de avaliação de desempenho.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ff96bf87-28a2-451b-9115-6f83a18dda2c",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Dar e Receber Feedback",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 8,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta formação, abordará a importância do feedback nas relações de trabalho, com foco na melhoria das habilidades de dar e receber feedback de forma eficaz. Serão explorados os obstáculos comuns desse processo através de exercícios práticos com o intuito de aprimorar os diálogos de feedback no dia a dia. Além disso, será discutida a troca de feedback em contexto de reuniões de avaliação de desempenho.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "9c22d3cb-e0f3-4f33-a607-fb31bff315bd",
                                        "trainingEventID": "f67deb70-0c8c-43ba-87f9-f3217f8f0986",
                                        "nHours": 8,
                                        "startDate": "2023-11-23T00:00:00"
                                    }
                                ],
                                "trainingEventID": "f67deb70-0c8c-43ba-87f9-f3217f8f0986",
                                "trainingEventName": "Dar e Receber Feedback 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Domingues (UCP)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-23T00:00:00",
                                "registrationLimit": "2023-11-23T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 16,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-11-23T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "f67deb70-0c8c-43ba-87f9-f3217f8f0986",
                        "trainingEventName": "Dar e Receber Feedback 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Joana Domingues (UCP)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-23T00:00:00",
                        "registrationLimit": "2023-11-23T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 16,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-11-23T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "131fa4c8-0424-4987-9068-5380031338ef",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "KM Legal Research Tools | Curia – Jurisprudência europeia",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "131fa4c8-0424-4987-9068-5380031338ef",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | Curia – Jurisprudência europeia",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "34b25b72-4e8a-4cbf-9ec5-bd6ffd01e507",
                                        "trainingEventID": "bd242a71-8996-4fbc-962c-61a2f9d28d39",
                                        "nHours": 1,
                                        "startDate": "2023-11-22T00:00:00"
                                    }
                                ],
                                "trainingEventID": "bd242a71-8996-4fbc-962c-61a2f9d28d39",
                                "trainingEventName": "KM Legal Research Tools | Curia – Jurisprudência europeia 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mariana Carvalho (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-22T00:00:00",
                                "registrationLimit": "2023-11-22T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-22T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "bd242a71-8996-4fbc-962c-61a2f9d28d39",
                        "trainingEventName": "KM Legal Research Tools | Curia – Jurisprudência europeia 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Mariana Carvalho (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-22T00:00:00",
                        "registrationLimit": "2023-11-22T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-11-22T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "30614d72-0594-4e03-a048-8588fba05acf",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (9/10)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "30614d72-0594-4e03-a048-8588fba05acf",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (9/10)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "fe208b38-92f6-4178-8572-e6120e92a40e",
                                        "trainingEventID": "6dacb000-057f-43e3-9e6e-ea94de95e799",
                                        "nHours": 1,
                                        "startDate": "2023-11-16T00:00:00"
                                    }
                                ],
                                "trainingEventID": "6dacb000-057f-43e3-9e6e-ea94de95e799",
                                "trainingEventName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (9/10) 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-16T00:00:00",
                                "registrationLimit": "2023-11-16T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-16T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "6dacb000-057f-43e3-9e6e-ea94de95e799",
                        "trainingEventName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (9/10) 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-16T00:00:00",
                        "registrationLimit": "2023-11-16T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-11-16T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "94e5a56e-752d-48cc-9915-f1882ef390b6",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Tema por confirmar c/ Fernanda Paula Oliveira (5/5)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 7,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "94e5a56e-752d-48cc-9915-f1882ef390b6",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Tema por confirmar c/ Fernanda Paula Oliveira (5/5)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 7,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "0d01a892-018f-436f-a9dd-cb83f6c957a7",
                                        "trainingEventID": "afa5e2cb-3ab0-487f-8ed5-3e001f14933a",
                                        "nHours": 7,
                                        "startDate": "2023-11-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "afa5e2cb-3ab0-487f-8ed5-3e001f14933a",
                                "trainingEventName": "Tema por confirmar c/ Fernanda Paula Oliveira (5/5) 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Fernanda Paula Oliveira (FDUC)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-15T00:00:00",
                                "registrationLimit": "2023-11-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "S",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": "d25e4c56-64a3-4767-a2e2-26fb94cbc927",
                                "credits": 14,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-11-15T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "afa5e2cb-3ab0-487f-8ed5-3e001f14933a",
                        "trainingEventName": "Tema por confirmar c/ Fernanda Paula Oliveira (5/5) 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Fernanda Paula Oliveira (FDUC)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-15T00:00:00",
                        "registrationLimit": "2023-11-15T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "S",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": "d25e4c56-64a3-4767-a2e2-26fb94cbc927",
                        "credits": 14,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-11-15T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "8b521f71-7999-406c-b900-0de3713c3b8d",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.2 Impossibilidade da prestação ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "8b521f71-7999-406c-b900-0de3713c3b8d",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.2 Impossibilidade da prestação ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "b8d2d81c-5275-4ef1-9940-d944f7624632",
                                        "trainingEventID": "663ec6b4-921a-4910-87f7-e679957d8321",
                                        "nHours": 1.5,
                                        "startDate": "2023-11-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "663ec6b4-921a-4910-87f7-e679957d8321",
                                "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.2 Impossibilidade da prestação  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-09T00:00:00",
                                "registrationLimit": "2023-11-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "9ff8c099-da34-4500-8477-2620cbd9366c",
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-09T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "8b521f71-7999-406c-b900-0de3713c3b8d",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.2 Impossibilidade da prestação ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "b8d2d81c-5275-4ef1-9940-d944f7624632",
                                        "trainingEventID": "663ec6b4-921a-4910-87f7-e679957d8321",
                                        "nHours": 1.5,
                                        "startDate": "2023-11-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "663ec6b4-921a-4910-87f7-e679957d8321",
                                "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.2 Impossibilidade da prestação  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Madalena Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-09T00:00:00",
                                "registrationLimit": "2023-11-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "9ff8c099-da34-4500-8477-2620cbd9366c",
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-09T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "663ec6b4-921a-4910-87f7-e679957d8321",
                        "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.2 Impossibilidade da prestação  1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-09T00:00:00",
                        "registrationLimit": "2023-11-09T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "9ff8c099-da34-4500-8477-2620cbd9366c",
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-11-09T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "2c166189-f49f-43d9-ab45-c4bb78da0ccf",
                        "trainingModuleTypeID": 971002,
                        "trainingModuleTypeDesc": "Formação Externa",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981003,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "Teste Rafael",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                        "internalManagerName": "Anne Fernandes",
                        "internalManagerUsername": "LAF@vda.pt",
                        "financialCost": 100,
                        "targetCategories": "108026,108022",
                        "targetCategoriesDesc": "Advogado(a),Assistente (Gestão)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 10,
                        "certifiedHours": null,
                        "nVacancies": 1,
                        "description": "<p>aaaaa</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "Z",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "2c166189-f49f-43d9-ab45-c4bb78da0ccf",
                                "trainingModuleTypeID": 971002,
                                "trainingModuleTypeDesc": "Formação Externa",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981003,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "Teste Rafael",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 100,
                                "targetCategories": "108026,108022",
                                "targetCategoriesDesc": "Advogado(a),Assistente (Gestão)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": 1,
                                "description": "<p>aaaaa</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "39e09996-009e-4dd5-bcff-ff2fa48a94f0",
                                        "trainingEventID": "28f32349-0a94-4054-9160-699ba0dfbba0",
                                        "nHours": 10,
                                        "startDate": "2023-11-09T10:00:00"
                                    }
                                ],
                                "trainingEventID": "28f32349-0a94-4054-9160-699ba0dfbba0",
                                "trainingEventName": "Teste Rafael 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mark Fritz (PBS)",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-08T00:00:00",
                                "registrationLimit": "2023-11-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "550b4155-8da3-4c85-8d11-2bc0cccd4df8",
                                "credits": 20,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995002002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Conhecimento de Negócio e Mercados",
                                "subCategoryDesc": "Compreender de forma alargada o negócio da Firma e a sua dimensão global e internacional",
                                "mainCategoryName": "Negócio",
                                "departments": null,
                                "departmentsDesc": "Bancário & Financeiro",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-11-08T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "28f32349-0a94-4054-9160-699ba0dfbba0",
                        "trainingEventName": "Teste Rafael 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Mark Fritz (PBS)",
                        "locationTypeID": 973004,
                        "locationTypeDesc": "Blended Learning – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-08T00:00:00",
                        "registrationLimit": "2023-11-08T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "550b4155-8da3-4c85-8d11-2bc0cccd4df8",
                        "credits": 20,
                        "trimester": null,
                        "subCategoryTypeID": 995002002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Espanhol",
                        "subCategoryName": "Conhecimento de Negócio e Mercados",
                        "subCategoryDesc": "Compreender de forma alargada o negócio da Firma e a sua dimensão global e internacional",
                        "mainCategoryName": "Negócio",
                        "departments": null,
                        "departmentsDesc": "Bancário & Financeiro",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-11-08T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "a9c05384-7608-4d49-8eb2-70f0d7edf32a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "KM Legal Research Tools | Diário da República e Eur-Lex",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "a9c05384-7608-4d49-8eb2-70f0d7edf32a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | Diário da República e Eur-Lex",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "037fb452-c541-4d2f-b12a-2bd607fef190",
                                        "trainingEventID": "275c8d2e-22fd-49c9-8c08-0e6acd596da2",
                                        "nHours": 1.5,
                                        "startDate": "2023-11-07T00:00:00"
                                    }
                                ],
                                "trainingEventID": "275c8d2e-22fd-49c9-8c08-0e6acd596da2",
                                "trainingEventName": "KM Legal Research Tools | Diário da República e Eur-Lex 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Mascarenhas (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-11-07T00:00:00",
                                "registrationLimit": "2023-11-07T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-11-07T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "275c8d2e-22fd-49c9-8c08-0e6acd596da2",
                        "trainingEventName": "KM Legal Research Tools | Diário da República e Eur-Lex 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Joana Mascarenhas (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-11-07T00:00:00",
                        "registrationLimit": "2023-11-07T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-11-07T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ed4d18d6-011a-47eb-a240-06801430730a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ed4d18d6-011a-47eb-a240-06801430730a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "66164253-89a5-4015-a1dc-d48fe3da0c4d",
                                        "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                                        "nHours": 1.5,
                                        "startDate": "2023-10-23T00:00:00"
                                    }
                                ],
                                "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                                "trainingEventName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mafalda Matos (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-23T00:00:00",
                                "registrationLimit": "2023-10-23T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-23T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "ed4d18d6-011a-47eb-a240-06801430730a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "66164253-89a5-4015-a1dc-d48fe3da0c4d",
                                        "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                                        "nHours": 1.5,
                                        "startDate": "2023-10-23T00:00:00"
                                    }
                                ],
                                "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                                "trainingEventName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Maria Inês Reis (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-23T00:00:00",
                                "registrationLimit": "2023-10-23T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-23T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                        "trainingEventName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Mafalda Matos (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-23T00:00:00",
                        "registrationLimit": "2023-10-23T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-10-23T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "3250a5c9-4152-4b39-9130-85bc50f65110",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | com Prof. Ana Perestrelo de Oliveira (8/10)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "3250a5c9-4152-4b39-9130-85bc50f65110",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | com Prof. Ana Perestrelo de Oliveira (8/10)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "9f5d2177-4c51-4b97-b6fe-736911e0c4c3",
                                        "trainingEventID": "c6dabe1e-8f78-4be0-846e-15674bb3100e",
                                        "nHours": 1,
                                        "startDate": "2023-10-19T00:00:00"
                                    }
                                ],
                                "trainingEventID": "c6dabe1e-8f78-4be0-846e-15674bb3100e",
                                "trainingEventName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (8/10) 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-19T00:00:00",
                                "registrationLimit": "2023-10-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-19T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "c6dabe1e-8f78-4be0-846e-15674bb3100e",
                        "trainingEventName": "Ciclo de Formações Corporate e M&A com Prof. Ana Perestrelo de Oliveira (8/10) 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-19T00:00:00",
                        "registrationLimit": "2023-10-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-10-19T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "8fe551bc-8fca-4691-96a6-8b4ee1df7523",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Excel - Nível Intermédio",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 14,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "8fe551bc-8fca-4691-96a6-8b4ee1df7523",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Excel - Nível Intermédio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 14,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "50dd603f-417f-4792-970e-b2b077cfe0d5",
                                        "trainingEventID": "3d4de5cf-ff83-489a-82d8-0831b6f40255",
                                        "nHours": 14,
                                        "startDate": "2023-10-16T00:00:00"
                                    }
                                ],
                                "trainingEventID": "3d4de5cf-ff83-489a-82d8-0831b6f40255",
                                "trainingEventName": "Excel - Nível Intermédio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ricardo Tomásio (eiCFormação)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-16T00:00:00",
                                "registrationLimit": "2023-10-16T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 28,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-16T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "3d4de5cf-ff83-489a-82d8-0831b6f40255",
                        "trainingEventName": "Excel - Nível Intermédio 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ricardo Tomásio (eiCFormação)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-16T00:00:00",
                        "registrationLimit": "2023-10-16T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 28,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-10-16T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "35b66f23-aea7-48d5-a3a8-d347a1d9be55",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.1 Resolução legal por incumprimento ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "Z",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "35b66f23-aea7-48d5-a3a8-d347a1d9be55",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.1 Resolução legal por incumprimento ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "ac4ee640-b242-480b-a373-d363625647be",
                                        "trainingEventID": "1cc4ff57-40c6-4253-8af3-905f3da930e2",
                                        "nHours": 1.5,
                                        "startDate": "2023-10-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "1cc4ff57-40c6-4253-8af3-905f3da930e2",
                                "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.1 Resolução legal por incumprimento  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-12T00:00:00",
                                "registrationLimit": "2023-10-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-12T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "35b66f23-aea7-48d5-a3a8-d347a1d9be55",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formação em Incumprimento Contratual | MOD.1 Resolução legal por incumprimento ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "ac4ee640-b242-480b-a373-d363625647be",
                                        "trainingEventID": "1cc4ff57-40c6-4253-8af3-905f3da930e2",
                                        "nHours": 1.5,
                                        "startDate": "2023-10-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "1cc4ff57-40c6-4253-8af3-905f3da930e2",
                                "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.1 Resolução legal por incumprimento  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Madalena Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-12T00:00:00",
                                "registrationLimit": "2023-10-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-12T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "1cc4ff57-40c6-4253-8af3-905f3da930e2",
                        "trainingEventName": "Ciclo de Formação em Incumprimento Contratual | MOD.1 Resolução legal por incumprimento  1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-12T00:00:00",
                        "registrationLimit": "2023-10-12T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-10-12T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "1cea1c41-5d22-4673-8d76-3e5073ca5eb3",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "KM Legal Research Tools | DGSI – Jurisprudência Nacional",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "1cea1c41-5d22-4673-8d76-3e5073ca5eb3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "KM Legal Research Tools | DGSI – Jurisprudência Nacional",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "0cdd098c-baad-4cbc-859e-7ca5ed49e67c",
                                        "trainingEventID": "72e7be01-966b-4300-b284-2fc9b97a6391",
                                        "nHours": 1,
                                        "startDate": "2023-10-10T00:00:00"
                                    }
                                ],
                                "trainingEventID": "72e7be01-966b-4300-b284-2fc9b97a6391",
                                "trainingEventName": "KM Legal Research Tools | DGSI – Jurisprudência Nacional 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Marta Presas (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-10T00:00:00",
                                "registrationLimit": "2023-10-10T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "S",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": "33906069-9764-4672-9c08-d74e46b25699",
                                "credits": 2,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-10T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "72e7be01-966b-4300-b284-2fc9b97a6391",
                        "trainingEventName": "KM Legal Research Tools | DGSI – Jurisprudência Nacional 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Marta Presas (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-10T00:00:00",
                        "registrationLimit": "2023-10-10T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "S",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": "33906069-9764-4672-9c08-d74e46b25699",
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-10-10T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "70719d01-2c80-41a9-ab85-e73fe701edb3",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Project Management",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 4.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventarem-se num ambiente em mudança constante. Os clientes tornaram-se mais exigentes, sendo essencial garantir uma melhor gestão do trabalho, das equipas, dos prazos e das propostas (horas e âmbito). A gestão de projetos funciona como base para esta melhoria. Esta formação permitirá aos participantes ficar a par dos principais conceitos de gestão de projetos. Estando estruturada em 3 sessões, a formação abordará os seguintes temas: conceito de projeto e gestão de projeto, boas práticas de gestão de projetos e as cinco fases da gestão de projetos. Os temas serão apresentados não só de uma forma teórica mas também prática, através da realização de dinâmicas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "70719d01-2c80-41a9-ab85-e73fe701edb3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventarem-se num ambiente em mudança constante. Os clientes tornaram-se mais exigentes, sendo essencial garantir uma melhor gestão do trabalho, das equipas, dos prazos e das propostas (horas e âmbito). A gestão de projetos funciona como base para esta melhoria. Esta formação permitirá aos participantes ficar a par dos principais conceitos de gestão de projetos. Estando estruturada em 3 sessões, a formação abordará os seguintes temas: conceito de projeto e gestão de projeto, boas práticas de gestão de projetos e as cinco fases da gestão de projetos. Os temas serão apresentados não só de uma forma teórica mas também prática, através da realização de dinâmicas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6a7ab18c-a70b-4cfe-979d-fd1983b14f5a",
                                        "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                        "nHours": 4.5,
                                        "startDate": "2023-10-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                "trainingEventName": "Project Management 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Carina Amaro (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-09T00:00:00",
                                "registrationLimit": "2023-10-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "70719d01-2c80-41a9-ab85-e73fe701edb3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventarem-se num ambiente em mudança constante. Os clientes tornaram-se mais exigentes, sendo essencial garantir uma melhor gestão do trabalho, das equipas, dos prazos e das propostas (horas e âmbito). A gestão de projetos funciona como base para esta melhoria. Esta formação permitirá aos participantes ficar a par dos principais conceitos de gestão de projetos. Estando estruturada em 3 sessões, a formação abordará os seguintes temas: conceito de projeto e gestão de projeto, boas práticas de gestão de projetos e as cinco fases da gestão de projetos. Os temas serão apresentados não só de uma forma teórica mas também prática, através da realização de dinâmicas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6a7ab18c-a70b-4cfe-979d-fd1983b14f5a",
                                        "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                        "nHours": 4.5,
                                        "startDate": "2023-10-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                "trainingEventName": "Project Management 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Guilherme Batlle y Font (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-09T00:00:00",
                                "registrationLimit": "2023-10-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "70719d01-2c80-41a9-ab85-e73fe701edb3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventarem-se num ambiente em mudança constante. Os clientes tornaram-se mais exigentes, sendo essencial garantir uma melhor gestão do trabalho, das equipas, dos prazos e das propostas (horas e âmbito). A gestão de projetos funciona como base para esta melhoria. Esta formação permitirá aos participantes ficar a par dos principais conceitos de gestão de projetos. Estando estruturada em 3 sessões, a formação abordará os seguintes temas: conceito de projeto e gestão de projeto, boas práticas de gestão de projetos e as cinco fases da gestão de projetos. Os temas serão apresentados não só de uma forma teórica mas também prática, através da realização de dinâmicas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6a7ab18c-a70b-4cfe-979d-fd1983b14f5a",
                                        "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                        "nHours": 4.5,
                                        "startDate": "2023-10-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                "trainingEventName": "Project Management 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Muriel Faden da Silva (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-09T00:00:00",
                                "registrationLimit": "2023-10-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "70719d01-2c80-41a9-ab85-e73fe701edb3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventarem-se num ambiente em mudança constante. Os clientes tornaram-se mais exigentes, sendo essencial garantir uma melhor gestão do trabalho, das equipas, dos prazos e das propostas (horas e âmbito). A gestão de projetos funciona como base para esta melhoria. Esta formação permitirá aos participantes ficar a par dos principais conceitos de gestão de projetos. Estando estruturada em 3 sessões, a formação abordará os seguintes temas: conceito de projeto e gestão de projeto, boas práticas de gestão de projetos e as cinco fases da gestão de projetos. Os temas serão apresentados não só de uma forma teórica mas também prática, através da realização de dinâmicas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6a7ab18c-a70b-4cfe-979d-fd1983b14f5a",
                                        "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                        "nHours": 4.5,
                                        "startDate": "2023-10-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                "trainingEventName": "Project Management 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Pedro Folque (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-09T00:00:00",
                                "registrationLimit": "2023-10-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "70719d01-2c80-41a9-ab85-e73fe701edb3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventarem-se num ambiente em mudança constante. Os clientes tornaram-se mais exigentes, sendo essencial garantir uma melhor gestão do trabalho, das equipas, dos prazos e das propostas (horas e âmbito). A gestão de projetos funciona como base para esta melhoria. Esta formação permitirá aos participantes ficar a par dos principais conceitos de gestão de projetos. Estando estruturada em 3 sessões, a formação abordará os seguintes temas: conceito de projeto e gestão de projeto, boas práticas de gestão de projetos e as cinco fases da gestão de projetos. Os temas serão apresentados não só de uma forma teórica mas também prática, através da realização de dinâmicas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6a7ab18c-a70b-4cfe-979d-fd1983b14f5a",
                                        "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                        "nHours": 4.5,
                                        "startDate": "2023-10-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                                "trainingEventName": "Project Management 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Pedro Lopes de Carvalho (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-09T00:00:00",
                                "registrationLimit": "2023-10-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "69cfec06-60cb-4b18-be7e-bb3461e85744",
                        "trainingEventName": "Project Management 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Carina Amaro (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-09T00:00:00",
                        "registrationLimit": "2023-10-09T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 9,
                        "trimester": null,
                        "subCategoryTypeID": 995003003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Project Management",
                        "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-10-09T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "fbf865e1-560a-4779-8fb4-0d1760834edc",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 133001,
                        "trainingMethodTypeID": 981003,
                        "trainingCategoryDesc": "Hard Skills",
                        "trainingModuleName": "teste acb",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108022",
                        "targetCategoriesDesc": "Assistente (Gestão)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": 2,
                        "description": "<p>aaaaa</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "P",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "fbf865e1-560a-4779-8fb4-0d1760834edc",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 133001,
                                "trainingMethodTypeID": 981003,
                                "trainingCategoryDesc": "Hard Skills",
                                "trainingModuleName": "teste acb",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108022",
                                "targetCategoriesDesc": "Assistente (Gestão)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": 2,
                                "description": "<p>aaaaa</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "P",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "1c35ab70-09cc-4495-8f5f-afb1f0df1242",
                                        "trainingEventID": "c0239736-fe14-4b99-9fd7-6f63bf81dc35",
                                        "nHours": 2,
                                        "startDate": "2023-10-10T15:49:00"
                                    }
                                ],
                                "trainingEventID": "c0239736-fe14-4b99-9fd7-6f63bf81dc35",
                                "trainingEventName": "teste acb 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mark Fritz (PBS)",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-09T00:00:00",
                                "registrationLimit": "2023-10-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995001002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Alemão",
                                "subCategoryName": "Visão Estratégica",
                                "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "CA (Gestão)",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "c0239736-fe14-4b99-9fd7-6f63bf81dc35",
                        "trainingEventName": "teste acb 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Mark Fritz (PBS)",
                        "locationTypeID": 973004,
                        "locationTypeDesc": "Blended Learning – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-09T00:00:00",
                        "registrationLimit": "2023-10-09T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995001002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Alemão",
                        "subCategoryName": "Visão Estratégica",
                        "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "CA (Gestão)",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-10-09T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "fdf7f943-359d-4478-8ef5-7327d88900ce",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Testes 19.09",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108010",
                        "targetCategoriesDesc": "Of Counsel",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>wertg</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "Z",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "fdf7f943-359d-4478-8ef5-7327d88900ce",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Testes 19.09",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108010",
                                "targetCategoriesDesc": "Of Counsel",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>wertg</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "68066d64-fa36-4eee-85b1-abeabd3a36dd",
                                        "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                                        "nHours": 2,
                                        "startDate": "2023-10-08T12:39:00"
                                    }
                                ],
                                "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                                "trainingEventName": "Testes 19.09 E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Alexandra Pinho Fontes",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-05T00:00:00",
                                "registrationLimit": "2023-10-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "95460218-111d-49c8-934f-2003a8c5f4ff",
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Energia & Recursos Naturais",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "2023-10-10T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "fdf7f943-359d-4478-8ef5-7327d88900ce",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Testes 19.09",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108010",
                                "targetCategoriesDesc": "Of Counsel",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>wertg</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "68066d64-fa36-4eee-85b1-abeabd3a36dd",
                                        "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                                        "nHours": 2,
                                        "startDate": "2023-10-08T12:39:00"
                                    }
                                ],
                                "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                                "trainingEventName": "Testes 19.09 E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-05T00:00:00",
                                "registrationLimit": "2023-10-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "95460218-111d-49c8-934f-2003a8c5f4ff",
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Energia & Recursos Naturais",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "2023-10-10T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "fdf7f943-359d-4478-8ef5-7327d88900ce",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Testes 19.09",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108010",
                                "targetCategoriesDesc": "Of Counsel",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>wertg</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "68066d64-fa36-4eee-85b1-abeabd3a36dd",
                                        "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                                        "nHours": 2,
                                        "startDate": "2023-10-08T12:39:00"
                                    }
                                ],
                                "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                                "trainingEventName": "Testes 19.09 E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "TESTE TESTE",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-05T00:00:00",
                                "registrationLimit": "2023-10-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "95460218-111d-49c8-934f-2003a8c5f4ff",
                                "credits": 3,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Energia & Recursos Naturais",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "2023-10-10T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "fb1649bb-6f58-4497-abc3-69de50e1d639",
                        "trainingEventName": "Testes 19.09 E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Alexandra Pinho Fontes",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-05T00:00:00",
                        "registrationLimit": "2023-10-06T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "95460218-111d-49c8-934f-2003a8c5f4ff",
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Espanhol",
                        "subCategoryName": "Project Management",
                        "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Energia & Recursos Naturais",
                        "experienceTypeDesc": "Tipo de Experiência de Treino",
                        "communicationDate": "2023-10-10T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "2a8cb772-8e8c-4f0e-aacb-aad0044a6f0a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": null,
                        "trainingMethodTypeID": 981003,
                        "trainingCategoryDesc": null,
                        "trainingModuleName": "teste 09.10",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108003,108006",
                        "targetCategoriesDesc": "Técnico(a),Técnico(a) Senior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": 2,
                        "description": "<p>asdfg</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "Z",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "2a8cb772-8e8c-4f0e-aacb-aad0044a6f0a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": null,
                                "trainingMethodTypeID": 981003,
                                "trainingCategoryDesc": null,
                                "trainingModuleName": "teste 09.10",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108003,108006",
                                "targetCategoriesDesc": "Técnico(a),Técnico(a) Senior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": 2,
                                "description": "<p>asdfg</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "b90db7f9-5d96-496a-af08-c5712edc14e0",
                                        "trainingEventID": "c47a7d9c-c894-43fc-8518-2c27c112c0fc",
                                        "nHours": 2,
                                        "startDate": "2023-10-12T12:54:00"
                                    }
                                ],
                                "trainingEventID": "c47a7d9c-c894-43fc-8518-2c27c112c0fc",
                                "trainingEventName": "teste 09.10 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Mark Fritz (PBS)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 1,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-05T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "E",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "145733c1-4578-48ff-825b-43f63558258e",
                                "credits": 4,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995001002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Alemão",
                                "subCategoryName": "Visão Estratégica",
                                "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Ambiente",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-10-09T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "c47a7d9c-c894-43fc-8518-2c27c112c0fc",
                        "trainingEventName": "teste 09.10 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Mark Fritz (PBS)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 1,
                        "cancelationLimit": "2023-10-04T00:00:00",
                        "registrationLimit": "2023-10-05T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "E",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "145733c1-4578-48ff-825b-43f63558258e",
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995001002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Alemão",
                        "subCategoryName": "Visão Estratégica",
                        "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "Ambiente",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-10-09T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Segredos de Negócio",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 3,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "Z",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Segredos de Negócio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "40bd2f19-f357-4683-9343-07663e50b779",
                                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                        "nHours": 3,
                                        "startDate": "2023-10-04T07:00:00"
                                    }
                                ],
                                "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                "trainingEventName": "Segredos de Negócio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Américo Oliveira Fragoso (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Segredos de Negócio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "40bd2f19-f357-4683-9343-07663e50b779",
                                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                        "nHours": 3,
                                        "startDate": "2023-10-04T07:00:00"
                                    }
                                ],
                                "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                "trainingEventName": "Segredos de Negócio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Falcão Afonso (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Segredos de Negócio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "40bd2f19-f357-4683-9343-07663e50b779",
                                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                        "nHours": 3,
                                        "startDate": "2023-10-04T07:00:00"
                                    }
                                ],
                                "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                "trainingEventName": "Segredos de Negócio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Bernardo (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Segredos de Negócio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "40bd2f19-f357-4683-9343-07663e50b779",
                                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                        "nHours": 3,
                                        "startDate": "2023-10-04T07:00:00"
                                    }
                                ],
                                "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                "trainingEventName": "Segredos de Negócio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "João Carlos Assunção (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Segredos de Negócio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "40bd2f19-f357-4683-9343-07663e50b779",
                                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                        "nHours": 3,
                                        "startDate": "2023-10-04T07:00:00"
                                    }
                                ],
                                "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                "trainingEventName": "Segredos de Negócio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ricardo Bordalo Junqueiro (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "3d324fab-c22c-4dad-8c6c-a06ac3dca5c2",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Segredos de Negócio",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No mercado global concorrencial, as empresas procuram vantagens competitivas pelo investimento na aquisição, desenvolvimento e implementação de conhecimento. O investimento na inovação e investigação, pela importância decisiva para o sucesso do desempenho empresarial, exige uma adequada proteção, para ser devidamente acautelado o respetivo retorno. O recurso a direitos de propriedade intelectual, como patentes, marcas, desenhos, modelos de utilidade ou direito de autor, constitui o meio de proteção mais difundido (cf. notícia de 28/03/2023, “Portugal base recorde dos pedidos de patentes”). </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "Z",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "40bd2f19-f357-4683-9343-07663e50b779",
                                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                        "nHours": 3,
                                        "startDate": "2023-10-04T07:00:00"
                                    }
                                ],
                                "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                                "trainingEventName": "Segredos de Negócio 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Sara Nazaré (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 6,
                                "trimester": "4º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-10-04T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "2402c08b-4d73-4686-a11d-6f5f357aa879",
                        "trainingEventName": "Segredos de Negócio 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Américo Oliveira Fragoso (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-10-04T00:00:00",
                        "registrationLimit": "2023-10-04T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 6,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-10-04T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "507d463d-97be-4a3b-956c-1b112b4d7fc6",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | Conflitos de interesses em sede de sociedades anónimas quanto (A): (i) à AG; (ii) o órgão de administração; (iii) conselho geral e de supervisão; (B) (i) direito de votar e direito de participar; (ii) direito a estar presente",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "507d463d-97be-4a3b-956c-1b112b4d7fc6",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | Conflitos de interesses em sede de sociedades anónimas quanto (A): (i) à AG; (ii) o órgão de administração; (iii) conselho geral e de supervisão; (B) (i) direito de votar e direito de participar; (ii) direito a estar presente",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "67e7a09b-8c64-4eb1-bc39-b6f683ac2089",
                                        "trainingEventID": "a54fd7a9-40d9-47e3-b64e-ed441021f02b",
                                        "nHours": 1,
                                        "startDate": "2023-09-25T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a54fd7a9-40d9-47e3-b64e-ed441021f02b",
                                "trainingEventName": "Conflitos de interesses em sede de sociedades anónimas quanto (A): (i) à AG; (ii) o órgão de administração; (iii) conselho geral e de supervisão; (B) (i) direito de votar e direito de participar; (ii) direito a estar presente 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-25T00:00:00",
                                "registrationLimit": "2023-09-25T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-25T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "a54fd7a9-40d9-47e3-b64e-ed441021f02b",
                        "trainingEventName": "Conflitos de interesses em sede de sociedades anónimas quanto (A): (i) à AG; (ii) o órgão de administração; (iii) conselho geral e de supervisão; (B) (i) direito de votar e direito de participar; (ii) direito a estar presente 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-25T00:00:00",
                        "registrationLimit": "2023-09-25T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-09-25T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ff158b04-de1f-405b-a362-a5abf901226c",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Energizar Equipas de Alta Performance",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 7,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Empowerment significa que cada um de nós deve ter o poder de agir, de propor alternativas e de fazer escolhas. Cada um de nós pode correr o extra mile e fazer toda a diferença. Nesta formação, dirigida para líderes com a responsabilidade de gerir uma equipa, o objetivo é aprender a inspirar e motivar os seus colaboradores a fazê-lo, à medida que os ajudam a crescer como pessoas e profissionais. Quais são as práticas mais modernas de captação, gestão e manutenção de equipas de alta performance? É necessário que os responsáveis a todos os níveis liderem com eficácia para, alinhados com a estratégia da empresa, serem capazes de obter o melhor dos seus colaboradores. Estes são alguns dos temas a abordar: gestão da diversidade, gestão dos diferentes estilos de trabalho, técnicas de feedback eficaz, o compromisso como motor da performance, gerir sob pressão, entre outros.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ff158b04-de1f-405b-a362-a5abf901226c",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Energizar Equipas de Alta Performance",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 7,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Empowerment significa que cada um de nós deve ter o poder de agir, de propor alternativas e de fazer escolhas. Cada um de nós pode correr o extra mile e fazer toda a diferença. Nesta formação, dirigida para líderes com a responsabilidade de gerir uma equipa, o objetivo é aprender a inspirar e motivar os seus colaboradores a fazê-lo, à medida que os ajudam a crescer como pessoas e profissionais. Quais são as práticas mais modernas de captação, gestão e manutenção de equipas de alta performance? É necessário que os responsáveis a todos os níveis liderem com eficácia para, alinhados com a estratégia da empresa, serem capazes de obter o melhor dos seus colaboradores. Estes são alguns dos temas a abordar: gestão da diversidade, gestão dos diferentes estilos de trabalho, técnicas de feedback eficaz, o compromisso como motor da performance, gerir sob pressão, entre outros.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "92cf7ed6-eb28-4dbe-ac9f-fac9a1086128",
                                        "trainingEventID": "29f73ef7-cff9-47a1-8414-ea032c5f197f",
                                        "nHours": 7,
                                        "startDate": "2023-09-20T00:00:00"
                                    }
                                ],
                                "trainingEventID": "29f73ef7-cff9-47a1-8414-ea032c5f197f",
                                "trainingEventName": "Energizar Equipas de Alta Performance 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ricardo Costa (ISEG)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-20T00:00:00",
                                "registrationLimit": "2023-09-20T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 14,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995004003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Leadership & Empowerment",
                                "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-09-20T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "29f73ef7-cff9-47a1-8414-ea032c5f197f",
                        "trainingEventName": "Energizar Equipas de Alta Performance 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ricardo Costa (ISEG)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-20T00:00:00",
                        "registrationLimit": "2023-09-20T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 14,
                        "trimester": null,
                        "subCategoryTypeID": 995004003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Leadership & Empowerment",
                        "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-09-20T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "dce8c844-bcdf-4c7f-86d6-3a7f60e46893",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Gestão de Pessoas ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "2249c368-2a71-4b79-9117-b8399fee8612",
                        "internalManagerName": "Constança Folque",
                        "internalManagerUsername": "COF@vda.pt",
                        "financialCost": 1500,
                        "targetCategories": "108003,108004",
                        "targetCategoriesDesc": "Associado(a),Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 5,
                        "totalHours": 10,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta formação bla bla bla</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "dce8c844-bcdf-4c7f-86d6-3a7f60e46893",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Gestão de Pessoas ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "2249c368-2a71-4b79-9117-b8399fee8612",
                                "internalManagerName": "Constança Folque",
                                "internalManagerUsername": "COF@vda.pt",
                                "financialCost": 1500,
                                "targetCategories": "108003,108004",
                                "targetCategoriesDesc": "Associado(a),Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 5,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta formação bla bla bla</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "f3243de1-d845-4a44-96b6-f849346d135f",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-09-20T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "c0d93cfb-51f6-4e5e-932f-b099e6fa5c18",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-09-27T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "b372c67f-fa0c-47d9-8a0e-7d33fc11506f",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-10-04T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "db6bc23e-9648-4fd0-91d4-4d1c4a320a4f",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-10-11T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "94b73b16-09a2-44a1-96be-b575109a2c02",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-10-17T11:00:00"
                                    }
                                ],
                                "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                "trainingEventName": "Gestão de Pessoas  E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-18T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995001003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Compliance Interno",
                                "subCategoryDesc": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "People Strategy,Recursos Humanos,Learning",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "dce8c844-bcdf-4c7f-86d6-3a7f60e46893",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Gestão de Pessoas ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "2249c368-2a71-4b79-9117-b8399fee8612",
                                "internalManagerName": "Constança Folque",
                                "internalManagerUsername": "COF@vda.pt",
                                "financialCost": 1500,
                                "targetCategories": "108003,108004",
                                "targetCategoriesDesc": "Associado(a),Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 5,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta formação bla bla bla</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "f3243de1-d845-4a44-96b6-f849346d135f",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-09-20T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "c0d93cfb-51f6-4e5e-932f-b099e6fa5c18",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-09-27T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "b372c67f-fa0c-47d9-8a0e-7d33fc11506f",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-10-04T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "db6bc23e-9648-4fd0-91d4-4d1c4a320a4f",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-10-11T11:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "94b73b16-09a2-44a1-96be-b575109a2c02",
                                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                        "nHours": 2,
                                        "startDate": "2023-10-17T11:00:00"
                                    }
                                ],
                                "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                                "trainingEventName": "Gestão de Pessoas  E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "TESTE TESTE",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-18T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995001003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Compliance Interno",
                                "subCategoryDesc": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "People Strategy,Recursos Humanos,Learning",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "91980d3b-6839-4a0e-89f9-a0a8597d322a",
                        "trainingEventName": "Gestão de Pessoas  E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Formador",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-18T00:00:00",
                        "registrationLimit": "2023-09-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 20,
                        "trimester": null,
                        "subCategoryTypeID": 995001003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Compliance Interno",
                        "subCategoryDesc": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "People Strategy,Recursos Humanos,Learning",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "8daeb639-7c18-4582-93c4-6aef65d6b469",
                        "trainingModuleTypeID": 971002,
                        "trainingModuleTypeDesc": "Formação Externa",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Teste (FE)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                        "internalManagerName": "Anne Fernandes",
                        "internalManagerUsername": "LAF@vda.pt",
                        "financialCost": 1000,
                        "targetCategories": "108003,108006,108004",
                        "targetCategoriesDesc": "Associado(a),Técnico(a) Senior,Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 2,
                        "totalHours": 10,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Teste</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "8daeb639-7c18-4582-93c4-6aef65d6b469",
                                "trainingModuleTypeID": 971002,
                                "trainingModuleTypeDesc": "Formação Externa",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Teste (FE)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003,108006,108004",
                                "targetCategoriesDesc": "Associado(a),Técnico(a) Senior,Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Teste</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "968979ff-2b10-48d8-84e9-f91f5bcc5565",
                                        "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                                        "nHours": 5,
                                        "startDate": "2023-09-20T18:23:00"
                                    },
                                    {
                                        "trainingEventSessionID": "a972fd9a-afc6-4a43-ad10-999eb0489207",
                                        "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                                        "nHours": 5,
                                        "startDate": "2023-09-21T18:23:00"
                                    }
                                ],
                                "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                                "trainingEventName": "Teste (FE) E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Recursos Humanos",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "8daeb639-7c18-4582-93c4-6aef65d6b469",
                                "trainingModuleTypeID": 971002,
                                "trainingModuleTypeDesc": "Formação Externa",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Teste (FE)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003,108006,108004",
                                "targetCategoriesDesc": "Associado(a),Técnico(a) Senior,Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Teste</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "968979ff-2b10-48d8-84e9-f91f5bcc5565",
                                        "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                                        "nHours": 5,
                                        "startDate": "2023-09-20T18:23:00"
                                    },
                                    {
                                        "trainingEventSessionID": "a972fd9a-afc6-4a43-ad10-999eb0489207",
                                        "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                                        "nHours": 5,
                                        "startDate": "2023-09-21T18:23:00"
                                    }
                                ],
                                "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                                "trainingEventName": "Teste (FE) E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador teste",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Recursos Humanos",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "299e431a-8498-4f60-9589-732fc024d331",
                        "trainingEventName": "Teste (FE) E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Formador",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-19T00:00:00",
                        "registrationLimit": "2023-09-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 20,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Recursos Humanos",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "a7dc94dd-4f61-4f44-be68-889f3e761b00",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Teste LAF (FI)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                        "internalManagerName": "Anne Fernandes",
                        "internalManagerUsername": "LAF@vda.pt",
                        "financialCost": 1000,
                        "targetCategories": "108003",
                        "targetCategoriesDesc": "Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 2,
                        "totalHours": 10,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p><strong>TESTE</strong></p><p><em>Teste</em></p><p><u>Teste</u></p><p><br></p><p><br></p><p><br></p><ol><li>Teste</li></ol><ul><li>Teste</li></ul><p><br></p><p><a href=\"https://forms.office.com/e/irXL2Ddx3d\" rel=\"noopener noreferrer\" target=\"_blank\">https://forms.office.com/e/irXL2Ddx3d</a></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "a7dc94dd-4f61-4f44-be68-889f3e761b00",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Teste LAF (FI)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p><strong>TESTE</strong></p><p><em>Teste</em></p><p><u>Teste</u></p><p><br></p><p><br></p><p><br></p><ol><li>Teste</li></ol><ul><li>Teste</li></ul><p><br></p><p><a href=\"https://forms.office.com/e/irXL2Ddx3d\" rel=\"noopener noreferrer\" target=\"_blank\">https://forms.office.com/e/irXL2Ddx3d</a></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "cce6e1de-9815-4e40-b7e3-777476c3ae0e",
                                        "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                                        "nHours": 5,
                                        "startDate": "2023-09-20T18:30:00"
                                    },
                                    {
                                        "trainingEventSessionID": "9fcd1251-617a-45d9-a3c6-a5efd920470f",
                                        "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                                        "nHours": 5,
                                        "startDate": "2023-09-21T18:30:00"
                                    }
                                ],
                                "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                                "trainingEventName": "Teste LAF (FI) E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador teste",
                                "locationTypeID": 973002,
                                "locationTypeDesc": "Presencial – VdA Porto",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "a7dc94dd-4f61-4f44-be68-889f3e761b00",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Teste LAF (FI)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p><strong>TESTE</strong></p><p><em>Teste</em></p><p><u>Teste</u></p><p><br></p><p><br></p><p><br></p><ol><li>Teste</li></ol><ul><li>Teste</li></ul><p><br></p><p><a href=\"https://forms.office.com/e/irXL2Ddx3d\" rel=\"noopener noreferrer\" target=\"_blank\">https://forms.office.com/e/irXL2Ddx3d</a></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "cce6e1de-9815-4e40-b7e3-777476c3ae0e",
                                        "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                                        "nHours": 5,
                                        "startDate": "2023-09-20T18:30:00"
                                    },
                                    {
                                        "trainingEventSessionID": "9fcd1251-617a-45d9-a3c6-a5efd920470f",
                                        "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                                        "nHours": 5,
                                        "startDate": "2023-09-21T18:30:00"
                                    }
                                ],
                                "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                                "trainingEventName": "Teste LAF (FI) E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "TESTE TESTE",
                                "locationTypeID": 973002,
                                "locationTypeDesc": "Presencial – VdA Porto",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "9160a789-d6ae-4e0d-8770-98aa56231e0d",
                        "trainingEventName": "Teste LAF (FI) E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Formador teste",
                        "locationTypeID": 973002,
                        "locationTypeDesc": "Presencial – VdA Porto",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-19T00:00:00",
                        "registrationLimit": "2023-09-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 20,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "38a5816e-0450-4e73-ab07-ab0a470cfcba",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Teste LAF 2",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                        "internalManagerName": "Anne Fernandes",
                        "internalManagerUsername": "LAF@vda.pt",
                        "financialCost": 1000,
                        "targetCategories": "108003",
                        "targetCategoriesDesc": "Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Teste</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "38a5816e-0450-4e73-ab07-ab0a470cfcba",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Teste LAF 2",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Teste</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "65a85181-9f23-4b12-97a6-9cd189e06543",
                                        "trainingEventID": "71dcc613-2e86-4dff-9c5d-fb981efc5b62",
                                        "nHours": 2,
                                        "startDate": "2023-09-20T06:00:00"
                                    }
                                ],
                                "trainingEventID": "71dcc613-2e86-4dff-9c5d-fb981efc5b62",
                                "trainingEventName": "Teste LAF 2 E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Recursos Humanos",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "38a5816e-0450-4e73-ab07-ab0a470cfcba",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Teste LAF 2",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "08c888bc-04fa-422e-9ef7-47263059b2b5",
                                "internalManagerName": "Anne Fernandes",
                                "internalManagerUsername": "LAF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Teste</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "65a85181-9f23-4b12-97a6-9cd189e06543",
                                        "trainingEventID": "71dcc613-2e86-4dff-9c5d-fb981efc5b62",
                                        "nHours": 2,
                                        "startDate": "2023-09-20T06:00:00"
                                    }
                                ],
                                "trainingEventID": "71dcc613-2e86-4dff-9c5d-fb981efc5b62",
                                "trainingEventName": "Teste LAF 2 E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador teste",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 2,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Recursos Humanos",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "71dcc613-2e86-4dff-9c5d-fb981efc5b62",
                        "trainingEventName": "Teste LAF 2 E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Formador",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 2,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-19T00:00:00",
                        "registrationLimit": "2023-09-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Recursos Humanos",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "18209dff-c8eb-4383-9f65-37ad948a4c34",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "TESTE SIMPLES",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 33,
                        "targetCategories": "108010",
                        "targetCategoriesDesc": "Of Counsel",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>TTESTE</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "18209dff-c8eb-4383-9f65-37ad948a4c34",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "TESTE SIMPLES",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 33,
                                "targetCategories": "108010",
                                "targetCategoriesDesc": "Of Counsel",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>TTESTE</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2f10ab58-3984-4e55-b5a1-f9c829f5ee81",
                                        "trainingEventID": "706e1a50-0f57-4ce7-b7ad-338f724c59e6",
                                        "nHours": 2,
                                        "startDate": "2023-09-28T16:34:00"
                                    }
                                ],
                                "trainingEventID": "706e1a50-0f57-4ce7-b7ad-338f724c59e6",
                                "trainingEventName": "TESTE SIMPLES E.2",
                                "trainingModuleDescription": null,
                                "instructorName": "Alexandra Pinho Fontes",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995001003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Compliance Interno",
                                "subCategoryDesc": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Faturação & Cobranças",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "18209dff-c8eb-4383-9f65-37ad948a4c34",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "TESTE SIMPLES",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 33,
                                "targetCategories": "108010",
                                "targetCategoriesDesc": "Of Counsel",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>TTESTE</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "efa2df08-a981-4f0b-ac48-e5c6f88fb0f8",
                                        "trainingEventID": "d1f90616-0919-4e85-af7b-f44b8b0b5ad3",
                                        "nHours": 2,
                                        "startDate": "2023-09-22T10:13:00"
                                    }
                                ],
                                "trainingEventID": "d1f90616-0919-4e85-af7b-f44b8b0b5ad3",
                                "trainingEventName": "TESTE SIMPLES E.3",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-20T00:00:00",
                                "registrationLimit": "2023-09-20T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "0cffdfc2-a4ad-43b0-b007-aadf02e55867",
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995001003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Compliance Interno",
                                "subCategoryDesc": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Faturação & Cobranças",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "706e1a50-0f57-4ce7-b7ad-338f724c59e6",
                        "trainingEventName": "TESTE SIMPLES E.2",
                        "trainingModuleDescription": null,
                        "instructorName": "Alexandra Pinho Fontes",
                        "locationTypeID": 973004,
                        "locationTypeDesc": "Blended Learning – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-19T00:00:00",
                        "registrationLimit": "2023-09-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995001003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Inglês",
                        "subCategoryName": "Compliance Interno",
                        "subCategoryDesc": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "Faturação & Cobranças",
                        "experienceTypeDesc": "Tipo de Experiência de Treino",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "073305dd-bd3c-41e4-8521-7cca5e93ecdb",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Clube de Línguas - 9ª Edição",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108003",
                        "targetCategoriesDesc": "Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 2,
                        "totalHours": 21,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Descrição da formação</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "073305dd-bd3c-41e4-8521-7cca5e93ecdb",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Clube de Línguas - 9ª Edição",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 21,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Descrição da formação</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d6cb6a38-4c1e-41b4-bf5f-58cc0f995e2b",
                                        "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                                        "nHours": 10.5,
                                        "startDate": "2023-09-20T15:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "4bbf1465-7fd5-4060-a370-ed0b74a30948",
                                        "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                                        "nHours": 10.5,
                                        "startDate": "2023-09-21T15:00:00"
                                    }
                                ],
                                "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                                "trainingEventName": "Clube de Línguas - 9ª Edição E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Alexandra Pinho Fontes",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-18T00:00:00",
                                "registrationLimit": "2023-09-18T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 42,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Alemão",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Comunicação Corporativa",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "073305dd-bd3c-41e4-8521-7cca5e93ecdb",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Clube de Línguas - 9ª Edição",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 2,
                                "totalHours": 21,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Descrição da formação</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d6cb6a38-4c1e-41b4-bf5f-58cc0f995e2b",
                                        "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                                        "nHours": 10.5,
                                        "startDate": "2023-09-20T15:00:00"
                                    },
                                    {
                                        "trainingEventSessionID": "4bbf1465-7fd5-4060-a370-ed0b74a30948",
                                        "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                                        "nHours": 10.5,
                                        "startDate": "2023-09-21T15:00:00"
                                    }
                                ],
                                "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                                "trainingEventName": "Clube de Línguas - 9ª Edição E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-18T00:00:00",
                                "registrationLimit": "2023-09-18T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 42,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Alemão",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Comunicação Corporativa",
                                "experienceTypeDesc": "Tipo de Experiência de Treino",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "2c7e022c-29d3-482e-852e-17ade43b9193",
                        "trainingEventName": "Clube de Línguas - 9ª Edição E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Alexandra Pinho Fontes",
                        "locationTypeID": 973004,
                        "locationTypeDesc": "Blended Learning – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-18T00:00:00",
                        "registrationLimit": "2023-09-18T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 42,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Alemão",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Comunicação Corporativa",
                        "experienceTypeDesc": "Tipo de Experiência de Treino",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "3eaea798-0f89-46fe-8e3a-4ec9b49dc199",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Workshop de Culinária",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "2249c368-2a71-4b79-9117-b8399fee8612",
                        "internalManagerName": "Constança Folque",
                        "internalManagerUsername": "COF@vda.pt",
                        "financialCost": 1000,
                        "targetCategories": "108003",
                        "targetCategoriesDesc": "Técnico(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Neste workshop de culinária....</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "3eaea798-0f89-46fe-8e3a-4ec9b49dc199",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Workshop de Culinária",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "2249c368-2a71-4b79-9117-b8399fee8612",
                                "internalManagerName": "Constança Folque",
                                "internalManagerUsername": "COF@vda.pt",
                                "financialCost": 1000,
                                "targetCategories": "108003",
                                "targetCategoriesDesc": "Técnico(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Neste workshop de culinária....</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "3d041140-f8e3-443e-bd60-8ae240048034",
                                        "trainingEventID": "312b55d4-8e8b-4b3c-ab0b-e6a45f986f46",
                                        "nHours": 2,
                                        "startDate": "2023-09-19T00:30:00"
                                    }
                                ],
                                "trainingEventID": "312b55d4-8e8b-4b3c-ab0b-e6a45f986f46",
                                "trainingEventName": "Workshop de Culinária E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "TESTE TESTE",
                                "locationTypeID": 973002,
                                "locationTypeDesc": "Presencial – VdA Porto",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-18T00:00:00",
                                "registrationLimit": "2023-09-18T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Learning",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "312b55d4-8e8b-4b3c-ab0b-e6a45f986f46",
                        "trainingEventName": "Workshop de Culinária E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "TESTE TESTE",
                        "locationTypeID": 973002,
                        "locationTypeDesc": "Presencial – VdA Porto",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-18T00:00:00",
                        "registrationLimit": "2023-09-18T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Espanhol",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Learning",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ff9231bf-4b15-41a3-b4b0-12a3134a0544",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Due Diligence",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>As due diligence visam identificar e avaliar os riscos inerentes às operações, bem como estabelecer as medidas negociais a tomar, permitindo planear adequadamente as operações, tendo por base os resultados obtidos. \nO procedimento de due diligence é, simultaneamente, de enorme responsabilidade para os advogados que o realizam, de grande importância para os clientes e para o sucesso das operações. \nNesta formação daremos a conhecer:\n- Os aspetos essenciais do procedimento de due diligence e o modo de o executar correta e eficientemente\n- As novas plataformas de Inteligência Artificial aplicadas às due diligence\n- As principais preocupações perante os diferentes tipos de transação e o impacto dos resultados das due diligence e a sua conjugação com a negociação e redação contratual</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ff9231bf-4b15-41a3-b4b0-12a3134a0544",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Due Diligence",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>As due diligence visam identificar e avaliar os riscos inerentes às operações, bem como estabelecer as medidas negociais a tomar, permitindo planear adequadamente as operações, tendo por base os resultados obtidos. \nO procedimento de due diligence é, simultaneamente, de enorme responsabilidade para os advogados que o realizam, de grande importância para os clientes e para o sucesso das operações. \nNesta formação daremos a conhecer:\n- Os aspetos essenciais do procedimento de due diligence e o modo de o executar correta e eficientemente\n- As novas plataformas de Inteligência Artificial aplicadas às due diligence\n- As principais preocupações perante os diferentes tipos de transação e o impacto dos resultados das due diligence e a sua conjugação com a negociação e redação contratual</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d6856670-9ef7-4b05-9098-73a2e380c86f",
                                        "trainingEventID": "bd31648c-519f-48ee-b143-a432d63f70a7",
                                        "nHours": 1.5,
                                        "startDate": "2023-09-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "bd31648c-519f-48ee-b143-a432d63f70a7",
                                "trainingEventName": "Due Diligence 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Sofia Barata (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-15T00:00:00",
                                "registrationLimit": "2023-09-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-15T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "bd31648c-519f-48ee-b143-a432d63f70a7",
                        "trainingEventName": "Due Diligence 3ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Sofia Barata (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-15T00:00:00",
                        "registrationLimit": "2023-09-15T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-09-15T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "5fa12807-8346-40b0-8e14-07d1a02a6a5a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Introdução aos Atos Notariais ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "5fa12807-8346-40b0-8e14-07d1a02a6a5a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Introdução aos Atos Notariais ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d1a27288-2852-4256-96f1-75abee2bc1eb",
                                        "trainingEventID": "d026c66a-b403-4ee9-a0c5-5fb14c16c852",
                                        "nHours": 1,
                                        "startDate": "2023-09-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "d026c66a-b403-4ee9-a0c5-5fb14c16c852",
                                "trainingEventName": "Introdução aos Atos Notariais 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Filipa Maia (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-15T00:00:00",
                                "registrationLimit": "2023-09-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-15T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "5fa12807-8346-40b0-8e14-07d1a02a6a5a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Introdução aos Atos Notariais ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d1a27288-2852-4256-96f1-75abee2bc1eb",
                                        "trainingEventID": "d026c66a-b403-4ee9-a0c5-5fb14c16c852",
                                        "nHours": 1,
                                        "startDate": "2023-09-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "d026c66a-b403-4ee9-a0c5-5fb14c16c852",
                                "trainingEventName": "Introdução aos Atos Notariais 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Inês Melo Grilo (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-15T00:00:00",
                                "registrationLimit": "2023-09-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-15T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "d026c66a-b403-4ee9-a0c5-5fb14c16c852",
                        "trainingEventName": "Introdução aos Atos Notariais 3ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Filipa Maia (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-15T00:00:00",
                        "registrationLimit": "2023-09-15T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-09-15T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "d1022460-7bc1-42c0-81ed-7320559a72e1",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Networking",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Num mundo em constante mudança e cada vez mais global, o desenvolvimento de uma rede de contactos firme, duradoura e de confiança é um fator crítico de sucesso, sobretudo para quem é advogado. Saber criar, manter e expandir a rede de contactos torna-se, assim, um ativo estratégico, cujo valor importa dominar.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "d1022460-7bc1-42c0-81ed-7320559a72e1",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Networking",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Num mundo em constante mudança e cada vez mais global, o desenvolvimento de uma rede de contactos firme, duradoura e de confiança é um fator crítico de sucesso, sobretudo para quem é advogado. Saber criar, manter e expandir a rede de contactos torna-se, assim, um ativo estratégico, cujo valor importa dominar.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "ad86eae1-ba31-4c7f-923f-b98fc8370980",
                                        "trainingEventID": "14c46964-e1f5-4edb-b7c4-d54063d68945",
                                        "nHours": 1.5,
                                        "startDate": "2023-09-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "14c46964-e1f5-4edb-b7c4-d54063d68945",
                                "trainingEventName": "Networking 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Benedita Aires (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-15T00:00:00",
                                "registrationLimit": "2023-09-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995004004,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Relacionamento Interpessoal",
                                "subCategoryDesc": "Interagir adequadamente com pessoas com diferentes características e em contextos sociais e profissionais distintos, suscitando confiança e contribuindo para uma cultura de coesão",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-15T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "14c46964-e1f5-4edb-b7c4-d54063d68945",
                        "trainingEventName": "Networking 3ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Benedita Aires (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-15T00:00:00",
                        "registrationLimit": "2023-09-15T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995004004,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Relacionamento Interpessoal",
                        "subCategoryDesc": "Interagir adequadamente com pessoas com diferentes características e em contextos sociais e profissionais distintos, suscitando confiança e contribuindo para uma cultura de coesão",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-09-15T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "357ea5e8-d88a-44cc-9a22-68c924f212b3",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Redação de Peças Processuais",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A redação de peças processuais clara e certeira é tão ou mais importante do que um bom argumento ou posição jurídica para ganhar o caso em Tribunal.\nEscrever muito não serve para convencer e tem hoje um risco acrescido de sobretaxas judiciais.\nEsta formação destina-se a transmitir guidelines para a redação de peças processuais.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "357ea5e8-d88a-44cc-9a22-68c924f212b3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Redação de Peças Processuais",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A redação de peças processuais clara e certeira é tão ou mais importante do que um bom argumento ou posição jurídica para ganhar o caso em Tribunal.\nEscrever muito não serve para convencer e tem hoje um risco acrescido de sobretaxas judiciais.\nEsta formação destina-se a transmitir guidelines para a redação de peças processuais.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "21885ea1-bb25-4b71-904e-5acdef808ea3",
                                        "trainingEventID": "fb8ab7db-6e86-4f83-b193-5754091f1e2c",
                                        "nHours": 1.5,
                                        "startDate": "2023-09-14T00:00:00"
                                    }
                                ],
                                "trainingEventID": "fb8ab7db-6e86-4f83-b193-5754091f1e2c",
                                "trainingEventName": "Redação de Peças Processuais 3º Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Falcão Afonso (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-14T00:00:00",
                                "registrationLimit": "2023-09-14T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-09-14T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "357ea5e8-d88a-44cc-9a22-68c924f212b3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Redação de Peças Processuais",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A redação de peças processuais clara e certeira é tão ou mais importante do que um bom argumento ou posição jurídica para ganhar o caso em Tribunal.\nEscrever muito não serve para convencer e tem hoje um risco acrescido de sobretaxas judiciais.\nEsta formação destina-se a transmitir guidelines para a redação de peças processuais.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "21885ea1-bb25-4b71-904e-5acdef808ea3",
                                        "trainingEventID": "fb8ab7db-6e86-4f83-b193-5754091f1e2c",
                                        "nHours": 1.5,
                                        "startDate": "2023-09-14T00:00:00"
                                    }
                                ],
                                "trainingEventID": "fb8ab7db-6e86-4f83-b193-5754091f1e2c",
                                "trainingEventName": "Redação de Peças Processuais 3º Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Tiago de Oliveira (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-14T00:00:00",
                                "registrationLimit": "2023-09-14T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-09-14T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "fb8ab7db-6e86-4f83-b193-5754091f1e2c",
                        "trainingEventName": "Redação de Peças Processuais 3º Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Falcão Afonso (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-14T00:00:00",
                        "registrationLimit": "2023-09-14T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-09-14T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "f628d1c3-0a2e-4193-b3f9-43e70b21caab",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Redação e Formatação de Contratos",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta formação destina-se a transmitir o estilo de redação de contratos vigentes na VdA, as ferramentas ao dispor dos advogados para maximizar a eficiência na redação de contratos e reduzir o risco de erro no processo, bem como dar a conhecer os principais standards contratuais adotados nos mercados internacionais.\nA ação pretende dar aos destinatários uma base comum na redação de contratos</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "f628d1c3-0a2e-4193-b3f9-43e70b21caab",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Redação e Formatação de Contratos",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta formação destina-se a transmitir o estilo de redação de contratos vigentes na VdA, as ferramentas ao dispor dos advogados para maximizar a eficiência na redação de contratos e reduzir o risco de erro no processo, bem como dar a conhecer os principais standards contratuais adotados nos mercados internacionais.\nA ação pretende dar aos destinatários uma base comum na redação de contratos</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "223a7be1-37f1-4c44-a26e-1fa343937bf4",
                                        "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                                        "nHours": 1,
                                        "startDate": "2023-09-14T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                                "trainingEventName": "Redação e Formatação de Contratos 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Claúdia Cruz Almeida (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-14T00:00:00",
                                "registrationLimit": "2023-09-14T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-14T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "f628d1c3-0a2e-4193-b3f9-43e70b21caab",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Redação e Formatação de Contratos",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta formação destina-se a transmitir o estilo de redação de contratos vigentes na VdA, as ferramentas ao dispor dos advogados para maximizar a eficiência na redação de contratos e reduzir o risco de erro no processo, bem como dar a conhecer os principais standards contratuais adotados nos mercados internacionais.\nA ação pretende dar aos destinatários uma base comum na redação de contratos</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "223a7be1-37f1-4c44-a26e-1fa343937bf4",
                                        "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                                        "nHours": 1,
                                        "startDate": "2023-09-14T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                                "trainingEventName": "Redação e Formatação de Contratos 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Cláudia Cruz de Almeida (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-14T00:00:00",
                                "registrationLimit": "2023-09-14T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-14T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "f628d1c3-0a2e-4193-b3f9-43e70b21caab",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Redação e Formatação de Contratos",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta formação destina-se a transmitir o estilo de redação de contratos vigentes na VdA, as ferramentas ao dispor dos advogados para maximizar a eficiência na redação de contratos e reduzir o risco de erro no processo, bem como dar a conhecer os principais standards contratuais adotados nos mercados internacionais.\nA ação pretende dar aos destinatários uma base comum na redação de contratos</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "223a7be1-37f1-4c44-a26e-1fa343937bf4",
                                        "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                                        "nHours": 1,
                                        "startDate": "2023-09-14T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                                "trainingEventName": "Redação e Formatação de Contratos 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Teresa Empis Falcão (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-14T00:00:00",
                                "registrationLimit": "2023-09-14T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-09-14T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "e86d6914-5363-4d3e-8e5c-758ad891cdc7",
                        "trainingEventName": "Redação e Formatação de Contratos 3ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Claúdia Cruz Almeida (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-14T00:00:00",
                        "registrationLimit": "2023-09-14T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-09-14T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ec2ba378-92ae-414d-baba-5080f4b3efdb",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "PowerPoint",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 14,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ec2ba378-92ae-414d-baba-5080f4b3efdb",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "PowerPoint",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 14,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "14bf0206-b0a4-4b5c-94fa-e90bf3e4d772",
                                        "trainingEventID": "78e34fb0-bbec-449f-84d1-62a3393369f6",
                                        "nHours": 14,
                                        "startDate": "2023-09-13T00:00:00"
                                    }
                                ],
                                "trainingEventID": "78e34fb0-bbec-449f-84d1-62a3393369f6",
                                "trainingEventName": "PowerPoint 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ricardo Costa (eiCFormação)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-13T00:00:00",
                                "registrationLimit": "2023-09-13T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 28,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-09-13T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "78e34fb0-bbec-449f-84d1-62a3393369f6",
                        "trainingEventName": "PowerPoint 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ricardo Costa (eiCFormação)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-13T00:00:00",
                        "registrationLimit": "2023-09-13T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 28,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-09-13T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "605fdf28-452e-4a37-a108-635519ded3bc",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "A avaliação ambiental estratégica de planos e avaliação ambiental de projetos neles enquadrados",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 7,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "605fdf28-452e-4a37-a108-635519ded3bc",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "A avaliação ambiental estratégica de planos e avaliação ambiental de projetos neles enquadrados",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 7,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "ad74bdc2-5fc2-4579-bd15-7bb21f7e981a",
                                        "trainingEventID": "5c886ed8-a484-4166-bd3b-182f40414762",
                                        "nHours": 7,
                                        "startDate": "2023-09-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "5c886ed8-a484-4166-bd3b-182f40414762",
                                "trainingEventName": "A avaliação ambiental estratégica de planos e avaliação ambiental de projetos neles enquadrados 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Fernanda Paula Oliveira (FDUC)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-12T00:00:00",
                                "registrationLimit": "2023-09-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 14,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-09-12T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "5c886ed8-a484-4166-bd3b-182f40414762",
                        "trainingEventName": "A avaliação ambiental estratégica de planos e avaliação ambiental de projetos neles enquadrados 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Fernanda Paula Oliveira (FDUC)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-09-12T00:00:00",
                        "registrationLimit": "2023-09-12T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 14,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-09-12T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "bf5487ae-1e7f-40cf-a3c0-2a4280f71187",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Testes ACB",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                        "internalManagerName": "Ana Costa Branco",
                        "internalManagerUsername": "ACB@vda.pt",
                        "financialCost": 4,
                        "targetCategories": "108009",
                        "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 3,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>dsfghjk</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "bf5487ae-1e7f-40cf-a3c0-2a4280f71187",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Testes ACB",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 4,
                                "targetCategories": "108009",
                                "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 3,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>dsfghjk</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "5c9d6aa0-16d8-4354-beea-2307818b17e7",
                                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-11T12:14:00"
                                    },
                                    {
                                        "trainingEventSessionID": "1fcb4bb4-b9c5-4c6b-8583-ce20e4d9aa87",
                                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-12T12:14:00"
                                    },
                                    {
                                        "trainingEventSessionID": "9257f1b6-23a9-456d-8a6a-1fd3001d3ca1",
                                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-13T12:14:00"
                                    }
                                ],
                                "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                "trainingEventName": "Testes ACB E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Alexandra Pinho Fontes",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-08-16T00:00:00",
                                "registrationLimit": "2023-08-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "0b6e79aa-b42e-4f68-9a49-1516070b6ae0",
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "VdA Moçambique",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "bf5487ae-1e7f-40cf-a3c0-2a4280f71187",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Testes ACB",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 4,
                                "targetCategories": "108009",
                                "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 3,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>dsfghjk</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "5c9d6aa0-16d8-4354-beea-2307818b17e7",
                                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-11T12:14:00"
                                    },
                                    {
                                        "trainingEventSessionID": "1fcb4bb4-b9c5-4c6b-8583-ce20e4d9aa87",
                                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-12T12:14:00"
                                    },
                                    {
                                        "trainingEventSessionID": "9257f1b6-23a9-456d-8a6a-1fd3001d3ca1",
                                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-13T12:14:00"
                                    }
                                ],
                                "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                                "trainingEventName": "Testes ACB E.1",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 1,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-08-16T00:00:00",
                                "registrationLimit": "2023-08-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "Y",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": "0b6e79aa-b42e-4f68-9a49-1516070b6ae0",
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "VdA Moçambique",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "bf5487ae-1e7f-40cf-a3c0-2a4280f71187",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Testes ACB",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "47befdac-f14d-40d4-9f94-51e5cea3f1c7",
                                "internalManagerName": "Ana Costa Branco",
                                "internalManagerUsername": "ACB@vda.pt",
                                "financialCost": 4,
                                "targetCategories": "108009",
                                "targetCategoriesDesc": "Assistente Senior (Operacional Rede)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 3,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>dsfghjk</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": true,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "9f804cdb-163a-4f27-bfac-26c29b6bb431",
                                        "trainingEventID": "65b0c9d6-7b0f-4262-90ca-13df2eddd40e",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-09-20T15:57:00"
                                    },
                                    {
                                        "trainingEventSessionID": "ff69e495-661d-4d72-ad8d-63e2dde67786",
                                        "trainingEventID": "65b0c9d6-7b0f-4262-90ca-13df2eddd40e",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-10-05T15:57:00"
                                    },
                                    {
                                        "trainingEventSessionID": "bed02e24-7a33-4d4a-8aa8-2811e0d844e3",
                                        "trainingEventID": "65b0c9d6-7b0f-4262-90ca-13df2eddd40e",
                                        "nHours": 0.6666666666666666,
                                        "startDate": "2023-10-11T15:57:00"
                                    }
                                ],
                                "trainingEventID": "65b0c9d6-7b0f-4262-90ca-13df2eddd40e",
                                "trainingEventName": "Testes ACB E.2",
                                "trainingModuleDescription": null,
                                "instructorName": "Formador",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-19T00:00:00",
                                "registrationLimit": "2023-09-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": false,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Espanhol",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "VdA Moçambique",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "0001-01-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": true,
                        "trainingEventSessions": [],
                        "trainingEventID": "0ff49fa7-153e-4c15-adfe-b2525f109a89",
                        "trainingEventName": "Testes ACB E.1",
                        "trainingModuleDescription": null,
                        "instructorName": "Alexandra Pinho Fontes",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 1,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-08-16T00:00:00",
                        "registrationLimit": "2023-08-17T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "Y",
                        "onWaitingList": false,
                        "canEnrole": false,
                        "trainingEventRegistryID": "0b6e79aa-b42e-4f68-9a49-1516070b6ae0",
                        "credits": 20,
                        "trimester": null,
                        "subCategoryTypeID": 995003003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Espanhol",
                        "subCategoryName": "Project Management",
                        "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "VdA Moçambique",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "0001-01-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "79cb37e6-0446-4938-baaf-72bb1cf5ce52",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Simplex Urbanístico",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 7,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "79cb37e6-0446-4938-baaf-72bb1cf5ce52",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Simplex Urbanístico",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 7,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "ef234545-cec3-4c9a-abe7-3698505e6a17",
                                        "trainingEventID": "98998813-49b2-4057-8659-0c11fbc679d8",
                                        "nHours": 7,
                                        "startDate": "2023-07-05T00:00:00"
                                    }
                                ],
                                "trainingEventID": "98998813-49b2-4057-8659-0c11fbc679d8",
                                "trainingEventName": "Simplex Urbanístico 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Fernanda Paula Oliveira (FDUC)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-07-05T00:00:00",
                                "registrationLimit": "2023-07-05T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 14,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-07-05T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "98998813-49b2-4057-8659-0c11fbc679d8",
                        "trainingEventName": "Simplex Urbanístico 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Fernanda Paula Oliveira (FDUC)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-07-05T00:00:00",
                        "registrationLimit": "2023-07-05T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 14,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-07-05T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "a7dddfdd-df71-4109-88fd-f7366f652d98",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Power On | Formação Simulador de Pricing",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a),Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer o novo Simulador de Pricing. Trata-se de uma importante ferramenta que permite, no momento de preparação de uma proposta, simular diferentes cenários de pricing, de acordo com os diversos regimes utilizados pela firma, e calcular a rentabilidade expectável de cada cenário.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "a7dddfdd-df71-4109-88fd-f7366f652d98",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Power On | Formação Simulador de Pricing",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a),Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer o novo Simulador de Pricing. Trata-se de uma importante ferramenta que permite, no momento de preparação de uma proposta, simular diferentes cenários de pricing, de acordo com os diversos regimes utilizados pela firma, e calcular a rentabilidade expectável de cada cenário.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "0326d3ae-ac56-430e-b303-90f4c2a0bc3a",
                                        "trainingEventID": "b1f40ab3-b374-44a2-963d-0b6b803df2d2",
                                        "nHours": 1,
                                        "startDate": "2023-07-03T00:00:00"
                                    }
                                ],
                                "trainingEventID": "b1f40ab3-b374-44a2-963d-0b6b803df2d2",
                                "trainingEventName": "Power On | Formação Simulador de Pricing 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Margarida Saragoça (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-07-03T00:00:00",
                                "registrationLimit": "2023-07-03T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-07-03T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "a7dddfdd-df71-4109-88fd-f7366f652d98",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Power On | Formação Simulador de Pricing",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a),Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer o novo Simulador de Pricing. Trata-se de uma importante ferramenta que permite, no momento de preparação de uma proposta, simular diferentes cenários de pricing, de acordo com os diversos regimes utilizados pela firma, e calcular a rentabilidade expectável de cada cenário.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "0326d3ae-ac56-430e-b303-90f4c2a0bc3a",
                                        "trainingEventID": "b1f40ab3-b374-44a2-963d-0b6b803df2d2",
                                        "nHours": 1,
                                        "startDate": "2023-07-03T00:00:00"
                                    }
                                ],
                                "trainingEventID": "b1f40ab3-b374-44a2-963d-0b6b803df2d2",
                                "trainingEventName": "Power On | Formação Simulador de Pricing 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Maria Araújo (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-07-03T00:00:00",
                                "registrationLimit": "2023-07-03T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-07-03T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "b1f40ab3-b374-44a2-963d-0b6b803df2d2",
                        "trainingEventName": "Power On | Formação Simulador de Pricing 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Margarida Saragoça (VdA)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-07-03T00:00:00",
                        "registrationLimit": "2023-07-03T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-07-03T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "12a95520-f0e4-40d6-9e69-1da2c92a0f19",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | Sociedades coligadas: da eventual aplicabilidade do Art.º 481º do CSC a demais países da UE",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "12a95520-f0e4-40d6-9e69-1da2c92a0f19",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | Sociedades coligadas: da eventual aplicabilidade do Art.º 481º do CSC a demais países da UE",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "70af247d-95ed-4b67-9c1a-674a892b5266",
                                        "trainingEventID": "1df5cf23-5cd7-4d4c-8d6c-1a1099c1808b",
                                        "nHours": 1,
                                        "startDate": "2023-06-22T00:00:00"
                                    }
                                ],
                                "trainingEventID": "1df5cf23-5cd7-4d4c-8d6c-1a1099c1808b",
                                "trainingEventName": "Sociedades coligadas: da eventual aplicabilidade do Art.º 481º do CSC a demais países da UE 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-06-22T00:00:00",
                                "registrationLimit": "2023-06-22T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-06-22T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "1df5cf23-5cd7-4d4c-8d6c-1a1099c1808b",
                        "trainingEventName": "Sociedades coligadas: da eventual aplicabilidade do Art.º 481º do CSC a demais países da UE 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-06-22T00:00:00",
                        "registrationLimit": "2023-06-22T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-06-22T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ccd1eec3-44d1-4dc8-b414-a1f1c811c0b5",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Public Speaking",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 6,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Nesta formação pretende-se, depois de identificar os maiores constrangimentos subjacentes ao ato de falar em público, apresentar dicas e soluções, utilizando técnicas clássicas e contemporâneas, que possam ser úteis a cada um dos formandos, em futuras situações de public speaking, que vão desde uma comunicação num congresso, passando por apresentações em reuniões com clientes ou pares, até mesmo à conversa social a que todos estamos, em maior e menor grau, obrigados. Esta formação destina-se a todos os indivíduos que sintam que podem beneficiar de uma melhoria nas suas competências comunicativas, quando as situações implicam que se fale para um determinado número de pessoas, que à partida nos constrange.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ccd1eec3-44d1-4dc8-b414-a1f1c811c0b5",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Public Speaking",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 6,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Nesta formação pretende-se, depois de identificar os maiores constrangimentos subjacentes ao ato de falar em público, apresentar dicas e soluções, utilizando técnicas clássicas e contemporâneas, que possam ser úteis a cada um dos formandos, em futuras situações de public speaking, que vão desde uma comunicação num congresso, passando por apresentações em reuniões com clientes ou pares, até mesmo à conversa social a que todos estamos, em maior e menor grau, obrigados. Esta formação destina-se a todos os indivíduos que sintam que podem beneficiar de uma melhoria nas suas competências comunicativas, quando as situações implicam que se fale para um determinado número de pessoas, que à partida nos constrange.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d63bb47e-aa1f-44cc-a070-822459a7fd3c",
                                        "trainingEventID": "70d2731b-53e5-4973-9bed-99b2db9a5c7a",
                                        "nHours": 6,
                                        "startDate": "2023-06-20T00:00:00"
                                    }
                                ],
                                "trainingEventID": "70d2731b-53e5-4973-9bed-99b2db9a5c7a",
                                "trainingEventName": "Public Speaking 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Pereira de Andrade (UCP)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-06-20T00:00:00",
                                "registrationLimit": "2023-06-20T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 12,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-06-20T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "70d2731b-53e5-4973-9bed-99b2db9a5c7a",
                        "trainingEventName": "Public Speaking 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Pereira de Andrade (UCP)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-06-20T00:00:00",
                        "registrationLimit": "2023-06-20T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 12,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-06-20T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "022e009d-8e89-4640-a536-aece5a6c0e6f",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Sessão de Sensibilização | Segurança da Informação - Serviços Cloud",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "022e009d-8e89-4640-a536-aece5a6c0e6f",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessão de Sensibilização | Segurança da Informação - Serviços Cloud",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "3c644a68-5fd5-4ca9-bb82-773aeda16cc7",
                                        "trainingEventID": "ab75aaae-8d9d-4c79-bdc2-799418f6eb7c",
                                        "nHours": 1,
                                        "startDate": "2023-06-19T00:00:00"
                                    }
                                ],
                                "trainingEventID": "ab75aaae-8d9d-4c79-bdc2-799418f6eb7c",
                                "trainingEventName": "Sessão de Sensibilização | Segurança da Informação - Serviços Cloud 1º Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Rui Alves (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-06-19T00:00:00",
                                "registrationLimit": "2023-06-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-06-19T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "ab75aaae-8d9d-4c79-bdc2-799418f6eb7c",
                        "trainingEventName": "Sessão de Sensibilização | Segurança da Informação - Serviços Cloud 1º Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Rui Alves (VdA)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-06-19T00:00:00",
                        "registrationLimit": "2023-06-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-06-19T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "8292224b-c984-49d6-847d-e35518ba3195",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "IT Skills for Executive Assistants",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 6,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Em colaboração com a área de Office Services, a VdA Academia promove uma formação de IT Skills altamente recomendada a Executive Assistants, onde serão abordadas, na prática, as principais funcionalidades das aplicações essenciais no dia-a-dia de uma Assistente na VdA, nomeadamente: Outlook, iManage, PDFDocs / Compare / Sharefile, Word e Formatação.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "8292224b-c984-49d6-847d-e35518ba3195",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "IT Skills for Executive Assistants",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 6,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Em colaboração com a área de Office Services, a VdA Academia promove uma formação de IT Skills altamente recomendada a Executive Assistants, onde serão abordadas, na prática, as principais funcionalidades das aplicações essenciais no dia-a-dia de uma Assistente na VdA, nomeadamente: Outlook, iManage, PDFDocs / Compare / Sharefile, Word e Formatação.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "b03d4b4c-77a8-40fc-a376-cf26bf268f9a",
                                        "trainingEventID": "69a2c4f8-835d-4616-893f-0def6ae64dd5",
                                        "nHours": 6,
                                        "startDate": "2023-05-29T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69a2c4f8-835d-4616-893f-0def6ae64dd5",
                                "trainingEventName": "IT Skills for Executive Assistants 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Santos (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-29T00:00:00",
                                "registrationLimit": "2023-05-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 12,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-29T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "8292224b-c984-49d6-847d-e35518ba3195",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "IT Skills for Executive Assistants",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 6,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Em colaboração com a área de Office Services, a VdA Academia promove uma formação de IT Skills altamente recomendada a Executive Assistants, onde serão abordadas, na prática, as principais funcionalidades das aplicações essenciais no dia-a-dia de uma Assistente na VdA, nomeadamente: Outlook, iManage, PDFDocs / Compare / Sharefile, Word e Formatação.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "b03d4b4c-77a8-40fc-a376-cf26bf268f9a",
                                        "trainingEventID": "69a2c4f8-835d-4616-893f-0def6ae64dd5",
                                        "nHours": 6,
                                        "startDate": "2023-05-29T00:00:00"
                                    }
                                ],
                                "trainingEventID": "69a2c4f8-835d-4616-893f-0def6ae64dd5",
                                "trainingEventName": "IT Skills for Executive Assistants 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Sónia Alferes de Sousa (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-29T00:00:00",
                                "registrationLimit": "2023-05-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 12,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-29T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "8292224b-c984-49d6-847d-e35518ba3195",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "IT Skills for Executive Assistants",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 6,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Em colaboração com a área de Office Services, a VdA Academia promove uma formação de IT Skills altamente recomendada a Executive Assistants, onde serão abordadas, na prática, as principais funcionalidades das aplicações essenciais no dia-a-dia de uma Assistente na VdA, nomeadamente: Outlook, iManage, PDFDocs / Compare / Sharefile, Word e Formatação.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d80994cf-8872-4f7c-9528-f0e96c374993",
                                        "trainingEventID": "3be98975-58f4-4668-97de-07ed5f2c010b",
                                        "nHours": 6,
                                        "startDate": "2023-06-26T00:00:00"
                                    }
                                ],
                                "trainingEventID": "3be98975-58f4-4668-97de-07ed5f2c010b",
                                "trainingEventName": "IT Skills for Executive Assistants 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Santos (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-06-26T00:00:00",
                                "registrationLimit": "2023-06-26T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 12,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-06-26T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "8292224b-c984-49d6-847d-e35518ba3195",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "IT Skills for Executive Assistants",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 6,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Em colaboração com a área de Office Services, a VdA Academia promove uma formação de IT Skills altamente recomendada a Executive Assistants, onde serão abordadas, na prática, as principais funcionalidades das aplicações essenciais no dia-a-dia de uma Assistente na VdA, nomeadamente: Outlook, iManage, PDFDocs / Compare / Sharefile, Word e Formatação.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d80994cf-8872-4f7c-9528-f0e96c374993",
                                        "trainingEventID": "3be98975-58f4-4668-97de-07ed5f2c010b",
                                        "nHours": 6,
                                        "startDate": "2023-06-26T00:00:00"
                                    }
                                ],
                                "trainingEventID": "3be98975-58f4-4668-97de-07ed5f2c010b",
                                "trainingEventName": "IT Skills for Executive Assistants 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Sónia Alferes de Sousa (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-06-26T00:00:00",
                                "registrationLimit": "2023-06-26T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 12,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-06-26T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "69a2c4f8-835d-4616-893f-0def6ae64dd5",
                        "trainingEventName": "IT Skills for Executive Assistants 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Santos (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-29T00:00:00",
                        "registrationLimit": "2023-05-29T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 12,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-05-29T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "8fe29d08-f253-4cb5-9926-14f7726f9dba",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Fintechs",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "8fe29d08-f253-4cb5-9926-14f7726f9dba",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Fintechs",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "d74fdeb9-9deb-47a2-9372-d36f518a30f7",
                                        "trainingEventID": "751a91b1-1e73-4873-83cc-af8371161b44",
                                        "nHours": 1.5,
                                        "startDate": "2023-05-24T00:00:00"
                                    }
                                ],
                                "trainingEventID": "751a91b1-1e73-4873-83cc-af8371161b44",
                                "trainingEventName": "Ciclo de Formações IST | Fintechs 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Nuno Horta (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-24T00:00:00",
                                "registrationLimit": "2023-05-24T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-05-24T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "751a91b1-1e73-4873-83cc-af8371161b44",
                        "trainingEventName": "Ciclo de Formações IST | Fintechs 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Nuno Horta (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-24T00:00:00",
                        "registrationLimit": "2023-05-24T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-05-24T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "0b6b631d-23e3-4ec1-ac37-c26f0ea97a6a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Veículos Autónomos",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "0b6b631d-23e3-4ec1-ac37-c26f0ea97a6a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Veículos Autónomos",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "241c76c2-eb9c-4004-87fe-463a673f022b",
                                        "trainingEventID": "c4ce986a-6e27-4241-98e9-7fd1f5742c11",
                                        "nHours": 1.5,
                                        "startDate": "2023-05-10T00:00:00"
                                    }
                                ],
                                "trainingEventID": "c4ce986a-6e27-4241-98e9-7fd1f5742c11",
                                "trainingEventName": "Ciclo de Formações IST | Veículos Autónomos 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Alexandre Bernardino (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-10T00:00:00",
                                "registrationLimit": "2023-05-10T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-05-10T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "c4ce986a-6e27-4241-98e9-7fd1f5742c11",
                        "trainingEventName": "Ciclo de Formações IST | Veículos Autónomos 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Alexandre Bernardino (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-10T00:00:00",
                        "registrationLimit": "2023-05-10T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-05-10T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "d37e6fa7-cd64-473b-955c-ebaa62f0fa49",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "O Poder dos seus Gestos - Legal Partners",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 9,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Mesmo sem falar, já está a comunicar! E, por vezes, mais alto do que pensava. Porque o corpo comunica e revela o estado emocional, a atitude, e até o poder de influência que detém enquanto profissional. Qual é a importância da comunicação não verbal no dia a dia profissional? Que gestos ajudam a comunicar de forma clara, assertiva e convincente? Qual é a reação que estes provocam nos outros? No treino dinâmico abordamos estas e outras questões e treinamos as competências necessárias para uma comunicação assertiva, eficaz e congruente.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "d37e6fa7-cd64-473b-955c-ebaa62f0fa49",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "O Poder dos seus Gestos - Legal Partners",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 9,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Mesmo sem falar, já está a comunicar! E, por vezes, mais alto do que pensava. Porque o corpo comunica e revela o estado emocional, a atitude, e até o poder de influência que detém enquanto profissional. Qual é a importância da comunicação não verbal no dia a dia profissional? Que gestos ajudam a comunicar de forma clara, assertiva e convincente? Qual é a reação que estes provocam nos outros? No treino dinâmico abordamos estas e outras questões e treinamos as competências necessárias para uma comunicação assertiva, eficaz e congruente.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "725d1961-6920-47aa-b2d0-a5ab1b171bd4",
                                        "trainingEventID": "2e1c640c-19d9-4b48-978f-d0d1c4493c46",
                                        "nHours": 9,
                                        "startDate": "2023-05-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "2e1c640c-19d9-4b48-978f-d0d1c4493c46",
                                "trainingEventName": "O Poder dos seus Gestos 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Irina Golovanova (The Body Language Academy)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-09T00:00:00",
                                "registrationLimit": "2023-05-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 18,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-09T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "2e1c640c-19d9-4b48-978f-d0d1c4493c46",
                        "trainingEventName": "O Poder dos seus Gestos 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Irina Golovanova (The Body Language Academy)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-09T00:00:00",
                        "registrationLimit": "2023-05-09T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 18,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-05-09T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Legal Project Management",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 4.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Legal Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "26e8def6-2099-4abf-ba9a-1c3d1c642951",
                                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                        "nHours": 4.5,
                                        "startDate": "2023-05-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                "trainingEventName": "Legal Project Management 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Carina Amaro (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-08T00:00:00",
                                "registrationLimit": "2023-05-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-05-08T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Legal Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "26e8def6-2099-4abf-ba9a-1c3d1c642951",
                                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                        "nHours": 4.5,
                                        "startDate": "2023-05-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                "trainingEventName": "Legal Project Management 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Guilherme Battle y Font (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-08T00:00:00",
                                "registrationLimit": "2023-05-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-05-08T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Legal Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "26e8def6-2099-4abf-ba9a-1c3d1c642951",
                                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                        "nHours": 4.5,
                                        "startDate": "2023-05-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                "trainingEventName": "Legal Project Management 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Muriel Faden da Silva (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-08T00:00:00",
                                "registrationLimit": "2023-05-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-05-08T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Legal Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "26e8def6-2099-4abf-ba9a-1c3d1c642951",
                                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                        "nHours": 4.5,
                                        "startDate": "2023-05-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                "trainingEventName": "Legal Project Management 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Pedro Folque (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-08T00:00:00",
                                "registrationLimit": "2023-05-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-05-08T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Legal Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "26e8def6-2099-4abf-ba9a-1c3d1c642951",
                                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                        "nHours": 4.5,
                                        "startDate": "2023-05-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                "trainingEventName": "Legal Project Management 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Pedro Lopes Carvalho (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-08T00:00:00",
                                "registrationLimit": "2023-05-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-05-08T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "77a346a6-f9de-44d2-beca-91275cb4d46b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Legal Project Management",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 4.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Hoje, um dos grandes desafios das firmas de advogados é aprenderem a reinventar-se num ambiente em mudança constante, ajustando-se ao crescente grau de exigência e sofisticação dos Clientes. Neste contexto, é essencial garantir que as equipas têm as competências e as ferramentas necessárias para gerir os projetos de forma eficiente e ágil. Esta formação, dirigida a advogados, permitirá aos participantes ficar a par dos principais conceitos de legal project management. Estando estruturada em 3 sessões, a formação abordará não só as quatro fases do Legal Project Management, como também as ferramentas e as boas práticas que devem ser utilizadas em cada fase. Os temas serão apresentados de forma teórica e prática através de um caso prático que será desenvolvido ao longo das sessões. Através desta formação, ficará a par dos principais conceitos de legal project management que poderá rapidamente passar a utilizar na gestão de dossiers de clientes, otimizando a sua gestão.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "26e8def6-2099-4abf-ba9a-1c3d1c642951",
                                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                        "nHours": 4.5,
                                        "startDate": "2023-05-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                                "trainingEventName": "Legal Project Management 2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Pedro Lopes de Carvalho (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-08T00:00:00",
                                "registrationLimit": "2023-05-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 9,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Project Management",
                                "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-05-08T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "a33ef519-ed69-40c1-8948-aa7ced17f0e8",
                        "trainingEventName": "Legal Project Management 2ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Carina Amaro (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-08T00:00:00",
                        "registrationLimit": "2023-05-08T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 9,
                        "trimester": null,
                        "subCategoryTypeID": 995003003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Project Management",
                        "subCategoryDesc": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-05-08T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "13a15ad4-324f-492e-88b6-06e5da26bfa7",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Penal",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 10,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "13a15ad4-324f-492e-88b6-06e5da26bfa7",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Penal",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "af7d96f9-9c7d-48c7-a8e5-2b78d31d7196",
                                        "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                        "nHours": 10,
                                        "startDate": "2023-05-04T00:00:00"
                                    }
                                ],
                                "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Penal 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Bruno Melo Alves (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-04T00:00:00",
                                "registrationLimit": "2023-05-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "13a15ad4-324f-492e-88b6-06e5da26bfa7",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Penal",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "af7d96f9-9c7d-48c7-a8e5-2b78d31d7196",
                                        "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                        "nHours": 10,
                                        "startDate": "2023-05-04T00:00:00"
                                    }
                                ],
                                "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Penal 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Bernardo (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-04T00:00:00",
                                "registrationLimit": "2023-05-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "13a15ad4-324f-492e-88b6-06e5da26bfa7",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Penal",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "af7d96f9-9c7d-48c7-a8e5-2b78d31d7196",
                                        "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                        "nHours": 10,
                                        "startDate": "2023-05-04T00:00:00"
                                    }
                                ],
                                "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Penal 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "João Medeiros (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-04T00:00:00",
                                "registrationLimit": "2023-05-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-04T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "13a15ad4-324f-492e-88b6-06e5da26bfa7",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Penal",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 10,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "af7d96f9-9c7d-48c7-a8e5-2b78d31d7196",
                                        "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                        "nHours": 10,
                                        "startDate": "2023-05-04T00:00:00"
                                    }
                                ],
                                "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Penal 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Rui Costa Pereira (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-04T00:00:00",
                                "registrationLimit": "2023-05-04T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 20,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-04T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "83fc063e-fe19-4929-9823-f84f8daf3494",
                        "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Penal 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Bruno Melo Alves (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-04T00:00:00",
                        "registrationLimit": "2023-05-04T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 20,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-05-04T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "44eed293-7aa0-4084-a9c1-cc7ef50bfc26",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Civil",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 9,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "44eed293-7aa0-4084-a9c1-cc7ef50bfc26",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Civil",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 9,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "72729aa7-1324-488d-bfe7-d97a1467ed71",
                                        "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                        "nHours": 9,
                                        "startDate": "2023-05-02T00:00:00"
                                    }
                                ],
                                "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Civil 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Lickfold Novais e Silva (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-02T00:00:00",
                                "registrationLimit": "2023-05-02T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 18,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-02T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "44eed293-7aa0-4084-a9c1-cc7ef50bfc26",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Civil",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 9,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "72729aa7-1324-488d-bfe7-d97a1467ed71",
                                        "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                        "nHours": 9,
                                        "startDate": "2023-05-02T00:00:00"
                                    }
                                ],
                                "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Civil 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Catarina Carvalho Cunha (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-02T00:00:00",
                                "registrationLimit": "2023-05-02T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 18,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-02T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "44eed293-7aa0-4084-a9c1-cc7ef50bfc26",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Civil",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 9,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "72729aa7-1324-488d-bfe7-d97a1467ed71",
                                        "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                        "nHours": 9,
                                        "startDate": "2023-05-02T00:00:00"
                                    }
                                ],
                                "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Civil 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Catarina Frutuoso dos Santos (VdA)\n",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-02T00:00:00",
                                "registrationLimit": "2023-05-02T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 18,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-02T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "44eed293-7aa0-4084-a9c1-cc7ef50bfc26",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Civil",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 9,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "72729aa7-1324-488d-bfe7-d97a1467ed71",
                                        "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                        "nHours": 9,
                                        "startDate": "2023-05-02T00:00:00"
                                    }
                                ],
                                "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Civil 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Neves (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-02T00:00:00",
                                "registrationLimit": "2023-05-02T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 18,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-02T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "44eed293-7aa0-4084-a9c1-cc7ef50bfc26",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Sessões de Apoio ao Estágio | Prática Processual Civil",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 9,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "72729aa7-1324-488d-bfe7-d97a1467ed71",
                                        "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                        "nHours": 9,
                                        "startDate": "2023-05-02T00:00:00"
                                    }
                                ],
                                "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                                "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Civil 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Matilde Líbano Monteiro(VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-05-02T00:00:00",
                                "registrationLimit": "2023-05-02T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 18,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-05-02T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "dd765b54-e656-40b0-9c39-0d06bc81de2f",
                        "trainingEventName": "Sessões de Apoio ao Estágio | Prática Processual Civil 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Lickfold Novais e Silva (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-05-02T00:00:00",
                        "registrationLimit": "2023-05-02T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 18,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-05-02T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "61f22bef-ed8e-4543-aab4-ab6c50120ba3",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | Validade de warranties dadas por sociedade fechada: (i) em caso de transmissão de posição minoritária e maioritária; (ii) em caso de aumento de capital aberto a terceiros (sem subscrição pelo público)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "61f22bef-ed8e-4543-aab4-ab6c50120ba3",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | Validade de warranties dadas por sociedade fechada: (i) em caso de transmissão de posição minoritária e maioritária; (ii) em caso de aumento de capital aberto a terceiros (sem subscrição pelo público)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "82eba006-08fc-45d7-820e-8c0b4e75f3b2",
                                        "trainingEventID": "48b1cd2a-da94-4313-810c-d48b0ee40535",
                                        "nHours": 1,
                                        "startDate": "2023-04-27T00:00:00"
                                    }
                                ],
                                "trainingEventID": "48b1cd2a-da94-4313-810c-d48b0ee40535",
                                "trainingEventName": "Validade de warranties dadas por sociedade fechada: (i) em caso de transmissão de posição minoritária e maioritária; (ii) em caso de aumento de capital aberto a terceiros (sem subscrição pelo público) 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-27T00:00:00",
                                "registrationLimit": "2023-04-27T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-04-27T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "48b1cd2a-da94-4313-810c-d48b0ee40535",
                        "trainingEventName": "Validade de warranties dadas por sociedade fechada: (i) em caso de transmissão de posição minoritária e maioritária; (ii) em caso de aumento de capital aberto a terceiros (sem subscrição pelo público) 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-27T00:00:00",
                        "registrationLimit": "2023-04-27T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-04-27T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "eed4e010-160a-4d2a-8289-1bb3244ea3b7",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Smart Grids",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "eed4e010-160a-4d2a-8289-1bb3244ea3b7",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Smart Grids",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "588c5362-f1dd-4ecf-a784-3c5b2cba1aac",
                                        "trainingEventID": "e561ed02-06dc-415a-af6e-a4c32b2fb331",
                                        "nHours": 1.5,
                                        "startDate": "2023-04-26T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e561ed02-06dc-415a-af6e-a4c32b2fb331",
                                "trainingEventName": "Ciclo de Formações IST | Smart Grids 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Rui Castro (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-26T00:00:00",
                                "registrationLimit": "2023-04-26T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-04-26T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "e561ed02-06dc-415a-af6e-a4c32b2fb331",
                        "trainingEventName": "Ciclo de Formações IST | Smart Grids 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Rui Castro (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-26T00:00:00",
                        "registrationLimit": "2023-04-26T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-04-26T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "e99f7a72-aaaa-4ec3-98ea-846917659729",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Tudo o que pode correr mal numa operação",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Nesta formação serão identificados os temas que, potencialmente, possam ocorrer no contexto de uma operação (financeira ou outra). Neste âmbito, serão abordados exemplos concretos de episódios ocorridos no passado, bem como mecanismos de antecipação desses problemas e mecanismos de controlo de danos (jurídicos ou reputacionais).</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "e99f7a72-aaaa-4ec3-98ea-846917659729",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Tudo o que pode correr mal numa operação",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Nesta formação serão identificados os temas que, potencialmente, possam ocorrer no contexto de uma operação (financeira ou outra). Neste âmbito, serão abordados exemplos concretos de episódios ocorridos no passado, bem como mecanismos de antecipação desses problemas e mecanismos de controlo de danos (jurídicos ou reputacionais).</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6aa9e798-2256-420f-962d-939eadf23963",
                                        "trainingEventID": "033b784b-75bd-4752-a271-79024bb1275f",
                                        "nHours": 2,
                                        "startDate": "2023-04-19T00:00:00"
                                    }
                                ],
                                "trainingEventID": "033b784b-75bd-4752-a271-79024bb1275f",
                                "trainingEventName": "Tudo o que pode correr mal numa operação 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Sebastião Nogueira (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-19T00:00:00",
                                "registrationLimit": "2023-04-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-04-19T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "e99f7a72-aaaa-4ec3-98ea-846917659729",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Tudo o que pode correr mal numa operação",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Nesta formação serão identificados os temas que, potencialmente, possam ocorrer no contexto de uma operação (financeira ou outra). Neste âmbito, serão abordados exemplos concretos de episódios ocorridos no passado, bem como mecanismos de antecipação desses problemas e mecanismos de controlo de danos (jurídicos ou reputacionais).</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6aa9e798-2256-420f-962d-939eadf23963",
                                        "trainingEventID": "033b784b-75bd-4752-a271-79024bb1275f",
                                        "nHours": 2,
                                        "startDate": "2023-04-19T00:00:00"
                                    }
                                ],
                                "trainingEventID": "033b784b-75bd-4752-a271-79024bb1275f",
                                "trainingEventName": "Tudo o que pode correr mal numa operação 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Tiago Correia Moreira (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-19T00:00:00",
                                "registrationLimit": "2023-04-19T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-04-19T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "033b784b-75bd-4752-a271-79024bb1275f",
                        "trainingEventName": "Tudo o que pode correr mal numa operação 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Sebastião Nogueira (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-19T00:00:00",
                        "registrationLimit": "2023-04-19T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-04-19T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "32c46012-0149-4b28-bebd-f71304796b93",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Finanças para não Financeiros – Nível I",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a),Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 24,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Programa destinado a desenvolver conhecimentos de forma prática e pragmática para a participação crítica e fundamentada de decisões financeiras assim como para a compreensão clara que a análise financeira representa nas decisões de gestão operacional e estratégica. O programa contempla 2 níveis de conhecimento, sendo este o nível 1 a incidir sobre Contabilidade Financeira e Análise Financeira.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "32c46012-0149-4b28-bebd-f71304796b93",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Finanças para não Financeiros – Nível I",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a),Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 24,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Programa destinado a desenvolver conhecimentos de forma prática e pragmática para a participação crítica e fundamentada de decisões financeiras assim como para a compreensão clara que a análise financeira representa nas decisões de gestão operacional e estratégica. O programa contempla 2 níveis de conhecimento, sendo este o nível 1 a incidir sobre Contabilidade Financeira e Análise Financeira.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "162aee3b-e5b8-4c5b-aeef-157fd1d4147b",
                                        "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                                        "nHours": 24,
                                        "startDate": "2023-04-13T00:00:00"
                                    }
                                ],
                                "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                                "trainingEventName": "Finanças para não Financeiros – Nível I 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Jorge Farinha (PBS)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-13T00:00:00",
                                "registrationLimit": "2023-04-13T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 48,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-04-13T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "32c46012-0149-4b28-bebd-f71304796b93",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Finanças para não Financeiros – Nível I",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a),Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 24,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Programa destinado a desenvolver conhecimentos de forma prática e pragmática para a participação crítica e fundamentada de decisões financeiras assim como para a compreensão clara que a análise financeira representa nas decisões de gestão operacional e estratégica. O programa contempla 2 níveis de conhecimento, sendo este o nível 1 a incidir sobre Contabilidade Financeira e Análise Financeira.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "162aee3b-e5b8-4c5b-aeef-157fd1d4147b",
                                        "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                                        "nHours": 24,
                                        "startDate": "2023-04-13T00:00:00"
                                    }
                                ],
                                "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                                "trainingEventName": "Finanças para não Financeiros – Nível I 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Renata Blanc (PBS)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-13T00:00:00",
                                "registrationLimit": "2023-04-13T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 48,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-04-13T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "32c46012-0149-4b28-bebd-f71304796b93",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Finanças para não Financeiros – Nível I",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a),Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 24,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Programa destinado a desenvolver conhecimentos de forma prática e pragmática para a participação crítica e fundamentada de decisões financeiras assim como para a compreensão clara que a análise financeira representa nas decisões de gestão operacional e estratégica. O programa contempla 2 níveis de conhecimento, sendo este o nível 1 a incidir sobre Contabilidade Financeira e Análise Financeira.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "162aee3b-e5b8-4c5b-aeef-157fd1d4147b",
                                        "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                                        "nHours": 24,
                                        "startDate": "2023-04-13T00:00:00"
                                    }
                                ],
                                "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                                "trainingEventName": "Finanças para não Financeiros – Nível I 3ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Renata Blanc e Jorge Farinha (PBS)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-13T00:00:00",
                                "registrationLimit": "2023-04-13T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 48,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-04-13T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "5be06f32-b713-4289-ae58-dd2fb04082af",
                        "trainingEventName": "Finanças para não Financeiros – Nível I 3ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Jorge Farinha (PBS)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-13T00:00:00",
                        "registrationLimit": "2023-04-13T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 48,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Longa Duração",
                        "communicationDate": "2023-04-13T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "3feaa277-5418-4e65-b94d-5e9b97a77d91",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Redes e Sistemas de Comunicações sem Fios",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "3feaa277-5418-4e65-b94d-5e9b97a77d91",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Redes e Sistemas de Comunicações sem Fios",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "45ddb6e3-ce1b-4e2e-a069-fd621a3a0978",
                                        "trainingEventID": "c08dc24d-c097-4a22-8dc8-d8fb5ac7c590",
                                        "nHours": 1.5,
                                        "startDate": "2023-04-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "c08dc24d-c097-4a22-8dc8-d8fb5ac7c590",
                                "trainingEventName": "Ciclo de Formações IST | Redes e Sistemas de Comunicações sem Fios 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Luís M. Correia (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-12T00:00:00",
                                "registrationLimit": "2023-04-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-04-12T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "c08dc24d-c097-4a22-8dc8-d8fb5ac7c590",
                        "trainingEventName": "Ciclo de Formações IST | Redes e Sistemas de Comunicações sem Fios 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Luís M. Correia (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-12T00:00:00",
                        "registrationLimit": "2023-04-12T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-04-12T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "0e15b725-42e2-4a65-b432-82da3dceaaf6",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Comunicação Escrita: para uma redação clara, cuidada e sem erros ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 6,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A comunicação escrita é atualmente uma realidade incontornável: profissional, social ou familiarmente, usamos o correio eletrónico, os chats e as redes sociais como meios privilegiados para nos relacionarmos com os outros, o que nos obriga a um maior cuidado com a linguagem, o vocabulário e a estruturação frásica, para que as mensagens sejam claras e redigidas com correção, sob pena de não alcançarem o destinatário ou sermos desacreditados por ele. Este workshop tem como objetivo primeiro fornecer uma série de dicas que permitam melhorar a qualidade da comunicação escrita, bem como esclarecer uma seleção de erros comuns da língua portuguesa, a evitar sempre.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "0e15b725-42e2-4a65-b432-82da3dceaaf6",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Comunicação Escrita: para uma redação clara, cuidada e sem erros ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 6,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A comunicação escrita é atualmente uma realidade incontornável: profissional, social ou familiarmente, usamos o correio eletrónico, os chats e as redes sociais como meios privilegiados para nos relacionarmos com os outros, o que nos obriga a um maior cuidado com a linguagem, o vocabulário e a estruturação frásica, para que as mensagens sejam claras e redigidas com correção, sob pena de não alcançarem o destinatário ou sermos desacreditados por ele. Este workshop tem como objetivo primeiro fornecer uma série de dicas que permitam melhorar a qualidade da comunicação escrita, bem como esclarecer uma seleção de erros comuns da língua portuguesa, a evitar sempre.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "c9934af7-6829-40eb-8932-8af1747e1c12",
                                        "trainingEventID": "bb04f333-89a8-40d4-affd-535a3fd60238",
                                        "nHours": 6,
                                        "startDate": "2023-04-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "bb04f333-89a8-40d4-affd-535a3fd60238",
                                "trainingEventName": "Comunicação Escrita: para uma redação clara, cuidada e sem erros  2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Andrade (UCP Porto)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-12T00:00:00",
                                "registrationLimit": "2023-04-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 12,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-04-12T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "bb04f333-89a8-40d4-affd-535a3fd60238",
                        "trainingEventName": "Comunicação Escrita: para uma redação clara, cuidada e sem erros  2ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Andrade (UCP Porto)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-12T00:00:00",
                        "registrationLimit": "2023-04-12T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 12,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-04-12T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "66bdb6a6-eedd-4e8b-8a28-8d1ff9c61414",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Thinking Like a Futurist - Workshop Modelo Cone Estratégico",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 7,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>O processo de evolução tecnológica decorre a um ritmo acelerado, traduzindo-se em novas expectativas e exigências por parte dos cidadãos, novos modelos de negócio, ecossistemas mais complexos, seja a nível de regulação, de governança e de postura na sociedade e ainda novas necessidades de produtos e serviços que precisam refletir as necessidades do mercado. Os participantes realizarão um exercício de planeamento estratégico, alicerçado numa metodologia de Harvard, identificando de tendências e potenciais eventos que estão a afetar o seu setor, em Portugal e no mundo, bem como eventos ou constrangimentos do contexto macro (demografia, tendências, mundo do trabalho, panorama empresarial, ...). Dezenas de organizações em todo o mundo usam o cone para enfrentar cenários de profunda incerteza, o que permite que os seus líderes pensem exponencialmente e realizem ações incrementais contínuas, colocando-os em posição de moldar o futuro.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "66bdb6a6-eedd-4e8b-8a28-8d1ff9c61414",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Thinking Like a Futurist - Workshop Modelo Cone Estratégico",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 7,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>O processo de evolução tecnológica decorre a um ritmo acelerado, traduzindo-se em novas expectativas e exigências por parte dos cidadãos, novos modelos de negócio, ecossistemas mais complexos, seja a nível de regulação, de governança e de postura na sociedade e ainda novas necessidades de produtos e serviços que precisam refletir as necessidades do mercado. Os participantes realizarão um exercício de planeamento estratégico, alicerçado numa metodologia de Harvard, identificando de tendências e potenciais eventos que estão a afetar o seu setor, em Portugal e no mundo, bem como eventos ou constrangimentos do contexto macro (demografia, tendências, mundo do trabalho, panorama empresarial, ...). Dezenas de organizações em todo o mundo usam o cone para enfrentar cenários de profunda incerteza, o que permite que os seus líderes pensem exponencialmente e realizem ações incrementais contínuas, colocando-os em posição de moldar o futuro.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "f82cad80-3dcf-4b43-947b-c61babc39124",
                                        "trainingEventID": "4a4bb976-8eec-40ea-a1fe-494cc2d8fdac",
                                        "nHours": 7,
                                        "startDate": "2023-04-12T00:00:00"
                                    }
                                ],
                                "trainingEventID": "4a4bb976-8eec-40ea-a1fe-494cc2d8fdac",
                                "trainingEventName": "Thinking Like a Futurist - Workshop Modelo Cone Estratégico 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Santos Silva (ISEG)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-12T00:00:00",
                                "registrationLimit": "2023-04-12T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 14,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995001002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Visão Estratégica",
                                "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-04-12T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "4a4bb976-8eec-40ea-a1fe-494cc2d8fdac",
                        "trainingEventName": "Thinking Like a Futurist - Workshop Modelo Cone Estratégico 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Joana Santos Silva (ISEG)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-12T00:00:00",
                        "registrationLimit": "2023-04-12T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 14,
                        "trimester": null,
                        "subCategoryTypeID": 995001002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Visão Estratégica",
                        "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-04-12T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "135a7e17-5e22-434c-8a67-156320200f9b",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Negociação para Juristas ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 20,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Resolver os problemas do cliente é o objetivo último do trabalho de um advogado e dificilmente alcançável sem negociar. Seja para evitar que um caso chegue à barra do tribunal ou para maximizar o valor de um contrato, o domínio das competências de negociação revela-se crucial para conseguir acordos positivos com clientes e opositores. Também na esfera pública, em ambientes de divergência ou de conflito como os que caracterizam o quotidiano dos juristas, negociar com eficácia, mais do que mero instrumento, é parte integrante da prática da justiça. O uso crescente da mediação no dirimir de disputas de variados tipos exige juristas preparados para algo mais do que fomentar a comunicação entre partes, apresentando factos e enquadramentos legais. Mediar conflitos envolve comunicar para facilitar a negociação de acordos. O jurista-mediador é um negociador. A formação \"Negociação para Juristas\" pretende desenvolver competências de negociação no contexto indicado.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "135a7e17-5e22-434c-8a67-156320200f9b",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Negociação para Juristas ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 20,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Resolver os problemas do cliente é o objetivo último do trabalho de um advogado e dificilmente alcançável sem negociar. Seja para evitar que um caso chegue à barra do tribunal ou para maximizar o valor de um contrato, o domínio das competências de negociação revela-se crucial para conseguir acordos positivos com clientes e opositores. Também na esfera pública, em ambientes de divergência ou de conflito como os que caracterizam o quotidiano dos juristas, negociar com eficácia, mais do que mero instrumento, é parte integrante da prática da justiça. O uso crescente da mediação no dirimir de disputas de variados tipos exige juristas preparados para algo mais do que fomentar a comunicação entre partes, apresentando factos e enquadramentos legais. Mediar conflitos envolve comunicar para facilitar a negociação de acordos. O jurista-mediador é um negociador. A formação \"Negociação para Juristas\" pretende desenvolver competências de negociação no contexto indicado.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "86a0e91b-a624-411d-abee-a1fc7c7cb577",
                                        "trainingEventID": "789590aa-a46e-4c86-b130-0d940bfc6f1d",
                                        "nHours": 20,
                                        "startDate": "2023-04-11T00:00:00"
                                    }
                                ],
                                "trainingEventID": "789590aa-a46e-4c86-b130-0d940bfc6f1d",
                                "trainingEventName": "Negociação para Juristas  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Eduardo Simões (ISPA)",
                                "locationTypeID": 973006,
                                "locationTypeDesc": "Digital Training Hub",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-04-11T00:00:00",
                                "registrationLimit": "2023-04-11T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 40,
                                "trimester": "2º Trimestre",
                                "subCategoryTypeID": 995002001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Gestão de Negócio",
                                "subCategoryDesc": "Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma",
                                "mainCategoryName": "Negócio",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-04-11T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "789590aa-a46e-4c86-b130-0d940bfc6f1d",
                        "trainingEventName": "Negociação para Juristas  1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Eduardo Simões (ISPA)",
                        "locationTypeID": 973006,
                        "locationTypeDesc": "Digital Training Hub",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-04-11T00:00:00",
                        "registrationLimit": "2023-04-11T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 40,
                        "trimester": null,
                        "subCategoryTypeID": 995002001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Gestão de Negócio",
                        "subCategoryDesc": "Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma",
                        "mainCategoryName": "Negócio",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Longa Duração",
                        "communicationDate": "2023-04-11T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ff43da7f-3b23-4b49-87d8-9ad9f76258fb",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Indústria 4.0",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ff43da7f-3b23-4b49-87d8-9ad9f76258fb",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Indústria 4.0",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "1b7c2e36-7d2b-4512-9ecc-87c913e1ae6a",
                                        "trainingEventID": "c96df6d1-c30e-470b-bd2e-90060ebdc181",
                                        "nHours": 1.5,
                                        "startDate": "2023-03-29T00:00:00"
                                    }
                                ],
                                "trainingEventID": "c96df6d1-c30e-470b-bd2e-90060ebdc181",
                                "trainingEventName": "Ciclo de Formações IST | Indústria 4.0 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Paulo Peças (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-29T00:00:00",
                                "registrationLimit": "2023-03-29T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-29T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "c96df6d1-c30e-470b-bd2e-90060ebdc181",
                        "trainingEventName": "Ciclo de Formações IST | Indústria 4.0 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Paulo Peças (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-29T00:00:00",
                        "registrationLimit": "2023-03-29T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-03-29T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "0a22420b-cac0-4a1f-a141-8b2243260295",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Skills-Set Lead",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 12,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Trabalhamos num mundo em constante e rápida mudança, o que significa que os nossos líderes necessitam, não só de se adaptar ao futuro, mas também de criar o futuro. A VdA Academia iniciou, no ano passado, uma jornada de desenvolvimento do mindset dos seus líderes onde foram abordadas temáticas sobre como os líderes devem pensar. E no que diz respeito a como os líderes devem agir? Ter o mindset certo para acompanhar as mudanças atuais é o primeiro passo para preparar o futuro, mas ter as competências certas é o que vai desencadear a mudança. Este programa da Nova SBE, customizado para a VdA, pretende dar continuidade ao desenvolvimento de competências adquiridas no programa anterior “Mind-Set_LEAD”, sendo igualmente dirigido a novas lideranças que pretendam abordar estas temáticas na preparação do futuro.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "0a22420b-cac0-4a1f-a141-8b2243260295",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Skills-Set Lead",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 12,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Trabalhamos num mundo em constante e rápida mudança, o que significa que os nossos líderes necessitam, não só de se adaptar ao futuro, mas também de criar o futuro. A VdA Academia iniciou, no ano passado, uma jornada de desenvolvimento do mindset dos seus líderes onde foram abordadas temáticas sobre como os líderes devem pensar. E no que diz respeito a como os líderes devem agir? Ter o mindset certo para acompanhar as mudanças atuais é o primeiro passo para preparar o futuro, mas ter as competências certas é o que vai desencadear a mudança. Este programa da Nova SBE, customizado para a VdA, pretende dar continuidade ao desenvolvimento de competências adquiridas no programa anterior “Mind-Set_LEAD”, sendo igualmente dirigido a novas lideranças que pretendam abordar estas temáticas na preparação do futuro.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "62818905-6439-4039-88e4-d7cae1db8879",
                                        "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                        "nHours": 12,
                                        "startDate": "2023-03-17T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                "trainingEventName": "Skills-Set Lead 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "António Alvarenga (Nova SBE)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-17T00:00:00",
                                "registrationLimit": "2023-03-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 24,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Leadership & Empowerment",
                                "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-03-17T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0a22420b-cac0-4a1f-a141-8b2243260295",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Skills-Set Lead",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 12,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Trabalhamos num mundo em constante e rápida mudança, o que significa que os nossos líderes necessitam, não só de se adaptar ao futuro, mas também de criar o futuro. A VdA Academia iniciou, no ano passado, uma jornada de desenvolvimento do mindset dos seus líderes onde foram abordadas temáticas sobre como os líderes devem pensar. E no que diz respeito a como os líderes devem agir? Ter o mindset certo para acompanhar as mudanças atuais é o primeiro passo para preparar o futuro, mas ter as competências certas é o que vai desencadear a mudança. Este programa da Nova SBE, customizado para a VdA, pretende dar continuidade ao desenvolvimento de competências adquiridas no programa anterior “Mind-Set_LEAD”, sendo igualmente dirigido a novas lideranças que pretendam abordar estas temáticas na preparação do futuro.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "62818905-6439-4039-88e4-d7cae1db8879",
                                        "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                        "nHours": 12,
                                        "startDate": "2023-03-17T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                "trainingEventName": "Skills-Set Lead 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Carla Rocha (Nova SBE)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-17T00:00:00",
                                "registrationLimit": "2023-03-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 24,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Leadership & Empowerment",
                                "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-03-17T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0a22420b-cac0-4a1f-a141-8b2243260295",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Skills-Set Lead",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 12,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Trabalhamos num mundo em constante e rápida mudança, o que significa que os nossos líderes necessitam, não só de se adaptar ao futuro, mas também de criar o futuro. A VdA Academia iniciou, no ano passado, uma jornada de desenvolvimento do mindset dos seus líderes onde foram abordadas temáticas sobre como os líderes devem pensar. E no que diz respeito a como os líderes devem agir? Ter o mindset certo para acompanhar as mudanças atuais é o primeiro passo para preparar o futuro, mas ter as competências certas é o que vai desencadear a mudança. Este programa da Nova SBE, customizado para a VdA, pretende dar continuidade ao desenvolvimento de competências adquiridas no programa anterior “Mind-Set_LEAD”, sendo igualmente dirigido a novas lideranças que pretendam abordar estas temáticas na preparação do futuro.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "62818905-6439-4039-88e4-d7cae1db8879",
                                        "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                        "nHours": 12,
                                        "startDate": "2023-03-17T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                "trainingEventName": "Skills-Set Lead 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Milton Sousa (Nova SBE)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-17T00:00:00",
                                "registrationLimit": "2023-03-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 24,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Leadership & Empowerment",
                                "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-03-17T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0a22420b-cac0-4a1f-a141-8b2243260295",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Skills-Set Lead",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 12,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Trabalhamos num mundo em constante e rápida mudança, o que significa que os nossos líderes necessitam, não só de se adaptar ao futuro, mas também de criar o futuro. A VdA Academia iniciou, no ano passado, uma jornada de desenvolvimento do mindset dos seus líderes onde foram abordadas temáticas sobre como os líderes devem pensar. E no que diz respeito a como os líderes devem agir? Ter o mindset certo para acompanhar as mudanças atuais é o primeiro passo para preparar o futuro, mas ter as competências certas é o que vai desencadear a mudança. Este programa da Nova SBE, customizado para a VdA, pretende dar continuidade ao desenvolvimento de competências adquiridas no programa anterior “Mind-Set_LEAD”, sendo igualmente dirigido a novas lideranças que pretendam abordar estas temáticas na preparação do futuro.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "62818905-6439-4039-88e4-d7cae1db8879",
                                        "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                        "nHours": 12,
                                        "startDate": "2023-03-17T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                "trainingEventName": "Skills-Set Lead 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Paula Marques (Nova SBE)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-17T00:00:00",
                                "registrationLimit": "2023-03-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 24,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Leadership & Empowerment",
                                "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-03-17T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0a22420b-cac0-4a1f-a141-8b2243260295",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Skills-Set Lead",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 12,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Trabalhamos num mundo em constante e rápida mudança, o que significa que os nossos líderes necessitam, não só de se adaptar ao futuro, mas também de criar o futuro. A VdA Academia iniciou, no ano passado, uma jornada de desenvolvimento do mindset dos seus líderes onde foram abordadas temáticas sobre como os líderes devem pensar. E no que diz respeito a como os líderes devem agir? Ter o mindset certo para acompanhar as mudanças atuais é o primeiro passo para preparar o futuro, mas ter as competências certas é o que vai desencadear a mudança. Este programa da Nova SBE, customizado para a VdA, pretende dar continuidade ao desenvolvimento de competências adquiridas no programa anterior “Mind-Set_LEAD”, sendo igualmente dirigido a novas lideranças que pretendam abordar estas temáticas na preparação do futuro.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "62818905-6439-4039-88e4-d7cae1db8879",
                                        "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                        "nHours": 12,
                                        "startDate": "2023-03-17T00:00:00"
                                    }
                                ],
                                "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                                "trainingEventName": "Skills-Set Lead 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Susana Carvalho (Nova SBE), Hugo de Sousa (Nova SBE)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-17T00:00:00",
                                "registrationLimit": "2023-03-17T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 24,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004003,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Leadership & Empowerment",
                                "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-03-17T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "e81d70db-c0d0-4184-988a-d40a35ed0c3d",
                        "trainingEventName": "Skills-Set Lead 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "António Alvarenga (Nova SBE)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-17T00:00:00",
                        "registrationLimit": "2023-03-17T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 24,
                        "trimester": null,
                        "subCategoryTypeID": 995004003,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Leadership & Empowerment",
                        "subCategoryDesc": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-03-17T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "214e2715-1347-46af-8d14-29ba90845682",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | Alcance do dever de sigilo do administrador indicado por pessoa coletiva eleita para o órgão de administração",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "214e2715-1347-46af-8d14-29ba90845682",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | Alcance do dever de sigilo do administrador indicado por pessoa coletiva eleita para o órgão de administração",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Coordenador,Associado(a) Sénior,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2767f3ec-db0d-4784-b3cb-e0e239a6c89c",
                                        "trainingEventID": "4ac84d17-1518-4c02-a771-a62628318a44",
                                        "nHours": 1,
                                        "startDate": "2023-03-16T00:00:00"
                                    }
                                ],
                                "trainingEventID": "4ac84d17-1518-4c02-a771-a62628318a44",
                                "trainingEventName": "Alcance do dever de sigilo do administrador indicado por pessoa coletiva eleita para o órgão de administração 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-16T00:00:00",
                                "registrationLimit": "2023-03-16T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-16T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "4ac84d17-1518-4c02-a771-a62628318a44",
                        "trainingEventName": "Alcance do dever de sigilo do administrador indicado por pessoa coletiva eleita para o órgão de administração 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-16T00:00:00",
                        "registrationLimit": "2023-03-16T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-03-16T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "db7bdca8-6983-489f-b686-61553f87f5ef",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Recuperação de Informação",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "db7bdca8-6983-489f-b686-61553f87f5ef",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Recuperação de Informação",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "e0da3522-f0f0-4629-be84-08733059d0ea",
                                        "trainingEventID": "bce09459-7b40-46d7-9746-fbc32fe29678",
                                        "nHours": 1.5,
                                        "startDate": "2023-03-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "bce09459-7b40-46d7-9746-fbc32fe29678",
                                "trainingEventName": "Ciclo de Formações IST | Recuperação de Informação 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Bruno Martins (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-15T00:00:00",
                                "registrationLimit": "2023-03-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-15T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "bce09459-7b40-46d7-9746-fbc32fe29678",
                        "trainingEventName": "Ciclo de Formações IST | Recuperação de Informação 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Bruno Martins (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-15T00:00:00",
                        "registrationLimit": "2023-03-15T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-03-15T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "3278476e-490f-4385-8c87-425acf936e09",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Escrita Criativa para Advogados",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A escrita jurídica é pouco valorizada e não é sequer ensinada nas Universidades. Julga-se que é um talento insondável que não se aprende nem trabalha. O que é um erro, pois as palavras são o nosso produto, a nossa marca e a nossa diferença. São o código da excelência que prometemos aos nossos Clientes. Todos os dias – em e-mails, cartas e peças processuais – saem da VdA inúmeras palavras. É possível aprender e trabalhar para alcançar uma boa escrita jurídica e nesta formação vão ser apresentadas técnicas que ajudam a atingir esse objetivo.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "3278476e-490f-4385-8c87-425acf936e09",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Escrita Criativa para Advogados",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A escrita jurídica é pouco valorizada e não é sequer ensinada nas Universidades. Julga-se que é um talento insondável que não se aprende nem trabalha. O que é um erro, pois as palavras são o nosso produto, a nossa marca e a nossa diferença. São o código da excelência que prometemos aos nossos Clientes. Todos os dias – em e-mails, cartas e peças processuais – saem da VdA inúmeras palavras. É possível aprender e trabalhar para alcançar uma boa escrita jurídica e nesta formação vão ser apresentadas técnicas que ajudam a atingir esse objetivo.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "6c87ae59-92c0-4289-8422-fd639d6f5418",
                                        "trainingEventID": "49a310f1-c44b-4531-84f8-fac08b342808",
                                        "nHours": 2,
                                        "startDate": "2023-03-09T00:00:00"
                                    }
                                ],
                                "trainingEventID": "49a310f1-c44b-4531-84f8-fac08b342808",
                                "trainingEventName": "Escrita Criativa para Advogados 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Pedro Fontes (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-09T00:00:00",
                                "registrationLimit": "2023-03-09T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-09T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "49a310f1-c44b-4531-84f8-fac08b342808",
                        "trainingEventName": "Escrita Criativa para Advogados 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Pedro Fontes (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-09T00:00:00",
                        "registrationLimit": "2023-03-09T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-03-09T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "1575c708-1e38-42e5-89e0-25442ee144fc",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Strategy: The Big Picture",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 3.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>O processo de evolução tecnológica está a decorrer a um ritmo acelerado e isto traduz-se em novas expetativas e exigências por parte dos clientes: novos modelos de negócio, ecossistemas mais complexos seja a nível de regulação, de governance e de postura na sociedade e ainda novas necessidades de produtos e serviços que precisam de refletir as necessidades do mercado. Estamos na era das plataformas, em que modelos lean e ágeis são aqueles que melhor se posicionam para a sustentabilidade futura da organização. Inteligência artificial, ciência de dados, machine learning e novos insights sobre o comportamento humano vão trazer novas formas de estar no mercado através da segmentação e personalização que serão fatures críticos de sucesso para o futuro do negócio.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "1575c708-1e38-42e5-89e0-25442ee144fc",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Strategy: The Big Picture",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 3.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>O processo de evolução tecnológica está a decorrer a um ritmo acelerado e isto traduz-se em novas expetativas e exigências por parte dos clientes: novos modelos de negócio, ecossistemas mais complexos seja a nível de regulação, de governance e de postura na sociedade e ainda novas necessidades de produtos e serviços que precisam de refletir as necessidades do mercado. Estamos na era das plataformas, em que modelos lean e ágeis são aqueles que melhor se posicionam para a sustentabilidade futura da organização. Inteligência artificial, ciência de dados, machine learning e novos insights sobre o comportamento humano vão trazer novas formas de estar no mercado através da segmentação e personalização que serão fatures críticos de sucesso para o futuro do negócio.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "db56ee31-ce32-4cf8-8199-8a1a6fe8da6e",
                                        "trainingEventID": "7e0b5478-8f6c-49d7-a068-69b657160ff6",
                                        "nHours": 3.5,
                                        "startDate": "2023-03-08T00:00:00"
                                    }
                                ],
                                "trainingEventID": "7e0b5478-8f6c-49d7-a068-69b657160ff6",
                                "trainingEventName": "Strategy: The Big Picture 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Joana Santos Silva (ISEG)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-08T00:00:00",
                                "registrationLimit": "2023-03-08T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 7,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995001002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Visão Estratégica",
                                "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Workshops & Learning Shots",
                                "communicationDate": "2023-03-08T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "7e0b5478-8f6c-49d7-a068-69b657160ff6",
                        "trainingEventName": "Strategy: The Big Picture 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Joana Santos Silva (ISEG)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-08T00:00:00",
                        "registrationLimit": "2023-03-08T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 7,
                        "trimester": null,
                        "subCategoryTypeID": 995001002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Visão Estratégica",
                        "subCategoryDesc": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Workshops & Learning Shots",
                        "communicationDate": "2023-03-08T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "1f82cb76-aafb-4a78-a32d-698670beb98f",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Criptoativos",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 2,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "1f82cb76-aafb-4a78-a32d-698670beb98f",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Criptoativos",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "30ae3b04-1064-4d20-8824-b251f6150573",
                                        "trainingEventID": "f8d1411a-a228-4bb4-88bd-6dec4e188830",
                                        "nHours": 2,
                                        "startDate": "2023-03-07T00:00:00"
                                    }
                                ],
                                "trainingEventID": "f8d1411a-a228-4bb4-88bd-6dec4e188830",
                                "trainingEventName": "Criptoativos 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "José Miguel Carracho (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-07T00:00:00",
                                "registrationLimit": "2023-03-07T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-07T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "1f82cb76-aafb-4a78-a32d-698670beb98f",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Criptoativos",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 2,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "30ae3b04-1064-4d20-8824-b251f6150573",
                                        "trainingEventID": "f8d1411a-a228-4bb4-88bd-6dec4e188830",
                                        "nHours": 2,
                                        "startDate": "2023-03-07T00:00:00"
                                    }
                                ],
                                "trainingEventID": "f8d1411a-a228-4bb4-88bd-6dec4e188830",
                                "trainingEventName": "Criptoativos 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Tiago Correia Moreira (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-07T00:00:00",
                                "registrationLimit": "2023-03-07T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 4,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-07T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "f8d1411a-a228-4bb4-88bd-6dec4e188830",
                        "trainingEventName": "Criptoativos 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "José Miguel Carracho (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-07T00:00:00",
                        "registrationLimit": "2023-03-07T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 4,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-03-07T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "0b61c660-ad6d-4654-883a-3ed7bd686794",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Power On | Formação DX: TIQ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer a nova plataforma TIQ Time, a aplicação que irá substituir o atual programa de registo de horas, que terá novas funcionalidades como a captação de horas trabalhadas de forma automática ou o pré-preenchimento do timesheet de cada Advogado através da informação recolhida. </p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "0b61c660-ad6d-4654-883a-3ed7bd686794",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Power On | Formação DX: TIQ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer a nova plataforma TIQ Time, a aplicação que irá substituir o atual programa de registo de horas, que terá novas funcionalidades como a captação de horas trabalhadas de forma automática ou o pré-preenchimento do timesheet de cada Advogado através da informação recolhida. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2ff02e45-8d88-4a9d-b96f-ba01295d1c25",
                                        "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                        "nHours": 1,
                                        "startDate": "2023-03-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                "trainingEventName": "Power On | Formação DX: TIQ 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Carolina Tita Maurício (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-06T00:00:00",
                                "registrationLimit": "2023-03-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-06T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0b61c660-ad6d-4654-883a-3ed7bd686794",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Power On | Formação DX: TIQ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer a nova plataforma TIQ Time, a aplicação que irá substituir o atual programa de registo de horas, que terá novas funcionalidades como a captação de horas trabalhadas de forma automática ou o pré-preenchimento do timesheet de cada Advogado através da informação recolhida. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2ff02e45-8d88-4a9d-b96f-ba01295d1c25",
                                        "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                        "nHours": 1,
                                        "startDate": "2023-03-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                "trainingEventName": "Power On | Formação DX: TIQ 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "David Paula (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-06T00:00:00",
                                "registrationLimit": "2023-03-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-06T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0b61c660-ad6d-4654-883a-3ed7bd686794",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Power On | Formação DX: TIQ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer a nova plataforma TIQ Time, a aplicação que irá substituir o atual programa de registo de horas, que terá novas funcionalidades como a captação de horas trabalhadas de forma automática ou o pré-preenchimento do timesheet de cada Advogado através da informação recolhida. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2ff02e45-8d88-4a9d-b96f-ba01295d1c25",
                                        "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                        "nHours": 1,
                                        "startDate": "2023-03-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                "trainingEventName": "Power On | Formação DX: TIQ 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Matilde Líbano Monteiro (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-06T00:00:00",
                                "registrationLimit": "2023-03-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-06T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "0b61c660-ad6d-4654-883a-3ed7bd686794",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Power On | Formação DX: TIQ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>No âmbito do Programa de Transformação Digital, a VdA Academia, em colaboração com a equipa Power On e VdA Inovação, criaram a Digital Transformation Factory, o programa formativo que promove o processo de transformação e que organiza sessões de formação para todos os colaboradores. \nNesta sessão de formação vamos dar a conhecer a nova plataforma TIQ Time, a aplicação que irá substituir o atual programa de registo de horas, que terá novas funcionalidades como a captação de horas trabalhadas de forma automática ou o pré-preenchimento do timesheet de cada Advogado através da informação recolhida. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "2ff02e45-8d88-4a9d-b96f-ba01295d1c25",
                                        "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                        "nHours": 1,
                                        "startDate": "2023-03-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                                "trainingEventName": "Power On | Formação DX: TIQ 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Rita Rocha (VdA)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-06T00:00:00",
                                "registrationLimit": "2023-03-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-03-06T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "acddd470-0c6f-4b5c-9a0a-648c2fae6e55",
                        "trainingEventName": "Power On | Formação DX: TIQ 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Carolina Tita Maurício (VdA)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-06T00:00:00",
                        "registrationLimit": "2023-03-06T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-03-06T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "445cdfbe-f774-4627-bb79-c79486b0b316",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Spoken English – Conversational Skills ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 8,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Mesmo na sua língua materna, a comunicação verbal é suscetível de inúmeros mal-entendidos. Agora imagine falantes não nativos? Em inglês, sotaques próprios, expressões, maneiras de falar, acentuação, e erros de pronúncia podem gerar situações embaraçosas. Este curso pretende, assim, dar-lhe-á ferramentas e truques para que possa comunicar melhor, em inglês, com diferentes interlocutores.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "445cdfbe-f774-4627-bb79-c79486b0b316",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Spoken English – Conversational Skills ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 8,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Mesmo na sua língua materna, a comunicação verbal é suscetível de inúmeros mal-entendidos. Agora imagine falantes não nativos? Em inglês, sotaques próprios, expressões, maneiras de falar, acentuação, e erros de pronúncia podem gerar situações embaraçosas. Este curso pretende, assim, dar-lhe-á ferramentas e truques para que possa comunicar melhor, em inglês, com diferentes interlocutores.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "3d21332f-3f76-481d-9fa5-f2e6f670c504",
                                        "trainingEventID": "1e5fe416-8f7f-4a8a-b328-e647d3118155",
                                        "nHours": 8,
                                        "startDate": "2023-03-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "1e5fe416-8f7f-4a8a-b328-e647d3118155",
                                "trainingEventName": "Spoken English – Conversational Skills  5ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Raquel Castro Valente (VdA)",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-03-06T00:00:00",
                                "registrationLimit": "2023-03-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 16,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-03-06T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "1e5fe416-8f7f-4a8a-b328-e647d3118155",
                        "trainingEventName": "Spoken English – Conversational Skills  5ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Raquel Castro Valente (VdA)",
                        "locationTypeID": 973004,
                        "locationTypeDesc": "Blended Learning – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-03-06T00:00:00",
                        "registrationLimit": "2023-03-06T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 16,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Inglês",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-03-06T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "5436373f-51c6-45fb-af3d-da79660c209a",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | O conceito de “unidade económica” para efeitos do disposto na alínea b) do n.º 1 do artigo 124º do Código das Sociedades Comerciais",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "5436373f-51c6-45fb-af3d-da79660c209a",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | O conceito de “unidade económica” para efeitos do disposto na alínea b) do n.º 1 do artigo 124º do Código das Sociedades Comerciais",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de M&A, está a realizar um Ciclo de Formações sobre temas de M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por sessões com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre o direito de aquisição de empresas e temas relacionados.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "ae20b4d2-35dc-4f91-90fa-26ec0db493f2",
                                        "trainingEventID": "7f477b3f-29d8-49db-965e-00b14dcb2359",
                                        "nHours": 1,
                                        "startDate": "2023-02-16T00:00:00"
                                    }
                                ],
                                "trainingEventID": "7f477b3f-29d8-49db-965e-00b14dcb2359",
                                "trainingEventName": "O conceito de “unidade económica” para efeitos do disposto na alínea b) do n.º 1 do artigo 124º do Código das Sociedades Comerciais 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-02-16T00:00:00",
                                "registrationLimit": "2023-02-16T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-02-16T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "7f477b3f-29d8-49db-965e-00b14dcb2359",
                        "trainingEventName": "O conceito de “unidade económica” para efeitos do disposto na alínea b) do n.º 1 do artigo 124º do Código das Sociedades Comerciais 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-02-16T00:00:00",
                        "registrationLimit": "2023-02-16T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-02-16T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "cfe02eda-64d0-426f-b1ed-6d94a85642a4",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Comunicação Não Verbal para o Dia a Dia Profissional - Palestra Interactiva",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>O conteúdo é importante. Mas o sucesso da sua comunicação depende da forma como este é transmitido. Postura, gestos, voz — são apenas alguns sinais não verbais que comunicam e condicionam a impressão e a relação que se cria. Qual é a importância da comunicação não verbal no dia a dia profissional? Quais são os gestos que contribuem para uma primeira impressão positiva? Como se treina a comunicação não verbal? A palestra interativa tem como objetivo sensibilizar os profissionais da VdA para a importância da sua forma de estar e de comunicar para falar e ser ouvido, comunicar e ser entendido, e para crescer profissionalmente.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "cfe02eda-64d0-426f-b1ed-6d94a85642a4",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Comunicação Não Verbal para o Dia a Dia Profissional - Palestra Interactiva",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>O conteúdo é importante. Mas o sucesso da sua comunicação depende da forma como este é transmitido. Postura, gestos, voz — são apenas alguns sinais não verbais que comunicam e condicionam a impressão e a relação que se cria. Qual é a importância da comunicação não verbal no dia a dia profissional? Quais são os gestos que contribuem para uma primeira impressão positiva? Como se treina a comunicação não verbal? A palestra interativa tem como objetivo sensibilizar os profissionais da VdA para a importância da sua forma de estar e de comunicar para falar e ser ouvido, comunicar e ser entendido, e para crescer profissionalmente.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "5d8fdce1-76a9-410e-bb2b-61ff5f7100b2",
                                        "trainingEventID": "8fd47810-22c6-416f-af70-9e64943e9fbd",
                                        "nHours": 1,
                                        "startDate": "2023-02-15T00:00:00"
                                    }
                                ],
                                "trainingEventID": "8fd47810-22c6-416f-af70-9e64943e9fbd",
                                "trainingEventName": "Comunicação Não Verbal para o Dia a Dia Profissional - Palestra Interactiva 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Irina Golovanova (The Body Language Academy)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-02-15T00:00:00",
                                "registrationLimit": "2023-02-15T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-02-15T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "8fd47810-22c6-416f-af70-9e64943e9fbd",
                        "trainingEventName": "Comunicação Não Verbal para o Dia a Dia Profissional - Palestra Interactiva 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Irina Golovanova (The Body Language Academy)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-02-15T00:00:00",
                        "registrationLimit": "2023-02-15T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-02-15T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "a89ea834-029a-480f-821f-0f427599a2be",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "English Writing Skills - Emails ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 20,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>O curso \"English Writing Skills – Emails\" powered by Clube de Línguas é um curso de inglês temático destinado a todos aqueles que pretendam desenvolver as suas competências escritas nesta língua. Neste curso, os participantes serão desafiados, entre outros, a treinar o ajuste da linguagem formal e informal a diferentes interlocutores, a pedir e dar informações, a usar linguagem direta e indireta, e a agendar compromissos e organizar reuniões. Os grupos de formandos serão organizados tendo em consideração o nível de conhecimento da língua, pelo que todos os inscritos serão sujeitos a uma avaliação prévia.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "a89ea834-029a-480f-821f-0f427599a2be",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "English Writing Skills - Emails ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 20,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>O curso \"English Writing Skills – Emails\" powered by Clube de Línguas é um curso de inglês temático destinado a todos aqueles que pretendam desenvolver as suas competências escritas nesta língua. Neste curso, os participantes serão desafiados, entre outros, a treinar o ajuste da linguagem formal e informal a diferentes interlocutores, a pedir e dar informações, a usar linguagem direta e indireta, e a agendar compromissos e organizar reuniões. Os grupos de formandos serão organizados tendo em consideração o nível de conhecimento da língua, pelo que todos os inscritos serão sujeitos a uma avaliação prévia.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "99c7edd8-a8ef-40a8-87a4-83ac1874e6de",
                                        "trainingEventID": "642d8b0d-377c-4a96-8a53-16cd19f2066b",
                                        "nHours": 20,
                                        "startDate": "2023-02-10T00:00:00"
                                    }
                                ],
                                "trainingEventID": "642d8b0d-377c-4a96-8a53-16cd19f2066b",
                                "trainingEventName": "English Writing Skills - Emails  2ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "David Sheen (Business English Centre)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-02-10T00:00:00",
                                "registrationLimit": "2023-02-10T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 40,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995004002,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Inglês",
                                "subCategoryName": "Comunicação e Influência",
                                "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "mainCategoryName": "Interação",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-02-10T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "642d8b0d-377c-4a96-8a53-16cd19f2066b",
                        "trainingEventName": "English Writing Skills - Emails  2ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "David Sheen (Business English Centre)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-02-10T00:00:00",
                        "registrationLimit": "2023-02-10T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 40,
                        "trimester": null,
                        "subCategoryTypeID": 995004002,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Inglês",
                        "subCategoryName": "Comunicação e Influência",
                        "subCategoryDesc": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                        "mainCategoryName": "Interação",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Longa Duração",
                        "communicationDate": "2023-02-10T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "125817f9-468a-40a4-903f-c01134b631e9",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Cartas de Auditoria",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a),Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p></p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "125817f9-468a-40a4-903f-c01134b631e9",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Cartas de Auditoria",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a),Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p></p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "a45d501e-b6c9-4ed9-ab24-93ba04fd46ef",
                                        "trainingEventID": "6b5b2f43-5342-4435-b56f-c81b2bb3042c",
                                        "nHours": 1,
                                        "startDate": "2023-02-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "6b5b2f43-5342-4435-b56f-c81b2bb3042c",
                                "trainingEventName": "Cartas de Auditoria 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Alexandra Resina da Silva (VdA)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-02-06T00:00:00",
                                "registrationLimit": "2023-02-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-02-06T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "6b5b2f43-5342-4435-b56f-c81b2bb3042c",
                        "trainingEventName": "Cartas de Auditoria 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Alexandra Resina da Silva (VdA)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-02-06T00:00:00",
                        "registrationLimit": "2023-02-06T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-02-06T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "ce39626e-fa96-4ad8-9a28-4d8877eaf394",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Formação Pedagógica Inicial de Formadores",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 90,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A formação profissional assume um papel central e de crescente importância perante os novos desafios que surgem, tendo sido exponenciada no atual contexto, tornando-se necessária a atualização e aquisição de competências mais frequente e contínua. \nNeste sentido, é indispensável a capacitação de formadores e que fiquem devidamente certificados pelo IEFP (obtenção do Certificado de Competências Pedagógicas – CCP) para que possam, sobretudo, dinamizar formação de acordo com o que é recomendável pedagogicamente.\nTendo em vista a qualidade da formação, esta será desenvolvida com uma componente essencialmente prática e adaptada ao grupo em formação, dando oportunidade aos futuros formadores para experienciarem uma série de situações mobilizadas. \nEstarão, desta forma, preparados para assumirem futuras tarefas enquanto formadores-animadores e formadores-consultores.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "ce39626e-fa96-4ad8-9a28-4d8877eaf394",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Formação Pedagógica Inicial de Formadores",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 90,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A formação profissional assume um papel central e de crescente importância perante os novos desafios que surgem, tendo sido exponenciada no atual contexto, tornando-se necessária a atualização e aquisição de competências mais frequente e contínua. \nNeste sentido, é indispensável a capacitação de formadores e que fiquem devidamente certificados pelo IEFP (obtenção do Certificado de Competências Pedagógicas – CCP) para que possam, sobretudo, dinamizar formação de acordo com o que é recomendável pedagogicamente.\nTendo em vista a qualidade da formação, esta será desenvolvida com uma componente essencialmente prática e adaptada ao grupo em formação, dando oportunidade aos futuros formadores para experienciarem uma série de situações mobilizadas. \nEstarão, desta forma, preparados para assumirem futuras tarefas enquanto formadores-animadores e formadores-consultores.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "36aefc2b-b059-4841-95ec-70f9c90c4f5c",
                                        "trainingEventID": "9909509f-361d-49f1-8534-897c57c3bf24",
                                        "nHours": 90,
                                        "startDate": "2023-02-06T00:00:00"
                                    }
                                ],
                                "trainingEventID": "9909509f-361d-49f1-8534-897c57c3bf24",
                                "trainingEventName": "Formação Pedagógica Inicial de Formadores 8ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "B-Training",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-02-06T00:00:00",
                                "registrationLimit": "2023-02-06T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 180,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995001001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Envolvimento e Experimentação",
                                "subCategoryDesc": "Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-02-06T00:00:00",
                                "observations": null
                            },
                            {
                                "trainingModuleID": "ce39626e-fa96-4ad8-9a28-4d8877eaf394",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Formação Pedagógica Inicial de Formadores",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Sénior,Associado(a) Coordenador",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 90,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A formação profissional assume um papel central e de crescente importância perante os novos desafios que surgem, tendo sido exponenciada no atual contexto, tornando-se necessária a atualização e aquisição de competências mais frequente e contínua. \nNeste sentido, é indispensável a capacitação de formadores e que fiquem devidamente certificados pelo IEFP (obtenção do Certificado de Competências Pedagógicas – CCP) para que possam, sobretudo, dinamizar formação de acordo com o que é recomendável pedagogicamente.\nTendo em vista a qualidade da formação, esta será desenvolvida com uma componente essencialmente prática e adaptada ao grupo em formação, dando oportunidade aos futuros formadores para experienciarem uma série de situações mobilizadas. \nEstarão, desta forma, preparados para assumirem futuras tarefas enquanto formadores-animadores e formadores-consultores.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "f7a5435e-d6b9-428e-8268-9db6a5c79e9b",
                                        "trainingEventID": "8f5635e8-d21c-4f74-8922-0272a3889bab",
                                        "nHours": 90,
                                        "startDate": "2023-09-21T00:00:00"
                                    }
                                ],
                                "trainingEventID": "8f5635e8-d21c-4f74-8922-0272a3889bab",
                                "trainingEventName": "Formação Pedagógica Inicial de Formadores 9ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "B-Training",
                                "locationTypeID": 973004,
                                "locationTypeDesc": "Blended Learning – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-09-21T00:00:00",
                                "registrationLimit": "2023-09-21T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 180,
                                "trimester": "3º Trimestre",
                                "subCategoryTypeID": 995001001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Envolvimento e Experimentação",
                                "subCategoryDesc": "Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação",
                                "mainCategoryName": "Alinhamento",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Longa Duração",
                                "communicationDate": "2023-09-21T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "9909509f-361d-49f1-8534-897c57c3bf24",
                        "trainingEventName": "Formação Pedagógica Inicial de Formadores 8ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "B-Training",
                        "locationTypeID": 973004,
                        "locationTypeDesc": "Blended Learning – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-02-06T00:00:00",
                        "registrationLimit": "2023-02-06T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 180,
                        "trimester": null,
                        "subCategoryTypeID": 995001001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Envolvimento e Experimentação",
                        "subCategoryDesc": "Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação",
                        "mainCategoryName": "Alinhamento",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Longa Duração",
                        "communicationDate": "2023-02-06T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "49d9b476-c6e9-438e-80a7-0fce9f1c8ea4",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações IST | Robotic Process Automation ",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1.5,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "49d9b476-c6e9-438e-80a7-0fce9f1c8ea4",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações IST | Robotic Process Automation ",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1.5,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, VdA Inovação, a área de ICT e a equipa que está a liderar a transformação digital da VdA , está a realizar um Ciclo de Formações sobre variadas temáticas ligadas à Tecnologia. O Ciclo de Formações que decorre desde 2021 aborda em cada sessão um tema específico lecionado por Professores do Instituto Superior Técnico, reconhecidos pela sua expertise em cada uma das temáticas.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "0455da2c-b96f-4637-a6a1-69af9cfe90f2",
                                        "trainingEventID": "54615326-08e2-42ef-82b1-8a02479e5a3d",
                                        "nHours": 1.5,
                                        "startDate": "2023-02-01T00:00:00"
                                    }
                                ],
                                "trainingEventID": "54615326-08e2-42ef-82b1-8a02479e5a3d",
                                "trainingEventName": "Ciclo de Formações IST | Robotic Process Automation  1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Miguel M. Silva (IST)",
                                "locationTypeID": 973003,
                                "locationTypeDesc": "Online",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-02-01T00:00:00",
                                "registrationLimit": "2023-02-01T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 3,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995003001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Literacia Digital",
                                "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "mainCategoryName": "Gestão",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-02-01T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "54615326-08e2-42ef-82b1-8a02479e5a3d",
                        "trainingEventName": "Ciclo de Formações IST | Robotic Process Automation  1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Miguel M. Silva (IST)",
                        "locationTypeID": 973003,
                        "locationTypeDesc": "Online",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-02-01T00:00:00",
                        "registrationLimit": "2023-02-01T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 3,
                        "trimester": null,
                        "subCategoryTypeID": 995003001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Literacia Digital",
                        "subCategoryDesc": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                        "mainCategoryName": "Gestão",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-02-01T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "71612c74-4b9c-4409-bcde-04519c8e36f1",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Ciclo de Formações Corporate e M&A | Limitações legais em sede parassocial à determinação pelos acionistas de maiorias qualificadas no órgão de administração, v.g., sobre eventual imperatividade da maioria definida no CSC",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 1,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de Corporate e M&A, está a realizar um Ciclo de Formações sobre temas de Corporate e M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por várias sessões independentes, com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre a temática indicada.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "71612c74-4b9c-4409-bcde-04519c8e36f1",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Ciclo de Formações Corporate e M&A | Limitações legais em sede parassocial à determinação pelos acionistas de maiorias qualificadas no órgão de administração, v.g., sobre eventual imperatividade da maioria definida no CSC",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 1,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>A VdA Academia, em parceria com o Departamento de Knowledge Management e a Área de Prática de Corporate e M&A, está a realizar um Ciclo de Formações sobre temas de Corporate e M&A com a Professora Doutora Ana Perestrelo de Oliveira. O Ciclo, composto por várias sessões independentes, com uma periodicidade mensal, oferece uma formação intensiva e prática a advogados e advogados estagiários que pretendam aprofundar os seus conhecimentos sobre a temática indicada.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "3392a1f6-86ef-4869-8db0-1449dcc868ab",
                                        "trainingEventID": "595e762b-191d-4871-88a6-e5abc5d3af32",
                                        "nHours": 1,
                                        "startDate": "2023-01-26T00:00:00"
                                    }
                                ],
                                "trainingEventID": "595e762b-191d-4871-88a6-e5abc5d3af32",
                                "trainingEventName": "Limitações legais em sede parassocial à determinação pelos acionistas de maiorias qualificadas no órgão de administração, v.g., sobre eventual imperatividade da maioria definida no CSC 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-01-26T00:00:00",
                                "registrationLimit": "2023-01-26T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 2,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Conferências & Webinars",
                                "communicationDate": "2023-01-26T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "595e762b-191d-4871-88a6-e5abc5d3af32",
                        "trainingEventName": "Limitações legais em sede parassocial à determinação pelos acionistas de maiorias qualificadas no órgão de administração, v.g., sobre eventual imperatividade da maioria definida no CSC 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Ana Perestrelo de Oliveira (FDUL)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-01-26T00:00:00",
                        "registrationLimit": "2023-01-26T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 2,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Conferências & Webinars",
                        "communicationDate": "2023-01-26T00:00:00",
                        "observations": null
                    },
                    {
                        "trainingModuleID": "2d2e063c-9eaa-42ae-b0af-afeb340e114c",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 995004,
                        "trainingMethodTypeID": null,
                        "trainingCategoryDesc": "Interação",
                        "trainingModuleName": "Planos de Pormenor com Eficácia Registral (e as respetivas vicissitudes)",
                        "supplierID": null,
                        "supplierDesc": null,
                        "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                        "internalManagerName": "Sandra Pedro Serrano",
                        "internalManagerUsername": "SPS@vda.pt",
                        "financialCost": 0,
                        "targetCategories": "108004,108007,108014",
                        "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                        "developedSkills": "",
                        "developedSkillsDesc": null,
                        "nSessions": 1,
                        "totalHours": 7,
                        "certifiedHours": null,
                        "nVacancies": null,
                        "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": true,
                        "eventStatus": "T",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "2d2e063c-9eaa-42ae-b0af-afeb340e114c",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 995004,
                                "trainingMethodTypeID": null,
                                "trainingCategoryDesc": "Interação",
                                "trainingModuleName": "Planos de Pormenor com Eficácia Registral (e as respetivas vicissitudes)",
                                "supplierID": null,
                                "supplierDesc": null,
                                "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                                "internalManagerName": "Sandra Pedro Serrano",
                                "internalManagerUsername": "SPS@vda.pt",
                                "financialCost": 0,
                                "targetCategories": "108004,108007,108014",
                                "targetCategoriesDesc": "Associado(a),Associado(a) Coordenador,Associado(a) Sénior",
                                "developedSkills": "",
                                "developedSkillsDesc": null,
                                "nSessions": 1,
                                "totalHours": 7,
                                "certifiedHours": null,
                                "nVacancies": null,
                                "description": "<p>Esta sessão insere-se num Ciclo de Formações com a Professora Fernanda Paula Oliveira. </p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": true,
                                "eventStatus": "T",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "90d9ecd8-7be5-4223-a3fc-728d8286c3c2",
                                        "trainingEventID": "5e2e7e5e-9e07-4d85-aadc-3924aa1b4756",
                                        "nHours": 7,
                                        "startDate": "2023-01-25T00:00:00"
                                    }
                                ],
                                "trainingEventID": "5e2e7e5e-9e07-4d85-aadc-3924aa1b4756",
                                "trainingEventName": "Planos de Pormenor com Eficácia Registral (e as respetivas vicissitudes) 1ª Edição",
                                "trainingModuleDescription": null,
                                "instructorName": "Fernanda Paula Oliveira (FDUC)",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial – VdA Lisboa",
                                "nEnrolled": 0,
                                "nWaitingList": 0,
                                "cancelationLimit": "2023-01-25T00:00:00",
                                "registrationLimit": "2023-01-25T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "P",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 14,
                                "trimester": "1º Trimestre",
                                "subCategoryTypeID": 995005001,
                                "mainCategoryTypeID": 0,
                                "experienceTypeID": 0,
                                "languageTypeID": 0,
                                "languageTypeDesc": "Português",
                                "subCategoryName": "Conhecimento Especializado",
                                "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "mainCategoryName": "Know-How",
                                "departments": null,
                                "departmentsDesc": "Talento",
                                "experienceTypeDesc": "Programas de Curta Duração",
                                "communicationDate": "2023-01-25T00:00:00",
                                "observations": null
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "5e2e7e5e-9e07-4d85-aadc-3924aa1b4756",
                        "trainingEventName": "Planos de Pormenor com Eficácia Registral (e as respetivas vicissitudes) 1ª Edição",
                        "trainingModuleDescription": null,
                        "instructorName": "Fernanda Paula Oliveira (FDUC)",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial – VdA Lisboa",
                        "nEnrolled": 0,
                        "nWaitingList": 0,
                        "cancelationLimit": "2023-01-25T00:00:00",
                        "registrationLimit": "2023-01-25T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "P",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 14,
                        "trimester": null,
                        "subCategoryTypeID": 995005001,
                        "mainCategoryTypeID": 0,
                        "experienceTypeID": 0,
                        "languageTypeID": 0,
                        "languageTypeDesc": "Português",
                        "subCategoryName": "Conhecimento Especializado",
                        "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                        "mainCategoryName": "Know-How",
                        "departments": null,
                        "departmentsDesc": "Talento",
                        "experienceTypeDesc": "Programas de Curta Duração",
                        "communicationDate": "2023-01-25T00:00:00",
                        "observations": null
                    }
                ],
                "trainingKPIs": [
                    {
                        "label": "Soft Skills",
                        "value": "0/20",
                        "unit": null
                    },
                    {
                        "label": "Hard Skills",
                        "value": "0/20",
                        "unit": null
                    },
                    {
                        "label": "Must Do",
                        "value": "7/1",
                        "unit": null
                    },
                    {
                        "label": "Nice-to-have",
                        "value": "5/0",
                        "unit": null
                    }
                ],
                "trainingCategories": [
                    {
                        "trainingCategoryID": 995001,
                        "trainingCategoryName": "Alinhamento",
                        "trainingCategoryDescription": null,
                        "trainingSubCategories": [
                            {
                                "trainingCategoryID": 995001002,
                                "trainingCategoryName": "Visão Estratégica",
                                "trainingCategoryDescription": "Analisar o ambiente interno e externo, antecipar a sua evolução e prever os impactos na Firma e na Área",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995001001,
                                "trainingCategoryName": "Envolvimento e Experimentação",
                                "trainingCategoryDescription": "Envolver-se de modo independente e proativo e atuar em projetos internos relevantes para a Firma com interesse genuíno na participação",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995001003,
                                "trainingCategoryName": "Compliance Interno",
                                "trainingCategoryDescription": "Compreender e adquirir um conjunto de saberes e de aplicação prática, de leis, regulamentos, princípios éticos e códigos de conduta da firma",
                                "trainingSubCategories": null
                            }
                        ]
                    },
                    {
                        "trainingCategoryID": 995002,
                        "trainingCategoryName": "Negócio",
                        "trainingCategoryDescription": null,
                        "trainingSubCategories": [
                            {
                                "trainingCategoryID": 995002001,
                                "trainingCategoryName": "Gestão de Negócio",
                                "trainingCategoryDescription": "Criar, desenvolver e manter uma rede de contactos robusta e potenciar o desenvolvimento do negócio da Firma",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995002002,
                                "trainingCategoryName": "Conhecimento de Negócio e Mercados",
                                "trainingCategoryDescription": "Compreender de forma alargada o negócio da Firma e a sua dimensão global e internacional",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995002003,
                                "trainingCategoryName": "Orientação para o Cliente",
                                "trainingCategoryDescription": "Estabelecer, construir e potenciar relações de proximidade com o Cliente assim como ser promotor da colaboração entre as Áreas de Prática e de Gestão da Firma",
                                "trainingSubCategories": null
                            }
                        ]
                    },
                    {
                        "trainingCategoryID": 995005,
                        "trainingCategoryName": "Know-How",
                        "trainingCategoryDescription": null,
                        "trainingSubCategories": [
                            {
                                "trainingCategoryID": 995005001,
                                "trainingCategoryName": "Conhecimento Especializado",
                                "trainingCategoryDescription": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995005002,
                                "trainingCategoryName": "Adaptabilidade e Resiliência",
                                "trainingCategoryDescription": "Ajustar à mudança e a novos desafios profissionais e ser empenhado no desenvolvimento e atualização técnica",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995005003,
                                "trainingCategoryName": "Criatividade e Inovação",
                                "trainingCategoryDescription": "Conceber novas soluções para os problemas e solicitações profissionais e desenvolver novos processos, com valor significativo para a Firma",
                                "trainingSubCategories": null
                            }
                        ]
                    },
                    {
                        "trainingCategoryID": 995004,
                        "trainingCategoryName": "Interação",
                        "trainingCategoryDescription": null,
                        "trainingSubCategories": [
                            {
                                "trainingCategoryID": 995004003,
                                "trainingCategoryName": "Leadership & Empowerment",
                                "trainingCategoryDescription": "Liderar e influenciar positivamente os colaboradores, mobilizando-os para os objetivos da Área e da Firma e estimular a iniciativa e responsabilização",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995004004,
                                "trainingCategoryName": "Relacionamento Interpessoal",
                                "trainingCategoryDescription": "Interagir adequadamente com pessoas com diferentes características e em contextos sociais e profissionais distintos, suscitando confiança e contribuindo para uma cultura de coesão",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995004002,
                                "trainingCategoryName": "Comunicação e Influência",
                                "trainingCategoryDescription": "Expressar com clareza e precisão, adaptar a linguagem aos diversos tipos de interlocutores, ser assertivo na exposição e defesa de ideias e demonstrar respeito e consideração pelas ideias dos outros",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995004001,
                                "trainingCategoryName": "Colaboração",
                                "trainingCategoryDescription": "Integrar em equipas de trabalho de constituição variada e gerar sinergias através de participação ativa",
                                "trainingSubCategories": null
                            }
                        ]
                    },
                    {
                        "trainingCategoryID": 995003,
                        "trainingCategoryName": "Gestão",
                        "trainingCategoryDescription": null,
                        "trainingSubCategories": [
                            {
                                "trainingCategoryID": 995003001,
                                "trainingCategoryName": "Literacia Digital",
                                "trainingCategoryDescription": "Utilizar eficazmente as ferramentas informáticas disponíveis na Firma e essenciais ao trabalho diário",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995003003,
                                "trainingCategoryName": "Project Management",
                                "trainingCategoryDescription": "Programar, organizar e controlar a atividade assim como projetos variados, definindo objetivos, estabelecendo prazos e determinando prioridades",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995003004,
                                "trainingCategoryName": "Engagement",
                                "trainingCategoryDescription": "Compreender e integrar o contributo da atividade no funcionamento da Área, exercendo-a de forma disponível e diligente",
                                "trainingSubCategories": null
                            },
                            {
                                "trainingCategoryID": 995003002,
                                "trainingCategoryName": "Qualidade e Otimização",
                                "trainingCategoryDescription": "Realizar, entregar e controlar o resultado do trabalho de forma eficaz\r\n\r\n",
                                "trainingSubCategories": null
                            }
                        ]
                    }
                ],
                "requiredFields": null,
                "hiddenFields": null
            },
            "error": false,
            "exceptionMessages": {
                "messages": [],
                "hasMessages": false
            }
        }
        setTimeout(() => {
            res(data);
        }, 1000);
    })
}

export const getTrainingEventDetail = async (id) => {
    return {
        "code": 200,
        "description": null,
        "value": {
            "trainingEvents": [
                {
                    "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                    "trainingModuleID": "ed4d18d6-011a-47eb-a240-06801430730a",
                    "trainingModuleTypeID": 971001,
                    "trainingCategoryID": 995004,
                    "trainingModuleTypeDesc": "Formação Interna",
                    "trainingMethodDesc": null,
                    "trainingEventName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital 1ª Edição",
                    "trainingModuleName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital",
                    "trainingCategoryDesc": "Interação",
                    "trainingMethodTypeDesc": null,
                    "instructorName": "Mafalda Matos (VdA)",
                    "locationTypeID": 973001,
                    "locationTypeDesc": "Presencial – VdA Lisboa",
                    "supplierID": 0,
                    "supplierDesc": null,
                    "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                    "internalManagerName": "Sandra Pedro Serrano",
                    "internalManagerUsername": "SPS@vda.pt",
                    "financialCost": 0.0,
                    "targetCategories": "108004,108007,108014",
                    "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                    "developedSkills": "",
                    "developedSkillsDesc": null,
                    "nSessions": 1,
                    "totalHours": 2,
                    "nVacancies": 0,
                    "nEnrolled": 0,
                    "nWaitingList": 0,
                    "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                    "registrationRequirement": "00000000-0000-0000-0000-000000000000",
                    "predefinedCancelation": false,
                    "inviteOnly": false,
                    "cancelationLimit": "2023-10-23T00:00:00",
                    "registrationLimit": "2023-10-23T00:00:00",
                    "registrationStatus": "P",
                    "onWaitingList": false,
                    "canEnrole": true,
                    "trainingEventRegistryID": null,
                    "username": null,
                    "trainingEventSessions": [
                        {
                            "trainingEventSessionID": "66164253-89a5-4015-a1dc-d48fe3da0c4d",
                            "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                            "nHours": 1.5,
                            "startDate": "2023-10-23T00:00:00"
                        }
                    ],
                    "credits": 3,
                    "languageTypeDesc": "Português",
                    "eventStatus": "P",
                    "experienceTypeDesc": "Conferências & Webinars",
                    "trimester": "4º Trimestre",
                    "trainingEventInstructors": [
                        {
                            "trainingEventInstructorID": "1e6752a2-742e-4a3f-91ba-03af2b1326d2",
                            "trainingInstructorID": "2d35fa4c-bdad-444b-8199-5a08f5f5b6d1",
                            "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                            "employeeID": null,
                            "trainingInstructorName": "Mafalda Matos (VdA)",
                            "trainingInstructorUsername": "notdefined@vda.pt",
                            "trainingInstructorDescription": "<p></p>",
                            "fileName": null,
                            "status": "A",
                            "eventStatus": "P",
                            "photo": "",
                            "instructorHistory": []
                        },
                        {
                            "trainingEventInstructorID": "4b8888db-f81e-4be4-87b6-d8dcefdba52b",
                            "trainingInstructorID": "c19c1627-0e68-4ab3-bc85-fb0ce92d96a8",
                            "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                            "employeeID": "b76ef045-c558-4aec-8fde-b329227b3d1d",
                            "trainingInstructorName": "Maria Inês Reis (VdA)",
                            "trainingInstructorUsername": "MNR@vda.pt",
                            "trainingInstructorDescription": "<p></p>",
                            "fileName": null,
                            "status": "A",
                            "eventStatus": "P",
                            "photo": "",
                            "instructorHistory": []
                        }
                    ],
                    "subCategoryName": "Conhecimento Especializado",
                    "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                    "mainCategoryName": "Know-How",
                    "departmentsDesc": "Talento",
                    "mandatory": true,
                    "communicationDate": "2023-10-23T00:00:00",
                    "observations": null
                },
                {
                    "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                    "trainingModuleID": "ed4d18d6-011a-47eb-a240-06801430730a",
                    "trainingModuleTypeID": 971001,
                    "trainingCategoryID": 995004,
                    "trainingModuleTypeDesc": "Formação Interna",
                    "trainingMethodDesc": null,
                    "trainingEventName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital 1ª Edição",
                    "trainingModuleName": "KM Legal Research Tools | Portal do Conhecimento e Biblioteca Digital",
                    "trainingCategoryDesc": "Interação",
                    "trainingMethodTypeDesc": null,
                    "instructorName": "Maria Inês Reis (VdA)",
                    "locationTypeID": 973001,
                    "locationTypeDesc": "Presencial – VdA Lisboa",
                    "supplierID": 0,
                    "supplierDesc": null,
                    "internalManagerID": "69b639f8-71b7-4341-b3f2-0b4bb98ba8e9",
                    "internalManagerName": "Sandra Pedro Serrano",
                    "internalManagerUsername": "SPS@vda.pt",
                    "financialCost": 0.0,
                    "targetCategories": "108004,108007,108014",
                    "targetCategoriesDesc": "Associado(a) Sénior,Associado(a) Coordenador,Associado(a)",
                    "developedSkills": "",
                    "developedSkillsDesc": null,
                    "nSessions": 1,
                    "totalHours": 2,
                    "nVacancies": 0,
                    "nEnrolled": 0,
                    "nWaitingList": 0,
                    "description": "<p>Dar a conhecer as principais bases de dados jurídicos utilizadas pela VdA de forma que possam maximizar o tempo que dedicam a este tipo de pesquisa e a obter resultados mais rigorosos. Este curso é comporto por 6 módulos.</p>",
                    "registrationRequirement": "00000000-0000-0000-0000-000000000000",
                    "predefinedCancelation": false,
                    "inviteOnly": false,
                    "cancelationLimit": "2023-10-23T00:00:00",
                    "registrationLimit": "2023-10-23T00:00:00",
                    "registrationStatus": "P",
                    "onWaitingList": false,
                    "canEnrole": false,
                    "trainingEventRegistryID": null,
                    "username": null,
                    "trainingEventSessions": [
                        {
                            "trainingEventSessionID": "66164253-89a5-4015-a1dc-d48fe3da0c4d",
                            "trainingEventID": "0d4efa66-e49f-48dc-9ba2-aa3e377aea28",
                            "nHours": 1.5,
                            "startDate": "2023-10-23T00:00:00"
                        }
                    ],
                    "credits": 3,
                    "languageTypeDesc": "Português",
                    "eventStatus": "P",
                    "experienceTypeDesc": "Conferências & Webinars",
                    "trimester": "4º Trimestre",
                    "trainingEventInstructors": null,
                    "subCategoryName": "Conhecimento Especializado",
                    "subCategoryDesc": "Desenvolver um conjunto de saberes, de informação técnica e experiência profissional, essenciais ao adequado desempenho das funções",
                    "mainCategoryName": "Know-How",
                    "departmentsDesc": "Talento",
                    "mandatory": true,
                    "communicationDate": "2023-10-23T00:00:00",
                    "observations": null
                }
            ],
            "requiredFields": {
                "TRAININGMETHODTYPEID": false,
                "SUPPLIERID": false,
                "NWAITINGLIST": false,
                "INSTRUCTORNAME": false,
                "DEVELOPEDSKILLS": false,
                "CERTIFIEDHOURS": false,
                "REGISTRATIONREQUIREMENT": false,
                "TRAININGCATEGORYTYPEID": false,
                "NVACANCIES": false
            }
        },
        "error": false,
        "exceptionMessages": {
            "messages": [],
            "hasMessages": false
        }
    };
    const trainings = await getAvailableTrainingModules();
    return new Promise(res => {
        if (trainings?.value) {
            const match = trainings.value.find(x => x.trainingModuleID === id);
            if (match) {
                setTimeout(() => {
                    res({
                        error: false,
                        value: match
                    });
                }, 3000);
            }
        } else {
            res({
                error: true,
                value: null
            })
        }
    })
}

export const getTrainers = async () => {
    return new Promise(res => {
        const data = {
            "error": false,
            "value": [
            ]
        }
        res(data);
    });
}

export const getTimelineData = async (selectedYear) => {
    if (false) {
        console.log(selectedYear);
    }
    return new Promise(res => {
        const data = {
            "error": false,
            "value": {
                data: [
                    {
                        "personalTraining": "true",
                        "year": "2023",
                        "trainingModuleID": "3fcfef71-d4c8-4a69-8991-3c7a68e9b8ab",
                        "trainingModuleTypeID": 971001,
                        "trainingModuleTypeDesc": "Formação Interna",
                        "trainingCategoryID": 133001,
                        "trainingMethodTypeID": 981003,
                        "trainingCategoryDesc": "Negócio",
                        "trainingSubCategoryDesc": "Orientação para o Negócio",
                        "trainingModuleName": "Advanced Architecture Worshops",
                        "supplierID": 972002,
                        "supplierDesc": "BI4ALL",
                        "internalManagerID": "09cdb47c-f2ae-4126-8295-8e253b55ce5d",
                        "internalManagerName": "Inês Lemos",
                        "internalManagerUsername": "ilemos@bi4all.pt",
                        "financialCost": 0,
                        "targetCategories": "108003,108005,108012,108039",
                        "targetCategoriesDesc": "Associate Manager,Senior Consultant,Manager,Senior Manager",
                        "developedSkills": "1330020725",
                        "developedSkillsDesc": "Architecture",
                        "nSessions": 1,
                        "totalHours": 4,
                        "certifiedHours": 0,
                        "nVacancies": 8,
                        "description": "<p>Open Workshop to discuss some architectures, use cases, technologies, and frameworks available to answers our customer’s needs.</p>",
                        "registrationRequirement": null,
                        "predefinedCancelation": false,
                        "cancelationDays": 0,
                        "mandatory": false,
                        "sys_Status": "A",
                        "username": null,
                        "trainingModuleSessions": null,
                        "trainingEvents": [
                            {
                                "trainingModuleID": "3fcfef71-d4c8-4a69-8991-3c7a68e9b8ab",
                                "trainingModuleTypeID": 971001,
                                "trainingModuleTypeDesc": "Formação Interna",
                                "trainingCategoryID": 133001,
                                "trainingMethodTypeID": 981003,
                                "trainingCategoryDesc": "Hard Skills",
                                "trainingModuleName": "Advanced Architecture Worshops",
                                "supplierID": 972002,
                                "supplierDesc": "BI4ALL",
                                "internalManagerID": "09cdb47c-f2ae-4126-8295-8e253b55ce5d",
                                "internalManagerName": "Inês Lemos",
                                "internalManagerUsername": "ilemos@bi4all.pt",
                                "financialCost": 0,
                                "targetCategories": "108003,108005,108012,108039",
                                "targetCategoriesDesc": "Associate Manager,Senior Consultant,Manager,Senior Manager",
                                "developedSkills": "1330020725",
                                "developedSkillsDesc": "Architecture",
                                "nSessions": 1,
                                "totalHours": 4,
                                "certifiedHours": 0,
                                "nVacancies": 8,
                                "description": "<p>Open Workshop to discuss some architectures, use cases, technologies, and frameworks available to answers our customer’s needs.</p>",
                                "registrationRequirement": null,
                                "predefinedCancelation": false,
                                "cancelationDays": 0,
                                "mandatory": false,
                                "sys_Status": "A",
                                "username": null,
                                "trainingModuleSessions": null,
                                "trainingEvents": null,
                                "hasEnrolement": false,
                                "trainingEventSessions": [
                                    {
                                        "trainingEventSessionID": "0f6a7ccd-2da4-47e1-a5ff-3cd2740c9c2b",
                                        "trainingEventID": "402a5cc5-10f2-4efa-880b-b97bcccf469a",
                                        "nHours": 4,
                                        "startDate": "2023-10-09T09:30:00"
                                    }
                                ],
                                "trainingEventID": "402a5cc5-10f2-4efa-880b-b97bcccf469a",
                                "trainingEventName": "Advanced Architecture Worshops E.2",
                                "trainingModuleDescription": null,
                                "instructorName": "N/D",
                                "locationTypeID": 973001,
                                "locationTypeDesc": "Presencial - Lisboa",
                                "nEnrolled": 8,
                                "nWaitingList": 2,
                                "cancelationLimit": "2023-10-04T00:00:00",
                                "registrationLimit": "2023-10-07T00:00:00",
                                "inviteOnly": false,
                                "trainingEventYear": 2023,
                                "registrationStatus": "X",
                                "onWaitingList": false,
                                "canEnrole": true,
                                "trainingEventRegistryID": null,
                                "credits": 0
                            }
                        ],
                        "hasEnrolement": false,
                        "trainingEventSessions": [],
                        "trainingEventID": "402a5cc5-10f2-4efa-880b-b97bcccf469a",
                        "trainingEventName": "Advanced Architecture Worshops E.2",
                        "trainingModuleDescription": null,
                        "instructorName": "N/D",
                        "locationTypeID": 973001,
                        "locationTypeDesc": "Presencial - Lisboa",
                        "nEnrolled": 8,
                        "nWaitingList": 2,
                        "cancelationLimit": "2023-10-04T00:00:00",
                        "registrationLimit": "2023-10-07T00:00:00",
                        "inviteOnly": false,
                        "trainingEventYear": 2023,
                        "registrationStatus": "X",
                        "onWaitingList": false,
                        "canEnrole": true,
                        "trainingEventRegistryID": null,
                        "credits": 0
                    }
                ],
                KPIData: [
                    {
                        label: "Créditos Recomendados",
                        value: "30",
                        unit: "Créditos"
                    },
                    {
                        label: "Créditos Realizados",
                        value: "10/30",
                        unit: "Créditos"
                    },
                    {
                        label: "Total Horas Recomendadas",
                        value: "20",
                        unit: "Horas"
                    },
                    {
                        label: "Total Horas Realizadas",
                        value: "18/20",
                        unit: "Horas"
                    }
                ]
            }
        }
        res(data);
    });
}
