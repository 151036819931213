import { useState, useEffect } from 'react';
import { List, ListItem, Typography, TextField, InputAdornment, Button } from '@material-ui/core';
import {
    RadioButtonChecked as RadioButtonCheckedIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    Search as SearchIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import RangeSlider from './RangeSlider';
import { useTranslation } from 'react-i18next';

const FiltersList = ({ data, filtersSelections, updateFilterSelection, clearFilters }) => {
    const [filtersData, setFiltersData] = useState(false);
    const { t } = useTranslation();

    const assembleFilters = () => {
        setFiltersData(data);
    }

    useEffect(() => {
        if (data?.length) {
            assembleFilters();
        }
    }, [data]);

    return (
        filtersData ? <List>
            <ListItem>
                <Button
                    variant="contained"
                    startIcon={<CloseIcon />}
                    color="secondary"
                    sx={{
                        color: "white !important"
                    }}
                    onClick={clearFilters}
                >
                    {t('LJ:clearFilters')}
                </Button>
            </ListItem>
            {
                filtersData.map((item, index) => (
                    <ListItem
                        key={"filters_" + index}
                        sx={{
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "flex-start"
                        }}
                    >
                        <>
                            <Typography
                                sx={{
                                    textTransform: "uppercase"
                                }}
                            >
                                <b>
                                    {item.translatedLabel ? t(`LJ:filters.${item.translatedLabel}`) : item.label}
                                </b>
                            </Typography>
                            {
                                item.type === "single" && <List
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    {
                                        item.options?.sort((a, b) => ('' + a.label).localeCompare(b.label)).map((opt, opt_i) => {
                                            const isSelected = filtersSelections[item.id] && filtersSelections[item.id].value !== undefined && filtersSelections[item.id].value === opt.value;
                                            return <ListItem
                                                key={`option_${opt.label}_${opt_i}`}
                                                sx={{
                                                    width: "100%"
                                                }}
                                                button
                                                onClick={() => updateFilterSelection(item.type, item.id, opt.value)}
                                            >
                                                {opt.translatedLabel ? t(`LJ:filters.${opt.translatedLabel}`) : opt.label}
                                                <div
                                                    style={{
                                                        marginLeft: "auto"
                                                    }}
                                                >
                                                    {isSelected ? <RadioButtonCheckedIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}
                                                </div>
                                            </ListItem>
                                        })
                                    }
                                </List>
                            }
                            {
                                item.type === "range" && <RangeSlider
                                    min={0}
                                    max={99}
                                    onChange={(values) => updateFilterSelection(item.type, item.id, values)}
                                />
                            }
                            {
                                item.type === "text" && <TextField
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small" />
                                            </InputAdornment>
                                        )
                                    }}
                                    value={filtersSelections[item.id] ? filtersSelections[item.id].value?.value : ""}
                                    onChange={(e) => updateFilterSelection(item.type, item.id, { searchIn: item.searchIn, value: e.target.value })}
                                    placeholder="Pesquisar..."
                                    variant="outlined"
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            color: "lightgray"
                                        },
                                        "& .MuiInputAdornment-root": {
                                            color: "white"
                                        },
                                        "& fieldset": {
                                            borderColor: "white",
                                            "&:hover": {
                                                borderColor: "white"
                                            }
                                        }
                                    }}
                                />
                            }
                        </>
                    </ListItem>
                ))
            }
            <ListItem>
                <Button
                    variant="contained"
                    startIcon={<CloseIcon />}
                    color="secondary"
                    sx={{
                        color: "white !important"
                    }}
                    onClick={clearFilters}
                >
                    {t('LJ:clearFilters')}
                </Button>
            </ListItem>
        </List>
            :
            null
    )
};

FiltersList.propTypes = {
    data: PropTypes.array,
    filtersSelections: PropTypes.object,
    updateFilterSelection: PropTypes.func,
    clearFilters: PropTypes.func
}

export default FiltersList;
