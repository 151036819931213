export default {
  styleOverrides: {
    title: {
      fontWeight: 'bold!important',
    },
  },
  root: {
    fontWeight: 'bold'
  }
};
