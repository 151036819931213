import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs,
  Link,
  Typography,
  Grid
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

const Bread = ({ levels, additionalContent, sx }) => {
  const theme = useTheme();
  if (!levels || levels.length <= 0) {
    return
  }
  return (
    levels && levels.length > 0 ?
      <Grid
        container
        spacing={2}
        sx={{ ...sx }}
      >
        <Grid
          item
          xs={12}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="h1"
              >
                <b>
                  {
                    levels[levels.length - 1] && levels[levels.length - 1].label ? levels[levels.length - 1].label : levels[levels.length - 2].label
                  }
                </b>
              </Typography>
              {
                levels.length > 1 ?
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRight fontSize="small" />}
                  >
                    {
                      levels.map((level, level_i) => (
                        level.label && (
                          <Typography
                            key={"breadcrumb_" + level_i}
                            color={level.color ? level.color : "textSecondary"}
                            variant="subtitle2"
                          >
                            {
                              level.link ?
                                <Link
                                  color="textPrimary"
                                  component={RouterLink}
                                  to={level.link}
                                  variant="subtitle2"
                                >
                                  {level.label}
                                </Link>
                                :
                                level.label
                            }
                          </Typography>
                        )
                      ))
                    }
                  </Breadcrumbs>
                  :
                  null
              }
            </div>
            <div
              style={{
                marginLeft: theme.spacing(2),
                marginTop: "auto"
              }}
              className="additionalContent"
            >
              {
                additionalContent
              }
            </div>
          </div>
        </Grid>
      </Grid>
      :
      null
  )
}

Bread.propTypes = {
  levels: PropTypes.array,
  additionalContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sx: PropTypes.object
}

export default Bread;
