import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useCustomAuth';
// import AuthorizationRequired from '../pages/AuthorizationRequired';

const AuthGuard = (props) => {
  const { children, roles, componentPermission, moduleID } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!auth.isAuthenticated || !auth.user) {
    // if (location.pathname !== requestedLocation) {
    //   setRequestedLocation(location.pathname);
    // }

    if (process.env.REACT_APP_CUSTOM_AUTH === 'LDAP') {
      return componentPermission ? <></> : <Navigate to="/authentication/login" />;
    }
    return componentPermission ? <></> : <Navigate to="/error/401" />;
  }

  if (!auth.user.roles?.includes(roles) || (moduleID && moduleID !== "" && !auth.user.userModules?.find(x => x.id === moduleID)?.enable)) {
    return componentPermission ? <></> : <Navigate to="/error/401" />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.string.isRequired,
  componentPermission: PropTypes.bool,
  moduleID: PropTypes.string
};

AuthGuard.defaultProps = {
  componentPermission: false
};

export default AuthGuard;
