import PropTypes from 'prop-types';
import {
  IconButton,
  Typography,
  Toolbar,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import Trash from '../../icons/Trash';
import RefreshIcon from '../../icons/Refresh';
import DownloadIcon from '../../icons/Download';
import DuplicateIcon from '../../icons/Duplicate';
import Archive from '../../icons/Archive';
import { useTranslation } from 'react-i18next';
// import { PostAdd } from '@material-ui/icons';

const TableAction = (props) => {
  const { enableBulkActions, selectedCounter, handleRemoveAction, activeTab, isDocumentsList,
    handleGetFile, selectedDocument, enableClone, handleCloneAction, enableRemove, enableArchived, handleArchivedAction, customActions } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      {enableBulkActions && (
        <Toolbar
          sx={{ backgroundColor: theme.palette.primary.transparent[15] }}
        >
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            sx={{
              flex: '1 1 100%'
            }}
          >
            {`${(selectedCounter)} ${t('common:tableActions.record', { count: selectedCounter })} ${t('common:tableActions.selected', { count: selectedCounter })}`}
          </Typography>
          {customActions}
          {isDocumentsList && selectedCounter === 1 && (
            <Tooltip
              title={t('common:buttons.download')}
            >
              <IconButton
                edge="end"
                onClick={() => handleGetFile(selectedDocument, true)}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>)}
          {enableClone && (
            <Tooltip
              title={t('common:buttons.clone')}
            >
              <IconButton
                onClick={handleCloneAction}
              >
                <DuplicateIcon />
              </IconButton>
            </Tooltip>
          )}
          {(enableRemove) && (
            <Tooltip
              title={activeTab === 'A' ? t('common:buttons.remove') : t('common:buttons.revert')}
            >
              <IconButton
                aria-label={t('common:buttons.remove')}
                onClick={handleRemoveAction}
                disabled={selectedCounter === 0}
              >
                {(activeTab === 'A') && (
                  <Trash />
                )}
                {activeTab === 'X' && (
                  <RefreshIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
          {(!isDocumentsList && enableArchived) && (
            <Tooltip
              title={activeTab === 'A' ? t('common:buttons.archive') : t('common:buttons.revert')}
            >
              <IconButton
                aria-label={t('common:buttons.archive')}
                onClick={handleArchivedAction}
                disabled={selectedCounter === 0}
              >
                {(activeTab === 'A') && (
                  <Archive />
                )}
                {activeTab === 'X' && (
                  <RefreshIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      )}
    </>
  );
};

TableAction.propTypes = {
  enableBulkActions: PropTypes.bool,
  selectedCounter: PropTypes.number,
  handleRemoveAction: PropTypes.func,
  activeTab: PropTypes.string,
  isDocumentsList: PropTypes.bool,
  handleGetFile: PropTypes.func,
  selectedDocument: PropTypes.object,
  enableClone: PropTypes.bool,
  handleCloneAction: PropTypes.func,
  enableRemove: PropTypes.bool,
  enableArchived: PropTypes.bool,
  handleArchivedAction: PropTypes.func,
  customActions: PropTypes.any
};

TableAction.defaultProps = {
  activeTab: "A",
  isDocumentsList: false,
};

export default TableAction;
