import palette from "../palette";

export default {
  styleOverrides: {
    root: {
      '.vacationCalendar': {
        paddingTop: "0px",
        '.rdp': {
          margin: 0,
          '.rdp-table': {
            margin: '5px'
          },
          '.rdp-caption': {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '18px',
            gap: '10px',
            boxShadow: 'inset 0px -1px 0px #EEEEEE',
            borderRadius: '4px 4px 0px 0px',
          },
          '.rdp-caption_label': {
            fontSize: '20px',
            border: 0
          },
          '.rdp-nav': {
            display: "none"
          },
          '.rdp-months': {
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(275px, 1fr))",
            gap: '20px',
            '.rdp-month': {
              display: "flex",
              // maxWidth: "281px",
              height: "360px",
              flexDirection: "column",
              alignSelf: "center",
              justifySelf: "center",
              borderRadius: '4px !important',
              background: "#F9FAFB",
              boxShadow: 'inset 0px -1px 0px #eeeeee',
              color: "#0B3B66",
              margin: "0px !important",
              '.rdp-day': {
                borderRadius: "2px",
                border: "initial",
                margin: '1px',
                color: '#0B3B66',
                fontWeight: 'bolder',
                fontSize: '1em',
                "&.rdp-day_today": {
                  position: "relative",
                  fontWeight: "bold",
                  opacity: 1,
                  color: palette.text.secondary,
                  "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "55%",
                    top: "68%",
                    borderBottom: `solid 2px ${palette.primary.main}`
                  },
                },
                '&:hover': {
                  backgroundColor: "transparent",
                  color: palette.text.primary,
                  border: `1px solid ${palette.text.primary}`,
                  "&.rdp-day_disabled": {
                    borderStyle: "dashed"
                  }
                },
                '&.rdp-day_selected': {
                  backgroundColor: "transparent",
                  color: palette.primary.main,
                  border: `2px solid ${palette.primary.main}`,
                  "&.rdp-day_disabled": {
                    opacity: 0.5,
                    border: "1px dashed !important"
                  }
                },
              },
              '.rdp-head_cell': {
                fontSize: '1em'
              }

            }
          },

        }
      },
    },
    container: {
      "&[class*=MuiGrid-spacing]": {
        /*REMOVER QUANDO GRID SPACING ESTIVER CORRIGIDO*/
        marginTop: 0
      }
    }
  }
};
