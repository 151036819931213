import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@material-ui/core';
import Modal from "../../ModalTemplate";
import TimelineComponent from './timelineComponent';

const TimelineModal = ({ open, onClose, timelineData, modalLoading }) => {
  return (
    <Modal
      size="md"
      title={timelineData?.displayName}
      open={open}
      onClose={onClose}
      content={
        modalLoading
          ? <>{
            [...Array(4)].map((e, i) =>
              <Grid
                key={`timelineSkeleton_${i}`}
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={1}
                >
                  <Skeleton variant="rounded" width={40} height={40} />
                </Grid>
                <Grid
                  item
                  xs={11}
                >
                  <Skeleton
                    height={40}
                    width="90%"
                    sx={{ ml: 2 }}
                  />
                </Grid>
              </Grid>)
          }</>
          : <TimelineComponent
            timelineData={timelineData}
          />
      }
    />
  );
}
TimelineModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  timelineData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool
  ]),
  modalLoading: PropTypes.bool,
};

export default TimelineModal;
