import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { List, ListItem, Link } from '@material-ui/core';
import { getFAQs } from '../../API/LJAPI';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
//import PropTypes from 'prop-types';

const FAQList = () => {
    const [FAQs, setFAQs] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    const handleGetFAQs = async () => {
        const result = await getFAQs();
        if (result && !result.error) {
            setTimeout(() => {
                setFAQs(result.value);
            }, 1000);
        } else if (result.exceptionMessages && result.exceptionMessages.messages && result.exceptionMessages.messages.length) {
            result.exceptionMessages.messages.forEach(m => toast.error(m.description));
        } else {
            toast.error(t("common:common.errors.unexpected"));
        }
    }

    useEffect(() => {
        handleGetFAQs();
    }, []);

    return FAQs ? <List>
        {
            FAQs.map((faq, index) => {
                const exactMatch = !!matchPath({
                    path: `/FAQ/${index}`,
                    end: true
                }, location.pathname);

                return <ListItem
                    key={faq.text + "_" + index}
                    sx={{
                        fontWeight: exactMatch ? "bold" : "initial",
                        textDecoration: exactMatch ? "underline" : "initial"
                    }}
                >
                    <Link
                        component={RouterLink}
                        to={`/FAQ/${index}`}
                        color="white"
                    >
                        {faq.title}
                    </Link>
                </ListItem>
            })
        }
    </List>
        :
        null
}

FAQList.propTypes = {
}

export default FAQList;
