import { useState, useEffect } from 'react';
import Modal from "../../../components/ModalTemplate";
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress, Box, TextField, FormHelperText, ToggleButtonGroup, ToggleButton, Button, Autocomplete, Grid, Checkbox, useTheme, Tooltip, Stack, Typography, Skeleton, Alert, AlertTitle } from "@material-ui/core";
import 'react-day-picker/dist/style.css';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { toBase64 } from '../../../utils/base64Utils';
import { trainingModuleAPI } from "../../../api/trainingModuleAPI";
import toast from "react-hot-toast";
import CustomFileDropzone from '../../../components/CustomFileDropzone';
import _ from 'lodash';
import { workflowAPI } from '../../../api/workflowAPI';
import TrainingRequestRejectModal from '../../../components/dashboard/trainingCatalogue/TrainingRequestRejectModal';
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  Download as DownloadIcon
} from "@material-ui/icons";
import bytesToSize from '../../../utils/bytesToSize';

const dateFormat = "YYYY-MM-DD";

const RequestTrainingModal = ({ open, detail, onClose, formatOptions, approvalMode, refreshData, trainingTypesOptions }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [rejectReasonOpen, setRejectReasonOpen] = useState(false);
  const theme = useTheme();
  const isSubmitted = detail?.status && detail?.status !== "";
  const canEdit = !isSubmitted || (approvalMode && detail.status !== "T")
  const canUploadEvidences = detail?.status && (detail.status === "Y" || detail.status === "D");
  const canSeeEvidencesDescription = detail?.status && detail.status === "D" && detail?.isRequest;
  const [fileCode, setFileCode] = useState([]);
  const _maxFileSize = 10485760;
  const validationSchema = {
    isRequest: Yup.bool(),
    externalTrainingName: Yup.string().required(t('common:formik.requiredField')),
    externalTrainingEntity: Yup.string().required(t('common:formik.requiredField')),
    trainingMethodType: Yup.object().required(t('common:formik.requiredField')),
    trainingType: Yup.object().required(t('common:formik.requiredField')),
    dates: Yup.object().shape({
      startDate: Yup.date().when('isRequest', {
        is: true,
        then: Yup.date().min(new Date(), t('training:messages.startDate')).required(t('common:formik.requiredField')),
        otherwise: Yup.date().required(t('common:formik.requiredField'))
      }),
      endDate: Yup.date().min(Yup.ref('startDate'), t('LJ:formik.endDate')).required(t('common:formik.requiredField')),
    }),
    totalHours: Yup.number().min(1).required(t('common:formik.requiredField')),
    /*cost: Yup.number().when('isRequest', {
      is: true,
      then: Yup.number().required(t('common:formik.requiredField')),
      otherwise: Yup.number()
    }),*/
    cost: Yup.number().nullable(),
    otherCosts: Yup.number().nullable(),
    credits: Yup.number().nullable(),
    description: Yup.string().required(t('common:formik.requiredField')),
    attachments: (canUploadEvidences && !approvalMode) ? Yup.array().when('isRequest', {
      is: true,
      then: Yup.array().min(1, t('common:formik.requiredField')).required(t('common:formik.requiredField'))
    }) : Yup.array(),
    evidenceDescription: Yup.string().nullable(),
    acceptedTerms: Yup.bool().when('isRequest', {
      is: true,
      then: Yup.bool().oneOf([true], t('LJ:formik.scholarshipCondition')),
      otherwise: Yup.bool()
    })
  }

  const getTermsFile = async () => {
    const link = document.createElement('a');
    link.href = "/static/Regulamento de Bolsas e Licenças.pdf";
    link.download = "Regulamento de Bolsas e Licenças.pdf";
    link.click();
    link.remove();
  }

  const handleReject = (values) => {
    setRejectReasonOpen(values?.status);
  }

  const handleDownloadFile = async (fileName, code) => {
    if (detail) {
      const result = await trainingModuleAPI.downloadTrainingRequestFile(detail.externalTrainingID, fileName, code);

      if (!result.error) {
        const fileUrl = URL.createObjectURL(result);

        const link = window.document.createElement('a');
        link.target = '_blank';
        link.href = fileUrl;
        link.download = fileName;
        // Append to html link element page
        window.document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        URL.revokeObjectURL(fileUrl);
      }
    }
  }

  const handleDrop = (currentAttachments, files, setFieldValue, isRequest) => {
    const _currentAttachments = _.cloneDeep(currentAttachments);

    const code = isRequest ? 'Request' : 'Registry';

    files.forEach((f) => {
      _currentAttachments.push({
        code,
        file: f,
        fileB64: null,
        filename: f.name,
      });
    });
    setFieldValue("attachments", _currentAttachments);
  };

  const handleDropFailed = () => {
    const formatedSize = bytesToSize(_maxFileSize);
    toast.error(t('LJ:messages.attachedFileSize') + ": " + formatedSize);
  };

  const handleRemove = (currentAttachments, fileID, setFieldValue) => {
    const _files = _.cloneDeep(currentAttachments).filter((attachment) => attachment.fileID !== fileID);
    setFieldValue('attachments', _files);
  };

  const handleRemoveAll = (setFieldValue) => {
    setFieldValue("attachments", []);
  };

  const handleSelectDate = (values, setFieldValue, type, value) => {
    const _dates = _.cloneDeep(values?.dates);
    _dates[type] = value;
    if (type === "from") {
      _dates.to = value;
    }
    setFieldValue('dates', _dates);
  }

  const setupInitialValues = async () => {
    setIsLoading(true);
    let trainingFiles = [];
    if (detail?.externalTrainingID) {
      const result = await trainingModuleAPI.getTrainingRequestFilesInfo(detail?.externalTrainingID);
      if (result && !result.error) {
        trainingFiles = result.value;
        setFileCode(result.value)
      }
    }
    setIsLoading(false);
    const _data = _.cloneDeep(detail) || {};
    _data.isRequest = _data.isRequest === undefined ? true : _data.isRequest;
    Object.keys(validationSchema).forEach(x => {
      if (_data[x] === undefined) {
        const { type } = validationSchema[x];
        let value = "";
        switch (type) {
          case "boolean":
            value = false;
            break;
          case "mixed":
            value = null;
            break;
          case "object":
            value = {};
            break;
          default:
            break;
        }
        _data[x] = value;
      }
    });

    _data.acceptedTerms = isSubmitted || false;
    _data.trainingFiles = trainingFiles;
    _data.attachments = [];
    _data.trainingMethodType = formatOptions.find(x => x.value === _data.trainingMethodTypeID);
    _data.trainingType = trainingTypesOptions.find(x => x.value === _data.trainingTypeID || x.value === _data.externalTrainingTypeID);
    _data.dates = {
      startDate: detail?.startDate && moment(detail.startDate).isValid() ? moment(detail.startDate).format(dateFormat) : moment().format(dateFormat),
      endDate: detail?.endDate && moment(detail.endDate).isValid() ? moment(detail.endDate).format(dateFormat) : moment().format(dateFormat)
    }
    _data.credits = _data.credits !== "-" ? _data.credits : 0
    setInitialValues(_data);
  }

  const groupedData = fileCode.reduce((acc, current) => {
    const { code } = current;
    if (!acc[code]) {
      acc[code] = [];
    }
    acc[code].push(current);
    return acc;
  }, {});

  useEffect(() => {
    if (detail !== false) {
      setupInitialValues();
    }
  }, [detail]);

  return (
    <Formik
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup
        .object()
        .shape(validationSchema)}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true);
        const attachments = [];
        if (values?.attachments) {
          const temp = values?.attachments.map(x => x.file);
          for (const file of temp) {
            const fileB64 = await toBase64(file)
            attachments.push({
              FileName: file.name.replaceAll("_", "-"),
              File: fileB64.split('base64,')[1]
            })
          }
        }
        const params = {
          externalTrainingID: values.externalTrainingID,
          externalTrainingName: values.externalTrainingName,
          externalTrainingEntity: values.externalTrainingEntity,
          description: values.description,
          startDate: values.dates.startDate,
          endDate: values.dates.endDate,
          cost: values.cost,
          otherCosts: values.otherCosts,
          totalHours: values.totalHours,
          isRequest: values.isRequest,
          attachments: attachments,
          evidenceDescription: values.evidenceDescription,
          trainingMethodTypeID: values?.trainingMethodType.value,
          credits: values?.credits,
          status: values?.status === "Y" || values?.status === "D" ? "D" : "P",
          trainingTypeID: values?.trainingType?.value
        }
        // if values?.submitAction -> HR REQUEST
        if (values?.submitAction) {
          try {
            if (detail) {
              const selectedRows = [detail]
              const payload = selectedRows.map((x) => ({
                SourceID: x.externalTrainingID,
                Context: "EXTERNAL TRAINING"
              }));
              setSubmitting(true);
              let result = {};
              if (values?.status === "D") {
                result = trainingModuleAPI.UpdateExternalTrainingStatus({
                  ...params,
                  justification: values?.justification,
                  status: values?.submitAction === "REJECT" ? "D" : "T"
                });
              } else {
                if (values?.submitAction === "APPROVE") {
                  const updateCreditsResult = await trainingModuleAPI.saveTrainingRequest(params);
                  if (updateCreditsResult.error) {
                    toast.error(result.description);
                    onClose(true);
                  }
                }
                result = await workflowAPI.UpdateTaskStatus(payload, values?.submitAction, values?.justification);
              }
              if (!result.error) {
                toast.success(result.description);
                onClose(true);
              } else if (result?.exceptionMessages && result?.exceptionMessages.hasMessages) {
                result.exceptionMessages.messages.forEach(m => {
                  toast.error(m.description);
                })
              }
            } else {
              toast.error(t('common:messages.error'))
            }
          } catch (err) {
            console.error(err);
          }
          refreshData();
        } else {
          const result = await trainingModuleAPI.saveTrainingRequest(params);
          setSubmitting(false);
          if (result && !result.error) {
            toast.success(result.description);
            setStatus({ success: true });
            onClose(false);
            refreshData();
          } else {
            setStatus({ success: false });
            setErrors({ submit: result.errorMessages });
            if (result.exceptionMessages && result.exceptionMessages.hasMessages) {
              result.exceptionMessages.messages.forEach(m => toast.error(m.description));
            } else if (result.description) {
              toast.error(result.description);
            } else {
              toast.error(t("common:common.errors.unexpected"));
            }
          }
        }
      }}
    >
      {({ errors, setFieldValue, handleSubmit, isSubmitting, touched, values }) => (
        <Modal
          onClose={() => onClose(false)}
          open={open}
          size="md"
          title={values?.isRequest ? t('LJ:reimbursedTraining') : t('LJ:externalTrainingRegistration')}
          content={
            !isLoading && initialValues ?
              <Box>
                <>
                  <form>
                    <Grid
                      container
                      spacing={2}
                    >
                      {
                        values?.justification && <Grid
                          item
                          xs={12}
                        >
                          <Alert variant="outlined" severity="warning">
                            {values?.justification}
                          </Alert>
                        </Grid>
                      }
                      {errors && Object.keys(errors).length > 0 && (
                        <Grid item xs={12}>
                          <Box>
                            <Alert severity="error">
                              <AlertTitle><b>{t('common:formik.validationErrors')}</b></AlertTitle>
                              {t('common:formik.correctGeneral')}
                            </Alert>
                          </Box>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                      >
                        {
                          isSubmitted || approvalMode ?
                            <Typography
                              variant="h2g"
                            >
                              {values?.isRequest ? t('LJ:reimbursedTraining') : t('LJ:externalTrainingRegistration')}
                            </Typography>
                            :
                            <ToggleButtonGroup
                              exclusive
                              variant="contained"
                              aria-label="Tipo de registo"
                              value={values?.isRequest ? 1 : 0}
                              onChange={(e, v) => setFieldValue('isRequest', v === 1)}
                            >
                              <ToggleButton value={1}>
                                <Tooltip
                                  title={t('LJ:externalReimbursedTrainingRequest')}
                                >
                                  <div>
                                    {t('LJ:reimbursedTraining')}
                                  </div>
                                </Tooltip>
                              </ToggleButton>
                              <ToggleButton value={0}>
                                <Tooltip
                                  title={t('LJ:trainingWithoutReimbursementRegistration')}
                                >
                                  <div>
                                    {t('LJ:externalTrainingRegistration')}
                                  </div>
                                </Tooltip>
                              </ToggleButton>
                            </ToggleButtonGroup>
                        }
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.externalTrainingName && errors.externalTrainingName)}
                              fullWidth
                              helperText={touched.externalTrainingName && errors.externalTrainingName}
                              label={t('LJ:trainingTitle')}
                              name="externalTrainingName"
                              onChange={(e) => setFieldValue('externalTrainingName', e.target.value)}
                              variant="outlined"
                              disabled={isSubmitted || approvalMode}
                              value={values.externalTrainingName || ''}
                              className="readOnly"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.externalTrainingEntity && errors.externalTrainingEntity)}
                              fullWidth
                              helperText={touched.externalTrainingEntity && errors.externalTrainingEntity}
                              label={t('LJ:trainingEntity')}
                              name="externalTrainingEntity"
                              onChange={e => setFieldValue('externalTrainingEntity', e.target.value)}
                              value={values.externalTrainingEntity}
                              variant="outlined"
                              disabled={isSubmitted || approvalMode}
                              className="readOnly"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            {
                              isSubmitted || approvalMode ?
                                <TextField
                                  error={Boolean(touched.trainingMethodType && errors.trainingMethodType)}
                                  fullWidth
                                  helperText={touched.trainingMethodType && errors.trainingMethodType}
                                  label={t('LJ:format')}
                                  variant="outlined"
                                  value={values?.trainingMethodType?.text || null}
                                  disabled={isSubmitted || approvalMode}
                                  className="readOnly"
                                />
                                :
                                <Autocomplete
                                  getOptionLabel={(option) => (option.text && option.text !== "" ? option.text : "")}
                                  isOptionEqualToValue={(option, value) => { return option?.value && value?.value && value.value !== "" && option.value === value.value }}
                                  options={formatOptions || []}
                                  onChange={(e, v) => setFieldValue('trainingMethodType', v)}
                                  value={values?.trainingMethodType || ""}
                                  name="trainingMethodType"
                                  disableClearable
                                  disabled={isSubmitted || approvalMode}
                                  renderInput={(parameters) => (
                                    <TextField
                                      error={Boolean(touched.trainingMethodType && errors.trainingMethodType)}
                                      helperText={touched.trainingMethodType && errors.trainingMethodType}
                                      fullWidth
                                      label={t('LJ:format')}
                                      variant="outlined"
                                      {...parameters}
                                    />)}
                                />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Autocomplete
                              getOptionLabel={(option) => (option.text && option.text !== "" ? option.text : "")}
                              isOptionEqualToValue={(option, value) => { return option?.value && value?.value && value.value !== "" && option.value === value.value }}
                              options={trainingTypesOptions || []}
                              onChange={(e, v) => setFieldValue('trainingType', v)}
                              value={values?.trainingType || ""}
                              name="trainingType"
                              disableClearable
                              disabled={isSubmitted || approvalMode}
                              renderInput={(parameters) => (
                                <TextField
                                  error={Boolean(touched.trainingType && errors.trainingType)}
                                  helperText={touched.trainingType && errors.trainingType}
                                  fullWidth
                                  label={t('LJ:trainingType')}
                                  variant="outlined"
                                  {...parameters}
                                />)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <Box>
                          <Grid
                            container
                          >
                            {
                              values?.dates && <Grid
                                item
                                xs={12}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <TextField
                                        fullWidth
                                        error={Boolean(touched.dates?.startDate && errors.dates?.startDate)}
                                        helperText={touched.dates?.startDate && errors.dates?.startDate}
                                        label={t('LJ:fromDate')}
                                        name="startDate"
                                        //format={dateFormat}
                                        onChange={e => handleSelectDate(values, setFieldValue, 'startDate', e.target.value)}
                                        value={values?.dates?.startDate}
                                        variant="outlined"
                                        type="date"
                                        disabled={!canEdit}
                                        className="readOnly"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <TextField
                                        fullWidth
                                        error={Boolean(touched.dates?.endDate && errors.dates?.endDate)}
                                        helperText={touched.dates?.endDate && errors.dates?.endDate}
                                        label={t('LJ:toDate')}
                                        name="endDate"
                                        //format={dateFormat}
                                        onChange={e => handleSelectDate(values, setFieldValue, 'endDate', e.target.value)}
                                        value={values?.dates?.endDate}
                                        variant="outlined"
                                        type="date"
                                        disabled={!canEdit}
                                        className="readOnly"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                    >
                                      <TextField
                                        error={Boolean(touched.totalHours && errors.totalHours)}
                                        fullWidth
                                        helperText={touched.totalHours && errors.totalHours}
                                        label={t('training:options.totalHours')}
                                        name="totalHours"
                                        onChange={e => setFieldValue('totalHours', e.target.value)}
                                        value={values.totalHours}
                                        variant="outlined"
                                        type="number"
                                        disabled={!canEdit}
                                        className="readOnly"
                                      />
                                    </Grid>
                                    {
                                      values?.isRequest &&
                                      <Grid
                                        item
                                        xs={12}
                                      >
                                        <TextField
                                          fullWidth
                                          error={Boolean(touched.cost && errors.cost)}
                                          helperText={touched.cost && errors.cost}
                                          name="cost"
                                          label={t('LJ:cost')}
                                          onChange={e => setFieldValue('cost', e.target.value)}
                                          value={values.cost}
                                          variant="outlined"
                                          type="number"
                                          disabled={!canEdit}
                                          className="readOnly"
                                          InputProps={{
                                            startAdornment: (
                                              <div style={{ marginRight: 10 }}>€</div>
                                            )
                                          }}
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Box>
                              </Grid>
                            }
                            <Grid
                              item
                              xs={12}
                              sx={{
                                mt: 2
                              }}
                            >
                              <TextField
                                fullWidth
                                error={Boolean(touched.otherCosts && errors.otherCosts)}
                                helperText={touched.otherCosts && errors.otherCosts}
                                name="otherCosts"
                                label={t('LJ:otherCosts')}
                                onChange={e => setFieldValue('otherCosts', e.target.value)}
                                value={values.otherCosts}
                                variant="outlined"
                                type="number"
                                disabled={!canEdit}
                                className="readOnly"
                                InputProps={{
                                  startAdornment: (
                                    <div style={{ marginRight: 10 }}>€</div>
                                  )
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          multiline
                          rows={5}
                          error={Boolean(touched.description && errors.description)}
                          fullWidth
                          helperText={touched.description && errors.description}
                          label={t('LJ:trainingDescription')}
                          name="description"
                          onChange={e => setFieldValue('description', e.target.value)}
                          value={values?.description || ""}
                          variant="outlined"
                          disabled={!canEdit}
                          className="readOnly"
                          sx={{
                            "& textarea": {
                              resize: "vertical"
                            }
                          }}
                        />
                      </Grid>
                      {
                        !isSubmitted && !approvalMode && <Grid
                          item
                          xs={12}
                        >
                          <Typography>
                            <b>{t('LJ:attachments')}</b>
                          </Typography>
                          <br />
                          <CustomFileDropzone
                            accept={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/bmp']}
                            maxFiles={10}
                            files={values.attachments?.filter((attachment) => {
                              if (values.isRequest) {
                                return attachment.code === 'Request';
                              }
                              return attachment.code === 'Registry';
                            })
                              .map((attachment) => attachment.file)}
                            maxSize={_maxFileSize}
                            onDrop={(files) => handleDrop(values?.attachments, files, setFieldValue, values.isRequest)}
                            onRemove={(file) => handleRemove(values?.attachments, file.fileID, setFieldValue)}
                            onRemoveAll={() => handleRemoveAll(setFieldValue)}
                            onDropRejected={handleDropFailed}
                            hasError={Boolean(touched.attachments && errors.attachments)}
                            preview={false}
                            sx={{
                              "& h1": {
                                fontSize: 12,
                                m: -1
                              }
                            }}
                          />
                          {(touched.attachments && errors.attachments) && (
                            <Box sx={{ mt: 2 }}>
                              <FormHelperText error>
                                {errors.attachments}
                              </FormHelperText>
                            </Box>
                          )}
                        </Grid>
                      }
                      {
                        values?.trainingFiles?.length > 0 && <Grid
                          item
                          xs={12}
                        >
                          {groupedData.REQUEST &&
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography sx={{ mb: 1 }}>{t('LJ:initialFiles')}</Typography>
                              <Stack
                                spacing={2}
                                direction="row"
                              >
                                {groupedData.REQUEST.map(file =>
                                  <Button
                                    key={file.filename}
                                    variant="outlined"
                                    component="label"
                                    onClick={() => handleDownloadFile(file.filename, file.code)}
                                    endIcon={<DownloadIcon size="small" />}
                                  >
                                    {file.filename}
                                  </Button>)}
                              </Stack>
                            </Box>}
                          {groupedData.EVIDENCE &&
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography sx={{ mt: 2, mb: 1 }}>{t('LJ:evidences')}</Typography>
                              <Stack
                                spacing={2}
                                direction="row"
                              >
                                {groupedData.EVIDENCE.map(file =>
                                  <Button
                                    key={file.filename}
                                    variant="outlined"
                                    component="label"
                                    onClick={() => handleDownloadFile(file.filename, file.code)}
                                    endIcon={<DownloadIcon size="small" />}
                                  >
                                    {file.filename}
                                  </Button>)}
                              </Stack>
                            </Box>}
                        </Grid>
                      }
                      {
                        !approvalMode && values?.isRequest && <Grid
                          item
                          xs={12}
                        >
                          <Box>
                            <Checkbox
                              disabled={isSubmitted || approvalMode}
                              checked={values?.acceptedTerms}
                              color="primary"
                              onChange={(e) => setFieldValue('acceptedTerms', e.target.checked)}
                              sx={{
                                cursor: "pointer",
                                color: touched.acceptedTerms && errors?.acceptedTerms ? theme.palette.error.main : theme.palette.primary.main
                              }}
                              required
                            />
                            <span>
                              {t('LJ:acceptConditions')}
                            </span>
                            <button
                              type="button"
                              style={{
                                background: "transparent",
                                textAlign: "left",
                                border: "none",
                                outline: "none",
                                boxShadow: "none",
                                color: theme.palette.primary.main,
                                cursor: "pointer"
                              }}
                              onClick={getTermsFile}
                            >
                              {t('LJ:scholarshipRegulation')}
                            </button>
                            {
                              touched.acceptedTerms && errors?.acceptedTerms && <FormHelperText error>
                                {errors.acceptedTerms}
                              </FormHelperText>
                            }
                          </Box>
                        </Grid>
                      }
                      {
                        (isSubmitted && canUploadEvidences && !approvalMode) && <Grid
                          item
                          xs={12}
                        >
                          <Typography>
                            <b>{t('LJ:trainingEvidences')}</b>
                          </Typography>
                          <br />
                          <CustomFileDropzone
                            accept={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/bmp']}
                            maxFiles={10}
                            files={values.attachments?.filter((attachment) => {
                              if (values.isRequest) {
                                return attachment.code === 'Request';
                              }
                              return attachment.code === 'Registry';
                            })
                              .map((attachment) => attachment.file)}
                            maxSize={_maxFileSize}
                            onDrop={(files) => handleDrop(values?.attachments, files, setFieldValue, values.isRequest)}
                            onRemove={(file) => handleRemove(values?.attachments, file.fileID, setFieldValue)}
                            onRemoveAll={() => handleRemoveAll(setFieldValue)}
                            onDropRejected={handleDropFailed}
                            hasError={Boolean(touched.attachments && errors.attachments)}
                            preview={false}
                            sx={{
                              "& h1": {
                                fontSize: 12,
                                m: -1
                              }
                            }}
                          />
                        </Grid>
                      }
                      {
                        ((isSubmitted && canUploadEvidences) || (approvalMode && canSeeEvidencesDescription)) && <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            multiline
                            rows={5}
                            error={Boolean(touched.evidenceDescription && errors.evidenceDescription)}
                            fullWidth
                            helperText={touched.evidenceDescription && errors.evidenceDescription}
                            label={t('LJ:evidencesDescription')}
                            name="evidenceDescription"
                            onChange={e => setFieldValue('evidenceDescription', e.target.value)}
                            value={values?.evidenceDescription || ""}
                            disabled={approvalMode}
                            variant="outlined"
                            className="readOnly"
                            sx={{
                              "& textarea": {
                                resize: "vertical"
                              }
                            }}
                          />
                        </Grid>
                      }
                      {
                        approvalMode && (!values.isRequest || values.status === "D") && <Grid
                          item
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%"
                            }}
                          >
                            <TextField
                              error={Boolean(touched.credits && errors.credits)}
                              fullWidth
                              helperText={touched.credits && errors.credits}
                              label={t('LJ:credits')}
                              name="credits"
                              onChange={e => setFieldValue('credits', e.target.value)}
                              value={values.credits}
                              variant="outlined"
                              type="number"
                              disabled={!approvalMode || values?.status === "T"}
                              className="readOnly"
                              sx={{
                                width: 150,
                                ml: "auto"
                              }}
                            />
                          </Box>
                        </Grid>
                      }
                      {
                        errors && <Grid
                          item
                          xs={12}
                        >
                          <Box>
                            <FormHelperText error>
                              {errors.submit}
                            </FormHelperText>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                  </form>
                  <TrainingRequestRejectModal
                    open={rejectReasonOpen !== false}
                    onClose={() => setRejectReasonOpen(false)}
                    confirm={(justification) => {
                      setRejectReasonOpen(false);
                      setFieldValue('submitAction', "REJECT");
                      setFieldValue('justification', justification);
                      handleSubmit();
                    }}
                  />
                </>
              </Box>
              :
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Skeleton variant="rectangular" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Skeleton variant="rectangular" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Skeleton variant="rectangular" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Skeleton variant="rectangular" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Skeleton variant="rectangular" />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
              </Grid>
          }
          customActions={
            values?.status !== "T" &&
            (!approvalMode ? <>
              <Button
                variant="outlined"
                sx={{
                  ml: 2
                }}
                onClick={() => onClose()}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : <CloseIcon />}
              >
                {t('common:buttons.cancel')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  ml: 2
                }}
                onClick={handleSubmit}
                disabled={isSubmitting || values?.status === "P" || values?.status === "T"}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : <SaveIcon />}
              >
                {t('common:buttons.save')}
              </Button>
            </> : values?.status !== "Y" ? <>
              <Button
                variant="outlined"
                sx={{
                  ml: 2
                }}
                onClick={() => handleReject(values)}
                disabled={isSubmitting || values?.status === "R"}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : <CloseIcon />}
              >
                {t('common:buttons.reject')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  ml: 2
                }}
                onClick={() => { setFieldValue('submitAction', "APPROVE"); handleSubmit() }}
                disabled={isSubmitting || values?.status === "R"}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : <CheckIcon />}
              >
                {t('common:buttons.approve')}
              </Button>
            </> : <></>)
          }
        />
      )}
    </Formik>
  )
}

RequestTrainingModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  detail: PropTypes.object,
  formatOptions: PropTypes.array,
  approvalMode: PropTypes.bool,
  refreshData: PropTypes.func,
  trainingTypesOptions: PropTypes.array
};

export default RequestTrainingModal;
