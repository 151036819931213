import { useContext } from 'react';
import Topbar from '../Components/Topbar';
import Sidebar from '../Components/Sidebar';
import { Outlet } from 'react-router-dom';
import { Box, useMediaQuery } from '@material-ui/core';
import './style.scss';
import { FiltersProvider } from '../Contexts/FiltersContext';
import SidebarContext, { SidebarProvider } from '../Contexts/SidebarContext';
import AnimBG from '../../../components/animBG';

const OutletComponent = () => {
    const { sidebarToggled } = useContext(SidebarContext);
    const isMobile = useMediaQuery('(max-width:998px)');
    const navHeight = useMediaQuery('(max-width:600px)') ? 56 : 64;
    const drawerWidth = 400;

    return <Box
        sx={{
            overflowY: "auto",
            overflowX: "hidden",
            width: `calc(100% - ${!isMobile && sidebarToggled ? drawerWidth : 0}px)`,
            height: `calc(100vh - ${navHeight}px)`,
            ml: `${!isMobile && sidebarToggled ? drawerWidth : 0}px`,
            transition: "225ms cubic-bezier(0, 0, 0.2, 1) 0ms"
        }}
    >
        <Outlet />
    </Box>
}

const LJDashboardLayout = () => {
    const isMobile = useMediaQuery('(max-width:998px)');
    const navHeight = useMediaQuery('(max-width:600px)') ? 56 : 64;
    const drawerWidth = 400;
    return (
        <SidebarProvider>
            <Box>
                <Topbar />
                <FiltersProvider>
                    <Box>
                        <Sidebar
                            navHeight={navHeight}
                            drawerWidth={drawerWidth}
                            isMobile={isMobile}
                        />
                        <OutletComponent />
                        <AnimBG />
                    </Box>
                </FiltersProvider>
            </Box>
        </SidebarProvider>
    )
};

export default LJDashboardLayout;
