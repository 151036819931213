import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import useAuth from '../../hooks/useCustomAuth';
import UserIcon from '../../icons/User';
import AuthGuard from '../AuthGuard';
import * as userPermissions from '../../consts/userRoles';
import PropTypes from 'prop-types';

const AccountPopover = ({ sx = {}, showMenu = true }) => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <Box
      sx={{
        ...sx
      }}
    >
      <Box
        component={showMenu ? ButtonBase : null}
        onClick={handleOpen}
        className="profileItem"
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar
          src={user?.avatar}
          sx={{
            height: 40,
            width: 40
          }}
        />
        <Typography
          sx={{
            ml: 1
          }}
          color={process.env.REACT_APP_THEME_CUSTOM_COLOR || "textPrimary"}
          variant="subtitle2"
        >
          {user?.name}
        </Typography>
      </Box>
      {
        showMenu && <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          keepMounted
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: { width: 240 }
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {user?.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              {user?.jobRole}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ mt: 2 }}>
            <AuthGuard roles={userPermissions.PORTAL_PROFILE} componentPermission>
              <MenuItem
                component={RouterLink}
                to="/dashboard/profile/myProfile"
              >
                <ListItemIcon>
                  <UserIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Perfil
                    </Typography>
                  )}
                />
              </MenuItem>
            </AuthGuard>
            {/* <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              )}
            />
          </MenuItem> */}
          </Box>
          {process.env.REACT_APP_CUSTOM_AUTH === 'LDAP' && (
            <Box sx={{ p: 2 }}>
              <Button
                color="primary"
                fullWidth
                onClick={handleLogout}
                variant="outlined"
              >
                Sair
              </Button>
            </Box>
          )}
        </Popover>
      }
    </Box>
  );
};

AccountPopover.propTypes = {
  sx: PropTypes.object,
  showMenu: PropTypes.bool
}

export default AccountPopover;
